<template>
  <div class="container">
    
    <el-row class="justify-content-md-center">
       <el-col cols="12" md="auto">
         <el-card >
      <h3>Reset your password</h3>
      <div v-if="resetted">
        <el-button type="button" style="margin-right:10px" @click="resetAgain()">Update again ?</el-button>
        <el-button type="button" @click="goToProfile()">Back to profile</el-button>
      </div>
      <el-form label-position="top" @submit.prevent="onSubmit" v-if="!resetted">
        <el-form-item id="name" label="Name" label-for="input-name" 
              >
                <el-input id="input-name" disabled v-model="form.name" :state="validation('name')" trim size="large"></el-input>
              </el-form-item>

        <el-form-item
                    label="Email address:"
                >
                    <el-input
                    id="input-1"
                    type="email"
                    v-model="form.email"
                    disabled="true"
                    placeholder="Enter email"
                    required
                    size="large"
                    validate-event="true"
                    ></el-input>
                </el-form-item>


        <el-form-item id="current_password" label="Current Password" label-for="input-current-password"
        validate-event="true"
        >
          <el-input size="large" type="password" id="input-current-password" 
          v-model="form.current_password" 
          trim></el-input>

          <div class="input-errors" v-for="error of v$.form.current_password.$errors" :key="error.$uid">
            <div class="error-text">{{ error.$message }}</div>
          </div>
        </el-form-item>

        <el-form-item id="new_password" label="New Password" label-for="input-new-password" 
        >
          <el-input size="large" type="password" id="input-new-password" v-model="form.new_password" 
          trim></el-input>
        </el-form-item>

        <div class="row input-errors" v-for="error of v$.form.new_password.$errors" :key="error.$uid">
            <div class="col-md-12 error-text">{{ error.$message }}</div>
        </div>
        <br v-if="v$.form.new_password.$errors.length > 0">

        <el-form-item id="confirm_new_password" label="Confirm New Password" label-for="input-confirm-new-password" 
        >
          <el-input size="large" type="password" id="input-confirm_new_password" 
          v-model="form.confirm_new_password" 
          trim></el-input>
        </el-form-item>

        <div class="row input-errors" v-for="error of v$.form.confirm_new_password.$errors" :key="error.$uid">
            <div class="col-md-12 error-text">{{ error.$message }}</div>
          </div>
        <br v-if="v$.form.new_password.$errors.length > 0">
     
        <el-button native-type="submit" type="primary">Submit</el-button>
      </el-form>
    </el-card>
       </el-col>
    </el-row>


    
  </div>
</template>

<script>
import { User } from '@/api_services/user'
import store from '@/store/index'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, email, minLength, maxLength, sameAs } from '@vuelidate/validators'

  export default {
    setup () {
      return { v$: useVuelidate({scope: false}) }
    },
    data() {
      return {
        validate: ['name', 'email', 'current_password', 'new_password','confirm_new_password'],
        form: {
          name: null,
          email: null,
          current_password: null,
          new_password: null,
          confirm_new_password: null
        },
        resetted: false
      }
    },
    validations () {
      return {
        form:{
        current_password: { required, },
        new_password: {
          required,
          minLength: minLength(8),
          containsPasswordRequirement: helpers.withMessage(
            () => `The password requires an uppercase, lowercase, and number`, 
            (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value)
          ),
        },
        confirm_new_password: { required, sameAsPassword: sameAs(this.form.new_password) }
        }
      }
    },
    created: function() {
     this.getUser()
    },
    methods: {
      getUser() {
          if (this.$store.state.savedUser) {
            console.log(this.$store.state.savedUser)
            this.form.email = this.$store.state.savedUser.email
            this.form.name = this.$store.state.savedUser.name
          }
      },
       isEmailValid(string) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(string).toLowerCase());
      },
      validation(field) {
        switch(field) {
          case 'current_password':
            if (!this.form.current_password) this.form.current_password = ""
            return this.form.current_password.length > 0
          case 'email':
            if (!this.form.email) this.form.email = ""
            return this.form.email.length > 0 && this.isEmailValid(this.form.email)
          case 'name':
              if (!this.form.name) this.form.name = ""
            return this.form.name.length > 0
          case 'new_password':
              if (!this.form.new_password) this.form.new_password = ""
            return this.form.new_password.length > 8
          case 'confirm_new_password':
            if (!this.form.confirm_new_password) this.form.confirm_new_password = ""
            return this.form.confirm_new_password === this.form.new_password
          default:
            return true;
        }
      },
      invalidFeedback(field) {
        if (!this.validation(field) && field == 'email') {
          if (!this.isEmailValid(this.form.email)) return "Email format is invalid"
          return "Email is required"
        }
        if (!this.validation(field) && field == 'name') return "Name is required"
        if (!this.validation(field) && field == 'current_password') return "Current password is required"
        if (!this.validation(field) && field == 'new_password') return "New password must be at leasts 8 characters"
        if (!this.validation(field) && field == 'confirm_new_password') return "Confirm password must match the new password"
        return ""
      },
      resetAgain() {
        this.resetted = false
        this.form = {
          current_password: null,
          new_password: null,
          confirm_new_password: null
        }
        this.getUser()
      },
      async onSubmit(evt) {
        evt.preventDefault()

        const result = await this.v$.$validate()
        if (!result) return

        User.updatePassword(this.form).then(result => {
          if (result.data.message) this.$toast.success(result.data.message)
          else this.$toast.success('Saved successfully')
          this.resetted = true
          if (result.data.user)  store.commit('saveUser',result.data.user);
          this.getUser()
          console.log(this.form)
        })
        .catch(error => {
          console.error('error:', error.response.data)
        })
      },
      goToProfile() {
        this.$router.push({name: 'profile'})
      }
    },
    computed: {
      validateAll() {
        var self = this
        var response = true
        this.validate.forEach(function(field){
          if (!self.validation(field)) response = false;
        })

        return response;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>