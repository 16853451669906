<template>
<div style="text-align: center;margin-top: 50px;background-color: #f5f5f5;">
  <el-result icon="error" title="Forbidden">
    <template #sub-title>
      <p> You are not allowed to access this Page
      </p>
    </template>
    <template #extra>
      <el-link type="primary" href="/">Home</el-link>
    </template>
  </el-result>
</div>
</template>

<script>
export default {
  name: 'NotAllowedComponent',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
