import api from './api'

const API = '/api/companies'

export const Company = {
  get: function(params) {
    return api.get(API, params) 
  },
  create: function(params) {
    return api.create(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  update: function(id, params) {
    return api.update(API+'/'+id, params)
  },
  updateCompany: function(params) {
    return api.create(API+'/company', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  exportCompanies: function(type, params) {
    return api.get(API+"/export_companies/" + type, params)
  },
  toggleCompanyZeroSavings: function(id, params) {
    return api.update(API+'/toggleCompanyZeroSavings/'+id, params)
  },
}
