import api from './api'
import { Supplier } from './suppliers'

const API = '/transaction_types'

export const TransactionType = {
  getFullPath(params) {
    return Supplier.getApi()+'/'+params.supplier_id+API
  },
  get: function(params) {
    return api.get(TransactionType.getFullPath(params), params)
  },
  create: function(params) {
    return api.create(TransactionType.getFullPath(params), params)
  },
  update: function(params) {
    return api.update(TransactionType.getFullPath(params)+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(TransactionType.getFullPath(params)+'/'+params.id, params)
  }
}
