<template>
  <div>
    <h5>Price Cost List</h5>
    <el-button size="small" plain type="primary" @click="handleShowForm">ADD</el-button>

    <el-table class="table-striped" header-row-class-name="text-primary" :data="costs" style="width: 100%">
      <el-table-column :min-width="50" class-name="td-actions" label="Band">
        <template v-slot="props">
          {{ props.row.band }}
        </template>
      </el-table-column>

      <el-table-column :min-width="50" class-name="td-actions"
        :label="pin_price_setting?.transaction_type == 'volume' ? 'Transaction Volume':'Transaction Value'">
        <template v-slot="props">
          {{ props.row.transaction_value }}
        </template>
      </el-table-column>

      <el-table-column :min-width="50" class-name="td-actions" label="Total Usage Cost">
        <template v-slot="props">
          {{ $helpers.toCurrency(props.row.total_usage_cost) }}
        </template>
      </el-table-column>

      <el-table-column :min-width="50" class-name="td-actions" label="Total Setup Cost">
        <template v-slot="props">
          {{ $helpers.toCurrency(props.row.total_setup_cost) }}
        </template>
      </el-table-column>

      <el-table-column :min-width="50" class-name="td-actions" label="Status">
        <template v-slot="props">

          <el-tag type="success" v-if="props.row.status == 1" effect="dark">Approved</el-tag>

          <el-tag type="info" v-if="props.row.status == 0" effect="light">Pending</el-tag>

          <el-tag type="danger" v-if="props.row.status == 2" effect="dark">Rejected</el-tag>

        </template>
      </el-table-column>

      <el-table-column :min-width="50" class-name="td-actions" label="Action">
        <template v-slot="props">
          <el-button-group>
            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Edit</span>
              </template>
              <el-button size="small" plain @click="handleEdit(props.row)"> <i class="fa fa-pencil"></i></el-button>
            </el-tooltip>
            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Delete</span>
              </template>
              <el-button size="small" plain @click="handleDelete(props.row)" type="danger"> <i
                  class="fa fa-times"></i></el-button>
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>


    </el-table>

    <el-drawer v-model="showForm" :show-close="true" size="70%" direction="rtl" :destroy-on-close="true"
      :append-to-body="true" :before-close="getCosts">
      <div class="container">
        <pin-supplier-cost-form @close="saveCost($event)" :pin_price_setting="pin_price_setting"
          :lot="lot" :cost="selected" v-if="showForm"></pin-supplier-cost-form>
      </div>
    </el-drawer>

  </div>
</template>

<script>

import {
  PinPriceCost
} from '@/api_services/pin_price_cost'
import PinSupplierCostForm from './PinSupplierCostForm.vue'

export default {
  components: { PinSupplierCostForm },
  props: {
    pin_notice: {
      type: Object,
      default: null
    },
    lot: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pin_price_setting: null,
      costs: [],
      loading: false,
      showForm: false,
      selected: null
    }
  },
  watch: {
   
  },
  created: function () {
    if (this.lot) this.getCosts()
  },
  methods: {
    handleDelete(cost) {
      this.loading = true
      PinPriceCost.delete(cost.id)
        .then(result => {
          this.$toast.success("Deleted")
          this.getCosts()
        })
        .catch(error => {

        })
        .finally(() => {
        })
    },
    handleEdit(cost) {
      this.selected = Object.assign({}, cost)
      this.showForm = true
    },
    getCosts() {
      this.loading = true
      this.costs = [] 
      this.showForm = false
      PinPriceCost.get({
        params: {
          supplierhub_lot_id: this.lot.id,
          for_participant: true
        }
      })
        .then(result => {
          this.pin_price_setting = result.data.pin_price_setting
          if (result.data.pin_price_costs) this.costs = result.data.pin_price_costs
        })
        .catch(error => {

        })
        .finally(() => {
          this.loading = false
        })
    },
    handleShowForm() {
      this.showForm = true
      this.selected = null
    },
    saveCost(cost) {
      this.getCosts()
    }
  }
}
</script>

<style></style>
