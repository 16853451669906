
<template>
    <ElementToggle 
    :value="zero_savings" 
    :label="'Activate Zero Savings?'" 
    @toggle="toggleCompanyZeroSavings">
    </ElementToggle>
    
  </template>
  
  <script>
  import ElementToggle from '@/components/common/ElementToggle.vue'
  import { Company } from '@/api_services/company'

  export default {
    components: {
        ElementToggle
    },
    props: ['value', 'type'],
    data() {
      return {
        zero_savings_params: {
            type: null,
            value: 0
        },
        zero_savings: null,
      }
    },
    created(){
        this.zero_savings = this.value
        this.zero_savings_params.type = this.type
    },
    methods: {
        toggleCompanyZeroSavings(value){
            let user = this.$store.state.savedUser
            let toggle_value = Number(value)

            this.zero_savings_params.value = toggle_value
            Company.toggleCompanyZeroSavings(
                user.company.id,
                this.zero_savings_params
            )
        .then(result => {
            if (result.data.message) {
                this.$toast.success(result.data.message || 'Successfully updated')
                this.zero_savings = toggle_value

                if(user){
                    if(this.zero_savings_params.type =="banking"){
                        user.company.banking_activate_zero_savings = this.zero_savings
                    }
                    else if(this.zero_savings_params.type =="merchant"){
                        user.company.merchant_activate_zero_savings = this.zero_savings
                    }
                    this.$store.commit('saveUser',user);
                }
            }
            })
            .catch(error => {
            console.error('error:', error.response.data)
            })
            .finally(() => {
            this.isLoading = false
            })
        },
    },
    watch: {
        value: {
            handler(newValue) {
                this.zero_savings = newValue
            },
            deep: true
        },
        type: {
            handler(newValue) {
                this.zero_savings_params.type = newValue
            },
            deep: true
        }
    }
  }
  </script>