<template>
  <div>
    <div v-if="invoice">
       <b-form @submit="onSubmit">
         <div class="row">
           <div class="col-md-6">
             <div class="row">
               <div class="col-md-6">
                   <b-form-group id="contact_person" label="Contact Person" label-for="input-contact_person" valid-feedback=""
                  :invalid-feedback="invalidFeedback('contact_person')"
                  :state="validation('contact_person')"
                >
                  <b-form-input id="input-contact_person" v-model="invoice.contact_person" :state="validation('contact_person')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>
             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="council_name" label="Council Name" label-for="input-council_name" valid-feedback=""
                  :invalid-feedback="invalidFeedback('council_name')"
                  :state="validation('council_name')"
                >
                  <b-form-input id="input-council_name" v-model="invoice.council_name" :state="validation('council_name')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>

             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="dba_name" label="DBA" label-for="input-coudba_namencil_name" valid-feedback=""
                  :invalid-feedback="invalidFeedback('dba_name')"
                  :state="validation('dba_name')"
                >
                  <b-form-input id="input-dba_name" v-model="invoice.dba_name" :state="validation('dba_name')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>

             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="address1" label="Address (House no./Street)" label-for="input-address1" valid-feedback=""
                  :invalid-feedback="invalidFeedback('address1')"
                  :state="validation('address1')"
                >
                  <b-form-input id="input-address1" v-model="invoice.address1" :state="validation('address1')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>

             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="city" label="City" label-for="input-city" valid-feedback=""
                  :invalid-feedback="invalidFeedback('city')"
                  :state="validation('city')"
                >
                  <b-form-input id="input-city" v-model="invoice.city" :state="validation('city')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>
             
             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="state" label="State" label-for="input-state" valid-feedback=""
                  :invalid-feedback="invalidFeedback('state')"
                  :state="validation('state')"
                >
                  <b-form-input id="input-state" v-model="invoice.state" :state="validation('state')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>

             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="zip" label="Zip" label-for="input-zip" valid-feedback=""
                  :invalid-feedback="invalidFeedback('zip')"
                  :state="validation('zip')"
                >
                  <b-form-input id="input-zip" v-model="invoice.zip" :state="validation('zip')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>
             
           </div>
           <div class="col-md-6">
             <div class="row">
               <div class="col-md-8">
             
                 <b-form-group id="number" label="Invoice Number" label-for="input-number" valid-feedback=""
                  :invalid-feedback="invalidFeedback('number')"
                  :state="validation('number')"
                >
                  <b-form-input id="input-number" v-model="invoice.number" :state="validation('number')" trim></b-form-input>
                  <span style="    font-size: 13px;">(Will auto generate if leave as blank)</span>
                </b-form-group>
               </div>
             </div>

              <div class="row">
               <div class="col-md-6">
                 <b-form-group id="date" label="Invoice Date" label-for="input-date" valid-feedback=""
                  :invalid-feedback="invalidFeedback('date')"
                  :state="validation('date')"
                >
                  <b-form-input id="input-date" v-model="invoice.date" :state="validation('date')" trim type="date"></b-form-input>
                </b-form-group>
               </div>
             </div>

              <div class="row">
               <div class="col-md-6">
                 <b-form-group id="due_date" label="Due Date" label-for="input-due_date" valid-feedback=""
                  :invalid-feedback="invalidFeedback('due_date')"
                  :state="validation('due_date')"
                >
                  <b-form-input id="input-due_date" v-model="invoice.due_date" :state="validation('due_date')" trim type="date" disabled></b-form-input>
                </b-form-group>
               </div>
             </div>

             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="account" label="Account" label-for="input-account" valid-feedback=""
                  :invalid-feedback="invalidFeedback('account')"
                  :state="validation('account')"
                >
                  <b-form-input id="input-account" v-model="invoice.account" :state="validation('account')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>

             <div class="row">
               <div class="col-md-6">
                 <b-form-group id="purchase_order" label="Purchase Order" label-for="input-purchase_order" valid-feedback=""
                  :invalid-feedback="invalidFeedback('purchase_order')"
                  :state="validation('purchase_order')"
                >
                  <b-form-input id="input-purchase_order" v-model="invoice.purchase_order" :state="validation('purchase_order')" trim></b-form-input>
                </b-form-group>
               </div>
             </div>

           </div>
         </div>

          <hr>

          <table class="table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr  v-for="item in invoice.invoice_items" :key="item.id">
                <td style="width: 80%">
                    <b-form-input  v-model="item.description" trim :disabled="item.editable == 0"></b-form-input>
                  </td>
                  <td>
                    <b-form-input  v-model="item.saving" type="number" step="0.01" :disabled="item.editable == 0"></b-form-input>
                  </td>
              </tr>
              <!-- <tr>
                <td style="width: 80%">
                    Management charge
                  </td>
                  <td>
                    <b-form-input  v-model="item.amount" type="number" step="0.01" :disabled="item.editable == 0"></b-form-input>
                  </td>
              </tr> -->
              <tr>
                <td colspan="2">
                  <b-button variant="info" type="button" size="sm" @click="addItem()">Add</b-button>
                </td>
              </tr>
            </tbody>
          </table>

           <div style="text-align: right; margin: 10px">
              <b-button variant="info" type="submit"  size="sm">Save</b-button>
              <b-button variant="secondary" type="button" size="sm" @click="close()" style="margin-left: 10px">Close</b-button>
            </div>
        </b-form>
    </div>
  </div>
</template>

<script>
import { Invoice } from '@/api_services/invoice'
import * as moment from 'moment';

export default {
  props: ['cost_data_report'],
  data() {
    return {
       show: true,
       invoice: null
    }
  },
  created: function() {
    if (this.cost_data_report) this.invoice = this.cost_data_report.invoice

    if (this.invoice.id) {
      this.showInvoice()
    } else {
      this.setDate()
    }
    
  },
  watch: {
    invoice: {
      handler(newValue, oldValue) { 
        this.invoice.due_date = moment(this.invoice.date).add(28, 'days').format('YYYY-MM-DD')
       },
      deep: true
    }
  },
  methods: {
    setDate() {
      if (this.invoice && !this.invoice.date) {
        this.invoice.date = moment().format('YYYY-MM-DD')
        this.invoice.due_date = moment(this.invoice.date).add(28, 'days').format('YYYY-MM-DD')
      }
    },
    addItem() {
      this.invoice.invoice_items.push({
        description: null,
        amount: null,
        editable: true
      })
    },
    close(invoice) {
      this.$emit('update', invoice);
    },
    showInvoice() {
      
      Invoice.show(this.invoice.id)
        .then(result => {
          this.invoice = result.data.invoice
          this.setDate()
        })
        .catch( error => {
          this.$toast.error('An error occured while showing invoice')
        })
    },
    onSubmit(event) {
      event.preventDefault()
      
      Invoice.update(this.invoice)
        .then(result => {
          this.$toast.success('Invoice updated successfully')
          this.close(this.invoice)
        })
        .catch( error => {
          this.$toast.error('An error occured while updating invoice')
        })
    },
    validation(field) {
        switch(field) {
          case 'account':
            if (!this.invoice.account) this.invoice.account = ""
            return this.invoice.account.length > 0
          default:
            return true;
        }
      },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'first_name') return "First name is required"
      if (!this.validation(field) && field == 'last_name') return "Last name is required"
      if (!this.validation(field) && field == 'street_address') return "Street address is required"
      if (!this.validation(field) && field == 'city') return "City is required"
      return ""
    },
  }
}
</script>

<style scoped>

</style>