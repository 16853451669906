<template>
  <el-form label-position="top" @submit.prevent="submit" v-loading="loading">
    <h5>Project {{ pin_notice.name }}</h5>
    <h5>Weightings</h5>
    <el-form-item label="Specifications" prop="specifications">
      <el-input v-model="form.specifications" type="number"></el-input>
    </el-form-item>

    <el-form-item label="Social Values" prop="social_values">
      <el-input v-model="form.social_values" type="number"></el-input>
    </el-form-item>

    <el-form-item label="Pricing" prop="pricing">
      <el-input v-model="form.pricing" type="number"></el-input>
    </el-form-item>

    <el-form-item label="Management Info" prop="management_info">
      <el-input v-model="form.management_info" type="number"></el-input>
    </el-form-item>

    <hr>

    <el-form-item label="Ranking" prop="list">
      <el-select v-model="form.list">
        <el-option :value="0" label="All Participants"></el-option>
        <el-option :value="1" label="Top 5 Participants"></el-option>
        <el-option :value="2" label="Top 10 Participants"></el-option>
        <el-option :value="3" label="Top 20 Participants"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Awardees Limit" prop="management_info">
      <el-select v-model="form.award_limit">
        <el-option :value="item" :label="item" v-for="item,i in awardList" :key="i"></el-option>
      </el-select> </el-form-item>


    <el-button native-type="submit" type="primary" plain>Save</el-button>
    <el-button plain @click="handleClose">Close</el-button>
  </el-form>
</template>

<script>
import { PinNotices } from '@/api_services/pin_notices'

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

export default {
  props: {
    weighting: {
      type: Object,
      default: null
    },
    pin_notice: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      form: {
  
      },
      loading: false,
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
      }
    }
  },
  computed: {
    awardList() {
      if (parseInt(this.form.list) == 0 || parseInt(this.form.list) == 3) {
        return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
      }

      if (parseInt(this.form.list) == 1) {
        return [1, 2, 3, 4, 5]
      }

      if (parseInt(this.form.list) == 2) {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      }

      return []
    }
  },
  watch: {
    weighting: function () {
      if (this.weighting) this.form = Object.assign({}, this.weighting)
      this.form.list = this.pin_notice?.pin_product_ranking?.list || 0
      this.form.award_limit = this.pin_notice?.pin_product_ranking?.award_limit || 0
    },
    form: {
      handler(newValue) {
       
      },
      deep: true
    },
  },
  created: function () {
    if (this.weighting) this.form = Object.assign({}, this.weighting)
    this.form.list = this.pin_notice?.pin_product_ranking?.list || 0
    this.form.award_limit = this.pin_notice?.pin_product_ranking?.award_limit || 0
  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return
      let total = parseFloat(this.form.specifications) + parseFloat(this.form.social_values) + parseFloat(this.form.pricing) + parseFloat(this.form.management_info)
      console.log(total)
      if (total != 100) {
        this.$toast.error('Total weight must be 100')
        return
      }

      this.loading = true

      PinNotices.saveWeightings(this.form.pin_notice_id, this.form)
        .then(result => {
          this.$toast.success('Saved')
        })
        .catch(error => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
