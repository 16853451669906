<template>
<!-- <div class="container"> -->
<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-header p-0 mx-3 mt-3 position-relative z-index-1">
        <form @submit.prevent="search()">
          <p class="card-title h5 d-block text-darker">
            Please select category
          </p>
          <div class="row px-0 my-1">
            <div class="col-sm-12 text-sm p-1" v-for="category in categories" :key="category.id">
              <div class="d-grid">
                <button type="button" :class="
                      form.category_id != category.id
                        ? 'btn btn-outline-dark btn-sm m-0 border-1 text-center text-dark'
                        : 'btn bg-dark btn-sm m-0 border-1 text-center text-white'
                    " @click="selectCategory(category)" style="text-align:left;height: 35px">
                  {{ category.name }} <span v-if="category.itt_framework">({{category.itt_framework.name}})</span>
                </button>
              </div>
            </div>
          </div>
        </form>

        <div v-if="
             (form.category_id &&
            products_count &&
            form.step > 0 &&
            !form.first_search &&
            form.back) || form.back
            ">
          <hr />

          <div class="table-responsive" v-if="form.specs?.length">
            <div style="text-align:left;margin-bottom: 10px">
              <h5>Features/Requirements/Benefits</h5>
            </div>
            <table class="table align-items-center">
              <thead>
                <tr>
                  <td></td>

                  <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="    width: 200px;">
                    Mandatory
                  </td>
                  <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="width: 100px;">
                    <div>Importance</div>
                    <div>(Out of 10)</div>
                  </td>
                  <!-- <td style="    width: 50px;"></td> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(spec, i) in form.specs" :key="spec.id">
                  <td class="text-sm text-wrap">

                    <text-with-show-more :string="
                          findOneGlobalSpecOriginalDescription(spec.description)
                        " :length="200"></text-with-show-more>
                  </td>
                  <td>
                    <div class="form-group">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input-sm" v-model="spec.required" :value="true" :unchecked-value="false" @input="updateSpec(i)" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <select class="form-select form-select-sm" v-model="spec.level_of_importance">
                        <option v-for="imp in importance" :key="imp">{{
                            imp
                          }}</option>
                      </select>
                    </div>
                  </td>

                </tr>
              </tbody>
            </table>

            <div class="container" style="text-align:center" v-if="form.subspecs?.length">
              <hr />
              <button type="button" class="btn btn-outline-info" @click="showSubSpecsModal()">
                Sub-Specifications
              </button>
            </div>
          </div>
          <div v-if="form.social_values?.length">
            <search-products-social-values :selected="form"></search-products-social-values>
          </div>
        </div>
      </div>

      <div class="container" style="text-align:left" v-if="form.step > 0 && form.category_id && !form.first_search">
        <form>
          <div class="row" v-if="
                form.category_id &&
                  products_count &&
                  form.step > 0 &&
                  !form.first_search &&
                  form.back
              ">
            <hr />

            <h5>Pricing Weighting</h5>
            <div class="col-md-12">
              <div class="form-group">
                <label for="Type">Ongoing charges weighting (Should total to 100% with other
                  charges)</label>
                <input type="number" class="form-control form-control-sm" v-model="form.pricing_cost_weighting" />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="Type">Other charges weighting (Should total to 100% with Ongoing
                  charges pricing)</label>
                <input type="number" class="form-control form-control-sm" v-model="form.additional_cost_weighting" />
              </div>
            </div>
          </div>
          <div class="row">
            <hr />
            <h5>Contract length</h5>
            <div class="col-md-12">
              <div class="form-group">
                <label for="contract_length">Prefered contract period (in Years)</label>
                <input type="number" class="form-control form-control-sm" id="contract_length" placeholder="length in years" v-model="form.contract_length" />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="container">
        <el-alert type="error" style="margin-bottom: 10px" v-if="!isPricingWeight100">Pricing/Cost plus Additional Cost Weighting should total to
          100%</el-alert>
        <button type="button" class="btn bg-gradient-info w-100 btn-lg" @click="onSearch()" :disabled="!form.category_id || !isPricingWeight100"
         v-if="params.page == 1">
          Search
        </button>
      </div>

      <modal v-model="subspecsModal" title="Sub-Specifications" size="lg" centered>
        <search-panel-sub-specs-component :form_specs="form.subspecs" @update="setSubSpecs($event)"></search-panel-sub-specs-component>
      </modal>
    </div>
  </div>
</div>
</template>

<script>
import Modal from '@/components/common/Modal'
import SearchPanelSubSpecsComponent from './SearchPanelSubSpecsComponent.vue'
import SearchProductsSocialValues from './SearchProductsSocialValues.vue'
import {
  Category
} from '@/api_services/category'

export default {
  data() {
    return {
      subspecsModal: null,
      total_weigh: 100,
      step: 1,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      term: null,
      form: {
        term: null,
        category_id: null,
        specs: [],
        charges: [],
        subspecs: [],
        social_values: [],
        volume: 0,
        volume_type: 0,
        cost_per_unit: 0,
        first_search: true,
        back: 0,
        contract_length: null,
        pricing_cost_weighting: 80,
        additional_cost_weighting: 20
      },
      searchClick: 0,
      selected: null,
      user_quantity: 0,
      importance: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      category_changed: false,
      category_type: null,
      global_specifications: []
    }
  },
  props: ['products_count', 'params', 'categories', 'products'],
  components: {
    Modal,
    SearchPanelSubSpecsComponent,
    SearchProductsSocialValues,
  },
  created: function () {
    if (this.params) {
      this.form = Object.assign({}, this.params)
      if (!this.form.subspecs) this.form.subspecs = []
      if (!this.form.charges) this.form.charges = []
      if (!this.form.social_values) this.form.social_values = []
      if (!this.form.volume_type) this.form.volume_type = 0
      if (!this.form.pricing_cost_weighting)
        this.form.pricing_cost_weighting = 80
      if (!this.form.additional_cost_weighting)
        this.form.additional_cost_weighting = 20
      // this.next()
      this.getGlobalSpescOriginalDescriptions()
    }
  },
  watch: {
    categories: function () {
      for (var x = 0; x <= this.categories.length - 1; x++) {
        if (this.form.category_id && this.categories[x].id == this.form.category_id) {
          this.selectCategory(this.categories[x])
        }
      }
    },
    params: {
      handler(oldVal, newVal) {
        this.form = Object.assign({}, this.params)
        if (!this.form.pricing_cost_weighting)
          this.form.pricing_cost_weighting = 80
        if (!this.form.additional_cost_weighting)
          this.form.additional_cost_weighting = 20
        this.getGlobalSpescOriginalDescriptions()
      },
      deep: true
    },
    'form.specs': {
      handler(oldVal, newVal) {
        if (!newVal) return false
        for (let i = 0; i < newVal.length; i++) {
          if (newVal[i].required && newVal[i].weighing !== 10) {
            newVal[i].weighing = 10
          } else if (
            !newVal[i].required &&
            newVal[i].weighing === 10 &&
            oldVal[i].required
          ) {
            newVal[i].weighing = oldVal[i].weighing
          }
        }
      },
      deep: true
    }
  },
  computed: {
    total: function () {
      if (this.selected) {
        return (
          this.selected.prices *
          (this.user_quantity / this.selected.quantity)
        ).toFixed(4)
      }
      return 0
    },
    filteredVolumeTypes: function () {
      return this.$store.state.option_volume_types.filter(
        (obj, i) => obj.value != null
      )
    },
    isPricingWeight100: function () {
      return (
        parseFloat(this.form.additional_cost_weighting) +
        parseFloat(this.form.pricing_cost_weighting) ==
        100
      )
    }
  },
  methods: {
    getDefaultVolumeType(categoryName) {
      switch (categoryName) {
        case 'Income Management System':
        case 'Merchant Services':
        case 'Banking':
        case 'IVR & Call Masking':
        case 'Open Banking Payments':
        case 'Open Banking Data Services':
        case 'Procurement Card Services':
          return 3
        default:
          return 0
      }
    },
    getDefaultCostPerUnit(categoryName) {
      switch (categoryName) {
        case 'Income Management System':
        case 'Merchant Services':
        case 'Banking':
        case 'IVR & Call Masking':
        case 'Open Banking Payments':
        case 'Open Banking Data Services':
        case 'Procurement Card Services':
          return 7000
        default:
          return 0
      }
    },
    getDefaultVolume(categoryName) {
      switch (categoryName) {
        case 'Income Management System':
        case 'Merchant Services':
        case 'Banking':
        case 'IVR & Call Masking':
        case 'Open Banking Payments':
        case 'Open Banking Data Services':
        case 'Procurement Card Services':
          return 100000
        default:
          return 0
      }
    },
    getDefaultContractLength(categoryName) {
      switch (categoryName) {
        case 'Income Management System':
        case 'Merchant Services':
        case 'Banking':
        case 'IVR & Call Masking':
        case 'Open Banking Payments':
        case 'Open Banking Data Services':
        case 'Procurement Card Services':
          return 10
        default:
          return 0
      }
    },
    setSubSpecs(subspecs) {
      this.form.subspecs = subspecs
    },
    onSearch() {
      if (this.searchClick > 0 || this.form.back) this.form.step = 2
      this.searchClick++

      if (this.form.category_id && this.form.back && this.form.page > 1) this.form.back = false
      this.$emit('search', this.form)
    },
    updateSpec(i) {},
    selectCategory(category) {
      this.form.category_id = category.id
      this.form.specs = []
      this.form.subspecs = []
      this.category_changed = true
      this.form.step = 0
      this.form.page = 1
      this.form.first_search = true
      this.form.volume_type = this.getDefaultVolumeType(category.name)
      this.form.cost_per_unit = this.getDefaultCostPerUnit(category.name)
      this.form.volume = this.getDefaultVolume(category.name)
      this.form.contract_length = this.getDefaultContractLength(category.name)
      this.form.back = false
      this.form.social_values = []
      this.category_type = category.type
      this.searchClick++
      this.form.submit = false
      this.next()
    },
    adjustWeigh() {
      let total = 0
      let self = this
      this.form.specs.forEach(function (spec, i) {
        total += spec.weighing
        if (total >= 100) {
          total -= spec.weighing
          spec.weighing = 100 - total
          self.form.specs[i].weighing = Math.round(spec.weighing * 100) / 100
          total += self.form.specs[i].weighing
        }
      })

      let array = this.form.specs
      this.form.specs = []
      setTimeout(function () {
        self.form.specs = array
        self.total_weigh = self.totalWeighing()
      }, 100)
    },
    totalWeighing: function () {
      let total = 0
      this.form.specs.forEach(function (spec) {
        total += spec.weighing
      })
      if (total == 99.99) total = 100
      return Math.round(total * 100) / 100
    },
    updateSpecWeigh(i, updatedWeigh) {
      this.form.specs[i] = updatedWeigh
      this.total_weigh = this.totalWeighing()
    },
    next() {
      this.$emit('search', this.form)
    },
    findOneGlobalSpecOriginalDescription(description) {
      let text = null
      this.global_specifications.forEach(function (spec) {
        if (spec.description.toLowerCase() == description.toLowerCase()) text = spec.original_description
      })

      return text
    },
    getGlobalSpescOriginalDescriptions() {
      if (!this.form.specs || !this.form.category_id) return false
      let descs = []

      this.form.specs.forEach(function (spec) {
        descs.push(spec.description)
      })

      this.global_specifications = []
      Category.specifications({
          id: this.form.category_id,
          per_page: 999,
          descriptions: descs
        })
        .then(result => {
          let self = this
          result.data.specifications.forEach(function (spec) {
            self.global_specifications.push(spec)
          })
        })
        .catch(error => {})
    },
    getConditionLabel(spec) {
      switch (spec.condition) {
        case '<=':
          return ' less or exact match to '
        case '>=':
          return ' greater or exact match to '
        case '=':
          return ' exact match to '
      }
    },
    onDelete(index) {
      if (index != null) {
        this.form.specs.splice(index, 1)
      }
    },
    showAddModal() {
      this.selected = {}
      this.$bvModal.show('modal-spec')
    },
    showSubSpecsModal() {
      this.subspecsModal.show()
    },
    closeModal() {
      this.$bvModal.hide('modal-spec')
    },
    onAddSpec(object) {
      if (object) {
        this.form.specs.push(object)
        let average = Math.round((100 / this.form.specs.length) * 100) / 100
        this.form.specs.forEach(function (spec) {
          spec.weighing = parseFloat(average)
        })

        this.adjustWeigh()
      }
      this.closeModal()
    },
    getCategories() {
      Category.get({
          params: {}
        })
        .then(result => {
          if (result.data.categories) this.categories = result.data.categories
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching categories')
          console.error('error:', error.response.data)
        })
    },
    getImagePath(product) {
      if (product.file_path) return product.absolute_path
      return require('@/assets/product.png')
    },
    joinedCategories(categories) {
      let string = ''
      categories.forEach(category => {
        string += string.length == 0 ? category.name : ', ' + category.name
      })
      return string
    },
    showDescription(product) {
      if (!product.description.length) return ''
      if (product.description.length > 100)
        return product.description.substring(0, 100) + '...'
      return product.description
    },

    search() {
      this.meta.page = 1
      // this.getProducts()
    }
  }
}
</script>

<style scoped>
.custom-tag {
  font-size: 12px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 5px;
  border-radius: 8px;
  margin: 3px;
}

.total-cost {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #f44336;
}

.list-padding-5>.list-group-item {
  padding: 5px;
}
</style>
