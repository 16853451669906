import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'

//prod only
const firebaseConfigProd = {
  apiKey: 'AIzaSyB0K184-MnJWNW328DrioY4IXEYrJtsqIE',
  authDomain: 'costcomparison-25661.firebaseapp.com',
  projectId: 'costcomparison-25661',
  storageBucket: 'costcomparison-25661.appspot.com',
  messagingSenderId: '24757738118',
  appId: '1:24757738118:web:00503923f5e90081da8f07',
  measurementId: 'G-RV6QJVL4V4'
}

//staging
const firebaseConfig = {
  apiKey: "AIzaSyDooZ49kCNrcGUjvBYNhu9z4qnrCxbodck",
  authDomain: "cpras-staging.firebaseapp.com",
  databaseURL: "https://cpras-staging-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cpras-staging",
  storageBucket: "cpras-staging.appspot.com",
  messagingSenderId: "351560256776",
  appId: "1:351560256776:web:309526e144951ec59e30bd",
  measurementId: "G-10L48H3CZ0",
};

const firebaseApp = firebase.initializeApp(process.env.VUE_APP_MODE == 'production' ? firebaseConfigProd : firebaseConfig)

export const db = firebaseApp.database()