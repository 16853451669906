import api from './api'

const API = '/api/company-bcp'

export const CompanyBcpApi = {
  get: (params) => {
    return api.get(`${API}`, {params: params})
  },
  show: (params) => {
    return api.get(`${API}/own`, {params: params})
  },
  updateStatus: (id, form) => {
    return api.update(`${API}/${id}/update-status`, form)
  },
  create: (form) => {
    return api.createFormData(`${API}`, form)
  },
  skip: () => {
    return api.create(`${API}/skip-activation`)
  }
}
