<template>
  <div
    ref="modal"
    :data-bs-backdrop="backdrop"
    :class="'modal fade ' + modal_main_class"
    tabindex="-1"
  >
    <div
      :class="
        `modal-dialog modal-${size} ${centered ? 'modal-dialog-centered' : ''}`
      "
    >
      <div :class="'modal-content ' + klass">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ title }}</h1>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-if="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backdrop: {
      type: [Boolean, String],
      required: false,
      default: true
    },
    title: {
      type: String,
      required: true
    },
    close: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    },
    klass: {
      type: String,
      required: false
    },
    modal_main_class: {
      type: String,
      required: false
    }
  },
  methods: {
    onShowModal() {
      this.$emit('show')
    },
    onHiddenModal() {
      this.$emit('hidden')
    }
  },
  mounted() {
    this.$refs.modal.addEventListener('show.bs.modal', this.onShowModal)
    this.$refs.modal.addEventListener('hidden.bs.modal', this.onHiddenModal)
    this.$emit('input', bootstrap.Modal.getOrCreateInstance(this.$refs.modal))
  },
  beforeUnmount() {
    bootstrap.Modal.getInstance(this.id)?.dispose()
    document.querySelector('.modal-backdrop')?.remove()
    this.$refs.modal?.removeEventListener('show.bs.modal', this.onShowModal)
    this.$refs.modal?.removeEventListener('hidden.bs.modal', this.onHiddenModal)
  }
}
</script>

<style scoped>
.modal {
  --bs-modal-padding: 1.5rem;
  --bs-modal-header-padding: 1.25rem 1.5rem 0.75rem;
  --bs-modal-header-border-width: 0;
  --bs-modal-footer-border-width: 0;
}

.modal-info {
  background: #e3f8f3;
}
::v-deep .modal-footer {
  padding: 1rem 1.5rem 1.5rem;
}
::v-deep .modal-footer button {
  min-width: 100px;
  margin: 0 0 0 0.5rem;
}
</style>
