<template>
  <div class="">
    <el-card>
       <h4>Settings</h4>
       <div class="text-sm">
            <p>
              Welcome to your  Account Settings page! Here, you can easily enable or disable Two-Factor Authentication (2FA) for added security. Simply switch on the ‘Require 2FA’ option to enable it. Once enabled, you’ll be prompted to set up 2FA and will receive a One-Time Password (OTP) for verification. Remember, your safety is our priority!
            </p>
        </div>
      <hr>
      <el-switch
      v-model="require_2fa"
      active-text="Require 2FA"
      @change="submit"
      >
    </el-switch>
    </el-card>
   
  </div>
</template>

<script>

import { User } from '@/api_services/user'
import Swal from 'sweetalert2'

export default {
  watch: {
    '$store.state.savedUser.require_2fa':{
      handler(value){
        this.require_2fa = value
      },
      deep:true
    }
  },
  data() {
    return {
      require_2fa: true,
    }
  },
  created: function () {
    let user = this.$store.state.savedUser
    if (user) this.require_2fa = user.require_2fa == 1
  },
  methods: {
  skip2fa() {
    this.$loading = true
    let params = { require_2fa: false }
    User.skip2fa(params)
      .then(result => {
        
        if(this.$store.state.savedUser){
          this.$store.state.savedUser.require_2fa = 0
          this.$store.commit('saveUser', this.$store.state.savedUser)
        }

        this.require_2fa = false
      })
      .catch( error => {
        this.$toast.error('An error occured while skipping 2fa')
        console.error('error:', error.response.data)
       })
      .finally(() => {
          this.$loading = false
      })
    },
  submit() {
    this.$loading = true
    let params = { require_2fa: this.require_2fa }
    User.skip2fa(params)
      .then(result => {
        this.$store.state.savedUser.require_2fa = this.require_2fa
        this.$toast.success('Saved successfully')

        if(this.require_2fa){
          Swal.fire({
            title: 'Send OTP?',
            text:
              'Do you want to send an OTP to your email to verify this device?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true
          }).then(result => {
            if (result.isConfirmed) {
              this.$loading = true
              window.location.reload()
            }
            else{
              this.skip2fa()
            }
          })
        }
      })
      .catch( error => {
        this.$toast.error('An error occured while setting 2fa')
        console.error('error:', error)
      })
      .finally(() => {
        this.$loading = false
      })
   }
  }
}
</script>

<style>

</style>
