<template>
  <div v-if="question">
    <el-drawer title="Reply" v-model="showReply" direction="rtl">
      <answer-form @update="updateParent()" :question="selected"></answer-form>
    </el-drawer>
    <el-alert :title="'Question: ' + (question.lot ? question.lot.name : '')" type="info" :closable="false">
      <p class="text-wrap">{{ question.question }}</p>
      <div v-if="question.files && question.files.length">
        <div>Attachments:</div>
        <div v-for="(file, i) in question.files" :key="i">
          <el-link :href="file.link" target="_blank">{{
            file.filename
            }}</el-link>
        </div>
      </div>
      <span class="text-xs">{{ showUserInformation() }}</span>
      <span v-if="!question.answer && reply">
        <a v-allowed="'answers,create'" style="color: #9dabff;cursor:pointer;font-size: 12px"
          @click="onShowReply(question)" v-if="!question.is_archived">- Answer now</a>
      </span>
    </el-alert>

    <div class="answer" v-if="question.answer">
      <div style="margin-left: 20px">
        <el-alert :title="'Answer:'" type="success" :closable="false" effect="dark" show-icon>
          <div class="text-wrap text-sm">
            {{ question.answer.answer }}
          </div>
          <span class="text-xs">{{
            question.answer.answered_by.name +
            ' | ' +
            getDate(question.answer.updated_at)
            }}</span>
        </el-alert>
      </div>
    </div>
  </div>
</template>

<script>
import AnswerForm from './AnswerForm.vue'
import * as moment from 'moment'

export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    reply: {
      type: Boolean,
      default: false
    },
    showUserInfo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AnswerForm
  },
  data() {
    return {
      selected: null,
      showReply: false
    }
  },
  created: function() {},
  methods: {
    showUserInformation() {
      if (this.showUserInfo)
        return (
          this.question.user.name +
          ' | ' +
          this.question.user.company_name +
          ' | ' +
          this.getDate(this.question.created_at)
        )
      return this.getDate(this.question.created_at)
    },
    onShowReply(question) {
      this.showReply = true
      this.selected = Object.assign({}, question)
    },
    updateParent() {
      this.showReply = false
      this.$emit('update', true)
    },
    getDate(date) {
      return moment(date).format('MM/DD/YYYY HH:mm:ss')
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
