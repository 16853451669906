import api from './api'

const API = '/api/supplier-lot-spec-values'

export const SupplierLotSpecValue = {
  get: function(params) {
    return api.get(API, { params : params })
  },
  show: function(id) {
    return api.get(`${API}/${id}`)
  },
  update: function(form) {
    return api.update(`${API}/${form.id}`, form)
  },
}
