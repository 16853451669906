<template>
  <div v-if="company">
    <loading :loading="loading"></loading>
    <div class="" style="text-align:left">
      <el-card class="border-0">
        <b-form >
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <b-form-group
                  id="input-group-1"
                  label=""
                  label-for="input-1"
                  description=""
                  valid-feedback=""
                >
                  <!--<b-form-file
                    @change="checkFile($event)"
                    v-model="form.file"
                    accept="image/*" 
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>-->

                  <argon-input
                    :multiple="false"
                    type="upload"
                    :max-size="30000000"
                    :value="form.file"
                    field="Logo"
                    :fileTypes="jpg"
                    :required="true"
                    :label="true"
                    @change="checkFile('files', $event)"
                  ></argon-input>

                </b-form-group>
              <div style="height: 200px;overflow: hidden;">
                <img style="width: 100%" :src="form.logo_path ? image.logo_path : $store.state.no_image" alt="Image" bottom :id="'picture'">
              </div>
            </div>
 
            
          </div>
        
          <b-button type="button" variant="primary" @click="save()" :disabled="!validateAll">Save</b-button> 
          <b-button type="button" @click="close()">Cancel</b-button>
        </b-form>
      </el-card>
    </div>
  </div>
</template>

<script>

import { ProductSupplier } from '@/api_services/supplier'
import ArgonInput from '@/components/common/Input/ArgonInput.vue'
import $ from 'jquery'


export default {
  mounted() {
    $(".el-upload__tip").text("Accepted file formats are jpg/png/jpeg files with a size less than 30 mb")
  },
  components: {
    ArgonInput
  },
  data() {
    return {
      loading: false,
      company: null,
      form: {name: null},
      validate: [],
      form_file: null,
    }
  },
  created: function() {
    this.company = this.$store.state.savedUser.company
    if (this.company && this.company.supplier) {
      this.form = Object.assign({}, this.company.supplier); 
    }
  },
  methods: {
    checkFile(name, event) {
      var file = event.target.files[0]
      this.form_file = file
      this.readFile(file)
    },
    readFile(file) {
      var loadFile = function(file) {
        var reader = new FileReader();
        reader.onload = function(){
          var output = document.getElementById('picture');
          output.src = reader.result;
        };

        reader.readAsDataURL(file);
        
      };
      loadFile(file)
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'name') return "Name is required"
      if (!this.validation(field) && field == 'postal') return "Postal address is required"
      if (!this.validation(field) && field == 'mobile') return "Mobile number is required"
      if (!this.validation(field) && field == 'email') return "Email format is invalid"
      if (!this.validation(field) && field == 'website') return "Website is required"
      if (!this.validation(field) && field == 'e_catalog') return "E-Catalog is required"
      if (!this.validation(field) && field == 'address') return "Address is required"
      if (!this.validation(field) && field == 'city') return "City is required"
      if (!this.validation(field) && field == 'state') return "State is required"
      if (!this.validation(field) && field == 'country') return "Country is required"
   
      return ""
    },
    validation(field) {
      switch(field) {
        case 'name':
          return this.$store.state.isStringValid(this.form.name)
        case 'postal':
          return this.$store.state.isStringValid(this.form.postal)
        case 'mobile':
          return this.$store.state.isStringValid(this.form.mobile)
        case 'website':
          return this.$store.state.isStringValid(this.form.website)
        case 'e_catalog':
          return this.$store.state.isStringValid(this.form.e_catalog)
        case 'email':
          return this.$store.state.isEmailValid(this.form.email)  
        case 'address':
          return this.$store.state.isStringValid(this.form.address)
        case 'city':
          return this.$store.state.isStringValid(this.form.city)
        case 'state':
          return this.$store.state.isStringValid(this.form.state)
        case 'country':
          return this.$store.state.isStringValid(this.form.country)
        default:
          return true;
      }
    },
    close() {
      this.$emit('update', null)
    },
    getForm() {
      var form = new FormData()
      if (this.form_file) form.append('file', this.form_file)
      if (this.form.id) form.append('id', this.form.id)

      return form
    },
    save() {
      this.loading = true
      ProductSupplier.create(this.getForm())
      .then(result => {
        this.loading = false
        this.$toast.success('Saved successfully')
        this.company.supplier = result.data.product_supplier
        let user = this.$store.state.savedUser
        user.company = this.company
        this.$store.commit('saveUser', user);

        let self = this
         setTimeout(() => {
            self.$emit('update', result.data.product_supplier)
          }, 500)
          
      })
      .catch(error => {
        this.loading = false
        this.$toast.error('An error occured while saving supplier')
        console.error('error:', error.response.data)
      })
      //console.error(this.getForm())
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>
