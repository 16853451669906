<template>
  <div class="" style="text-align:left">
    <button
      type="button"
      class="btn transparent text-white shadow-none"
      @click="$router.back()"
    >
      &#8592; Back to results
    </button>
    <hr />

    <div v-if="product_id">
      <selected-product-view-only-component
        :category_id="category_id"
        :product_id="product_id"
        :volume="volume"
      ></selected-product-view-only-component>
    </div>
  </div>
</template>

<script>
import SelectedProductViewOnlyComponent from '../products/SelectedProductViewOnlyComponent.vue'

export default {
  components: { SelectedProductViewOnlyComponent },
  data() {
    return {
      category_id: null,
      volume: null,
      product_id: null
    }
  },
  created: function() {
    this.category_id = this.$route.query.category_id
    this.product_id = this.$route.params.id
    this.volume = this.$route.query.volume
  }
}
</script>

<style scoped>
.custom-tag {
  font-size: 12px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 5px;
  border-radius: 8px;
  margin: 3px;
}

.total-cost {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #f44336;
}

.light-blue-color {
  color: #53a5d0;
}

.product-info {
  font-size: 12px;
  color: #404040;
}

.orange-color {
  color: #f5b371;
}

.gray-color {
  color: #404040;
}
</style>
