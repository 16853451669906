<template>
  <div style="text-align:left;padding: 10px">
    <div v-if="product_search_result && product_search_result.add_statement_absolute_links.length">
      <h5>Saved Files</h5>
      <div
        v-for="(link, i) in product_search_result.add_statement_absolute_links"
        :key="i"
      >
        <a :href="link" download target="_blank">File {{ i + 1 }}</a>
      </div>

      
      <div v-if="product_search_result.add_statement_absolute_links.length">
        <label>Remove saved files?</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="fcustomCheck1"
              checked=""
              v-model="remove"
            />
            <label class="custom-control-label" for="customCheck1">
              {{ remove ? 'YES' : 'NO' }}</label
            >
          </div>
      </div>
    </div>

    <dropzone
      label="Drop or select file"
      :max_files="5"
      :clean="clearUpload"
      :multiple="true"
      extensions="xlsx, pdf, docx"
      @input="setFile($event)"
    ></dropzone>

    <button
      type="button"
      class="btn btn-success"
      :disabled="isSaveDisabled"
      @click="save()"
    >
      {{ isSaving ? 'Saving...' : 'Save' }}
    </button>
  </div>
</template>

<script>
import Dropzone from '@/components/common/Dropzone.vue'
import { ProductSearchResult } from '@/api_services/product_search_result'

export default {
  props: ['product_search_result'],
  components: { Dropzone },
  data() {
    return {
      selected: {},
      files: [],
      clearUpload: 0,
      isSaving: false,
      remove: false
    }
  },
  computed: {
    isSaveDisabled() {
      return this.isSaving
    }
  },
  watch: {
    product_search_result: function() {
      this.selected = Object.assign({}, this.product_search_result)
      this.clearUpload++
    }
  },
  created: function() {
    this.selected = Object.assign({}, this.product_search_result)
    this.clearUpload++
  },
  methods: {
    setFile(files) {
      let self = this
      this.files = []
      files.forEach(file => {
        self.files.push(file.file)
      })
    },
    save() {
      this.isSaving = true
      ProductSearchResult.uploadAdditionalStatements(
        this.selected.id,
        this.getFormData()
      )
        .then(resp => {
          this.$toast.success(resp.data.message || 'Files has been uploaded')
          this.$emit('update')
        })
        .catch(error => {
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    getFormData() {
      var formData = new FormData()
      this.files.forEach(function(file) {
        formData.append('files[]', file)
      })

      formData.append('remove', this.remove)
      return formData
    }
  }
}
</script>
