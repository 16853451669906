<template>
  <div class="row" style="--bs-gutter-x: 0">
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center">
      <h3 class="text-center">Two-Step Verification</h3>
      <div class="text-center mb-3">
        <img :src="require('@/assets/2fa.png')">
      </div>
      <p v-if="!done_send_otp">Click the button below and an email containing the code will be dispatched to your inbox.</p>
      <el-button 
      v-if="!done_send_otp"
      class="btn-block" 
      type="success" @click="resendOtp()"
      size="large"
      >Send OTP</el-button>
      <br>
      <p v-if="done_send_otp">Kindly input the OTP (One-time password) that has been sent to your email to authorize this device.</p> 
      <div v-if="done_send_otp" class="text-center">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                      <el-input size="medium" placeholder="000000" style="width: 100%" v-model="code" class="mb-3"></el-input>
                    
            </div>
          </div>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
              <el-button style="width: 100%;" class="btn-block" type="primary" @click="verifyCode()" :disabled="$loading">{{ $loading ? 'Verifying' : 'Verify Code' }}</el-button>
          </div>
          <div class="col-md-12">
            <el-link @click="resendOtp()" class="mt-3">Resend OTP</el-link>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-md-12">
            <div style="text-align: right;"><el-link @click="skip()" class="mt-3">Skip Setup</el-link></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { User } from '@/api_services/user'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      done_send_otp: false,
      code:null
    }
  },
  created: function () {
    this.$loading = false
  },
  methods: {
    async skip() {
      const { value: res } = await Swal.fire({
        title: 'Attention!',
        text: "You have the option to activate this verification within your account settings.",
        icon: 'info',
        confirmButtonClass: 'btn btn-success',
        buttonsStyling: true,
        showCancelButton: true,
        cancelButtonClass: 'btn ml-2',
        cancelButtonText: "Back"
      })

      if (res) this.onSkip()
    },
    onSkip() {
      this.$loading = true
      let params = { require_2fa: false }
      User.skip2fa(params)
        .then(result => {
          if(this.$store.state.savedUser){
            this.$store.state.savedUser.require_2fa = 0
            this.$store.commit('saveUser', this.$store.state.savedUser)
          }

          window.location.reload()
        })
        .catch(() => { })
        .finally(() => {
          this.$loading = false
        })
    },
    resendOtp() {
      this.$loading = true
      let params = { mac_address: this.mac_address, device_type: this.$helpers.getDeviceType() }
      User.resendOtp(params)
        .then(result => {
          if(!this.done_send_otp){
            this.resendSuccess("new")
            this.done_send_otp = true
          }
          else{
            this.resendSuccess("resend")
          }
        })
        .catch(() => { })
        .finally(() => {
          this.$loading = false
        })
    },
    verifyCode() {
      this.$loading = true
      let params = {code: this.code, mac_address: this.mac_address }
      User.verify2fa(params)
        .then(result => {
         this.success()
        })
        .catch(() => { })
        .finally(() => {
          this.$loading = false
        })
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: 'Success!',
        text: "Device has been authorized",
        icon: 'success',
        confirmButtonClass: 'btn btn-success',
        buttonsStyling: false
      })

      window.location.reload()
    },
    async resendSuccess(type) {
      const { value: res } = await Swal.fire({
        title: 'Success!',
        text: "An "+ ( type == "new" ? "" : "updated" ) +" OTP has been dispatched to your email.",
        icon: 'success',
        confirmButtonClass: 'btn btn-success',
        buttonsStyling: false
      })
    }
  },
  computed: {
    ...mapState(['savedUser','mac_address'])
  },
}
</script>
