import api from './api'

const API = '/api/help'

export const UserHelpService = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  reply: function(params) {
    return api.createFormData(API + '/reply', params)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  update: function(params) {
    return api.update(API + '/' + params.id, params)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id, params)
  }
}
