<template>
  <b-form  @submit="onSubmit" >
    <h4 class="signup-h4">Login information</h4>

    <h5 class="signup-h4">What type of user are you?</h5>
    <div style="text-align:center">
      <b-form-group label="" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="form.role_name"
          :options="options"
          :aria-describedby="ariaDescribedby"
          button-variant="outline-info"
          size="md"
          buttons
        >
        </b-form-radio-group>
      </b-form-group>
    </div>

    <b-form-group id="name" label-for="input-name" valid-feedback=""
      :invalid-feedback="invalidFeedback('name')"
      :state="validation('name')"
    >
      <b-form-input id="input-name" v-model="form.name" placeholder="Name" :state="validation('name')" trim></b-form-input>
    </b-form-group>

    <b-form-group
        id="input-group-1"
        label-for="input-1"
        description=""
        valid-feedback=""
        :invalid-feedback="invalidFeedback('email')"
        :state="validation('email')"
    >
        <b-form-input
        id="input-1"
        type="email"
        v-model="form.email"
        placeholder="Enter email"
        required
        :state="validation('email')"
        ></b-form-input>
    </b-form-group>

    <b-form-group id="password" label-for="input-password" valid-feedback=""
      :invalid-feedback="invalidFeedback('password')"
      :state="validation('password')"
    >
      <b-form-input autocomplete="on" id="input-password" placeholder="Password" v-model="form.password" :state="validation('password')" trim type="password"></b-form-input>
    </b-form-group>

    <b-form-group id="confirm-password" label-for="input-confirm-password" valid-feedback=""
      :invalid-feedback="invalidFeedback('confirm_password')"
      :state="validation('confirm_password')" 
    >
      <b-form-input autocomplete="on" id="input-confirm-password" placeholder="Confirm Password" v-model="form.confirm_password" :state="validation('confirm_password')" trim type="password"></b-form-input>
    </b-form-group>

    <b-form-group id="company_name" label-for="input-company_name" valid-feedback=""
      :invalid-feedback="invalidFeedback('company_name')"
      :state="validation('company_name')"
    >
      <b-form-input id="input-company_name" v-model="form.company_name" placeholder="Organisation" :state="validation('company_name')" trim></b-form-input>
    </b-form-group>

    <b-form-group id="position_name" label-for="input-position_name" valid-feedback=""
      :invalid-feedback="invalidFeedback('position_name')"
      :state="validation('position_name')"
    >
      <b-form-input id="input-position_name" v-model="form.position_name" placeholder="Role" :state="validation('position_name')" trim></b-form-input>
    </b-form-group>
  
    <!-- <b-button type="button" variant="info" @click="back()">Back</b-button>  -->
    <b-button type="submit" variant="info"  :disabled="!validateAll">Next</b-button>
</b-form>
</template>

<script>

export default {
  props: ['selected'],
  data() {
    return {
      validate: ['name', 'email','password','confirm_password','company_name','position_name'],
      options: [
        { value: 'supplier', text: 'Supplier' },
        { value: 'buyer', text: 'Buyer' }
      ],
      form: {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        company_name: null,
        position_name: null
      }
    }
  },
  created: function() {
    this.form = Object.assign({}, this.selected);
  },
  methods: {
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'name') return "Name is required"
      if (!this.validation(field) && field == 'password') return "Password must be at least 6 characters"
      if (!this.validation(field) && field == 'company_name') return "Organisation is required"
      if (!this.validation(field) && field == 'position_name') return "Role is required"
      if (!this.validation(field) && field == 'confirm_password') return "Mismatch password"
      if (!this.validation(field) && field == 'email') {
          if (!this.$store.state.isEmailValid(this.form.email)) return "Email format is invalid"
          return "Email is required"
        }
      return ""
    },
    validation(field) {
      switch(field) {
        case 'name':
          if (!this.form.name) this.form.name = ""
          return this.form.name.length > 0
        case 'company_name':
          if (!this.form.company_name) this.form.company_name = ""
          return this.form.company_name.length > 0
        case 'position_name':
          if (!this.form.position_name) this.form.position_name = ""
          return this.form.position_name.length > 0
        case 'password':
          if (!this.form.password) this.form.password = ""
          return this.form.password.length >= 6
        case 'confirm_password':
          if (!this.form.confirm_password) this.form.confirm_password = ""
          return this.form.confirm_password == this.form.password && this.form.confirm_password.length > 0
        case 'email':
            if (!this.form.email) this.form.email = ""
            return this.form.email.length > 0 && this.$store.state.isEmailValid(this.form.email)
        default:
          return true;
      }
    },
 
    onSubmit(evt) {
      evt.preventDefault()
      this.$emit('success', {next: true, form: this.form})
    },
    back() {
      this.$emit('success', {next: false, form: this.form})
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>
