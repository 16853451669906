<template>
   
    <div v-for="setting in selected_guidance_setting">
    <div v-if="selected_result[setting.question_codes] !== undefined">

      <template v-if="if_admin_view">
          
          <el-card shadow="never" style="background: white" class="mb-4 mt-4 p-0 border-1" >
            <h5>Result</h5>
            <el-text >Question No: <b>{{ selected_result[setting.question_codes].display_question_no }}</b></el-text>
            <el-divider />
            <el-text >Result:
              <el-radio-group @change="resultChange(setting.question_codes, selected_result[setting.question_codes].result)" v-model="selected_result[setting.question_codes].result" class="ml-4">
                <el-radio border :value="1" >Passed</el-radio>
                <el-radio border :value="0" >Failed</el-radio>
            </el-radio-group>
            </el-text>  
            <el-divider />
            
            <el-input autosize type="textarea" v-model="selected_result[setting.question_codes].comment" placeholder="Your comment here..." />
          </el-card>
          
        </template>

      <template v-else>
        
      <el-card v-if="!selected_result[setting.question_codes].need_admin_verification && !selected_result[setting.question_codes].result && selected_result[setting.question_codes].result !== 0" shadow="never" style="background: #f3d19e" class="mb-2 p-0 border-0" >
        <el-text>{{ selected_result[setting.question_codes].display_question_no }}: No result yet!</el-text>
      </el-card>

      <template v-else>
          <el-card v-if="
            selected_result[setting.question_codes].need_admin_verification &&
            (!latest_supplier_qa_submission || latest_supplier_qa_submission.if_verified == 0)
            " shadow="never" style="background: #f3d19e" class="mb-2 p-0 border-0" >
            <el-text> {{ selected_result[setting.question_codes].display_question_no }}: Need admin verification</el-text>
          </el-card>

          <el-card v-else shadow="never" :style="selected_result[setting.question_codes].result == 1 ? 'background: #67c23a; ' : 'background: #f89898'" class="mb-2 p-0 border-0" >
              
              <div >
                  <el-text class="text-dark">Question No: <b>{{ selected_result[setting.question_codes].display_question_no }}</b></el-text>
                  <hr>
                  <el-text class="text-dark">Result: <b>{{ 
                    selected_result[setting.question_codes].result == 1 ? "Passed" : ( !latest_supplier_qa_submission ? "Failed" : (latest_supplier_qa_submission && latest_supplier_qa_submission.if_verified == 0 ? "Provisionally failed. Pending review" : "Failed" ) )
                  }}</b></el-text>  
                  <hr>
                  <el-text v-if="latest_supplier_qa_submission && latest_supplier_qa_submission.if_verified == 1" class="text-dark">Admin Comment: <b>{{ selected_result[setting.question_codes].comment ?? "None" }}</b></el-text>
              </div>
          </el-card>
      </template>
    </template>
    </div>
    
    </div>
</template>

<script>

export default {
  emits :{
      resultChange: null
  },
  props:{
    result: {
      required: true
    }, 
    guidance_setting: {
      type: Object, required: true
    }, 
    if_admin_view: {
      required: true
    },
    latest_supplier_qa_submission: {
      type: Object
    }, 
  },
  data() {
    return {
      selected_result: null,
      selected_guidance_setting: null,
    }
  },
  watch: {
      guidance_setting: {
            deep: true,
            handler(new_value) {
                this.selected_guidance_setting = new_value
            }
        },
  },
  created: function() {
      this.selected_result = this.result
      this.selected_guidance_setting = this.guidance_setting
      var self = this

      //console.log(this.selected_result)

      for(var x = 0; x <= this.selected_guidance_setting.length - 1; x++){
        if(this.selected_guidance_setting[x].question_codes){
          
          var codes = this.selected_guidance_setting[x].question_codes
          var key = ['result', codes].join('.')

          /*this.$watch(key, function(newValue) {
            this.selected_result[codes] = newValue
            console.log(this.selected_result[codes])
          })*/
        }
        //console.log(this.selected_guidance_setting[x].display_question_no)
      }
  },
  methods: {
    resultChange(code, result){
      this.$emit('resultChange', {
                code: code,
                result: result
            })
    }
  }
}
</script>
