<template>
<div>
  <card-imports-list :all="true" :add="false"></card-imports-list>
</div>
</template>


<script>
import CardImportsList from '../CardImportsList.vue'

export default {
  components: {
    CardImportsList
  },
  data() {
    return {

    }
  }
}
</script>
