<template>
  <div>
    <div header="Company Details  " style="text-align: left;" class="card my-3">
      <div class="card-header">
        Company Details
      </div>
      <div class="card-body">
        <form @submit.prevent="save">
          <div class="row">
            <!--<div class="col-md-12">

              <div class="form-group" id="office_address">
                <label for="input-call_off_pre_sign"></label>
                <div class="form-check" v-if="$store.state.isViewable('supplier')">
                  <input class="form-check-input" type="checkbox" id="checkbox-1" v-model="form.call_off_pre_sign"
                    name="checkbox-1" :value="1" :unchecked-value="0">
                  <label class="form-check-label" for="checkbox-1">
                    Call Off Pre-Sign
                  </label>
                </div>
              </div>
            </div>-->


            <CompanyFormComponent
              v-if="$store.state.isAllowed('COMPANY', 'CREATE') || $store.state.isAllowed('COMPANY', 'UPDATE')"
              v-on:update_data="updateData" :if_hide_button="true" ref="company_form_component" :company="company">
            </CompanyFormComponent>

            <template v-if="!$store.state.isUserABuyer() && !$store.state.isUserAnOriginator()">

              <div class="form-group mt-4">
              <label class="form-label">Registered website address</label>
              <input type="text" class="form-control" id="website_address" v-model="form.website_address" trim>
            </div>

            <div class="form-group">
              <label class="form-label">Phone number</label>
              <input type="text" class="form-control" id="phone" v-model="form.phone" trim>

            </div>

            <div class="form-group">
              <label class="form-label">E Catalog</label>
              <input type="text" class="form-control" id="e_catalog" v-model="form.e_catalog" trim>
            </div>

            <div class="form-group">
              <label class="form-label">Trading status</label>

              <select id="input-trading_status" class="form-select" v-model="form.trading_status">
                <option value="Public limited company">Public limited company</option>
                <option value="Limited company">Limited company</option>
                <option value="Limited liability partnership">Limited liability partnership</option>
                <option value="Other partnership">Other partnership</option>
                <option value="Sole trader">Sole trader</option>
                <option value="Other">Other (Please specify your trading status)</option>
              </select>
            </div>

            <div class="form-group">
              <label class="form-label">Other trading status</label>
              <input type="text" class="form-control" id="other_trading_status" v-model="form.other_trading_status"
                trim>
            </div>

            <div class="form-group">
              <label class="form-label">Date of registration in country of origin</label>
              <input type="date" class="form-control" id="registration_date" v-model="form.registration_date" trim
                :class="{ 'is-invalid': v$.form.registration_date.$errors.length, 'form-control': true }">

              <div class="input-errors" v-for="error of v$.form.registration_date.$errors" :key="error.$uid">
                <div class="error-text">Date of registration in country of origin {{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group">
              <label class="form-label">Registration Number</label>
              <input type="text" class="form-control" id="company_registration_number"
                v-model="form.company_registration_number" trim>
            </div>


            <div class="col-md-12">

              <div class="mb-3">
                <label for="charity_registration_number" class="form-label">Charity registration number</label>
                <input type="text" class="form-control" id="charity_registration_number"
                  v-model="form.charity_registration_number" trim>
              </div>
            </div>

            <div class="col-md-12">

              <div class="mb-3">
                <label for="head_office_duns_number" class="form-label">Head office DUNS number</label>
                <input type="text" class="form-control" id="head_office_duns_number"
                  v-model="form.head_office_duns_number" trim>
              </div>
            </div>

            <div class="col-md-12">
              <div class="mb-3">
                <div class="form-group">
                  <label class="form-label">Registered VAT number</label>
                  <input type="text" class="form-control" id="registered_vat_number"
                    v-model="form.registered_vat_number" trim
                    :class="{ 'is-invalid': v$.form.registered_vat_number.$errors.length, 'form-control': true }">

                  <div class="input-errors" v-for="error of v$.form.registered_vat_number.$errors" :key="error.$uid">
                    <div class="error-text">Registered VAT number {{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">

              <div class="mb-3">
                <label for="is_registered_with_trade_register" class="form-label">If applicable, is your organisation
                  registered with the appropriate professional or trade register(s) in the member state where it is
                  established?</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="some-is_registered_with_trade_register"
                    id="input-is_registered_with_trade_register-yes" v-model="form.is_registered_with_trade_register"
                    :value="1">
                  <label class="form-check-label" for="input-is_registered_with_trade_register-yes">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="some-is_registered_with_trade_register"
                    id="input-is_registered_with_trade_register-no" v-model="form.is_registered_with_trade_register"
                    :value="0">
                  <label class="form-check-label" for="input-is_registered_with_trade_register-no">No</label>
                </div>

              </div>
            </div>

            <div class="col-md-12" v-if="form.is_registered_with_trade_register">
              <div class="mb-3">
                <label for="registered_vat_number_relevant_details" class="form-label">Please provide the relevant
                  details, including the registration number(s)</label>
                <textarea id="textarea-no-resize"
                  :class="{ 'is-invalid': v$.form.registered_vat_number_relevant_details.$errors.length, 'form-control': true }"
                  rows="3" v-model="form.registered_vat_number_relevant_details"></textarea>

                <div class="input-errors" v-for="error of v$.form.registered_vat_number_relevant_details.$errors"
                  :key="error.$uid">
                  <div class="error-text">Relevant Details {{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="col-md-12">

              <div class="mb-3">
                <label for="input-is_it_legal_requirement" class="form-label">Is it a legal requirement in the state
                  where you are established for you to possess a particular authorization, or be a member of a
                  particular organization in order to provide the services specified in this procurement?</label>
                <div class="form-check">
                  <input type="radio" id="some-is_it_legal_requirement-1" class="form-check-input"
                    name="some-is_it_legal_requirement" :value="1" v-model="form.is_it_legal_requirement">
                  <label for="some-is_it_legal_requirement-1" class="form-check-label">Yes</label>
                </div>
                <div class="form-check">
                  <input type="radio" id="some-is_it_legal_requirement-0" class="form-check-input"
                    name="some-is_it_legal_requirement" :value="0" v-model="form.is_it_legal_requirement">
                  <label for="some-is_it_legal_requirement-0" class="form-check-label">No</label>
                </div>

              </div>
            </div>

            <div class="col-md-12" v-if="form.is_it_legal_requirement">


              <div class="mb-3">
                <label for="is_it_legal_requirement_additional_details" class="form-label">Please provide additional
                  details of what is required and confirmation that you have complied with this.</label>
                <textarea id="textarea-no-resize" class="form-control" placeholder="" rows="3"
                  v-model="form.is_it_legal_requirement_additional_details"></textarea>

              </div>
            </div>

            <div class="col-md-12">
              <div class="mb-3">
                <div class="form-group">
                  <label class="form-label">Trading name(s) that will be used if successful in this procurement</label>
                  <input type="text" class="form-control" id="trading_names" v-model="form.trading_names" trim
                    :class="{ 'is-invalid': v$.form.trading_names.$errors.length, 'form-control': true }">

                  <div class="input-errors" v-for="error of v$.form.trading_names.$errors" :key="error.$uid">
                    <div class="error-text">Trading Names {{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>



            <div class="col-md-12">


              <div class="mb-3">
                <label for="enterprise_type" class="form-label">Are you a Small, Medium or Micro Enterprise
                  (SME)?</label>
                <!-- <select id="enterprise_typee" class="form-select" v-model="form.enterprise_type">
                <option value="Small Enterprise">Small Enterprise</option>
                <option value="Medium Enterprise">Medium Enterprise</option>
                <option value="Micro Enterprise">Micro Enterprise</option>
              </select> -->
                <div class="form-check">
                  <input type="radio" id="some-enterprise_type-1" class="form-check-input" name="some-enterprise_type"
                    value="yes" v-model="form.enterprise_type">
                  <label for="some-enterprise_type-1" class="form-check-label">Yes</label>
                </div>
                <div class="form-check">
                  <input type="radio" id="some-enterprise_type-0" class="form-check-input" name="some-enterprise_type"
                    value="no" v-model="form.enterprise_type">
                  <label for="some-enterprise_type-0" class="form-check-label">No</label>
                </div>


              </div>
            </div>


            <div class="col-md-12">
              <hr>
              <h5 class="col-md-12">Persons of Significant Control</h5>
              <div class="col-md-12" v-if="loaded_pscs">
                <div v-for="(psc,i) in form.pscs" :key="i">
                  <!-- <b-link @click="showPscModal(psc,i)">{{psc.name}}</b-link> <b-link @click="removePsc(i)">(Remove)</b-link> -->
                  <a href="#" @click="showPscModal(psc, i)">{{ psc.name }}</a>
                  <a href="#" @click="removePsc(i)">(Remove)</a>
                </div>
              </div>
              <div>
                <!-- <b-link @click="showPscModal(null,null)" >+ Add</b-link>  -->
                <a href="#" @click="showPscModal(null,null)">+ Add</a>
              </div>

              <hr>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Full name of the immediate parent company</label>
                <input type="text" class="form-control" id="input-i_parent_company_name"
                  v-model="form.i_parent_company_name" trim
                  :class="{ 'is-invalid': v$.form.i_parent_company_name.$errors.length, 'form-control': true }">

                <div class="input-errors" v-for="error of v$.form.i_parent_company_name.$errors" :key="error.$uid">
                  <div class="error-text">Full name {{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="col-md-12">

              <div class="form-group" id="i_parent_company_office_address">
                <label for="input-i_parent_company_office_address">Registered office address</label>
                <input type="text" class="form-control" id="input-i_parent_company_office_address"
                  v-model="form.i_parent_company_office_address" />
              </div>
            </div>
            <div class="col-md-12">

              <div class="form-group" id="i_parent_company_website_address">
                <label for="input-i_parent_company_website_address">Registered website address</label>
                <input type="text" class="form-control" id="input-i_parent_company_website_address"
                  v-model="form.i_parent_company_website_address" />
              </div>
            </div>
            <div class="col-md-12">

              <div class="form-group" id="i_parent_company_registration_number">
                <label for="input-i_parent_company_registration_number">Registration number</label>
                <input type="text" class="form-control" id="input-i_parent_company_registration_number"
                  v-model="form.i_parent_company_registration_number" />
              </div>
            </div>

            <div class="col-md-12">

              <div class="form-group" id="i_parent_company_duns_number">
                <label for="input-i_parent_company_duns_number">Head office DUNS number</label>
                <input type="text" class="form-control" id="input-i_parent_company_duns_number"
                  v-model="form.i_parent_company_duns_number" />
              </div>
            </div>

            <div class="col-md-12">

              <div class="form-group" id="i_parent_company_vat_number">
                <label for="input-i_parent_company_vat_number">Head office VAT number</label>
                <input type="text" class="form-control" id="input-i_parent_company_vat_number"
                  v-model="form.i_parent_company_vat_number" />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Full name of the ultimate parent company</label>
                <input type="text" class="form-control" id="input-u_parent_company_name"
                  v-model="form.u_parent_company_name" trim
                  :class="{ 'is-invalid': v$.form.u_parent_company_name.$errors.length, 'form-control': true }">

                <div class="input-errors" v-for="error of v$.form.u_parent_company_name.$errors" :key="error.$uid">
                  <div class="error-text">Full name {{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="col-md-12">

              <div class="form-group" id="u_parent_company_office_address">
                <label for="input-u_parent_company_office_address">Registered office address</label>
                <input type="text" class="form-control" id="input-u_parent_company_office_address"
                  v-model="form.u_parent_company_office_address" />
              </div>
            </div>

            <div class="col-md-12">

              <div class="form-group" id="u_parent_company_registration_number">
                <label for="input-u_parent_company_registration_number">Registration number</label>
                <input type="text" class="form-control" id="input-u_parent_company_registration_number"
                  v-model="form.u_parent_company_registration_number" />
              </div>
            </div>

            <div class="col-md-12">


              <div class="form-group" id="u_parent_company_duns_number">
                <label for="input-u_parent_company_duns_number">Head office DUNS number</label>
                <input type="text" class="form-control" id="input-u_parent_company_duns_number"
                  v-model="form.u_parent_company_duns_number" />
              </div>
            </div>

            <div class="col-md-12">


              <div class="form-group" id="u_parent_company_vat_number">
                <label for="input-u_parent_company_vat_number">Head office VAT number</label>
                <input type="text" class="form-control" id="input-u_parent_company_vat_number"
                  v-model="form.u_parent_company_vat_number" />
              </div>
            </div>

            </template>
          
          </div>

          <div style="text-align:center">

            <button type="submit" class="btn btn-info">Save</button>
            <button type="button" class="btn" @click="close()" v-if="editing" style="margin-left: 10px">Close</button>
          </div>
        </form>
      </div>
    </div>


    <!-- <div id="modal-psc" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-dark text-light">
          </div>
          <div class="modal-body">
          </div>
        </div>
      </div>
    </div> -->

    <el-dialog v-model="showPsc" :show-close="true" size="50%" :destroy-on-close="true">
      <div >
        <supplier-psc-component :selected="psc" @save="savePsc($event)"></supplier-psc-component>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import { ProductSupplier } from '@/api_services/supplier'
import SupplierPscComponent from './SupplierPscComponent.vue'
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import CompanyFormComponent from '../company_settings/CompanyFormComponent.vue'

export default {
  setup () {
      return { v$: useVuelidate() }
    },
  components: { SupplierPscComponent, CompanyFormComponent },
  props: ['selected','editing'],
  data() {
    return {
      psc: null,
      psc_index: null,
      loaded_pscs: true,
      validate: [
        // 'full_name','registration_date','registered_vat_number','trading_names',
        // 'i_parent_company_name','u_parent_company_name', 'email'
        // ,'trading_status','enterprise_type'
      ],
      form: {
        'full_name': null,'office_address': null, 'email': null, 'phone': null, 'e_catalog': null , 'website_address': null, 'trading_status': null,
        'other_trading_status' :null,'registration_date': null,'company_registration_number' :null,'charity_registration_number': null,
        'head_office_duns_number': null,'registered_vat_number': null,'is_registered_with_trade_register': false,'registered_vat_number_relevant_details': null,
        'is_it_legal_requirement': false,'is_it_legal_requirement_additional_details': null, 'trading_names': null,'enterprise_type': 'yes',
        'psc_name': null,'psc_date_of_birth': null,'psc_nationality': null,'psc_country_state': null,'psc_service_address':null,'psc_join_date':null,'psc_conditions':null,
        'i_parent_company_name':null,'i_parent_company_office_address':null,'i_parent_company_website_address':null,'i_parent_company_registration_number':null,
        'i_parent_company_duns_number':null,'i_parent_company_vat_number':null,
        'u_parent_company_name':null,'u_parent_company_office_address':null,'u_parent_company_website_address':null,'u_parent_company_registration_number':null,
        'u_parent_company_duns_number':null,'u_parent_company_vat_number':null
      },
      showPsc: false
    }
  },
  validations() {
      return {
        form: {
          registration_date: {
            required,
            $autoDirty: true
          },
          registered_vat_number: {
            required,
            $autoDirty: true
          },
          trading_names: {
            required,
            $autoDirty: true
          },
          i_parent_company_name: {
            required,
            $autoDirty: true
          },
          u_parent_company_name: {
            required,
            $autoDirty: true
          },
          registered_vat_number_relevant_details: {
            required: requiredIf(function () {
              var ifreq = false
              if(this.form.is_registered_with_trade_register == 1) ifreq = true
              return ifreq
            }),
            $autoDirty: true
          },
        },
      }
    },
  watch: {
    form() {
      if (this.form.trading_status == 'Other') this.validate.push('other_trading_status')
      if (this.form.is_registered_with_trade_register == true) {
        this.validate.push('registered_vat_number_relevant_details')
      } else {
        const index = this.validate.indexOf("registered_vat_number_relevant_details");
          if (index !== -1) {
            this.validate.splice(index, 1);
          }
      }
      if (this.form.is_it_legal_requirement == true) this.validate.push('is_it_legal_requirement_additional_details')
      else {
        const index = this.validate.indexOf("is_it_legal_requirement_additional_details");
          if (index !== -1) {
            this.validate.splice(index, 1);
          }
      }

      this.validate = this.validate.filter((v, i, a) => a.indexOf(v) === i);

    }
  },
  mounted() {
    //this.initializeDatePicker();
  },
  created: function() {
    this.form = Object.assign({}, this.selected);
    this.form.call_off_pre_sign = 0
    this.init()
  },
  methods: {
    init() {
      this.company = Object.assign({},this.$store.state.savedUser.company)

      if(this.company){
        this.form.full_name = this.company.name
        this.form.office_address = this.company.address1 + ", " + this.company.city + ", " + this.company.state + ", " + this.company.zip
        this.form.email = this.company.email
      }
      if (!this.form.enterprise_type) this.form.enterprise_type = 'yes'
    },
    updateData:function(type, value){
      if(this.$store.state.isAllowed('COMPANY', 'CREATE') || this.$store.state.isAllowed('COMPANY', 'UPDATE')){
        if(type == "full_name") this.form.full_name = value
        if(type == "office_address") this.form.office_address = value
        if(type == "email") this.form.email = value
      }
    },
    initializeDatePicker() {
      flatpickr(this.$refs.dateInput, {
        dateFormat: "Y-m-d",
        altFormat: "F j, Y",
        altInput: true,
        altInputClass: "date-input-flatpickr-sdc form-control is-valid"
      });
    },
    openDatePicker() {
      flatpickr(this.$refs.dateInput).open();
    },
    savePsc(psc) {

      let self = this
      if (psc) {
        if (!this.form.pscs) this.form.pscs = []
        let index = this.psc_index
        if (index == null) {
          this.form.pscs.push(psc)
        } else {
          this.form.pscs.forEach(function(sub,i){
              if (index == i){
                self.form.pscs[i] = psc
              }
          })
        }
        this.loaded_pscs = false

        setTimeout(function(){
            self.loaded_pscs = true
        }, 1000);

        this.showPsc = false

          // this.$bvModal.hide('modal-psc')
      } else {
        this.psc = null
        this.psc_index = null
        // this.$bvModal.hide('modal-psc')
        this.showPsc = false
      }
    },
    removePsc(i) {
      this.form.pscs.splice(i,1)
      let self = this
      this.loaded_pscs = false
      setTimeout(function(){
          self.loaded_pscs = true
        }, 1000);
    },
    showPscModal(model,i) {
      // this.$bvModal.show('modal-psc')
      // const modalElement = document.getElementById('modal-psc');
      // const modal = new bootstrap.Modal(modalElement);
      // modal.show();

      if (model) this.psc = Object.assign({}, model);
      this.showPsc = true
      this.psc_index = i
    },
    async save(evt) {

      evt.preventDefault()

        const result = await this.v$.$validate()
        if (!result) return
      
      ProductSupplier.saveSupplierDetails(this.form)
        .then(result => {

          if(this.$store.state.isAllowed('COMPANY', 'CREATE') || this.$store.state.isAllowed('COMPANY', 'UPDATE')){
            this.$refs.company_form_component.clickSaveButton()
          }

          this.$toast.success('Supplier details are saved successfully')
          this.$emit('save', result.data.supplier)
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a company info')
          console.error('error:', error)
        })
    },
    close() {
      this.$emit('save', null)
    },
  },
  computed: {
    
  }
}
</script>

<style>
.date-input-flatpickr-sdc {
  background-color: transparent !important;
  /* padding-left: 0.75rem !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px  */
}
</style>