<template>
  <div v-loading="loading">
    <el-form label-position="top" @submit.prevent="submit">
      <div class="row">
        <div class="col-md-12">
          {{ form.product_price_cost.band }}
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Transaction Value</label>
            <br />
            <el-input type="number" v-model="form.transaction_value"></el-input>
          </div>
        </div>
      </div>

      <hr />
      <div>
        <el-button native-type="submit" type="primary" plain>Save</el-button>
        <el-button @click="onCancel()" class="mr-2">Close</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

import { ProcProdPriceCost } from '@/api_services/proc_prod_price_cost'

export default {
  props: ['price_cost'],
  data() {
    return {
      form: {},
      loading: false
    }
  },
  watch: {
    price_cost: {
      deep: true,
      handler() {
        this.loading = false
        if (this.price_cost) this.form = Object.assign({}, this.price_cost)
      }
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
      
      }
    }
  },
  created: function () {
    this.loading = false
    if (this.price_cost) this.form = Object.assign({}, this.price_cost)
  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.loading = true
      let request = this.form.id ? ProcProdPriceCost.update(this.form) : ProcProdPriceCost.create(this.form)

      request.then(result => {
        this.$toast.success("Price cost saved")
        this.$emit('close', null)
      })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })

    },
    onCancel() {
      this.$emit('close', null)
    },
  },
}
</script>
