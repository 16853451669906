<template>
  <el-form label-position="top" @submit.prevent="submit" v-loading="loading">
    <h5 v-if="pin_notice">Project {{ pin_notice.name }}</h5>
    <div>Pricing Setting</div>
    <br>
    <el-form-item label="Transaction Type" prop="transaction_type">
      <el-radio-group v-model="form.transaction_type">
        <el-radio-button label="Volume" value="volume" />
        <el-radio-button label="Value" value="value" />
      </el-radio-group>
    </el-form-item>

    <el-form-item label="Direction" prop="direction">
      <el-radio-group v-model="form.direction">
        <el-radio-button label="Greater or Equal" value=">=" />
        <el-radio-button label="Less than or Equal" value="<=" />
      </el-radio-group>
    </el-form-item>

    <el-form-item :label="form.transaction_type == 'value' ? 'Transaction Value' :'Transaction Volume'"
      prop="transaction_volume">
      <el-input v-model="form.transaction_volume" type="number"></el-input>
    </el-form-item>



    <el-form-item label="Total Usage Cost %" prop="total_usage_cost">
      <el-input v-model="form.total_usage_cost" type="number"></el-input>
    </el-form-item>

    <el-form-item label="Total Setup Cost %" prop="total_setup_cost">
      <el-input v-model="form.total_setup_cost" type="number"></el-input>
    </el-form-item>

    <el-button native-type="submit" type="primary" plain>Save</el-button>
    <el-button plain @click="handleClose">Close</el-button>
  </el-form>
</template>

<script>
import { SupplierhubLot } from '@/api_services/supplierhub_lot'

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

export default {
  props: {
    pricing: {
      type: Object,
      default: null
    },
    pin_notice: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      form: {

      },
      loading: false,
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
      }
    }
  },
  watch: {
    pricing: function () {
      if (this.pricing) this.form = Object.assign({}, this.pricing)
    },
    form: {
      handler(newValue) {

      },
      deep: true
    },
  },
  created: function () {
    if (this.pricing) this.form = Object.assign({}, this.pricing)
  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return
      let total = parseFloat(this.form.total_setup_cost) + parseFloat(this.form.total_usage_cost)
      if (total != 100) {
        this.$toast.error('Total cost must be 100')
        return
      }

      this.loading = true

      SupplierhubLot.savePricing(this.form.supplierhub_lot_id, this.form)
        .then(result => {
          this.$toast.success('Saved')
        })
        .catch(error => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
