import api from './api'

const API = '/api/card_transactions'

export const CardTransaction = {
  get: function(params) {
    return api.get(API, params)
  },
  generateReport: function(params) {
    return api.create(API + '/generate_report', params)
  },
  getFirstAndLastDate: function(params) {
    return api.get(API + '/first_and_last_date', params)
  },
  costDataReports: function(params) {
    return api.get(API + '/cost_data_reports', params)
  },
  emailCostDataReport: function(form) {
    let id = form.get('id')
    return api.updateFormData(API + '/cost_data_reports/' + id + '/email_report', form)
  },
  saveCostDataReport: function(form) {
    let id = form.get('id')
    return api.updateFormData(API + '/cost_data_reports/' + id + '/save_report_image', form)
  },
  resetCostDataReport: function(id) {
    return api.update(API + '/cost_data_reports/' + id, {})
  },
  deleteCostDataReport: function(id) {
    return api.delete(API + '/cost_data_reports/' + id, {})
  },
  groupedTransactionsReport: function(params) {
    return api.get(API + '/grouped_transactions_report', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  update: function(form) {
    let id = form.get('id')
    return api.updateFormData(API + '/' + id, form)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id, params)
  },
  deleteBySubmitDate: function(params) {
    return api.create(API + '/delete_by_submit_date', params)
  },
}
