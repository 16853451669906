<template>
  <div>
    <el-drawer
      :title="selected?.id ? 'Edit ' + selected.name : 'New User'"
      v-model="showForm"
      :destroy-on-close="true"
      direction="rtl"
    >
      <user-form
        :user="selected"
        @update="getUsers()"
      ></user-form>
    </el-drawer>

    <el-drawer
      :title="selected?.id ? 'Assign companies to ' + selected.name : ''"
      v-model="showCompanies"
      :destroy-on-close="true"
      direction="rtl"
    >
      <div style="padding: 10px"  v-if="selected">
        <user-companies-component
         style="margin-bottom: 10px;"
          :user="selected"
         
          @update="setUserCompanies($event)"
        ></user-companies-component>
        <hr>

        <user-company-component :user="selected" v-if="selected" @update="setUserCompany($event)"></user-company-component>
        <!-- <el-select
          v-model="selected.company_id"
          class="m-2"
          placeholder="Select"
        >
          <el-option
          :key="item.id"
            v-for="item in usercompanylist"
            
            :label="item.name"
            :value="item.id"
          />
        </el-select> -->

          <button
            type="button"
            class="btn bg-gradient-success btn-sm"
            @click="onSubmit()"
          >
            Save
          </button>
      </div>
    </el-drawer>

    <el-drawer
      :title="selected?.id ? 'Assign roles to ' + selected.name : ''"
      v-model="showRoles"
      :destroy-on-close="true"
      direction="rtl"
    >
      <div style="padding: 10px">
        <user-roles-component
          style="margin-bottom: 10px"
          :user="selected"
          v-if="selected"
          @update="setUserRoles($event)"
        ></user-roles-component>
        <button
          type="button"
          class="btn bg-gradient-success btn-sm"
          @click="onSubmit()"
        >
          Save
        </button>
      </div>
    </el-drawer>

    <not-allowed v-if="!$store.state.isViewable('users')"></not-allowed>

    <div v-else>
      <h2 class="text-white">Users</h2>
      <div style="text-align: left;" class="row">
        <div class="col-md-12">
          <div class="card my-3">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group text-sm bg-secondary rounded-3 my-4">
                    <div
                      class="form-check form-check-inline pt-2"
                      v-for="optionStatus in optionsStatus"
                      :key="optionStatus.id"
                    >
                      <input
                        :id="'mainradio' + optionStatus.value"
                        type="radio"
                        v-model="status"
                        @change="search()"
                        :value="optionStatus.value"
                      />
                      <label :for="'mainradio' + optionStatus.value" class="form-check-label text-bold text-light">{{
                        optionStatus.text
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <nav class="navbar shadow-none my-3 navbar-transparent">
                    <div class="bg-white border-radius-lg d-flex me-2">
                      <input
                        type="text"
                        name=""
                        id=""
                        class="form-control form-control-sm border-1 ps-3"
                        v-model="term"
                        placeholder="Search"
                      />
                      <button
                        class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                        @click="search()"
                      >
                        Search
                      </button>
                      <button
                        class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                        @click="create()"
                        v-allowed="'users,create'"
                      >
                        Add
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div v-if="isLoading" class="lmask"></div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr class="text-sm">
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Name
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Role
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Email
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Status
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Company</th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Companies
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-sm" v-for="user in users" :key="user.id">
                      <td>{{ user.name }}</td>
                      <td class="text-wrap">{{ getRoleNames(user) }}</td>
                      <td>{{ user.email }}</td>
                      <td>
                        <span class="badge badge-success">{{
                          statuses[user.status]
                        }}</span>
                      </td>
                      <td>{{ user.company ? user.company.name : '' }}</td>
                      <td>{{ user.companies.length }}</td>
                      <td style="width:1%">
                   

                        <el-button type="info" plain size="small"
                                  @click="showEdit(user)"
                            v-tooltip="'Edit credential'"
                            v-allowed="'users,update'"
                               >
                                <i class="fas fa-user-edit" ></i>
                               </el-button>
               
                        <el-button type="info" plain size="small"
                               @click="onShowCompanies(user)"
                            v-tooltip="'Show companies'"
                            v-allowed="'users,update'"
                             >
                              <i class="fas fa-building" ></i>
                             </el-button>
                  
                        <el-button type="info" plain size="small"
                             @click="onShowRoles(user)"
                            v-tooltip="'Show roles'"
                            v-allowed="'users,update'"
                           >
                            <i class="fas fa-briefcase" ></i>
                           </el-button>

                         <el-button type="danger" plain size="small"
                          @click="showRemove(user)"
                              v-tooltip="'Delete'"
                              v-allowed="'users,delete'"
                         >
                          <i class="fas fa-trash" ></i>
                         </el-button>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="getUsers()"></pagination>
            </div>
          </div>
        </div>

      </div>

      <b-modal
        id="modal-remove"
        title="Attention"
        centered
        header-bg-variant="danger"
        header-text-variant="light"
        @ok="remove"
      >
        <div v-if="to_remove">
          You are about to remove {{ to_remove.name }}, Proceed?
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Company } from '@/api_services/company'
import { User } from '@/api_services/user'
import UserRolesComponent from './UserRolesComponent.vue'
import UserForm from './UserForm.vue'
import UserCompaniesComponent from './UserCompaniesComponent.vue'
import UserCompanyComponent from './UserCompanyComponent.vue'
import { ProductSupplier } from '@/api_services/supplier'

export default {
  components: {
    UserForm,
    UserCompaniesComponent,
    UserRolesComponent,
    UserCompanyComponent
  },
  data() {
    return {
      optionsStatus: [
        { text: 'All', value: null },
        { text: 'Pending', value: 0 },
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 2 }
      ],
      status: 1,
      users: [],
      status_variant: ['info', 'success', 'danger'],
      statuses: ['Pending', 'Active', 'Inactive'],
      fields: [
        'id',
        'name',
        'roles',
        'email',
        'status',
        'company',
        'companies',
        'action'
      ],
      selected: null,
      original: null,
      to_remove: null,
      term: null,
      roles: [],
      usercompanylist:[],
      companies: [],
      role_names: [],
      company_names: [],
      selected_roles: [],
      selected_companies: [],
      isLoading: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      showForm: false,
      showCompanies: false,
      showRoles: false,
      product_details_form: {
        full_name: null, email: null, i_parent_company_name: null,
        registered_vat_number: null, registration_date: null, trading_names: null,
        u_parent_company_name: null
      }
    }
  },
  created: function() {
    this.getCompanies()
    this.getUsers()
  },
  methods: {
    getCompanies() {
      Company.get({ params: { per_page: 99999 } })
        .then(result => {
          if (result.data.companies) {
            this.usercompanylist = result.data.companies
          }
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching companies')
          console.error('error:', error.response.data)
        })
    },
    setUserCompany(company) {
      this.selected.company_id = company
    },
    setUserCompanies(collection) {
      this.selected.companies = collection || []
      this.selected.company_ids = [...new Set(this.selected.companies)]
    },
    setUserRoles(collection) {
      this.selected.roles = []
      let self = this

      if (!this.selected.roles) this.selected.roles = []
      collection.forEach(function(role) {
        self.selected.roles.push(role)
      })

      this.selected.role_ids = [...new Set(this.selected.roles)]
    },
    search() {
      this.meta.page = 1
      this.getUsers()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      User.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.$bvModal.hide('modal-remove')
          })
          this.getUsers()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing a user')
          console.error('error:', error.response.data)
        })
    },
    getRoleNames(user) {
      var role_names = ''
      user.roles.forEach(function(role, i) {
        if (i > 0) role_names += ', '
        role_names += role.name
      })
      return role_names
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.$bvModal.show('modal-remove')
    },
    showEdit(object) {
      this.selected = Object.assign({}, object)
      this.original = Object.assign({}, object)
      this.showForm = true
    },
    onShowRoles(object) {
      this.selected = Object.assign({}, object)
      this.showRoles = true
    },
    onShowCompanies(object) {
      this.selected = Object.assign({}, object)
      this.getCompanies()
      this.showCompanies = true
    },
    getUsers() {
      this.reset()
      this.isLoading = true
      this.showForm = false
      this.showCompanies = false
      this.showRoles = false
      User.get({
        params: { term: this.term, status: this.status, page: this.meta.page }
      })
        .then(result => {
          this.isLoading = false
          if (result.data.users) this.users = result.data.users
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching users')
          console.error('error:', error.response.data)
          this.isLoading = false
        })
    },
    gatherRoles() {
      var list = []
      var self = this
      this.selected_roles.forEach(function(name) {
        self.roles.forEach(function(r) {
          if (r.name === name && !list.includes(r.id)) {
            list.push(r.id)
          }
        })
      })
      this.selected.roles = list
    },
    gatherCompanies() {
      var list = []
      var self = this
      this.selected_companies.forEach(function(name) {
        self.companies.forEach(function(company) {
          if (company.name === name && !list.includes(company.id)) {
            list.push(company.id)
          }
        })
      })
      this.selected.companies = list
    },
    onSubmit(evt) {
      if (evt) evt.preventDefault()
      var self = this

      var action = this.selected.id
        ? User.update(this.selected)
        : User.create(this.selected)
      action
        .then(result => {

          Company.show(self.selected.company_id)
          .then(result => {
            if (result.data) {
              
              this.product_details_form.set_from_admin = 1
              this.product_details_form.email = result.data.company.email
              this.product_details_form.full_name = result.data.company.name
              this.product_details_form.i_parent_company_name = null
              this.product_details_form.registered_vat_number = null
              this.product_details_form.registration_date = null
              this.product_details_form.trading_names = null
              this.product_details_form.u_parent_company_name = null

              ProductSupplier.saveSupplierDetails(this.product_details_form)
              .then(results => {
                self.$toast.success('Saved successfully.')
                self.getUsers()
              })
              .catch(error => {
                self.$toast.error('An error occured while saving a user')
                console.error('error:', error.response.data)
              })
            }
          })
          .catch(error => {
            self.$toast.error('An error occured while fetching company')
            console.error('error:', error.response.data)
          })
          
        })
        .catch(error => {
          self.$toast.error('An error occured while saving a user')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
      evt.preventDefault()
      this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.showForm = true
      this.reset()
    }
  }
}
</script>