<template>
    <div>
      
      <el-tabs type="border-card">
        <el-tab-pane v-if="show_invite_tab" label="Import Invitations">
          <template v-if="!inputVisible">
            <el-button type="success" class="button-new-tag" :size="button_size" @click="showModal = true"
              ><i class="fas fa-file-import"></i>&nbsp;Import Excel/CSV</el-button
            >

            <el-table border class="mt-3" v-if="imported_data.length > 0" :data="imported_data" style="width: 100%">
                <el-table-column type="expand">
                    <template #default="props">
                        <div class="ms-4 text-sm">
                            <p class="text-sm mb-2">Company/Organization: <b>{{ props.row.company_name }}</b></p>
                            <p class="text-sm mb-2">Sector: <b>{{ props.row.sector }}</b></p>
                            <p class="text-sm mb-2">Alternate Email: <b>{{ props.row.alternate_email }}</b></p>
                            <p class="text-sm mb-2">Telephone: <b>{{ props.row.tel_no }}</b></p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="Primary Email" />
                <el-table-column prop="first_name" label="First Name" />
                <el-table-column prop="middle_name" label="Middle Name" />
                <el-table-column prop="last_name" label="Last Name" />
                <el-table-column prop="position" label="Position" />
                <el-table-column prop="title" label="Title" />
                
                <el-table-column align="right">
                    <template #default="scope">
                        <el-button
                            size="small"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)"
                            >
                        <i class="fa fa-trash"></i>&nbsp;Remove
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>  

          </template>
        </el-tab-pane>
        <el-tab-pane label="List of Invitations">
          <el-table :empty-text="'No invited external users found!'" border class="mt-3" :data="invitation_list" style="width: 100%">
                <el-table-column type="expand">
                    <template #default="props">
                        <div class="ms-4 text-sm">
                            <p class="text-sm mb-2">Company/Organization: <b>{{ props.row.company_name }}</b></p>
                            <p class="text-sm mb-2">Sector: <b>{{ props.row.sector }}</b></p>
                            <p class="text-sm mb-2">Alternate Email: <b>{{ props.row.alternate_email }}</b></p>
                            <p class="text-sm mb-2">Telephone: <b>{{ props.row.tel_no }}</b></p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="Primary Email" />
                <el-table-column prop="invite_date" label="Invitation Date" />
                <el-table-column prop="first_name" label="First Name" />
                <el-table-column prop="middle_name" label="Middle Name" />
                <el-table-column prop="last_name" label="Last Name" />
                <el-table-column prop="position" label="Position" />
                <el-table-column prop="title" label="Title" />
            </el-table>  
        </el-tab-pane>
        
      </el-tabs>

      
    </div>

    <el-dialog destroy-on-close v-model="showModal" title="Upload File" width="500" >
      <form @submit="uploadFile">
        <p>Please upload an .xlsx or .csv file. You can use the below template for the importing.<br> <b>Note: Primary email address is mandatory while other fields can be left blank.</b><br>

          <el-button-group>
            <el-link class="me-3" type="success" link href="/template/import_external_user_official_template.xlsx">Sample Excel
              Template</el-link>
            <el-link type="primary" link href="/template/import_external_user_official_template.csv">Sample CSV
              Template</el-link>
          </el-button-group>
        </p>

        <dropzone label="Drop or select file" :max_files="1" :clean="0" :multiple="false" extensions="'xlsx,xls,csv'" @input="setFile($event)"></dropzone>
        <button type="submit" class="btn btn-success" v-loading="loading" :disabled="!form.file">
          {{ loading ? 'Importing' : 'Import' }}
        </button>
      </form>
    </el-dialog>

    <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="false" v-model="error_modal" width="50%">
      <ul class="p-4 list-group">
        <template v-for="(message,i) in error_message" :key="i">
          <li v-if="message != '' " class="list-group-item"><i class="text-danger fa fa-info-circle"></i>&nbsp;{{ message
            }}</li>
        </template>
      </ul>
    </el-dialog>

  </template>
  
  <script>
  import { fa } from 'element-plus/es/locale/index.mjs';
import Dropzone from './Dropzone.vue'
  import { User } from '@/api_services/user'

  export default {
    emits: ['update'],
    components: {
      Dropzone
    },
    props:{
        show_invite_tab: {
            type: Boolean,
            required: false,
            default: true
        },
        invitation_list: {
            type: Object,
            required: false,
            default: []
        },
        add_text: {
            type: String,
            required: false,
            default: "New Email"
        },
        button_size: {
            type: String,
            required: false,
            default: "small"
        },
        tag_size: {
            type: String,
            required: false,
            default: "small"
        },
    },
    data() {
      return {
        error_modal: false,
        imported_data: [],
        loading: false,
        showModal: false,
        form: {},
        error: null,
        inputVisible: false
      }
    },
    watch: {
        imported_data: {
            handler(newValue, oldValue) {
                this.$emit('update', newValue)
            },
            deep: true
        }
    },
    created: function() {
      
    },
    methods: {
      handleDelete(index, row){
        this.imported_data.splice(index, 1)
      },
      getFormData() {
        var formData = new FormData()
        formData.append('file', this.form.file)
        formData.append('imported_data', JSON.stringify(this.imported_data))
        return formData
      },
      uploadFile(evt) {
        evt.preventDefault()
        let formData = this.getFormData()

        this.loading = true

        User.importExternalInvitations(formData)
          .then(result => {
            if(result.data.status == 1 || result.data.status == 2){
                //result.data.status == 1 ? this.$toast.success(result.data.message) : this.$toast.warning(result.data.message)
                this.imported_data = result.data.imported_data
              }
              else{
                this.error_message = result.data.message.split(',')
                this.error_modal = true
              }

            this.showModal = false
            this.form.file = null
          })
          .catch(error => {

          }).finally(() => {
            this.loading = false
        })
      },
      setFile(files) {
        this.form.file = files[0]
      },
    }
  }
  </script>
  
