<template>
    <div class="card my-3">
            <div class="card-header">
              <h5>{{ titleForPermissions() }}</h5>
            </div>
            <div class="card-body">
              <div v-for="(permission, i) in permissions" :key="i">
                <span class="text-lg text-bold">{{ i }}</span>
                <span class="text-xxs">
                  <a
                    class="link-primary text-bold mx-1"
                    @click="setPermission(i, 'select')"
                    >SELECT ALL</a
                  >
                  <a
                    class="link-primary text-bold mx-1"
                    @click="setPermission(i, 'unselect')"
                    >UNSELECT ALL</a
                  >
                </span>
                <div class="">
                  <span v-for="(act, index) in permission" :key="act">
                    <el-button
                      size="small"
                      :class="' bg-' + isTaken(act, i) + ' link-light'"
                      @click="setPermission(i, act)"
                      style="margin-right:5px"
                      >{{ act }}</el-button
                    >
                    <br v-if="index == 5" />
                    <!--                     
                    <button
                      :class="
                        'btn bg-' + isTaken(act, i) + ' link-light btn-sm'
                      "
                      @click="setPermission(i, act)"
                      style="margin-right:5px"
                    >
                      {{ act }}
                    </button> -->
                  </span>
                </div>
                <hr class="bg-secondary border-2" />
              </div>
            </div>
          </div>
</template>
    
<script>
import { Role } from '@/api_services/role_service'

export default {
    props: {
        permissions: {
            type: Object,
            required: false
        },
        role_permissions: {
            type: Object,
            required: false
        },
        original: {
            type: String,
            required: false
        },
        selected: {
            type: String,
            required: false
        },
    },
    data() {
        return{
            
        }
    },
    watch: {
        
    },
    created: function () {
       
    },
    methods: {
        isTaken(action, mod) {
            var variant = 'secondary'
            this.role_permissions.forEach(function(rp) {
                if (rp.permission) {
                if (rp.permission.action === action && rp.permission.module === mod) {
                    variant = 'info'
                }
                }
            })

            return variant
            },
        setPermission(title, action) {
            var params = {
                module: title,
                action: action
            }
            Role.setPermission(this.selected.id, params)
                .then(result => {
                    this.$emit("showpermission", this.selected)
                })
                .catch(error => {
                this.$toast.error('An error occured while fetching role permissions')
                console.error('error:', error.response.data)
                })
        },
        titleForPermissions() {
        return this.original.name + ' permissions'
        },
    }
}
</script>
    
<style></style>
    