<template>
  <div>
    <not-allowed v-if="!$store.state.isViewable('companies') && if_from_files == 0"></not-allowed>

    <div v-else>
      <loading-component :loading="loading" :message="loading_title_message" :content="loading_content_message"></loading-component>

      <h2 v-if="if_from_files == 0" class="text-white">Companies</h2>
      <div style="text-align: left;" class="row">
        <div :class="if_from_files == 0 ? 'col-md-8' : 'col-md-12'">
          <div class="card my-3">
            <div class="px-0 pb-2 card-body">
              
              <div class="table-responsive">

                <div class="row">
                  <div class="col-md-8">
                    <h5 v-if="if_from_files == 1" class="ms-4 mb-4 text-dark">Uploader Companies ({{ project_data.name }})</h5>
                  </div>
                  <div class="col-md-4">
                    <el-button v-tooltip="'Close'" type="danger" size="large" @click="close()" v-if="if_from_files == 1" class="float-end me-3">
                      <i class="fa fa-times"></i>&nbsp;
                      Close</el-button>
                  </div>
                </div>

                <nav
                  v-if="if_from_files == 0"
                  class="navbar shadow-none my-3 navbar-transparent float-start"
                >
                <button
                      class="pull-left btn bg-success text-white my-1 me-1 btn-sm"
                      @click="showExport"
                    >
                    <i class="fa-solid fa-file-export"></i> Export
                    </button>
              </nav>
                
                <nav
                  v-if="if_from_files == 0"
                  class="navbar shadow-none my-3 navbar-transparent float-end"
                >
                  <div class="bg-white border-radius-lg d-flex me-2">
                    

                    <input
                      type="text"
                      name=""
                      id=""
                      class="form-control form-control-sm border-1 ps-3"
                      v-model="term"
                      placeholder="Search"
                    />
                    <button
                      class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                      @click="search()"
                    >
                      Search
                    </button>
                    <button
                      class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                      @click="createCompany"
                      v-allowed="'companies,create'"
                    >
                      Add
                    </button>
                    
                  </div>
                </nav>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Name
                      </th>
                      <th
                        v-if="if_from_files == 0"
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Contact Person
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!companies.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else v-for="company in companies" :key="company.id">
                      <td class="text-sm">{{ company.name }}</td>
                      <td v-if="if_from_files == 0" class="text-sm">{{ company.contact_person }}</td>
                      <td class="text-sm" style="width:1%">
                        <el-button
                        v-if="if_from_files == 1"
                              type="default"
                              size="small"
                              @click="handleViewFiles(company)"
                              >View Files ({{ company?.supplier_hub_files?.length }})</el-button
                            >

                        <a
                          v-if="if_from_files == 0"
                          class="link me-3"
                          @click.prevent="editCompany(company)"
                          v-tooltip="'Edit'"
                          v-allowed="'companies,update'"
                        >
                          <i class="fas fa-user-edit text-secondary"></i>
                        </a>
                        <a
                          v-if="if_from_files == 0"
                          class="link"
                          @click.prevent="showRemove(company)"
                          v-tooltip="'Delete'"
                          v-allowed="'companies,delete'"
                        >
                          <i class="fas fa-trash text-secondary"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="getCompanies()" />
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="showCompanyForm">
          <div class="card my-3">
            <div class="pb-0 card-header">
              <h5 class="font-weight-bolder">{{ selectedName }}</h5>
            </div>
            <div class="card-body">
              <company-form-component :key="'companyform' + company_form_key" :company="selected" @save="closeCompanyForm" />
            </div>
          </div>
        </div>
      </div>


      <el-dialog
        id="modal-remove"
        v-model="dialogVisible"
        title="Confirm Delete?"
        width="500"
      >
        <div v-if="to_remove">
          You are about to remove {{ to_remove.name }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>

      <el-dialog
        id="modal-export"
        v-model="modal_export"
        title="Export Companies"
        width="70%"
      >
      
      <TransferComponent
        :key="'transfer_' + transfer_key"
        :filter_placeholder="'Search...'"
        :transfer_data="companies"
        :left_title="'List of Companies'"
        :right_title="'Selected Companies for Export'"
        :right_button_text="'Export'"
        @update_value="getSelectedCompanies"
        @handle_click="exportCompanies"
      ></TransferComponent>

        <template #footer>
          <div class="dialog-footer">
            <el-button @click="modal_export = false">Close</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Company } from '@/api_services/company'
import { Council } from '@/api_services/council'
import CompanyFormComponent from '../company_settings/CompanyFormComponent.vue'
import TransferComponent from '@/components/common/TransferComponent.vue'
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import $ from 'jquery'
import Swal from 'sweetalert2'

export default {
  props: {
    if_from_files: {
      type: Number,
      default: 0
    },
    project_data: {
      type: Object,
      default: null
    },
    company_data: {
      type: Object,
      default: null
    },
    filetype: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    CompanyFormComponent, LoadingComponent, TransferComponent
  },
  data() {
    return {
      company_form_key: 1,
      transfer_key: 1,
      selected_export_ids: null,
      loading_title_message: null,
      loading_content_message: null,
      loading: false,
      modal_export: false,
      dialogVisible: false,
      showCompanyForm: false,
      councils: [],
      isLoading: true,
      companies: [],
      selected: null,
      original: null,
      to_remove: null,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  created: function() {
    this.getCompanies()
    this.getCouncils()
  },
  methods: {
    handleViewFiles(data){
      this.project_data.selected_company_id = data.id
      this.$emit("viewFile", this.project_data)
    },
    close() {
      this.$emit("close_file", false)
    },
    getSelectedCompanies(value){
      this.selected_export_ids = value
    },
    showExport(){
      this.transfer_key++
      this.modal_export = true
    },
    exportCompanies(){
      Swal.fire({
        icon: 'info',
          text:
            'You are about to export these companies, are you sure you are going to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',  
      }).then(result => {
          if (result.isConfirmed) {
            
            this.loading_title_message = "Exporting Companies"
            this.loading_content_message = "Currently exporting selected companies. Please wait!"

            this.loading = true
            Company.exportCompanies("export_as_excel", { params: { selected_ids: this.selected_export_ids } })
              .then(result => {
                const url = result.data
                const link = document.createElement('a')
                link.href = url
                link.click()

                this.modal_export = false
              })
              .catch(error => {
                this.$toast.error('An error occured while exporting companies')
                console.error('error:', error.response.data)
              })
              .finally(() => {
                this.loading = false
              })
            
          }
      })
    },
    getCouncils() {
      Council.get({ params: {} })
        .then(result => {
          let cs = result.data.councils ? result.data.councils : [];
          this.councils = cs
          .slice(1)
          .map(c => {
            return { value: c.council, label: c.council };
          });
        })
        .catch(error => {
          console.error('error:', error.response.data);
        });
    },
    search() {
      this.meta.page = 1
      this.getCompanies()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      Company.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          if (this.to_remove?.id == this.selected?.id) {
            this.reset()
          }
          this.getCompanies()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing company')
          console.error('error:', error.response.data)
        })
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    // showEdit(type) {
    //   this.selected = Object.assign({}, type)
    //   this.original = Object.assign({}, type)
    // },
    getCompanies() {
      this.isLoading = true

      let company_ids = []
      if(this.if_from_files == 1){
        for (const [key, value] of Object.entries(this.company_data)) {
          company_ids.push(value.id)
        }
      }
      
      Company.get({ params: { 
          term: this.term, 
          page: this.meta.page, 
          company_ids: company_ids, 
          project_type: this.filetype,
          project_id: this.project_data?.id,
        }
      })
        .then(result => {
          if (result.data.companies) this.companies = result.data.companies
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching companies')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // onSubmit(evt) {
    //   evt.preventDefault()
    //   var action = this.selected.id
    //     ? Company.update(this.selected.id, this.selected)
    //     : Company.create(this.selected)
    //   action
    //     .then(result => {
    //       this.$toast.success('Saved successfully')
    //       if (result.data.company) {
    //         this.showEdit(result.data.company)
    //       } else {
    //         this.reset()
    //       }
    //       this.getCompanies()
    //     })
    //     .catch(error => {
    //       this.$toast.error('An error occured while saving a company')
    //       console.error('error:', error.response.data)
    //     })
    // },
    // onCancel(evt) {
    //   evt.preventDefault()
    //   this.reset()
    // },
    reset() {
      this.original = null
      this.selected = null
    },
    createCompany() {
      this.showCompanyForm = true,
      this.selected = null
      this.company_form_key = 0
    },
    editCompany(company) {
      this.showCompanyForm = true
      this.selected = company
      this.company_form_key = this.selected.id
    },
    closeCompanyForm() {
      this.getCompanies()
      this.showCompanyForm = false
    }, 
    // create() {
    //   this.original = {
    //     name: null,
    //     contact_person: null,
    //     dba_name: null,
    //     council_name: null,
    //     address1: null,
    //     city: null,
    //     state: null,
    //     zip: null,
    //     proposed_annual_net_saving: null
    //   }
    //   'contact_person',
    //     'dba_name',
    //     'council_name',
    //     'address1',
    //     'city',
    //     'state',
    //     'zip',
    //     'proposed_annual_net_saving'
    //   this.selected = Object.assign({}, this.original)
    // }
  },
  computed: {
    selectedName() {
      return this.selected?.id ? `Edit ${this.selected.name}` : 'New'
    }
  }
}
</script>
