<template>
  <div>
    <b-table :items="transactions" :fields="fields" responsive="sm" thead-class="hidden_header">
      <template #cell(transactions)="row" > 
        
        <div class="row" style="padding: 0px">
          <div class="col-md-3">
            <!-- <label class="sr-only" for="inline-form-input-username">Card Type</label> -->
            <div class="input-group mb-3">
              <button type="button" class="btn btn-outline-secondary btn-sm mb-0 py-0 px-1">card type</button>
              <v-select v-model="row.item.terminology" style="width: 60%" 
                class="search-term" label="description" :filterable="false" 
                :options="terminologies" @search="onSearchTerms">
                <template v-slot:no-options>
                  search card types
                </template>
                <template v-slot:option="option" >
                  <div class="d-center">
                    {{ option.description }}
                    </div>
                </template>
                <template v-slot:selected-option="option" >
                  <div class="selected d-center text-xxs">
                    {{ option.description }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <div class="col-md-2">
            <span>{{ getCategoryLabel(row.item.terminology)}}</span>
          </div>
          <div class="col-md-2" v-if="category < 1">
            <div class="input-group mb-3">
              <button type="button" class="btn btn-outline-secondary btn-sm mb-0 px-1">count</button>
              <input type="number" class="form-control form-control-sm px-1" placeholder="0" v-model="row.item.volume">
            </div>
          </div>
          <div class="col-md-2"  v-if="category < 1">
            <div class="input-group mb-3">
              <button type="button" class="btn btn-outline-secondary btn-sm mb-0 px-1">Txn Value</button>
              <input type="number" class="form-control form-control-sm px-1" placeholder="0" v-model="row.item.transaction_value">
            </div>
          </div>
          <div class="col-md-2">
            <div class="input-group mb-3">
              <button type="button" class="btn btn-outline-secondary btn-sm mb-0 px-1">Txn Charges</button>
              <input type="number" class="form-control form-control-sm px-1" placeholder="0" v-model="row.item.transaction_charges">
            </div>
          </div>
          <div class="col-md-1" style="padding-left: 5px">
            <a @click="removeTransaction(row)" v-tooltip="'remove transaction'" class="btn bg-danger btn-sm">
              <i class="fa-solid fa-trash-can text-white"></i>
            </a>
            <!-- <b-button variant="danger" size="sm" class="mr-2" @click="removeTransaction(row)" >Remove</b-button> -->
          </div>
        </div>
      </template>
    </b-table>
    <b-button size="sm" class="btn bg-gradient-secondary btn-sm" @click="addTransaction()">Add</b-button>
  </div>
</template>

<script>

import { MerchantTerminology } from '@/api_services/merchant_terminology'
import { compareByFieldSpecs } from '@fullcalendar/core/internal';

export default {
  props: ['items','category'],
  data() {
    return {
      all_transactions: this.items,
      others: [],
      transactions: [],
      terminologies: [],
      fields: ['transactions'],
      transaction_count: 0
    }
  },
  watch: {
    all_transactions: {
      deep: true,
      handler() {
          // this.filterByCategory()
      },
    },
    category: function() {
      this.filterByCategory()
    },
    transactions: {
      deep: true,
      handler() {
        let self = this
        this.transactions.forEach(function(transaction){
          let found = false

          self.items.forEach(function(item,index){
              if (transaction.code == item.code) {
                found = true
                self.items[index] = transaction
              }
          })

          if (!found) {
            self.items.push(transaction)
          }
        })
        
        this.$emit('update', this.items)
      }
    },
  },
  created: function() {
    // this.transactions = Object.assign([], this.items);
    // this.transactions = this.items;
    // if (this.transactions.length == 0) this.addTransaction()
    // else this.filterByCategory()
    this.filterByCategory()
  },
  methods: {
    getRandomCode() {
      let code = Math.random().toString(16).slice(2)
      let unique = true
      this.items.forEach(function(item){
        if (item.code == code) unique = false
      })
      
      return unique ? code : this.getRandomCode()
    },
    filterByCategory() {
      let list = []
      let self = this
      let others = []
      if (this.items) {
      this.items.forEach(function(transaction){
        transaction.code = self.getRandomCode()
        if (transaction.terminology) {
          if (self.category == transaction.terminology.category) {
            list.push(transaction)
          } else {
            others.push(transaction)
          }
        } else {
          if (self.category == 0) {
            list.push(transaction)
          } {
            others.push(transaction)
          }
        }
      })
    }

      this.transactions = list
      this.others = others
    },
    showItem(transaction) {
      let show = false
        if (transaction.terminology) {
          if (self.category == transaction.terminology.category) {
            show = true
          }
        } else {
          if (self.category == 0) {
            show = true
          }
        }

        return show
    },
    removeTransaction(transaction, props) {
      this.transactions.splice(transaction.index, 1)
      props.items.splice(transaction.index, 1)
    },
    addTransaction() {
      this.transaction_count += 1
      this.transactions.push({
        terminology: null,
        volume: null,
        transaction_charges: null,
        amount: null,
        code: this.getRandomCode()
      })
    },
    getCategoryLabel(terminology) {
      if (!terminology) return ""
      let string = ""

      switch (terminology.category) {
        case 1:
          string = '(Premium)'
          break;
        case 2:
          string = "(ACC Fees)"
          break;
        case 3:
          string = "(Non-Transactional)"
          break;
      }

      return string
    },
    onSearchTerms(search, loading) {
      if(search.length) {
        loading(true);
        this.searchTerminologies(loading, search, this);
      }
    },
    searchTerminologies(loading, search, vm) {
        MerchantTerminology.get({params: { per_page: 20, term: search, category: this.category}})
        .then(result => {
          if (result.data.terminologies)
            vm.terminologies = result.data.terminologies
            loading(false);
        })
        .catch(error => console.error('error:', error.response.data))
    },
  }
}
</script>

<style>
  .hidden_header {
    display: none !important;
  }

  .small-input .input-group-prepend .input-group-text {
    font-size: 10px;
  }

  .search-term .vs__dropdown-toggle{
    height: 30px;
  }
</style>
