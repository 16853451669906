<style>
.el-segmented{
  padding: 0px !important;
}

.el-segmented__item{
  margin: 0px !important;
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border: 1px solid #dcdfe6
}


.el-segmented__item.is-selected{
  font-weight: bold !important;
}

</style>

<template>

  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>My Pipeline</h5>
            <div class="pb-0 mt-5">
              <div class="row">
                <div class="col-md-2">
                  <el-input v-on:keyup.enter="loadPipelines" v-model="search_value" placeholder="Search"
                    class="input-with-select">
                    <template #append>
                      <el-button @click="loadPipelines"><i class="fa fa-search"></i></el-button>
                    </template>
                  </el-input>
                </div>
                <div class="col-md-10" style="text-align: right;">
                  <button type="button" class="btn btn-info btn-sm mb-0 me-2" @click="showModal = true">
                    <i class="fa-solid fa-upload me-1"></i> Import
                  </button>
                  <button type="button" class="btn btn-success btn-sm mb-0 me-2" @click="modal_export = true">
                    <i class="fa-solid fa-download me-1"></i> Export
                  </button>

                  <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" @click="loadPipelines">
                    <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mb-0 me-2" @click="addPipeline">
                    <i class="fa-solid fa-plus me-1"></i> Add
                  </button>
                </div>
              </div>
              <div class="text-end">
              </div>
            </div>
            <br>
            <div class="table-responsive" v-loading="loading">
              <table class="table table-fluid align-items-center mb-0 table-hover ">
                <thead>
                  <tr>

                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Procurement Name
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Call-Off Start Date
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Contact Person Name
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Contact Person Email
                    </th>

                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Keywords
                    </th>
                    <th v-if="$store.state.isAdmin()"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      User
                    </th>
                    <th v-if="$store.state.isAdmin()"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Company
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!pipelines.length">
                    <td v-if="loading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="pipeline in pipelines" :key="pipeline.id">

                    <td>{{ pipeline.project_name }}</td>
                    <td>{{ $filters.dateFormatFilter(pipeline.call_off_start_date) }}</td>
                    <td>{{ pipeline.person.first_name + " " + pipeline.person.last_name }}</td>
                    <td>{{ pipeline.person.email }}</td>
                    <td><el-tag class="me-1" v-for="tag in pipeline.keywords" :key="tag" :disable-transitions="false">
                        {{ tag }}
                      </el-tag>
                    </td>
                    <td v-if="$store.state.isAdmin()">{{ pipeline.user }}</td>
                    <td v-if="$store.state.isAdmin()">{{ pipeline.company }}</td>
                    <td>
                      <el-button-group>
                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Edit</span>
                          </template>

                          <el-button v-allowed="'my_pipeline,update'" type="primary" size="small"
                            @click="editPipeline(pipeline)">
                            <i class="fa fa-edit"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Delete</span>
                          </template>

                          <el-button v-allowed="'my_pipeline,delete'" type="danger" size="small"
                            @click="deletePipeline(pipeline.id)">
                            <i class="fa fa-trash"></i></el-button>
                        </el-tooltip>
                      </el-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="loadPipelines()" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog destroy-on-close id="add-modal" :close-on-click-modal="false" v-model="add_modal" width="60%">
    <MyPipelineForm :key="'project_' + selected.id" @save="afterSave" @cancel="handleClose" :selected="selected">
    </MyPipelineForm>
  </el-dialog>

  <el-dialog destroy-on-close v-model="showModal" title="Upload File" width="500">
    <form @submit="uploadFile" v-loading="loading">
      <p>Please upload an .xlsx or .csv file. You can use the below template for the importing.<br>

        <el-button-group>
          <el-link class="me-3" type="success" link href="/template/import_pipepile_official_template.xlsx">Sample Excel
            Template</el-link>
          <el-link type="primary" link href="/template/import_pipeline_official_csv_template.csv">Sample CSV
            Template</el-link>
        </el-button-group>
      </p>

      <dropzone label="Drop or select file" :max_files="1" :clean="0" :multiple="false" extensions="'xlsx,xls,csv'"
        @input="setFile($event)"></dropzone>
      <button type="submit" class="btn btn-success" v-loading="loading" :disabled="!import_form.file">
        {{ loading ? 'Saving' : 'Save' }}
      </button>
    </form>
  </el-dialog>

  <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="false" v-model="error_modal" width="50%">
    <ul class="p-4 list-group">
      <template v-for="(message,i) in error_message" :key="i">
        <li v-if="message != '' " class="list-group-item"><i class="text-danger fa fa-info-circle"></i>&nbsp;{{ message
          }}</li>
      </template>
    </ul>
  </el-dialog>

  <el-dialog destroy-on-close id="modal-export" v-model="modal_export" title="Export Pipelines" width="70%">

    <TransferComponent :filter_placeholder="'Search...'" :transfer_data="pipelines" :left_title="'List of Pipelines'"
      :right_title="'Selected Pipelines for Export'" :right_button_text="'Export'" :show_export_type="true"
      @update_value="getSelectedPipelines" @handle_click="exportPipelines"></TransferComponent>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="modal_export = false">Close</el-button>
      </div>
    </template>
  </el-dialog>

</template>
    
    <script>

    import MyPipelineForm from './MyPipelineForm.vue'
    import { MyPipeline } from '@/api_services/my_pipeline';
    import Swal from 'sweetalert2';
    import Dropzone from '@/components/common/Dropzone'
    import TransferComponent from '@/components/common/TransferComponent.vue'

    export default {
      computed: {
        user () {
          return this.$store.state.savedUser // get state
        }
      }, 
      components: {
        MyPipelineForm, Dropzone, TransferComponent
      },
      data() {
        return {
            selected_export_ids: null,
            modal_export: false,
            error_message: [],
            error_modal: false,
            import_form: {},
            showModal: false,
            search_value: "",
            add_modal: false,
            pipelines: [],
            meta: {
              page: 1,
              total: 0,
              pages: 1
            },
          selected: null,
            loading: false
        }
      },
      watch: {
      },
      created: function () {
        this.loadPipelines()
      },
      methods: {
        exportPipelines(export_type){
          Swal.fire({
            icon: 'info',
              text:
                'You are about to export these pipelines, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {
                
                this.loading_title_message = "Exporting Pipelines"
                this.loading_content_message = "Currently exporting selected pipelines. Please wait!"

                this.loading = true
                this.globalState.loading = true
                MyPipeline.export(export_type, { params: { selected_ids: this.selected_export_ids } })
                  .then(result => {

                    const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);

                    link.href = url;
                    link.setAttribute('download', 'pipeline_export.' + (export_type == 'csv' ? 'csv':'xlsx'));  // Set the file name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url); 

                    // const url = result.data
                    // const link = document.createElement('a')
                    // link.href = url
                    // link.target ="_blank"
                    // link.click()

                    this.modal_export = false
                    this.loading = false
                    this.globalState.loading = false
                  })
                  .catch(error => {
                    this.$toast.error('An error occured while exporting pipelines')
                    console.error('error:', error.response.data)
                  })
                  .finally(() => {
                    this.loading = false
                    this.globalState.loading = false
                  })
                
              }
          })
        },
        getSelectedPipelines(value){
          this.selected_export_ids = value
        },
        uploadFile(evt) {
          evt.preventDefault()

          var formData = new FormData()
          formData.append('file', this.import_form.file)

          this.loading = true

          MyPipeline.import(formData)
            .then(result => {
              if(result.data.status == 1 || result.data.status == 2){
                result.data.status == 1 ? this.$toast.success(result.data.message) : this.$toast.warning(result.data.message)
                this.loadPipelines()
              }
              else{
                this.$toast.error('Failed to import')
                this.error_message = result.data.message.split(',')
                this.error_modal = true
              }

              this.showModal = false
              this.import_form.file = null
            })
            .catch(error => {

            }).finally(() => {
              this.loading = false
          })
        },
        setFile(files) {
          this.import_form.file = files[0]
        },
        deletePipeline(id) {

          Swal.fire({
            icon: 'info',
              text:
                'You are about to delete this pipeline, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {

                this.globalState.loading = true
                MyPipeline.delete({ id: id})
                  .then(result => {
                    this.$toast.success(result?.data?.message || 'Pipeline has been removed successfully')
                    this.loadPipelines()
                    
                    this.handleClose()
                  })
                  .catch(error => {
                      this.$toast.error('An error occured while deleting pipeline')
                      console.error('error:', error.response.data)

                  }).finally(() => {
                    this.globalState.loading = false
                  })
                
              }
          })
        },
        editPipeline(pipeline){
          this.selected = pipeline
          this.add_modal = true
        },
        addPipeline(){
          this.selected = { id: null }
          this.add_modal = true
        },
        handleClose() {
          this.add_modal = false
        },
        afterSave() {
          this.loadPipelines()
          this.add_modal = false
        },
        loadPipelines(){
          this.globalState.loading = true
          this.handleClose()
          this.procurement_projects = []
          MyPipeline.get({
              params: {
                page: this.meta.page,
                search_value: this.search_value
              }
            })
            .then(result => {
              if (result.data.pipelines) this.pipelines = result.data.pipelines
              this.meta = result.data.meta
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      },
    }
    </script>
    