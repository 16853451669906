<template>
    <h4>Procurement Project History</h4>
    <p>{{ selected.name }}</p>
    <div class="card">
        <div class="card-body">
            <div class="row">
              <div class="col-md-8 mb-5">
                <label>Date Range:</label><br>
                          <el-date-picker
                              v-model="date_range"
                              type="daterange"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                            />
              </div>
              <div class="col-md-4 mb-5">
                <label class="hide"></label><br>
                <el-button class="mt-1" type="warning" @click="exportHistories"><i class="fas fa-file-export"></i>&nbsp; Export as Excel</el-button>
              </div>  
            </div>

            <el-timeline v-if="histories.length > 0" >
                <el-timeline-item
                placement="top"
                v-for="(history, index) in histories"
                :key="index"
                :timestamp="$filters.datetimeFormatFilter(history.created_at)"
                :hollow="true"
                :type=" index == 0 ? 'primary' : '' "
                >
                
                <!--<h6 class="mb-0">{{ history.column_name.replace('_', ' ').replace("s", "") }}</h6>-->
                <p class="p-0">
                    {{ history.history_text }}
                </p>

                </el-timeline-item>
            </el-timeline>

            <h5 v-else>No history found!</h5>
        </div>
    </div>
    
  </template>
  
  <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects'
    import Swal from 'sweetalert2'

    export default {
      props: {
        procurement_project_id: Number,
        name: String
      },
      data() {
        return {
          date_range: null, 
            selected: {
                id: null,
                name: null
            },
            histories: [],
        }
      },
      created: function () {
        this.selected.id = this.procurement_project_id
        this.selected.name = this.name
        this.loadHistory(this.selected)

      },
      watch: {
        procurement_project_id(procurement_project_id) {
          this.selected = procurement_project_id
        },
        name(name) {
          this.selected.name = name
        }
      },
      computed: {
        
      },
      methods: {
        exportHistories(){
          Swal.fire({
            icon: 'info',
              text:
                'You are about to export this project histories, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {

                this.loading = true
                ProcurementProjects.exportHistories("export_as_excel", this.selected.id, { params: { 
                  date_range: this.date_range
                 } })
                  .then(result => {

                    this.$toast.success('Export success!')

                    const url = result.data
                    const link = document.createElement('a')
                    link.href = url
                    link.click()
                  })
                  .catch(error => {
                    this.$toast.error('An error occured while exporting companies')
                    console.error('error:', error.response.data)
                  })
                  .finally(() => {
                    this.loading = false
                  })
                
              }
          })
        },
        loadHistory(project){
            ProcurementProjects.load_history(project.id)
            .then(result => {
                if (result.data.procurement_project_history) {
                    this.histories = result.data.procurement_project_history
                }
                else{
                    this.$toast.error('No data found')
                }
            })
            .catch(error => {
                this.$toast.error('An error occured while fetching request')
                console.error('error:', error.response.data)
            })
        }
      }
    }
  </script>
  