<template>
  <el-card
            
    title=""
    img-top
    tag="article"
    style="margin-bottom: 10px;text-align:left"
    class=""
  >
  <div class="row">
    <div class="col-md-3">
      <image-carousel-component :images="getProductImages(product)"></image-carousel-component>
    </div>
    <!-- <div class="col-md-3">
        <div style="height: 100px;overflow: hidden;margin:10px">
          <b-img center :src="getImagePath(product) " alt="Image" style="width: 100px;"></b-img>
        </div>
    </div> --> 
    <div class="col-md-9">
        <div style="margin-bottom: 10px;">
          <h4>{{ product.name }}</h4>
    <div style="font-size: 25px;color:#ce6221;font-weight:bold">£ {{ product.prices  }}  {{ product.quantity > 0 ? "("+product.quantity+" qty)" : '' }}</div>
    <div class="card-text" style="height: 50px;font-size: 12px;color: #676666;">
    Descriptin: {{ showDescription(product)}}
    </div>
    <div style="font-size: 12px" >Supplier: <a v-if="product.product_supplier" :href="'/supplier/'+product.product_supplier.name">{{product.product_supplier.name}}</a></div>
    <div>
      <span v-for="category in product.categories" :key="category.id" class="custom-tag">{{category.name}}</span>
    </div>
    <div>
      Specifications
      <div v-for="product_spec in product.product_specifications" :key="product_spec.id" class="custom-tag">{{product_spec.description}}</div>
    </div>
  </div>  
    <p v-if="!preview">{{product.points}} Points</p>
    <b-button :href="'/search-solution/'+product.id+'?category_id='+category_id" variant="primary" size="sm" v-if="!preview" style="margin-right: 10px">More details</b-button>
    <b-button variant="info" size="sm" v-if="!preview" @click="viewQA()">Continue Quote</b-button>
    </div>
  </div>

  <el-dialog
      v-if="product"
      :id="'modal-question-answer-'+product.id"
      title="Quotation"
      v-model="question_answer_modal[product.id]"
      width="60%"
    >
      <div v-if="product">
        <question-and-answer-component :product="product" @save="closeQA()"></question-and-answer-component>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="question_answer_modal[product.id] = false">
            Ok
          </el-button>
        </div>
      </template>
    </el-dialog>
   
  </el-card>
</template>

<script>

import ImageCarouselComponent from '../../common/ImageCarouselComponent.vue'
// import QuestionAndAnswerComponent from './QuestionAndAnswerComponent.vue'

  export default {
    props: ['product','preview','category_id'],
    data() {
      return {
        question_answer_modal:[ this.product.id ]
      }
    },
    components: { ImageCarouselComponent },
    created: function() {
      console.log(this.category_id)
      // this.getProducts()
    },
    // computed: {
    //   total: function() {
    //     if (this.selected) {
    //       return (this.selected.prices * (this.user_quantity / this.selected.quantity)).toFixed(4);
    //     }
    //     return 0
    //   }
    // },
    methods: {
      viewQA() {
        this.question_answer_modal[this.product.id] = true
      },
      closeQA() {
        this.question_answer_modal[this.product.id] = false
      },

      getProductImages(product) {
        var images = []
        product.product_images.forEach(function(image){
          images.push(image.absolute_path)
        })

        return images
      },
      showDescription(product) {
        if (!product.short_description) return "n/a"
        if (!product.short_description.length) return 'n/a'
        if (product.short_description.length > 100)  return product.short_description.substring(0, 100)+'...';
        if (product.short_description == 'undefined') product.short_description = 'n/a'
        return product.short_description
      },
    
    }
  }
</script>

<style scoped>
  .custom-tag {
      font-size: 12px;
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      padding: 5px;
      border-radius: 8px;
      margin: 3px;
  }

  .total-cost {
        padding: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #F44336;
  }
</style>