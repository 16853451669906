<template>
    <template v-for="(question, index) in questions">
      <el-card class="mb-2 question_card " shadow="never">
                <template #header>
                  <p  class="mb-0" v-html="'<b>' + question.item.question_no + 
                  (question.item.question_no_letter != '' ? ' ('+question.item.question_no_letter+')' : '') + '</b> ' +
                  question.item.question_text"></p>
                </template>


                <BooleanTypeQuestionComponent
                  v-if="question.item.type == QaQuestionTypes.TYPE_BOOLEAN"
                  @change="triggerQuestionBoolean"
                  :data="question.item"

                  :key="'boolean-' + question.item.code"
                ></BooleanTypeQuestionComponent>

                <TextTypeQuestionComponent
                  v-if="question.item.type == QaQuestionTypes.TYPE_TEXT"

                  :data="question.item"
                  @change="textTypeChange"
                  :key="'text-' + question.item.code"
                ></TextTypeQuestionComponent>

                <FileTypeQuestionComponent
                  v-if="question.item.type == QaQuestionTypes.TYPE_FILE"

                  :if_admin_view="if_admin_view"
                  :data="question.item"
                  @uploadChange="fileTypeChange"
                  @setCarouselBodyHeight="setCarouselBodyHeight"
                  :key="'file-' + question.item.code"
                ></FileTypeQuestionComponent>

                <OptionalFileUploadComponent
                  v-if="question.item.if_has_answer_file_upload == 1"
                  
                  :if_admin_view="if_admin_view"
                  :answer="file_answers[question.item.code]"
                  :data="question.item"
                  @optionalUploadChange="optionalFileTypeChange"
                  @setCarouselBodyHeight="setCarouselBodyHeight"
                  :key="'optional-file-' + question.item.code"
                ></OptionalFileUploadComponent>

                <DynamicTableTypeComponent
                  :if_admin_view="if_admin_view"
                  v-if="question.item.type == QaQuestionTypes.TYPE_TABLE"
                  @tableChange="tableChange"
                  @setCarouselBodyHeight="setCarouselBodyHeight"
                  :data="question.item"
                  :answer="table_answers[question.item.code]"
                  :key="'table-' + question.item.code"
                ></DynamicTableTypeComponent>

                </el-card>

                <SectionPassFailLabelComponent
                v-if="question.item.guidance_setting.length > 0"
                :guidance_setting="question.item.guidance_setting"
                ></SectionPassFailLabelComponent>

                <SQGuidanceEvaluationResultComponent
                v-if="question.item.guidance_setting.length > 0"
                :guidance_setting="question.item.guidance_setting"
                :result="evaluation_result"
                :if_admin_view="if_admin_view"
                :latest_supplier_qa_submission="latest_supplier_qa_submission"
                ></SQGuidanceEvaluationResultComponent>
              </template>
  </template>
  
  <script>


  import { SqQuestion } from '@/api_services/sq_questions'
  import BooleanTypeQuestionComponent from './BooleanTypeQuestionComponent.vue' 
  import TextTypeQuestionComponent from './TextTypeQuestionComponent.vue' 
  import FileTypeQuestionComponent from './FileTypeQuestionComponent.vue' 
  import OptionalFileUploadComponent from './OptionalFileUploadComponent.vue' 
  import DynamicTableTypeComponent from './DynamicTableTypeComponent.vue' 
  import SectionPassFailLabelComponent from './SectionPassFailLabelComponent.vue' 
  import SQGuidanceEvaluationResultComponent from './SQGuidanceEvaluationResultComponent.vue' 
  import { mapState } from 'vuex'

  export default {
    emits :{
      triggerQuestionBoolean: null,
      textTypeChange: null,
      fileTypeChange: null,
      optionalFileTypeChange: null,
      tableChange: null,
      setCarouselBodyHeight: null
    },
    computed: {
      ...mapState(['QaQuestionTypes', 'GuidanceEvaluationAnswerRequirements', 'GuidanceEvaluationAnswerType'])
    },
    components: { BooleanTypeQuestionComponent, TextTypeQuestionComponent, FileTypeQuestionComponent, SectionPassFailLabelComponent, OptionalFileUploadComponent, DynamicTableTypeComponent, SQGuidanceEvaluationResultComponent },
    props: ['data', 'file_a', 'normal_a', 'ev_result', 'if_admin_view', 'latest_supplier_qa_submission', 'table_a'],
    data() {
      return {
        questions: null,
        file_answers: null,
        answers: null,
        evaluation_result: null,
        table_answers: null,
      }
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.questions = this.data
            }
        },
        file_a: {
            deep: true,
            handler() {
                this.file_answers = this.file_a
            }
        },
        normal_a: {
            deep: true,
            handler() {
                this.answers = this.normal_a
            }
        },
        ev_result: {
            deep: true,
            handler() {
                this.evaluation_result = this.ev_result
            }
        },
        table_a: {
            deep: true,
            handler() {
                this.table_answers = this.table_a
            }
        },
    },
    created: function() {
        this.questions = this.data
        this.file_answers = this.file_a
        this.answers = this.normal_a
        this.evaluation_result = this.ev_result
        this.file_answers = this.table_a
    },
    methods: {
      triggerQuestionBoolean({question_code, code, value}){
        
        this.$emit('triggerQuestionBoolean', {
          question_code: question_code,
          code: code,
          value: value
        })
      },
      textTypeChange({question_code, value}){
        this.$emit('textTypeChange', {
          question_code: question_code,
          value: value
        })
      },
      fileTypeChange({question_code, fileList}){
        this.$emit('fileTypeChange', {
          question_code: question_code,
          fileList: fileList
        })
      },
      optionalFileTypeChange({question_code, fileList}){
        this.$emit('optionalFileTypeChange', {
          question_code: question_code,
          fileList: fileList
        })
      },
      tableChange({question_code, value}){
        this.$emit('tableChange', {
          question_code: question_code,
          value: value
        })
        this.$emit('setCarouselBodyHeight', value)
      },
      setCarouselBodyHeight(){
        this.$emit('setCarouselBodyHeight', true)
      }
    }
  }
  </script>
  