<template>
<div>
  <form @submit="onSubmit">
    <dropzone label="Drop or select file" :max_files="1" :clean="clearUpload" :multiple="false" extensions="xlsx" @input="setFile($event)"></dropzone>
    <button type="submit" class="btn bg-gradient-success btn-sm" v-loading="loading" :disabled="!form.file">
      {{ loading ? 'Saving' : 'Save' }}
    </button>
  </form>
</div>
</template>

<script>
import {
  CardImport
} from '@/api_services/card_import'
import Dropzone from '../../common/Dropzone.vue'

export default {
  props: {
      category: {
        type: String,
        required: false
      }
    },
  data() {
    return {
      form: {},
      loading: false,
      clearUpload: 0
    }
  },
  components: {
    Dropzone
  },
  methods: {
    setFile(files) {
      this.form.file = files[0]
    },
    onSubmit(evt) {
      evt.preventDefault()
      let formData = this.getFormData()

      this.loading = true

      CardImport.create(formData)
        .then(result => {
          this.$toast.success('Saved successfully')
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    },
    getFormData() {
      var formData = new FormData()
      formData.append('file', this.form.file)
      formData.append('file_name', this.form.file?.name)
      formData.append('category', this.category)
      return formData
    },
  }
}
</script>
