<template>
    <b v-if="input_upload.length == 0">No files uploaded!</b>
    <el-upload
        ref="uploadRef"
        multiple
        drag
        v-model:file-list="input_upload"
        :on-exceed="handleExceed"
        :on-change="onUploadChange"
        :before-upload="beforeUpload"
        :http-request="customHttp"
        :on-preview="onPreview"
        :before-remove="beforeRemove"
        accept="application/pdf"
    >
        <el-icon v-if="!if_file_disable" class="el-icon--upload"><upload-filled /></el-icon>
        <div v-if="!if_file_disable" class="el-upload__text">
          Drop PDF here or <em>click to upload</em>
        </div>
        <template #tip>
        <div v-if="!if_file_disable" class="el-upload__tip">
          PDF with a size less than or equal to {{size_limit}}MB.
        </div>
        </template>
    </el-upload>
  </template>
  
  <script>

  export default {
    emits :{
      uploadChange: null,
    },
    props: ['data', 'if_can_delete'],
    data() {
      return {
        display: "block",
        if_file_disable: false,
        input_upload: [],
        if_disable: false,
        size_limit: 30,
        if_from_max_limit: 0
      }
    },
    watch: {
        'data.disabled': {
            deep: true,
            handler() {
                this.if_file_disable = this.data.disabled
            }
        },
    },
    created: function() {
        
        this.if_file_disable = this.data.disabled
        if(this.if_file_disable){
          this.display = 'none'
        }

        if(this.data.files && this.if_file_disable){
          var files = JSON.parse(this.data.files)
          for(var x = 0; x <= files.length - 1; x++){
            
            this.input_upload.push({
              name: files[x].filename,
              url: process.env.VUE_APP_API_ROOT + "/storage/" + files[x].path,
              path: files[x].path
            })
          }
        }

    },
    methods: {
      beforeRemove(file, filelist){
        if(!this.if_can_delete){
          this.$toast.warning('Deletion is not allowed at this stage.')
          return false
        }

        if(this.if_from_max_limit == 0 && this.if_file_disable){

          return new Promise((resolve, reject) => {
          this.$confirm('Are you sure you want to delete this file?')
            .then(_ => {
              this.$emit('deleteFile', file)
              resolve(true)
            })
            .catch(_ => {
              return false
          })
        })
        }
      },
      onPreview(file){
        window.open(file.url, 'Download');
      },
      customHttp(){
        return true
      },
      beforeUpload(file, fileList){
        this.if_from_max_limit = 0
        
        const isLimit = file.size / 1024 / 1024 < this.size_limit
        if (!isLimit) {
            this.$toast.warning('The maximum file size is '+this.size_limit+'MB!')
            this.if_from_max_limit = 1
            return false
        }

        var allowedTypes = [
          'application/pdf'
        ];
        if (!allowedTypes.includes(file.type)) {
          
          this.$toast.warning('Invalid file type. Please upload a document or image file only.')
          this.if_from_max_limit = 1
          return false
        }
      },
      onUploadChange(file, fileList){
        this.$emit('uploadChange', fileList)
      },
      handleExceed(files, fileList) {
        this.$message.warning(`The limit is 3, you selected ${files.length + fileList.length} totally, please first remove the unwanted file`);
      },
    }
  }
  </script>
  <style>
    .el-upload{
      display: v-bind(display) !important;
    }
  </style>
  