import api from './api'

const API = '/api/additional_contracting_authority'

export const AdditionalContractingAuthority = {
    sendInvitationOrRequest: function(params) {
      return api.create(API + "/send_invitation_or_request", params)
    },
    activate: function(params) {
      return api.create(API + "/activate", params)
    },
    get: function(params) {
      return api.get(API, params)
    },
    updateRequest: function(params) {
      return api.update(`${API}/update-request/${params.id}`, params)
    },
    loadInvitations: function(id, params){
      return api.get(API+'/load-invitations/'+id, params)
    },
}