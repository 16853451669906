<style>
  .disabled_question{
    color:gray !important
  }
  .question_card .el-card__body{
    padding-top: 10px !important;
    padding-bottom: 12px !important
  }

  .question_card .el-card__header{
    padding-top: 10px !important;
    padding-bottom: 13px !important
  }

  .border_top{
    border-top: 10px solid #337ecc !important
  }

  .question_card .el-upload-dragger{
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .step_hide{
    display:none !important
  }

  .el-radio__input.is-disabled.is-checked .el-radio__inner{
    background-color: #409eff !important
  }

  .el-radio__input.is-disabled.is-checked .el-radio__inner::after{
    background-color: white !important
  }
  
</style>
  <template>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8 mb-1">
            <h5 class="">Supplier Questionaire Form</h5>
            <!--<el-button @click="testfunc" >test </el-button>-->
          </div>
          <div class="col-md-3 mb-3">
            <el-switch
              active-text="Form Layout"
              inactive-text="Quiz Layout"
              class="float-end"
              @change="switchChange"
              v-model="display_type"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </div>
          <div class="col-md-1 mb-3">
          <el-button 
            v-if="!if_admin_view"
                  type="primary"
                  tag="a"
                  href="/main/supplier"
                  rel="noopener noreferrer" class="float-end"><i class="fa fa-arrow-left"></i>&nbsp;Back</el-button>
                </div>
        </div>

        <div v-if="!if_admin_view && (latest_supplier_qa_submission && latest_supplier_qa_submission.if_verified == 0)" class="row">
          <div class="col-md-12 mb-3">
            <el-alert show-icon :closable="false" 
            title="Submission is currently Pending"
            description="Admin is currently reviewing your submission. Please wait for approval before you can do any new changes to your answers."
            type="warning" />
          </div>
        </div>

        <!-- load individual question -->
        <div  v-if="display_type == 0" >

          <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
            <h6 class="m-0 text-white">{{ "Part " + carousel_part_label }}</h6>
          </el-card>

          <el-card shadow="never" style="background: #c6e2ff" class=" mb-2 p-0 border-0"  >
            <h6 class="m-0 text-dark">{{ "Section " + carousel_section_label }}</h6>
          </el-card>

          <el-carousel height="auto" @change="carouselChange"  arrow="never" ref="question_carousel" :autoplay="false" :pause-on-hover="true" indicator-position="none">
            <template v-for="(question, index) in carousel_parent_questions" :key="index">
              <el-carousel-item >

                <el-card class="mb-2 question_card " shadow="never">
                <template #header>
                  <p  class="mb-0" v-html="'<b>' + question[1].item.question_no + 
                  (question[1].item.question_no_letter != '' ? ' ('+question[1].item.question_no_letter+')' : '') + '</b> ' +
                  question[1].item.question_text"></p>
                </template>


                <BooleanTypeQuestionComponent
                  v-if="question[1].item.type == QaQuestionTypes.TYPE_BOOLEAN"
                  @change="triggerQuestionBoolean"
                  :data="question[1].item"

                  :key="'boolean-' + question[1].item.code"
                ></BooleanTypeQuestionComponent>

                <TextTypeQuestionComponent
                  v-if="question[1].item.type == QaQuestionTypes.TYPE_TEXT"

                  :data="question[1].item"
                  @change="textTypeChange"
                  :key="'text-' + question[1].item.code"
                ></TextTypeQuestionComponent>

                <FileTypeQuestionComponent
                  v-if="question[1].item.type == QaQuestionTypes.TYPE_FILE"

                  :if_admin_view="if_admin_view"
                  :data="question[1].item"
                  @uploadChange="fileTypeChange"
                  @setCarouselBodyHeight="setCarouselBodyHeight"
                  :key="'file-' + question[1].item.code"
                ></FileTypeQuestionComponent>

                <OptionalFileUploadComponent
                  v-if="question[1].item.if_has_answer_file_upload == 1"
                  
                  :if_admin_view="if_admin_view"
                  :answer="file_answers[question[1].item.code]"
                  :data="question[1].item"
                  @optionalUploadChange="optionalFileTypeChange"
                  @setCarouselBodyHeight="setCarouselBodyHeight"
                  :key="'optional-file-' + question[1].item.code"
                ></OptionalFileUploadComponent>

                <DynamicTableTypeComponent
                :if_admin_view="if_admin_view"
                  v-if="question[1].item.type == QaQuestionTypes.TYPE_TABLE"
                  @tableChange="tableChange"
                  @setCarouselBodyHeight="setCarouselBodyHeight"
                  :answer="table_answers[question[1].item.code]"
                  :data="question[1].item"
                  :key="'table-' + question[1].item.code"
                ></DynamicTableTypeComponent>

                </el-card>

                <SectionPassFailLabelComponent
                v-if="question[1].item.guidance_setting.length > 0"
                :guidance_setting="question[1].item.guidance_setting"
                ></SectionPassFailLabelComponent>

                <SQGuidanceEvaluationResultComponent
                v-if="question[1].item.guidance_setting.length > 0"
                :guidance_setting="question[1].item.guidance_setting"
                :result="evaluation_result"
                :if_admin_view="if_admin_view"
                @resultChange="resultChange"
                :latest_supplier_qa_submission="latest_supplier_qa_submission"
                ></SQGuidanceEvaluationResultComponent>

                <IndividualQuestionComponent
                  v-if="carousel_child_questions[question[1].item.code]"
                  :data="carousel_child_questions[question[1].item.code]"
                  :file_a="file_answers"
                  :normal_a="answers"
                  :ev_result="evaluation_result"
                  :if_admin_view="if_admin_view"
                  :latest_supplier_qa_submission="latest_supplier_qa_submission"
                  :table_a="table_answers"

                  @triggerQuestionBoolean="triggerQuestionBoolean"
                  @textTypeChange="textTypeChange"
                  @fileTypeChange="fileTypeChange"
                  @optionalFileTypeChange="optionalFileTypeChange"
                  @tableChange="tableChange"
                ></IndividualQuestionComponent>

                <!--<el-button 
                v-if="index == (carousel_parent_questions.size - 1) &&
                !latest_supplier_qa_submission ||
                (latest_supplier_qa_submission && latest_supplier_qa_submission.if_verified == 1)"
                
                @click="saveAnswers" class="mt-4" type="primary"><i class="fa fa-save"></i>&nbsp;Save</el-button>-->

                </el-carousel-item>
            </template>
            
          </el-carousel>

          <el-button v-if="active_step > 0" type="warning" class="mt-5" @click="setQuestionActive('prev')"><i class="fa fa-arrow-left"></i>&nbsp;Previous Question</el-button>
          <el-button v-if="carousel_parent_questions && active_step < (carousel_parent_questions.size - 1)" type="primary" class="mt-5" @click="setQuestionActive('next')">Next Question &nbsp;<i class="fa fa-arrow-right"></i></el-button>
        </div>
        
        <!-- load all questions -->
        <div class="row">
          <div class="col-md-0">
            <!--<el-affix v-if="display_type == 1" :offset="60">
              <el-anchor :offset="70" style="width: 100%">

                <el-anchor-link aria-current="page" v-for="(question, index) in carousel_parent_questions" :key="'anchor_' + index" :href="'#' + question[1].item.code">
                  {{ question[1].item.code }}
                </el-anchor-link>
              </el-anchor>
            </el-affix>-->
          </div>
          <div class="col-md-12">
            <div v-if="display_type == 1" v-for="(question, index) in questions">
          
              <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0" v-if="question[1].item.part_name_label" >
                <h6 class="m-0 text-white">{{ "Part " + question[1].item.part + ": " + question[1].item.part_name_label }}</h6>
              </el-card>

              <el-card shadow="never" style="background: #c6e2ff" class="mt-4 mb-2 p-0 border-0" v-if="question[1].item.section_name_label" >
                <h6 class="m-0 text-dark">{{ "Section " + question[1].item.section + ": " + question[1].item.section_name_label }}</h6>
              </el-card>

              <!--<a v-if="!question[1].item.parent_question_code" :href="'#' + question[1].item.code" class="stretched-link">#
              </a>-->
              <el-card :id="!question[1].item.parent_question_code ? question[1].item.code  : '' " class="mb-2 question_card " :class="question[1].item.parent_question_code ? 'ms-5' : '' " shadow="never">
              
                
                <template #header>
                  <p  class="mb-0" v-html="'<b>' + question[1].item.question_no + 
                  (question[1].item.question_no_letter != '' ? ' ('+question[1].item.question_no_letter+')' : '') + '</b> ' +
                  question[1].item.question_text"></p>
                </template>
                

                  <BooleanTypeQuestionComponent
                    v-if="question[1].item.type == QaQuestionTypes.TYPE_BOOLEAN"
                    @change="triggerQuestionBoolean"
                    :data="question[1].item"

                    :key="'boolean-' + question[1].item.code"
                  ></BooleanTypeQuestionComponent>

                  <TextTypeQuestionComponent
                    v-if="question[1].item.type == QaQuestionTypes.TYPE_TEXT"

                    :data="question[1].item"
                    @change="textTypeChange"
                    :key="'text-' + question[1].item.code"
                  ></TextTypeQuestionComponent>

                  <FileTypeQuestionComponent
                    v-if="question[1].item.type == QaQuestionTypes.TYPE_FILE"

                    :if_admin_view="if_admin_view"
                    :data="question[1].item"
                    @uploadChange="fileTypeChange"
                    @setCarouselBodyHeight="setCarouselBodyHeight"
                    :key="'file-' + question[1].item.code"
                  ></FileTypeQuestionComponent>

                  <OptionalFileUploadComponent
                    v-if="question[1].item.if_has_answer_file_upload == 1"
                    
                    :if_admin_view="if_admin_view"
                    :answer="file_answers[question[1].item.code]"
                    :data="question[1].item"
                    @optionalUploadChange="optionalFileTypeChange"
                    @setCarouselBodyHeight="setCarouselBodyHeight"
                    :key="'optional-file-' + question[1].item.code"
                  ></OptionalFileUploadComponent>

                  <DynamicTableTypeComponent
                  :if_admin_view="if_admin_view"
                    v-if="question[1].item.type == QaQuestionTypes.TYPE_TABLE"
                    @tableChange="tableChange"
                    @setCarouselBodyHeight="setCarouselBodyHeight"
                    :answer="table_answers[question[1].item.code]"
                    :data="question[1].item"
                    :key="'table-' + question[1].item.code"
                  ></DynamicTableTypeComponent>

              </el-card>

              <SectionPassFailLabelComponent
                v-if="question[1].item.guidance_setting.length > 0"
                :guidance_setting="question[1].item.guidance_setting"
              ></SectionPassFailLabelComponent>

              <SQGuidanceEvaluationResultComponent
                v-if="question[1].item.guidance_setting.length > 0"
                :guidance_setting="question[1].item.guidance_setting"
                :result="evaluation_result"
                :if_admin_view="if_admin_view"
                @resultChange="resultChange"
                :latest_supplier_qa_submission="latest_supplier_qa_submission"
              ></SQGuidanceEvaluationResultComponent>
            </div>
          </div>
        </div>

        <el-button 
        style="float:right"
        v-if="
        (if_admin_view == 1 && (latest_supplier_qa_submission && latest_supplier_qa_submission.if_verified == 0) ) ||
        ( !if_admin_view &&
        ( display_type == 1 || (display_type == 0 && carousel_parent_questions && active_step == (carousel_parent_questions.size - 1)) ) &&
        (
          (!latest_supplier_qa_submission ||
          (latest_supplier_qa_submission && latest_supplier_qa_submission.if_verified == 1)) && if_has_change
        ) 
        )
        "
        @click="saveAnswers" size="large" class="mt-4" type="success"><i class="fa fa-save"></i>&nbsp; {{ if_admin_view == 1 ? "Verify" : "Save" }}</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import { Edit, Picture, Upload } from '@element-plus/icons-vue'
  import { SqQuestion } from '@/api_services/sq_questions'
  import BooleanTypeQuestionComponent from './BooleanTypeQuestionComponent.vue' 
  import TextTypeQuestionComponent from './TextTypeQuestionComponent.vue' 
  import FileTypeQuestionComponent from './FileTypeQuestionComponent.vue' 
  import OptionalFileUploadComponent from './OptionalFileUploadComponent.vue' 
  import DynamicTableTypeComponent from './DynamicTableTypeComponent.vue' 
  import SectionPassFailLabelComponent from './SectionPassFailLabelComponent.vue' 
  import SQGuidanceEvaluationResultComponent from './SQGuidanceEvaluationResultComponent.vue' 
  import IndividualQuestionComponent from './IndividualQuestionComponent.vue' 
  import { mapState } from 'vuex'
  import $ from 'jquery'
  import { EvaluationResultSubmission } from '@/api_services/evaluation_result_submissions'
  import Swal from 'sweetalert2'
  import api from '@/api_services/axios'

  export default {
    props: {
      load_type: {
        type: Number,
        default: 0
      },
      supplier_id: {
        type: Number,
        default: null
      },
      selected_submission: {
        type: Number,
        default: null
      },
    },
    computed: {
      ...mapState(['QaSubmissionStatuses', 'QaQuestionTypes', 'GuidanceEvaluationAnswerRequirements', 'GuidanceEvaluationAnswerType'])
    },
    components: { IndividualQuestionComponent, BooleanTypeQuestionComponent, TextTypeQuestionComponent, FileTypeQuestionComponent, SectionPassFailLabelComponent, OptionalFileUploadComponent, DynamicTableTypeComponent, SQGuidanceEvaluationResultComponent },
    data() {
      return {
        if_has_change: true,
        if_admin_view: null,
        carousel_parent_questions: null,
        carousel_child_questions: [],
        carousel_question_codes: [],
        carousel_part_label: "",
        carousel_section_label: "",
        display_type: 0,
        active_step: 0,
        questions: null,
        boolean_trigger_answer_yes: [],
        answers: {},
        file_answers: {},
        table_answers: {},
        supplier_data: {
          id: null
        },
        qa_answers: {
          answers: null
        },
        qa_results: {
          evaluations: null,
          if_verified: 0,
          verification_status: 0,
          verifier: null,
          user_id: null
        },
        guidance_settings: {},
        evaluation_result: [],
        evaluation_answer_count: [],
        counter: 0,
        latest_supplier_qa_submission: null
      }
    },
    watch: {

    },
    created: function() {
        if(this.load_type == 1){
          this.supplier_data.id = this.supplier_id
          this.if_admin_view = this.load_type
          this.display_type = 1

          this.latest_supplier_qa_submission = this.selected_submission
        }
        else{
          this.supplier_data = this.$store.state.savedUser.company.supplier
          this.loadUserLatestSqSubmission()
        }
        this.loadQuestions()
    },
    methods: {  
      checkIfSavingIsDoneInside(file_answers, count, success_count, table_answers, table_count, table_success_count ){
        var if_file_answer_success = 0;
                  if(Object.keys(file_answers).length == 0){
                    if_file_answer_success = 1
                  }
                  else{
                    if(
                      success_count == count &&
                      count == Object.keys(file_answers).length
                    ){
                      if_file_answer_success = 1
                    }
                  }

                  var if_table_answer_success = 0;
                  if(Object.keys(table_answers).length == 0){
                    if_table_answer_success = 1
                  }
                  else{
                    if(
                      table_success_count == table_count && 
                      table_count == Object.keys(table_answers).length 
                    ){
                      if_table_answer_success = 1
                    }
                  }

                  if(if_file_answer_success == 1 && if_table_answer_success == 1){
                    this.$toast.success('Saved successfully!')
                    window.location.reload()
                  }
      },
      testfunc(){
        var self = this
        Object.keys(self.table_answers).forEach(key => {

        var formData2 = new FormData()
        
        var table_answers = self.table_answers[key].table_answer

//console.log(self.table_answers[key].table_answer)

                  if(table_answers){
                    

                    Object.keys(table_answers).forEach(key2 => {
                      var table_answer = table_answers[key2]

                      let table_code = table_answer.data.table_code
                      
                    //console.log(table_answer)
                      if(table_answer.data.temp_optional_file){
                        console.log(table_answer.data.temp_optional_file)
                      }
                    })
                  }
                })
      },
      loadUserLatestSqSubmission(){
        this.isLoading = true
        EvaluationResultSubmission.get({params: {supplier_id: this.supplier_data.id, type: 1}})
          .then(result => {
            if(result.data.latest_submission)

              this.latest_supplier_qa_submission = result.data.latest_submission
          })
          .catch( error => {
            this.$toast.error('An error occured while fetching submissions')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      resultChange({ code, result }){
        //alert(code + " -- " + result)
        //console.log(this.evaluation_result[code])
      },
      switchChange(){
        this.globalState.loading = true

        this.questions = null
        this.carousel_parent_questions = null
        this.carousel_child_questions = []
        this.evaluation_result = []
        this.file_answers = {}
        this.answer = {}

        this.loadQuestions()
      },
      carouselChange(new_index, old_index){
        
      },
      setCarouselBodyHeight(){
        var totalHeight = 0;
        $('.el-carousel__item:eq('+ this.active_step +') > div').each(function(){
            totalHeight += $(this).height();
        });
        $('.el-carousel__item:eq('+ this.active_step +') > button').each(function(){
            totalHeight += $(this).height();
        });
        $(".el-carousel__container").css("height", (totalHeight + 100) + "px")
        //console.log(this.active_step)
      },
      setQuestionActive(type){
        
        if(type == "prev" && this.active_step > 0){
          this.active_step--
        }

        if(type == "next" && this.active_step < (this.carousel_parent_questions.size- 1)){
          this.active_step++
        }

        let data = this.questions.get(this.carousel_question_codes[this.active_step]).item

        if(data.part_name !== undefined && data.part_name){
          this.carousel_part_label = data.part + ": " + data.part_name
        }
        else{
          if(data.parent_question){
            this.carousel_part_label = data.parent_question.part + ": " + data.parent_question.part_name
          }
        }
        
        if(data.section_name !== undefined && data.section_name){
          this.carousel_section_label = data.section + ": " + data.section_name
        }
        else{
          if(data.parent_question){
            this.carousel_section_label = data.parent_question.section + ": " + data.parent_question.section_name
          }
        }
        this.setCarouselBodyHeight()
        if(this.display_type == 0){
          this.$refs.question_carousel.setActiveItem(this.active_step);
        }
        
      },
      tableChange({question_code, value}){
        if(question_code !== undefined && question_code !== "" && question_code !== null){
          
          this.setResult(question_code, value, "text", 0)

          this.table_answers[question_code] = { 
              question_code: question_code, 
              supplier_id: this.supplier_data.id, 
              answer: "table_data", 
              file_answer: null, 
              optional_file_uploaded: null,
              table_answer: value
          }

          //console.log(this.table_answers)

          /*if(this.questions.get(question_code).item.if_has_answer_file_upload == 1){

            if(this.file_answers[question_code] !== undefined){
              this.file_answers[question_code].table_answer = value
            }
            else{
              this.file_answers[question_code] = { 
                question_code: question_code, 
                supplier_id: this.supplier_data.id, 
                answer: "table_data", 
                file_answer: null, 
                optional_file_uploaded: null, 
                if_has_answer_file_upload: this.questions.get(question_code).item.if_has_answer_file_upload,
                table_answer: value
              }
            }
          }
          else{
            this.answers[question_code] = { 
              question_code: question_code, 
              supplier_id: this.supplier_data.id, 
              answer: "table_data", 
              file_answer: null, 
              optional_file_uploaded: null,
              table_answer: value
            }
          }*/
        }
        this.setCarouselBodyHeight() 
      },
      saveEvaluationResult(evaluation_result, type, status){
        this.qa_results.evaluations = evaluation_result

        if(type == 1){
          this.qa_results.if_verified = 1
          this.qa_results.verification_status = status
          this.qa_results.verifier = this.$store.state.savedUser.id
          this.qa_results.submission_id = this.latest_supplier_qa_submission.id
        }
        else{
          this.qa_results.user_id = this.$store.state.savedUser.id
        }

        let save_result_call = SqQuestion.saveResults(
                this.supplier_data.id,
                this.qa_results
              )
              .then(result => {
                if(type == 1){
                  this.$toast.success(result.data.message)
                  this.$emit('save', true)
                }
              })
              .catch(error => {
                this.$toast.error('An error occured while saving results')
                console.error('error:', error)
              })

        return save_result_call
      },
      async saveAnswers(){
        let self = this

        let confirm_text = 'Continue saving your answers? It will still pending and needed admin verification before it will be posted.'
        if(this.if_admin_view == 1){
          confirm_text = 'Verify this results?'
        }

        Swal.fire({
          text: confirm_text,
          showCancelButton: true,
          confirmButtonText: 'Proceed',
          cancelButtonText: 'Cancel',
          reverseButtons: true,
          icon: 'info',
        }).then(result => {
          if (result.isConfirmed) {

            let save_requests = []

            this.evaluation_result = Object.assign({}, this.evaluation_result)

            if(this.if_admin_view == 1){
              save_requests.push(this.saveEvaluationResult(this.evaluation_result, 1, this.QaSubmissionStatuses.STATUS_APPROVED))
            }
            else{
              
              if(this.supplier_data){
                this.qa_answers.answers = this.answers
                this.globalState.loading = true

                let main_save = SqQuestion.saveAnswers(
                  this.supplier_data.id,
                  this.qa_answers
                )
                .then(result => {

                  save_requests.push(self.saveEvaluationResult(self.evaluation_result, 0, self.QaSubmissionStatuses.STATUS_PENDING))

                  var success_count = 0
                  var count = 0

                  var table_success_count = 0
                  var table_count = 0
                  
                  // saving answers with files
                  Object.keys(self.file_answers).forEach(key => {
                    count++
                    self.globalState.loading = true 
                    var formData = new FormData()
                    
                    if(self.file_answers[key].file_answer){
                      self.file_answers[key].file_answer.forEach(function(file) {
                        formData.append('files[]', file.raw)
                      })
                    }

                    if(self.file_answers[key].optional_file_uploaded){
                      self.file_answers[key].optional_file_uploaded.forEach(function(file) {
                        if(file.raw){
                          formData.append('files[]', file.raw)
                        }
                      })
                    }

                    formData.append('question_code', self.file_answers[key].question_code)
                    formData.append('supplier_id', self.file_answers[key].supplier_id)
                    formData.append('answer', self.file_answers[key].answer)
                    formData.append('if_has_answer_file_upload', self.file_answers[key].if_has_answer_file_upload)

                    let file_save = SqQuestion.saveAnswersWithFiles(
                      self.supplier_data.id,
                      formData
                    )
                    .then(result => {
                      
                    })
                    .catch(error => {
                      self.$toast.error('An error occured while saving answers')
                      console.error('error:', error)
                    })
                    .finally(() => {
                      success_count++
                      this.checkIfSavingIsDoneInside(self.file_answers, count, success_count, self.table_answers, table_count, table_success_count )
                    })

                    save_requests.push(file_save)
                  })

                  //saving table data
                  
                  Object.keys(self.table_answers).forEach(key => {
                    table_count++
                  var formData2 = new FormData()

                  formData2.append('question_code', self.table_answers[key].question_code)
                  formData2.append('supplier_id', self.table_answers[key].supplier_id)

                  var table_answers = self.table_answers[key].table_answer

                  if(table_answers){
                    
                    Object.keys(table_answers).forEach(key2 => {
                      var table_answer = table_answers[key2]

                      let table_code = table_answer.data.table_code
                    
                      if(table_answer.data.temp_optional_file){
                        table_answer.data.temp_optional_file.forEach(function(file) {
                          if(file.raw){
                            formData2.append(table_code + '-files[]', file.raw)
                          }
                        })
                      }
                    })
                  }

                  formData2.append('table_answer', JSON.stringify(table_answers))
                  formData2.append('if_has_answer_file_upload', self.table_answers[key].if_has_answer_file_upload)
                  
                  let table_save = SqQuestion.saveTableAnswersWithFiles(
                      self.supplier_data.id,
                      formData2
                    )
                    .then(result => {
                        
                    })
                    .catch(error => {
                      self.$toast.error('An error occured while saving answers')
                      console.error('error:', error)
                    })
                    .finally(() => {
                      table_success_count++
                      this.checkIfSavingIsDoneInside(self.file_answers, count, success_count, self.table_answers, table_count, table_success_count )
                    })

                    save_requests.push(table_save)

                  })

                })
                .catch(error => {
                  this.$toast.error('An error occured while saving answers')
                  console.error('error:', error)
                })
                .finally(() => {
                  this.globalState.loading = false
                })

                save_requests.push(main_save)
              }
            }
          }
        })
        
      },
      optionalFileTypeChange({question_code, fileList}){
        if(question_code !== undefined && question_code !== "" && question_code !== null){
          if(this.file_answers[question_code] !== undefined){
            this.file_answers[question_code].optional_file_uploaded = fileList
          }
        }
      },
      fileTypeChange({question_code, fileList}){
        if(question_code !== undefined && question_code !== "" && question_code !== null){
          this.file_answers[question_code] = { 
            question_code: question_code, 
            supplier_id: this.supplier_data.id, 
            answer: "none", 
            file_answer: fileList, 
            optional_file_uploaded: null,
            if_has_answer_file_upload: this.questions.get(question_code).item.if_has_answer_file_upload
          }
        }
      }, 
      textTypeChange({question_code, value}){
        if(question_code !== undefined && question_code !== "" && question_code !== null){
          this.setResult(question_code, value, "text", 0)

          if(this.questions.get(question_code).item.if_has_answer_file_upload == 1){

            if(this.file_answers[question_code] !== undefined){
              this.file_answers[question_code].answer = value
            }
            else{
              this.file_answers[question_code] = { 
                question_code: question_code, 
                supplier_id: this.supplier_data.id, 
                answer: value, 
                file_answer: null, 
                optional_file_uploaded: null, 
                if_has_answer_file_upload: this.questions.get(question_code).item.if_has_answer_file_upload
              }
            }
          }
          else{
            this.answers[question_code] = { 
              question_code: question_code, 
              supplier_id: this.supplier_data.id, 
              answer: value, 
              file_answer: null, 
              optional_file_uploaded: null
            }
          }
        } 
      },
      triggerQuestionBoolean({question_code, code, value, in_create}){
        
        if(code !== undefined && code !== "" && code !== null){
          var disabled_value = true

          if(this.boolean_trigger_answer_yes[code] === undefined){
            this.boolean_trigger_answer_yes[code] = []
          }
          this.boolean_trigger_answer_yes[code][question_code] = value

          var yes_count = this.countBooleanYesAnswer(this.boolean_trigger_answer_yes[code])
          var count = this.getUniqueTriggerCodes(this.questions, code)

          if(yes_count == 0){
            disabled_value = true
          }

          if(yes_count <= count && yes_count > 0){
            disabled_value = false
          }

          var codes = code.split(",")
          for(var x = 0; x <= codes.length - 1; x++){
            this.questions.get(codes[x]).item.disabled = disabled_value
          }
        }

        this.setResult(question_code, value, "boolean", in_create)

        if(question_code !== undefined && question_code !== "" && question_code !== null){

          if(this.questions.get(question_code).item.if_has_answer_file_upload == 1){

            if(this.file_answers[question_code] !== undefined){
              this.file_answers[question_code].answer = value
            }
            else{
              this.file_answers[question_code] = { 
                question_code: question_code, 
                supplier_id: this.supplier_data.id, 
                answer: value, 
                file_answer: null, 
                optional_file_uploaded: null,
                if_has_answer_file_upload: this.questions.get(question_code).item.if_has_answer_file_upload
              }
            }
          }
          else{
            this.answers[question_code] = { 
              question_code: question_code, 
              supplier_id: this.supplier_data.id, 
              answer: value, 
              file_answer: null, 
              optional_file_uploaded: null
            }
          }
        }
      },
      setResult(question_code, value, input_type, if_create){
        if(input_type == "text"){
          if(value || value != ""){
            value = 1
          }
          else{
            value = 0
          }
        }

        if(question_code !== undefined && this.questions.get(question_code).item.individual_guidance_setting && this.questions.get(question_code).item.individual_guidance_setting !== undefined ){
          var individual_guidance_setting = this.questions.get(question_code).item.individual_guidance_setting
          
          var settings_question_codes = individual_guidance_setting.question_codes

          if(this.evaluation_answer_count[settings_question_codes] === undefined){
            this.evaluation_answer_count[settings_question_codes] = { positive_answer: [], negative_answer: [] }
          }

          if(value == 1){
            this.evaluation_answer_count[settings_question_codes].positive_answer.push(question_code)
            if(this.evaluation_answer_count[settings_question_codes].negative_answer.includes(question_code)){
              this.evaluation_answer_count[settings_question_codes].negative_answer.splice(this.evaluation_answer_count[settings_question_codes].negative_answer.indexOf(question_code), 1)
            }
          }
          if(value == 0){
            this.evaluation_answer_count[settings_question_codes].negative_answer.push(question_code)
            if(this.evaluation_answer_count[settings_question_codes].positive_answer.includes(question_code)){
              this.evaluation_answer_count[settings_question_codes].positive_answer.splice(this.evaluation_answer_count[settings_question_codes].positive_answer.indexOf(question_code), 1)
            }
            
          }

          var evaluation_yes_count = this.evaluation_answer_count[settings_question_codes].positive_answer.length
          var evaluation_no_count = this.evaluation_answer_count[settings_question_codes].negative_answer.length

            
            var need_admin_verification = false
            if(individual_guidance_setting.if_need_admin_verification == 1){
              need_admin_verification = true
            }

            var result = this.checkResult(evaluation_yes_count, evaluation_no_count, individual_guidance_setting, value)
            
            var this_id = null
            var this_result = null
            var this_comment = null

            if(individual_guidance_setting.evaluation_result){
              this_id = individual_guidance_setting.evaluation_result.id
              this_result = individual_guidance_setting.evaluation_result.result
              this_comment = individual_guidance_setting.evaluation_result.comment
            }

            if(if_create == 1){
              result = this_result
            }

            this.evaluation_result[individual_guidance_setting.question_codes] = { 
              id: this_id,
              submissions_id: null,
              guidance_settings_id: individual_guidance_setting.id,
              display_question_no: individual_guidance_setting.display_question_no, 
              result: result, 
              need_admin_verification: need_admin_verification,
              comment: this_comment
            } 
            

          }
      },
      checkResult(positive_answer_count, negative_answer_count, individual_guidance_setting, value){
        var result = null
        var question_codes_count = individual_guidance_setting.question_codes.split(",").length
        //pass checking
        if(individual_guidance_setting.pass_answer == value){
          if(individual_guidance_setting.pass_answer_requirement == this.GuidanceEvaluationAnswerRequirements.ANSWER_REQUIREMENT_ALL){
            
            if(value == this.GuidanceEvaluationAnswerType.POSITIVE_ANSWER){
              if(positive_answer_count >= question_codes_count){
                result = 1
              }
            }

            if(value == this.GuidanceEvaluationAnswerType.NEGATIVE_ANSWER){
              if(negative_answer_count >= question_codes_count){
                result = 1
              }
            }
          }

          if(individual_guidance_setting.pass_answer_requirement == this.GuidanceEvaluationAnswerRequirements.ANSWER_REQUIREMENT_ATLEAST_1){
            if(value == this.GuidanceEvaluationAnswerType.POSITIVE_ANSWER){
              if(positive_answer_count >= 1){
                result = 1
              }
            }

            if(value == this.GuidanceEvaluationAnswerType.NEGATIVE_ANSWER){
              if(negative_answer_count >= 1){
                result = 1
              }
            }
          }
          
          if(individual_guidance_setting.pass_answer_requirement == this.GuidanceEvaluationAnswerRequirements.ANSWER_REQUIREMENT_NO_ANSWER){
            if(value == "" || !value || value == 0){
              result = 1
            }
          }
        }

        //fail checking
        if(individual_guidance_setting.fail_answer == value){
          if(individual_guidance_setting.fail_answer_requirement == this.GuidanceEvaluationAnswerRequirements.ANSWER_REQUIREMENT_ALL){
            
            if(value == this.GuidanceEvaluationAnswerType.POSITIVE_ANSWER){
              if(positive_answer_count >= question_codes_count){
                result = 0
              }
            }

            if(value == this.GuidanceEvaluationAnswerType.NEGATIVE_ANSWER){
              if(negative_answer_count >= question_codes_count){
                result = 0
              }
            }
          }

          if(individual_guidance_setting.fail_answer_requirement == this.GuidanceEvaluationAnswerRequirements.ANSWER_REQUIREMENT_ATLEAST_1){
            if(value == this.GuidanceEvaluationAnswerType.POSITIVE_ANSWER){
              if(positive_answer_count >= 1){
                result = 0
              }
            }

            if(value == this.GuidanceEvaluationAnswerType.NEGATIVE_ANSWER){
              if(negative_answer_count >= 1){
                result = 0
              }
            }
          }
          
          if(individual_guidance_setting.fail_answer_requirement == this.GuidanceEvaluationAnswerRequirements.ANSWER_REQUIREMENT_NO_ANSWER){
            if(value == "" || !value || value == 0){
              result = 0
            }
          }
        } 

        return result
      },
      countBooleanYesAnswer(answers){
        var count = 0
        for (var key in answers) {
          var value = answers[key];
          if(value == 1){
            count++
          }
        }
        return count
      },
      countBooleanNoAnswer(answers){
        var count = 0
        for (var key in answers) {
          var value = answers[key];
          if(value == 0){
            count++
          }
        }
        return count
      },
      getUniqueTriggerCodes(array, code){

        var count = 0

        for (const [key, value] of array.entries()) {

          if( value.item.trigger_question_code == code){
            count++
          }
        }

        return count
      },
      loadQuestions(){
        var self = this
        this.globalState.loading = true

        SqQuestion.get({
          params: {
            supplier_id: this.supplier_data.id
          }
        })
        .then(result => {
          if (result.data.questions) this.questions = result.data.questions

          var current_part = ""
          var current_section
          
          var map = new Map();
          var self = this

          var parent_carousel_map = new Map()
          

          this.questions.forEach(function(item, index){
            
            var child_carousel_map = new Map()
            self.carousel_question_codes[index] = item.code

            if(self.if_admin_view){
              item.if_admin_view = self.if_admin_view
            }

            if(item.part_name != current_part && item.part_name != ""){
              current_part = item.part_name
              item.part_name_label = current_part
            }

            if(item.section_name != current_section && item.section_name != ""){
              current_section = item.section_name
              item.section_name_label = current_section


            }

            if(item.if_has_answer_file_upload == 1){
              if(item.answer){
                self.file_answers[item.code] = { 
                  question_code: item.answer.question_code, 
                  supplier_id: item.answer.supplier_id, 
                  answer: item.answer.answer, 
                  file_answer: null, 
                  optional_file_uploaded: null, 
                  if_has_answer_file_upload: item.if_has_answer_file_upload
                } 
              }
            }
            
            if(item.answer && item.answer.table_answer){
              
              self.table_answers[item.code] ={ 
                  question_code: item.code, 
                  supplier_id: item.answer.supplier_id, 
                  answer: item.answer.answer, 
                  file_answer: null, 
                  optional_file_uploaded: null,
                  table_answer: item.answer.table_answer
              }
              //JSON.parse(item.answer.table_answer.substring(1, item.answer.table_answer.length-1))
              //let dd = JSON.parse(item.answer.table_answer.substring(1, item.answer.table_answer.length - 1))
              //console.log(dd[0])
              //console.log(table_answer)
            }

            if(item.guidance_setting){
              if(self.guidance_settings[item.guidance_setting.id] === undefined){
                self.guidance_settings[item.guidance_setting.id] = item.guidance_setting
              }
            }

            if(item.individual_guidance_setting){
              //var eval_result = (item.individual_guidance_setting.evaluation_result ? item.individual_guidance_setting.evaluation_result : null)
              //var this_id = (eval_result.id ? eval_result.id : null)
              //var this_result = (eval_result.result ? eval_result.result : null)

              var this_id = null
              var this_result = null
              var this_need_admin_verification = false
              var this_comment = null

              if(item.individual_guidance_setting.evaluation_result){
                
                this_id = item.individual_guidance_setting.evaluation_result.id
                this_result = item.individual_guidance_setting.evaluation_result.result
                this_comment = item.individual_guidance_setting.evaluation_result.comment

                this_need_admin_verification = (item.individual_guidance_setting.evaluation_result.need_admin_verification == 1 ? true : false)
              }
              

              var individual_guidance_setting = item.individual_guidance_setting
              var settings_question_codes = individual_guidance_setting.question_codes
              if(settings_question_codes && self.evaluation_result[settings_question_codes] === undefined){
                self.evaluation_result[settings_question_codes] = {}
              }
              self.evaluation_result[settings_question_codes] = { 
                id: this_id,
                submissions_id: null,
                guidance_settings_id: individual_guidance_setting.id,
                display_question_no: individual_guidance_setting.display_question_no, 
                result: this_result, 
                need_admin_verification: this_need_admin_verification,
                comment: this_comment
              }

              //console.log(this_id+ " -- " + this_result)
              
              //console.log(self.evaluation_result[settings_question_codes])
            }

            //console.log(item.individual_guidance_setting)
            
            map.set(item.code, {item})  

            if(!item.parent_question_code){
              parent_carousel_map.set(item.code, {item})
            }
            else{
              if(self.carousel_child_questions[item.parent_question_code] === undefined){
                self.carousel_child_questions[item.parent_question_code] = []
              }
              self.carousel_child_questions[item.parent_question_code].push({item})
            }
            
          })

          this.questions = map
          this.carousel_parent_questions = parent_carousel_map

          //console.log(this.carousel_child_questions['2221A'])
        })
        .catch(error => {
    
        })
        .finally(() => {
          this.globalState.loading = false
          this.setQuestionActive("")
        })
      }
    },
  }
  </script>
  