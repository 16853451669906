<template>
  <div class="container h-100" style="margin-top: 100px">
    <div class="d-flex justify-content-center h-100">
      <div class="user_card">
        <div class="d-flex justify-content-center">
          <div class="brand_logo_container">
            <img :src="logo" class="brand_logo" alt="Logo" />
          </div>
          <div style="position:absolute;top: 90px;width:100%;padding: 10px" v-if="message">
            <el-alert effect="dark" type="error" show-icon> {{message}}</el-alert>
          </div>

        </div>
        <div class="d-flex justify-content-center form_container mt-9">

          <form @submit="onSubmit">
            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fa-solid fa-user"></i></span>
              </div>
              <input type="text" id="email" required v-model="form.email" class="form-control-sm input_user border-0"
                placeholder="email" />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fa-solid fa-key"></i></span>
              </div>
              <input type="password" required v-model="form.password" class="form-control-sm input_pass border-0"
                placeholder="password" />
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customControlInline"
                  v-model="form.remember_me" />
                <label class="custom-control-label" for="customControlInline" style="color: white">Remember me</label>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-3 login_container">
              <button type="submit" name="button" class="btn login_btn" v-loading="loading">
                Login
              </button>
            </div>
            <div style="text-align: center;">
              <el-link type="warning" style="color:#fff" href="/forgot-password">Forgot Password?</el-link>
            </div>
          </form>
        </div>

        <!-- <div class="mt-4">
					<div class="d-flex justify-content-center links" style="color: white">
						Don't have an account? <router-link to="/signup" class="ml-2 link-light">  Sign up now</router-link>
					</div>
				</div> -->
      </div>
    </div>

    <cookie></cookie>
  </div>
</template>

<script>
import { Authenticate } from '@/api_services/authenticate'
import CPRAS_logo from '@/assets/CPRAS_logo.png'
import Swal from 'sweetalert2'
import { LocalStorageService } from '@/services/LocalStorageService'

export default {
  name: 'LoginComponent',
  data() {
    return {
      logo: CPRAS_logo,
      form: {
        email: '',
        password: '',
        remember_me: false
      },
      message: "",
      loading: false
    }
  },
  created: function () {
   this.init()
  },
  methods: {
    async init() {
      await this.$delay(1000);
      this.globalState.loading = false
      if (this.$store.state.credential) {
        this.form = this.$store.state.credential
      }

      if (!this.$store.state.mac_address)
        this.$store.state.mac_address = LocalStorageService.getMacAddress()

    },
    onSubmit(evt) {
      var self = this
      evt.preventDefault()

      this.globalState.loading = true
      this.globalState.message = 'Logging in, please wait.......'

      this.form.bcp = parseInt(process.env.VUE_APP_IS_BCP)
      this.loading = true

      Authenticate.login(this.form, function(user, message) {
        if (user) {
          self.$toast.success('Login successfully')
          self.checkForSupplier()
        } else {
          self.showError(message)
        }
        self.globalState.loading = false
        self.globalState.message = null
        self.loading = false
      })
    },
    showError(message) {
      let self = this
      this.message = message

      setTimeout(function(res){ self.message = null }, 5000);
    },
    checkForSupplier() {
      let query = parseInt(process.env.VUE_APP_IS_BCP) ? { bcp: 1 } : {}
      if (this.$store.state.savedUser.role_names.includes('supplier')) {
        this.$router.push({ name: 'supplier', query: query })
      } else {
        this.$router.push({ name: 'Home', query: query })
      }

      if (this.form.remember_me) {
        this.$store.commit('setCredential', this.form)
      }
    }
  }
}
</script>

<style scoped>
.user_card {
  height: 400px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #007799;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background: #ffffff;
  padding: 10px;
  text-align: center;
}
.brand_logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid black;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #ffb703 !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #ffb703 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}
</style>
