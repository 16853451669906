<template>
  <div style="text-align:left">
    <el-table
        class="table-striped"
        header-row-class-name="text-primary"
        :data="message_attachments || []"
        style="width: 100%"
      >
        <el-table-column
          :min-width="120"
          class-name="td-actions"
          label="Attachments"
        >
          <template v-slot="props">
            <el-link :href="props.row.link" target="blank"> {{ props.row.filename }}</el-link>
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
export default {
  props: ['message_attachments'],
  data() {
    return {
      selected: {}
    }
  }
}
</script>
