<template>
  <div class="card my-3">
    <div class="card-header" style="text-align: left;">Contact Details</div>
    <div class="card-body">
      <form @submit.prevent="save">
        <div class="row">

          <div class="col-md-12">
            <div class="form-group" id="contact_name">
              <label for="input-contact_name">Contact name</label>
              <input type="text" class="form-control" id="input-contact_name" v-model="form.contact_name"
              :class="{ 'is-invalid': v.form.contact_name.$errors.length }" >
              
              <div class="input-errors" v-for="error of v.form.contact_name.$errors" :key="error.$uid">
                <div class="error-text">Contact Name {{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" id="organisation_name">
              <label for="input-organisation_name">Name of organisation</label>
              <input type="text" class="form-control" id="input-organisation_name" v-model="form.organisation_name" 
              :class="{ 'is-invalid': v.form.organisation_name.$errors.length }">
              
              <div class="input-errors" v-for="error of v.form.organisation_name.$errors" :key="error.$uid">
                <div class="error-text">Org Name {{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" id="role">
              <label for="input-role">Role in organisation</label>
              <input type="text" class="form-control" id="input-role" v-model="form.role" 
              :class="{ 'is-invalid': v.form.role.$errors.length }">
              
              <div class="input-errors" v-for="error of v.form.role.$errors" :key="error.$uid">
                <div class="error-text">Role {{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" id="phone_number">
              <label for="input-phone_number">Phone Number</label>
              <input type="text" class="form-control" id="input-phone_number" v-model="form.phone_number"
              :class="{ 'is-invalid': v.form.phone_number.$errors.length }">
              
              <div class="input-errors" v-for="error of v.form.phone_number.$errors" :key="error.$uid">
                <div class="error-text">Phone number {{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" id="email_address">
              <label for="input-email_address">E-mail Address</label>
              <input type="email" class="form-control" id="input-email_address" v-model="form.email_address" 
              :class="{ 'is-invalid': v.form.email_address.$errors.length }">
              
              <div class="input-errors" v-for="error of v.form.email_address.$errors" :key="error.$uid">
                <div class="error-text">E-mail address {{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" id="postal_address">
              <label for="input-postal_address">Postal address</label>
              <input type="text" class="form-control" id="input-postal_address" v-model="form.postal_address" 
              :class="{ 'is-invalid': v.form.postal_address.$errors.length }">
              
              <div class="input-errors" v-for="error of v.form.postal_address.$errors" :key="error.$uid">
                <div class="error-text">Postal address {{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" id="date">
              <label for="input-date" class="form-label">Date</label>
              <div class="">
                <input type="text" id="input-date" class="form-control" :readonly="false" style="background-color: transparent;" 
                v-model="form.date" 
                :class="{ 'is-invalid': v.form.date.$errors.length }" ref="dateInput" />
                
                <div class="input-errors" v-for="error of v.form.date.$errors" :key="error.$uid">
                  <div class="error-text">Date {{ error.$message }}</div>
                </div>
              </div>
              
            </div>
          </div>
          

          <div class="col-md-12">
            
                <Dropzone
                  label="Drop or select file"
                  :max_files="1"
                  v-model="form.file"
                  :clean="clear_upload"
                  :multiple="false"
                  extensions=".jpg, .jpeg, .png"
                  :state="validation('file')"
                  @input="checkFile($event)"
                ></Dropzone>
            <!-- </b-form-group> --> 

            <!-- <b-img :src="form.signature_url" v-if="form.signature_url" style="height: 150px" :id="'picture'"></b-img> -->
            <img :src="form.signature_url" v-if="form.signature_url" alt="Signature" style="height: 150px" :id="'picture'">
          </div>

        </div>

        <div style="text-align:center">
          <!-- <b-button type="button" variant="info"  :disabled="!validateAll" @click="save($event)">Save</b-button>
          <b-button type="button"  @click="close()" v-if="editing" style="margin-left: 10px">Close</b-button> -->
          <button type="submit" class="btn btn-info" >Save</button>
          <button type="button" class="btn" @click="close()" v-if="editing" style="margin-left: 10px">Close</button>
        </div>
      </form>
    </div>


  </div>
</template>

<script>

import { ProductSupplier } from '@/api_services/supplier'
import Dropzone from '../../common/Dropzone.vue'
import $ from 'jquery'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup () {
      return { v: useVuelidate() }
    },
  props: ['selected','editing'],
  components: {
    Dropzone
  },
  data() {
    return {
      clear_upload: 0,
      validate: [
        
      ],
      form: {
        'supplier_id': null, 'contact_name': null, 'organisation_name': null, 'role': null,  'phone_number': null, 'email_address': null,
        'postal_address': null, 'signature': null, 'date': null
      }
    }
  },
  validations() {
      return {
        form: {
          contact_name: {
            required,
            
          },
          organisation_name: {
            required,
            
          },
          role: {
            required,
            
          },
          phone_number: {
            required,
            
          },
          email_address: {
            required,
            
          },
          postal_address: {
            required,
            
          },
          date: {
            required,
            
          }
        },
      }
    },
  watch: {
    selected() {
      if (this.selected) this.form = Object.assign({}, this.selected);
    }
  },
  created: function() {
    if (this.selected) this.form = Object.assign({}, this.selected);
  },
  mounted() {
    this.initializeDatePicker();
  },
  methods: {
    initializeDatePicker() {
      flatpickr(this.$refs.dateInput, {
        dateFormat: "Y-m-d",
        altFormat: "F j, Y",
        altInput: true,
        altInputClass: "date-input-flatpickr-sdc form-control "
      });
    },
    openDatePicker() {
      flatpickr(this.$refs.dateInput).open();
    },
    checkFile(event) {
      //var file = event[0].file
       //var file = event.target.files[0]
      var file = event
      this.readFile(file[0])
    },
    readFile(file) {
      var loadFile = function(file) {
        var reader = new FileReader();
        reader.onload = function(){
          //var output = document.getElementById('picture');
          //output.src = reader.result;
          $("#picture").attr("src", reader.result)
        };

        reader.readAsDataURL(file);

      };
      loadFile(file)
    },
    invalidFeedback(field) {
      return ""
    },
    async save(evt) {
      evt.preventDefault()
      const result = await this.v.$validate()
      if (!result) return

      ProductSupplier.saveSupplierContactDetails(this.getFormData())
        .then(result => {
          this.$toast.success('Saved successfully')
          this.$emit('save', result.data.supplier)
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a user')
          console.error('error:', error.response.data)
        })
    },
    close() {
      this.$emit('save', null)
    },
    getFormData() {
      var formData = new FormData();
      formData.append("contact_name", this.form.contact_name);
      formData.append("organisation_name", this.form.organisation_name);
      formData.append("role", this.form.role);
      formData.append("phone_number", this.form.phone_number);
      formData.append("email_address", this.form.email_address);
      formData.append("postal_address", this.form.postal_address);
      formData.append("date", this.form.date);

      formData.append("file", this.form.file);

      return formData;
    },
    validation(field) {
      return true
      // switch(field) {
      //   case 'contact_name':
      //     return this.$store.state.isStringValid(this.form.contact_name);
      //   case 'organisation_name':
      //     return this.$store.state.isStringValid(this.form.organisation_name);
      //   case 'role':
      //     return this.$store.state.isStringValid(this.form.role);
      //   case 'phone_number':
      //     return this.$store.state.isStringValid(this.form.phone_number);
      //   case 'email_address':
      //     return this.$store.state.isEmailValid(this.form.email_address);
      //   case 'postal_address':
      //     return this.$store.state.isStringValid(this.form.postal_address);
      //   case 'date':
      //     return this.$store.state.isDateValid(this.form.date);

      //   default:
      //     return true;
      // }
    },
  },
  computed: {
    validateAll() {
      var self = this
      var response = true

      this.validate.forEach(function(field){
        if (!self.validation(field)) {
           response = false;
        }
      })
      return response;
    }
  }
}
</script>
