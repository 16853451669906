<template>
  <div style="text-align:left">
    <div v-if="selected.call_off_status == 4">
      Call Off Agreement is now available
      <a :href="selected.call_off_file_link" target="_blank">Download now</a>
      <hr />
    </div>

    <div v-if="selected.call_off_status != 4">
      <div>
        <div>
          You can review the Call Off Agreement by downloading the file below
        </div>

        <div v-if="selected.call_off_approval">
          <el-link v-if="selected.call_off_approval.final_signature == 'buyer'"
            :href="selected.call_off_approval.full_path" target="_blank"> Download Call Off Document
            Preview</el-link>
          <el-link v-if="selected.call_off_approval.final_signature == 'supplier'"
            :href="selected.call_off_approval.supplier_full_path" target="_blank"> Download Call Off Document
            Preview</el-link>
        </div>

        <a v-else :href="previewDownloadLink" download target="_blank">Download Call Off Document Preview</a>
      </div>
      <hr />
    </div>
    <div v-if="selected.call_off_start_date && selected.call_off_status != 4">
      Call Off Start Date:
      {{
      start_date
      ? selected.call_off_start_date
      : $store.state.formattedDate(selected.call_off_start_date)
      }}
    </div>
    <div v-else-if="selected.call_off_start_date && selected.call_off_status == 4">
      Call Off Start Date:
      {{ $store.state.formattedDate(selected.call_off_start_date) }}
    </div>
    <div v-if="selected.contract_period">
      Contract Period :
      {{ contract_period ? contract_period : selected.contract_period }} years
    </div>
    <div>
      Extension Period (Option 1):
      <span v-if="selected.extension_period">
        {{ ext_1 ? ext_1 : selected.extension_period }} years
      </span>
    </div>
    <div>
      Extension Period (Option 2):
      <span v-if="selected.extension_period_2">
        {{
        ext_2 ? ext_2 : selected.extension_period_2
        }} years</span>
    </div>
    <div>
      Extension Period (Option 3):
      <span v-if="selected.extension_period_3">{{
        ext_3 ? ext_3 : selected.extension_period_3
        }} years</span>
    </div>
    <div>
      Extension Period (Option 4):
      <span v-if="selected.extension_period_4">{{
        ext_4? ext_4 : selected.extension_period_4
        }} years </span>
    </div>
    <div v-if="selected.call_off_status === 3 || selected.call_off_status == 4">
      <hr />
      <div style="font-weight:bold">Buyer Info</div>
      <div>Name: {{ selected.buyer_name }}</div>
      <div>
        Signature:
        <span v-if="selected.call_off_approval && selected.call_off_approval.status == 1">Approved
        </span>
        <span class="comic-sans" v-else>{{ selected.buyer_signature }} </span>
      </div>
      <div>
        Date Signed:
        {{ selected.buyer_signed_date_formatted }}
        <!-- {{ $store.state.formattedDate(selected.buyer_signed_date_formatted) }} -->
      </div>
    </div>
    <div v-if="selected.call_off_status === 2 || selected.call_off_status == 4">
      <hr />
      <div style="font-weight:bold">Supplier Info</div>
      <div>Name: {{ selected.supplier_name }}</div>
      <div>
        Signature:
        <span v-if="selected.call_off_approval && selected.call_off_approval.supplier_status == 1">Approved
        </span>
        <span class="comic-sans" v-else>{{ selected.supplier_signature }}</span>
      </div>
      <div>
        Date Signed:
        {{
        selected.supplier_signed_date_formatted
        }}
      </div>
    </div>

    <div v-if="selected.call_off_status == 4">
      <hr />
      <div style="font-weight:bold">Owner Info</div>
      <div>Name: {{ selected.owner_name }}</div>
      <div>Signature: <img src="/owner_signature.png" /></div>
      <div>
        Date Signed:
        {{ $store.state.formattedDate(selected.owner_signed_date) }}
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/fonts.css'; // Adjust the path as needed

export default {
  props: [
    'product_search_result',
    'start_date',
    'contract_period',
    'ext_1',
    'ext_2',
    'ext_3',
    'ext_4'
  ],
  data() {
    return {
      selected: {}
    }
  },
  created: function () {
    this.selected.contract_period = this.contract_period,
    this.selected.extension_period = this.ext_1
    this.selected.extension_period_2 = this.ext_2
    this.selected.extension_period_3 = this.ext_3
    this.selected.extension_period_4 = this.ext_4
    this.selected = Object.assign({}, this.product_search_result)
  },
  watch: {
    product_search_result: function () {
      this.selected.contract_period = this.contract_period,
      this.selected.extension_period = this.ext_1
      this.selected.extension_period_2 = this.ext_2
      this.selected.extension_period_3 = this.ext_3
      this.selected.extension_period_4 = this.ext_4
      this.selected = Object.assign({}, this.product_search_result)
    },
    start_date(newValue) {
      this.selected.call_off_start_date = newValue
    },
    contract_period(newValue) {
      this.selected.contract_period = newValue
    },
    ext_1(newValue) {
      this.selected.extension_period = newValue
    },
    ext_2(newValue) {
      this.selected.extension_period_2 = newValue
    },
    ext_3(newValue) {
      this.selected.extension_period_3 = newValue
    },
    ext_4(newValue) {
      this.selected.extension_period_4 = newValue
    },

  },
  computed: {
    previewDownloadLink() {
      let url = this.$store.state.call_off_preview_file
      let data = '?'
      data += '&call_off_start_date=' + this.selected.call_off_start_date
      data += '&contract_period=' + this.selected.contract_period
      data += '&extension_period=' + this.selected.extension_period
      data += '&extension_period_2=' + this.selected.extension_period_2
      data += '&extension_period_3=' + this.selected.extension_period_3
      data += '&extension_period_4=' + this.selected.extension_period_4
      data += '&supplier_name=' + this.selected.supplier_name
      data += '&supplier_title=' + this.selected.supplier_title
      data += '&buyer_name=' + this.selected.buyer_name
      data += '&buyer_title=' + this.selected.buyer_title
      data += '&product_name=' + this.selected.product.name
      data += '&buyer_address=' + this.selected.buyer_address
      data += '&supplier_address=' + this.selected.supplier_address
      data += '&call_off_start_date_1=' + this.selected.call_off_start_date

      return url + data
    }
  },
  methods: {}
}
</script>

<style scoped>
.comic-sans {
  font-family: 'Comic Sans MS', cursive;
}
</style>
