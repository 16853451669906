<template>
  <div>
     <el-select
        v-model="selected_roles"
        multiple
        placeholder="Select"
        class="w-100"
      >
        <el-option
          v-for="role in roles"
          :key="role.id"
          :label="role.code"
          :value="role.id"
        />
      </el-select>
  </div>
</template>

<script>

import { Role } from '@/api_services/role_service'

export default {
  props: ['user','term'],
  data() {
    return {
      selid:"userrolesdropdown",
      selected_roles: [],
      roles: [],
    }
  },
  watch: {
    selected_roles: {
       handler(newValue) {
        this.$emit('update', newValue)
       },
      deep: true
    }

  },
  created: function() {
    this.selected_roles = []
    let self = this
    if (!this.user.roles) this.user.roles = []
    this.user.roles.forEach(role => {
      self.selected_roles.push(role.id)
    });

    this.getRoles()

  },
  computed: {
    userRoleOptions() {
      return this.roles?.map(c => { return { value: c.id.toString(), label: c.name } } ) || []
    },
  },
  methods: {
    getRoles() {
      this.roles = []
      Role.get({params: {term: this.term}})
        .then(result => {
          if(result.data.roles) {
            this.roles = result.data.roles
          }
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching roles')
          console.error('error:', error.response.data)
        })
    },
  }
}
</script>
