<template>
  <div>
    <h2 v-if="supplier" class="text-white">Manage Transaction types for {{ supplier.name}}</h2>
    <p>
      <back label="Back to suppliers" link="/main/suppliers"></back>
    </p>
    <div class="row">
      <div class="col-md-7">
        <div class="card my-3">
          <div class="card-header"><h5>Transaction Types</h5></div>
          <div class="card-body">
            <div class="table-responsive">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term" placeholder="Search">
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="search()">Search</button>
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="create()">Add</button>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Global Term</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Rate</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ranges</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-sm" v-for="transaction_type in transaction_types" :key="transaction_type.id">
                    <td class="text-wrap">{{ transaction_type.name }}</td>
                    <td class="text-wrap">{{ getTransactionTypeName(transaction_type) }}</td>
                    <td>{{ transaction_type.rate }}</td>
                    <td>
                      <button type="button" class="btn bg-gradient-secondary btn-xs" @click="showRanges(transaction_type)">Rate Ranges</button>
                    </td>
                    <td style="width:1%">
                      <a class="link me-3" @click="showEdit(transaction_type)" v-tooltip="'Edit'">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(transaction_type)" v-tooltip="'Delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="original && !show_ranges">
        <div class="card my-3">
          <div class="card-header"><h5>{{ selectedName(original) }}</h5></div>
          <div class="card-body">
            <form  @submit="onSubmit" @reset="onCancel">
              <div class="form-group">
                <label for="select-term">Global term</label>
                <select class="form-select form-select-sm" id="select-term" v-model="selected.global_transaction_type" :state="validation('global_transaction_type')">
                  <option v-for="(g, index) in global_transaction_types" :key="index" :value="g" class="text-wrap text-sm">{{ g.name + ' - ' + g.category_name}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="select-category">Category</label>
                <select class="form-select form-select-sm" id="select-category" v-model="selected.category" :state="true">
                  <option v-for="(g, index) in categories" :key="index" :value="index">{{ g }}</option>
                </select>
              </div>
              <div class="form-group">
                <label 
                  id="rate" label-for="input-name" valid-feedback=""
                  :invalid-feedback="invalidFeedback('name')"
                  :state="validation('name')"
                >Name</label>
                <a class="link-primary text-bold mx-1" @click="copyGlobalTerm()">copy global term</a>
                <input id="input-name" v-model="selected.name" :state="validation('name')" type="text" class="form-control form-control-sm">
              </div>
              <div class="form-group">
                <label 
                  id="rate" for="input-rate" valid-feedback=""
                  :invalid-feedback="invalidFeedback('rate')"
                  :state="validation('rate')"
                >Default rate</label>
                <input id="input-rate" step="0.0001" v-model="selected.rate" :state="validation('rate')" type="number" class="form-control form-control-sm">
              </div>
              <button type="submit"  :disabled="!validateAll" class="btn bg-gradient-success btn-sm">Save</button>
              <button type="reset" variant="danger" class="btn bg-gradient-secondary btn-sm">Cancel</button>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-5" v-if="original && show_ranges">
        <ranges :transaction_type="original"></ranges>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>
 
  </div>
</template>

<script>

import { TransactionType } from '@/api_services/transaction_type'
import  { Supplier } from '@/api_services/suppliers'
import { GlobalTransactionType } from '@/api_services/global_transaction_type'
import Ranges from '../ranges/ranges.vue'

export default {
  data() {
    return {
      dialogVisible: false,
      categories: ['N/A','Premium','Acct Fee','Excluded'],
      fields: ['id', 'name','global_term','category','rate', 'ranges', 'action'],
      transaction_types: [],
      selected: null,
      original: null,
      validate: ['global_transaction_type', 'name'],
      to_remove: null,
      supplier_id: null,
      show_ranges: false,
      term: null,
      supplier: null,
      global_transaction_types: []
    }
  },
  components: { Ranges },
  created: function() {
    this.supplier_id = this.$route.params.supplier_id
    this.getSupplier()
  },
  methods: {
    getSupplier() {
        Supplier.show(this.supplier_id)
        .then(result => {
          if (result.data.supplier)
            this.supplier = result.data.supplier
            this.getTransactionTypes()
            this.getGlobalTransactionTypes()
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching supplier')
          console.error('error:', error.response.data)
        })
    },
    copyGlobalTerm() {
      if ( this.selected.global_transaction_type) this.selected.name = this.selected.global_transaction_type.name
    },
    getGlobalTransactionTypes() {
      GlobalTransactionType.get({params: {per_page: 999}})
        .then(result => {
          if (result.data.global_transaction_types)
            this.global_transaction_types = result.data.global_transaction_types
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching global transaction types')
          console.error('error:', error.response.data)
        })
    },
    getTransactionTypeName(type) {
      if (type.global_transaction_type) return type.global_transaction_type.name
      return ""
    },
    search() {
      this.getTransactionTypes()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      TransactionType.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getTransactionTypes()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing transaction type')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit '+selected.name: 'New'
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
      this.show_ranges = false
    },
    showRanges(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
      this.show_ranges = true
    },
    getTransactionTypes() {
      this.reset()
      TransactionType.get({supplier_id: this.$route.params.supplier_id, params: {term: this.term}})
        .then(result => {
          if (result.data.transaction_types)
            this.transaction_types = result.data.transaction_types
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching transaction types')
          console.error('error:', error.response.data)
        })
    },
    validation(field) {
      switch(field) {
        case 'global_transaction_type_id':
          return this.selected.global_transaction_type_id ? true: false
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'global_category_type_id') return "Name is required"
      return ""
    },
    onSubmit(evt) {
        evt.preventDefault()
        if (this.selected.global_transaction_type) this.selected.global_transaction_type_id = this.selected.global_transaction_type.id

        var action = this.selected.id ? TransactionType.update(this.selected) : TransactionType.create(this.selected)
        
        action.then(result => {
          this.$toast.success('Saved successfully')
          this.getTransactionTypes()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving transaction type')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {name: null, rate: 0, supplier_id: this.supplier_id}
      this.selected = Object.assign({}, this.original);
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>