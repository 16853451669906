<style>
.el-segmented{
  padding: 0px !important;
}

.el-segmented__item{
  margin: 0px !important;
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border: 1px solid #dcdfe6
}


.el-segmented__item.is-selected{
  font-weight: bold !important;
}

</style>

<template>

  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Procurement Project</h5>
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-md-2">
                  <el-input v-model="search_value" placeholder="Search" class="input-with-select">
                    <template #append>
                      <el-button><i class="fa fa-search"></i></el-button>
                    </template>
                  </el-input>
                </div>
                <div class="col-md-5">
                  <el-segmented @change="filterChange" v-model="selected_filter" :options="filter_options" />
                </div>
                <div class="col-md-5" style="text-align: right;">
                  <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2"
                    @click="loadProcurementProjects()">
                    <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                  </button>
                  <button type="button" class="btn btn-info btn-sm mb-0 me-2" @click="import_modal = true">
                    <i class="fa-solid fa-upload me-1"></i> Import
                  </button>
                  <button v-if="if_from_settings == 0" type="button" class="btn btn-primary btn-sm mb-0 me-2"
                    @click="addProject">
                    <i class="fa-solid fa-plus me-1"></i> Add
                  </button>
                </div>
              </div>
              <div class="text-end">
              </div>
            </div>
            <br>
            <div class="table-responsive" v-loading="loading">
              <table class="table table-fluid align-items-center mb-0 table-hover ">
                <thead>
                  <tr>

                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Name
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Type
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Date
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Status
                    </th>
                    <th v-if="selected_filter != ProjectFilters.FILTER_MY"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Creator
                    </th>
                    <th v-if="selected_filter != ProjectFilters.FILTER_MY"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Company
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!procurement_projects.length">
                    <td v-if="loading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="procurement_project in procurement_projects"
                    :key="procurement_project.id">

                    <td>{{ procurement_project.name }}</td>
                    <td>{{ procurement_project.type_name }}</td>
                    <td>{{ $filters.dateFormatFilter(procurement_project.created_at) }}</td>
                    <td>
                      <el-tag v-tooltip="procurement_project.status_name" :type="procurement_project.status_tag.color"
                        :effect="procurement_project.status_tag.type">{{ procurement_project.status_name }}</el-tag>
                    </td>
                    <td v-if="if_from_settings == 1">
                      <el-tooltip :enterable="false" placement="top-start">
                        <template #content>
                          <span>View PIN Participants</span>
                        </template>

                        <el-button type="success" size="small"
                          @click="viewParticipants(procurement_project.pin_notice.id, 'pin')">
                          <i class="fa fa-eye"></i>&nbsp;View PIN Participants ({{ procurement_project.pin_participants
                          ? Object.keys(procurement_project.pin_participants).length : 0 }})</el-button>
                      </el-tooltip>

                      <el-tooltip :enterable="false" placement="top-start">
                        <template #content>
                          <span>View ITT Participants</span>
                        </template>

                        <el-button type="primary" size="small"
                          @click="viewParticipants(procurement_project?.itt_framework?.id, 'itt')">
                          <i class="fa fa-eye"></i>&nbsp;View ITT Participants ({{ procurement_project.itt_participants
                          ? Object.keys(procurement_project.itt_participants).length : 0 }})</el-button>
                      </el-tooltip>
                    </td>
                    <td v-if="selected_filter != ProjectFilters.FILTER_MY"
                      :class="procurement_project?.user?.id == user.id ? 'text-info text-bold' : ''">{{
                      procurement_project?.user?.name }}</td>
                    <td v-if="selected_filter != ProjectFilters.FILTER_MY">{{ procurement_project?.company?.name }}</td>

                    <td v-if="if_from_settings == 0 && 
                        (
                          $store.state.isAdmin() ||
                          procurement_project?.user?.id == user.id || 
                          user?.company?.id == procurement_project?.company?.id || 
                          company_ids.includes(procurement_project?.company?.id) 
                          )">

                      <el-button-group>
                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Manage</span>
                          </template>

                          <el-button v-allowed="'procurement_project,update'" type="primary" size="small"
                            @click="manageProject(procurement_project)">
                            <i class="fa fa-edit"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>View PIN</span>
                          </template>

                          <el-button type="success" size="small" @click="viewPIN(procurement_project)">
                            <i class="fa fa-eye"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>View PIN Participants</span>
                          </template>

                          <el-button type="info" size="small"
                            @click="viewParticipants(procurement_project.pin_notice.id, 'pin')">
                            <i class="fa fa-list"></i>&nbsp;({{ procurement_project.total_pin_participants
                            }})</el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>View ITT Participants</span>
                          </template>

                          <el-button type="primary" size="small"
                            @click="viewParticipants(procurement_project?.itt_framework?.id, 'itt')">
                            <i class="fa fa-list"></i>&nbsp; ({{ procurement_project.total_itt_framework_participants
                            }})</el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>View History</span>
                          </template>

                          <el-button type="warning" size="small" @click="viewProjectHistory(procurement_project)">
                            <i class="fa fa-history"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Delete</span>
                          </template>

                          <el-button v-allowed="'procurement_project,delete'" type="danger" size="small"
                            @click="showRemove(procurement_project)">
                            <i class="fa fa-trash"></i></el-button>
                        </el-tooltip>

                      </el-button-group>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="loadProcurementProjects()" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="if_from_settings == 1 && if_has_leave_request == 1">
      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h5>PIN Leave Request</h5>
            <LeaveParticipantRequestList :type="'pin'"></LeaveParticipantRequestList>
          </div>
        </div>

      </div>
      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h5>ITT Leave Request</h5>
            <LeaveParticipantRequestList :type="'itt'"></LeaveParticipantRequestList>
          </div>
        </div>
      </div>
    </div>

    <el-dialog id="modal-remove" v-model="dialogVisible" title="Confirm Delete?" width="500"
      :before-close="handleClose">
      <div v-if="to_remove">
        You are about to remove this procurement project {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button v-bind:disabled="loading" type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog id="add-remove" v-model="add_modal" width="60%">
      <ProcurementProjectsForm :key="'project_' + selected.id" @save="afterSave" @cancel="handleClose"
        :selected="selected" @reload="loadProcurementProjects"></ProcurementProjectsForm>
    </el-dialog>
  </div>

  <el-drawer v-model="showForm" :show-close="true" size="80%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div v-if="pin_notice" class="container">
      <PinNoticeView :view_itt="1" :selected_framework="pin_notice"></PinNoticeView>
    </div>
  </el-drawer>

  <el-drawer v-model="history_drawer" :show-close="true" size="40%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div v-if="selected" class="container">
      <ProcurementProjectHistory :procurement_project_id="selected.id" :name="selected.name">
      </ProcurementProjectHistory>
    </div>
  </el-drawer>

  <el-drawer v-model="participant_drawer" :show-close="true" size="40%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <ParticipantsList :id="participant_form.id" :type="participant_form.type"></ParticipantsList>
    </div>
  </el-drawer>

  <el-dialog destroy-on-close v-model="import_modal" title="Upload File" width="500">
    <form @submit="uploadFile" v-loading="loading">
      <p>Please upload an .xlsx file. You can use the below template for the importing.<br>

        <el-button-group>
          <el-link class="me-3" type="success" link href="/template/import_project_template_official.xlsx">Sample Excel
            Template</el-link>
        </el-button-group>
      </p>

      <dropzone label="Drop or select file" :max_files="1" :clean="0" :multiple="false" extensions="'xlsx,xls'"
        @input="setFile($event)"></dropzone>
      <button type="submit" class="btn btn-success" v-loading="loading" :disabled="!import_form.file">
        {{ loading ? 'Saving' : 'Save' }}
      </button>
    </form>
  </el-dialog>

  <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="false" v-model="error_modal" width="50%">
    <ul class="p-4 list-group">
      <template v-for="(message,i) in error_message" :key="i">
        <li v-if="message != '' " class="list-group-item"><i class="text-danger fa fa-info-circle"></i>&nbsp;{{ message
          }}</li>
      </template>
    </ul>
  </el-dialog>

</template>
    
    <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects'
    import ProcurementProjectsForm from './ProcurementProjectsForm.vue'
    import PinNoticeView from '../pin_notice/PinNoticeView'
    import { PinNotices } from '@/api_services/pin_notices'
    import ProcurementProjectHistory from './ProcurementProjectHistory.vue'
    import ParticipantsList from './ParticipantsList.vue'
    import LeaveParticipantRequestList from './LeaveParticipantRequestList.vue'
    import { mapState } from 'vuex'
    import Dropzone from '@/components/common/Dropzone'

    export default {
      computed: {
        ...mapState(['ProjectFilters']),
        user () {
          return this.$store.state.savedUser // get state
        }
      }, 
      props: {
        if_from_settings: {
          required: false,
          default: 0
        },
        if_has_leave_request: {
          required: false,
          default: 0
        },
      },
      emits: ["save", "cancel"],
      components: {
        ProcurementProjectsForm, PinNoticeView, ProcurementProjectHistory, ParticipantsList, LeaveParticipantRequestList, Dropzone
      },
      data() {
        return {
          error_message: [],
          error_modal: false,
          import_form: {},
          import_modal: false,
          selected_filter: "all",
          filter_options: [
            {
              label: 'All Projects',
              value: 'all'
            },
            {
              label: 'My Company(s) Projects',
              value: 'company'
            },
            {
              label: 'My Projects',
              value: 'my'
            },
          ],
          company_ids: [],
          participant_form: {
            id: null,
            type: null
          },
          participant_drawer: false,
          history_drawer: false,
          pin_notice: null,
          selected: null,
          add_modal: false,
          dialogVisible: false,
          procurement_projects: [],
          to_remove: null,
          meta: {
            page: 1,
            total: 0,
            pages: 1
          },
          showForm: false,
          loading: false,
          search_value: null,
        }
      },
      watch: {
        search_value: function () {
            if (!this.search_value || this.search_value.length > 2) this.loadProcurementProjects()
        }
      },
      created: function () {
        this.loadProcurementProjects()
        
        for (const [key, value] of Object.entries(this.user.companies)) {
          this.company_ids.push(value.id)
        }
      },
      methods: {
        uploadFile(evt) {
          evt.preventDefault()

          var formData = new FormData()
          formData.append('file', this.import_form.file)

          this.loading = true

          ProcurementProjects.importProjects(formData)
            .then(result => {
              if(result.data.status == 1 || result.data.status == 2){
                result.data.status == 1 ? this.$toast.success(result.data.message) : this.$toast.warning(result.data.message)
                this.meta.page = 1
                this.loadProcurementProjects()
              }
              else{
                this.$toast.error('Failed to import')
                this.error_message = result.data.message.split(',')
                this.error_modal = true
              }

              this.import_modal = false
              this.import_form.file = null
            })
            .catch(error => {

            }).finally(() => {
              this.loading = false
          })
        },
        setFile(files) {
          this.import_form.file = files[0]
        },
        filterChange(value){
          this.loadProcurementProjects()
        },
        viewParticipants(id, type){
          if(!id){
            id = 0
          }
          this.participant_form.id = id
          this.participant_form.type = type
          this.participant_drawer = true
        },
        viewProjectHistory(project){
            this.selected = project
            this.history_drawer = true
        },
        viewPIN(project){
          this.globalState.loading = true

          PinNotices.show(project.pin_notice.id)
          .then(result => {
            if (result.data.pin_notice) {
              this.pin_notice = result.data.pin_notice
              this.pin_notice.name = project.name
              this.showForm = true
            }
            else{
              this.$toast.error('No data found')
            }
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching request')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.globalState.loading = false
          })
        },
        addProject(){
            this.selected = { id: null }
            this.add_modal = true
        },
        manageProject(project){
            this.selected = project
            this.add_modal = true
        },
        handleClose() {
          this.add_modal = false
          this.dialogVisible = false
        },
        afterSave() {
          this.loadProcurementProjects()
          this.add_modal = false
        },
        search() {
          this.meta.page = 1
          this.loadProcurementProjects()
        },
        remove(bvModalEvt) {
          bvModalEvt.preventDefault()

          this.globalState.loading = true
          ProcurementProjects.delete({ id: this.to_remove.id})
            .then(result => {
              this.$toast.success(result?.data?.message || 'Project has been removed successfully')
              this.loadProcurementProjects()
              
              this.handleClose()
            })
            .catch(error => {
                this.$toast.error('An error occured while deleting project')
                console.error('error:', error.response.data)

            }).finally(() => {
              this.globalState.loading = false
            })
        },
        showRemove(type) {
          this.to_remove = Object.assign({}, type)
          this.dialogVisible = true
        },
        loadProcurementProjects() {
          this.globalState.loading = true
          this.handleClose()
          this.procurement_projects = []
          this.loading = true
          ProcurementProjects.get({
              params: {
                page: this.meta.page,
                search_value: this.search_value,
                selected_filter: this.selected_filter 
              }
            })
            .then(result => {
              if (result.data.procurement_projects) this.procurement_projects = result.data.procurement_projects
              this.meta = result.data.meta
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
              this.loading = false
            })
        }
      },
    }
    </script>
    