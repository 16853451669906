<template>
  <div>
    <not-allowed v-if="!$store.state.isViewable('requests')"></not-allowed>

    <div class="container" v-else>
      <div class="card p-4">
        <h2>Further Competition upload statements/files</h2>
        <div class="row">
          <div class="col-md-4 col-sm-12 col-xs-12">
            <div class="input-group mb-3"> 
              <input type="text" class="form-control">
              <button class="btn btn-primary mb-0" type="button" id="button-addon1" @click="search()">Search</button>
            </div>
          </div>
          <div class="col-md-2 col-sm-12 col-xs-12">
            <select class="form-select" v-model="is_active" name="" id="">
              <option v-for="option in options" :key="option.id" :value="option.value" >{{ option.text }}</option>
            </select>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-fluid align-items-center mb-0">
            <thead>
              <tr>
                <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">name</td>
                <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</td>
                <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Category</td>
                <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">File/Attachment</td>
                <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</td>
                <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Product Name</td>
                <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="request in requests" :key="request.id">
                <td class="text-sm">{{ request.name }}</td>
                <td class="text-sm">{{ request.email }}</td>
                <td class="text-sm">{{ request.category_name }}</td>
                <td class="text-sm"><a :href="getLink(request)" target="_blank">Download</a></td>
                <td class="text-sm">
                  <span v-if="request.status == 0" class="badge bg-info">New</span>
                  <span v-if="request.status == 1" class="badge bg-secondary">Sending</span>
                  <span v-if="request.status == 2" class="badge bg-primary">Emailed</span>

                </td>
                <td class="text-sm">{{ request.product }}</td>
                <td class="text-sm"> 
                  <a
                    class="link"
                    @click="showRemove(request)"
                    v-tooltip="'Delete'"
                  >
                    <i class="fas fa-trash text-secondary"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginateComponent :meta="meta" v-on:update="getRequests()" />

        </div>
        
      </div>

      <!-- <el-card class="mx-auto"> -->
        <!-- <h2>Detailed Cost Comparison Requests</h2> -->
        <!-- <div>
          <b-row class="text-center"> -->
            <!-- <b-col md="4" sm="12" xs="12">
              <b-input-group style="margin-bottom: 5px;">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  aria-label="Text input with checkbox"
                  v-model="term"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    text="Button"
                    variant="primary"
                    @click="search()"
                    >Search</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-col> -->
            <!-- <b-col md="2" sm="12" xs="12">
              <b-form-select
                v-model="is_active"
                :options="options"
                class="mt-3"
                @change="search()"
              ></b-form-select>
            </b-col> -->
          <!-- </b-row>
        </div>
        <hr />
        <b-table responsive striped :items="requests" :fields="fields">
          <template #cell(category)="row">
            <span>{{ getCategoryString(row.item.category) }}</span>
          </template>
          <template #cell(file)="row">
            <a target="_blank" :href="getLink(row.item)">Download</a>
          </template>
          <template #cell(product)="row">
            <span v-if="row.item.product">{{ row.item.product.name }}</span>
          </template>

          <template #cell(status)="row">
            <b-badge variant="light" v-if="row.item.status === 0">New</b-badge>
            <b-badge variant="light" v-if="row.item.status === 1"
              >Sending</b-badge
            >
            <b-badge variant="light" v-if="row.item.status === 2"
              >Emailed</b-badge
            >
          </template>
          <template #cell(action)="row"> -->
            <!-- <b-button
              size="sm"
              class="mr-2"
              variant="primary"
              @click="showReplyModal(row.item)"
              >Reply</b-button
            > -->
            <!-- <b-button
              :id="'delete-' + row.item.id"
              size="sm"
              class="mr-2"
              @click="showRemove(row.item)"
              ><b-icon
                :icon="row.item.is_active ? 'eye-slash-fill' : 'eye-fill'"
              ></b-icon
            ></b-button>
            <b-tooltip :target="'delete-' + row.item.id" triggers="hover">
              {{ row.item.is_active ? 'Hide' : 'Unhide' }}
            </b-tooltip>
          </template>
        </b-table>
        <PaginateComponent :meta="meta" v-on:update="getRequests()" />
      </el-card> -->

      <el-dialog
        id="modal-reply"
        v-model="modal_reply"
        title="Reply to request"
        width="70%"
      >
        
      <div v-if="selected">
          <b-form>
            <b-form-group
              id="title"
              label="Title"
              label-for="input-title"
              valid-feedback=""
              :invalid-feedback="invalidFeedback('title')"
              :state="validation('title')"
            >
              <b-form-input
                id="input-title"
                v-model="selected.title"
                :state="validation('title')"
                trim
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="message"
              label="Message"
              label-for="input-message"
              valid-feedback=""
              :invalid-feedback="invalidFeedback('message')"
              :state="validation('message')"
            >
              <b-form-textarea
                id="input-message"
                v-model="selected.message"
                :state="validation('message')"
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>
            <b-form-group>
              <b-form-file
                multiple
                v-model="selected.files"
                placeholder="Choose files or drop it here..."
                drop-placeholder="Drop files here..."
              ></b-form-file>

              <div class="mt-3">
                Selected files:
                <div v-for="(file, i) in selected.files" v-bind:key="i">
                  {{ file.name }}
                  <b-button style="margin-left: 5px;" @click="removeFile(i)">
                    <b-icon icon="file-earmark-x"></b-icon>
                  </b-button>
                </div>
              </div>
            </b-form-group>
          </b-form>
        </div>      

        <template #footer>
          <div class="dialog-footer">
            <el-button @click="modal_reply = false">Cancel</el-button>
            <el-button type="primary" @click="onReply">
              Send
            </el-button>
          </div>
        </template>
      </el-dialog>

      <el-dialog
        id="modal-remove"
        v-model="dialogVisible"
        title="Are you sure?"
        width="500"
      >
        <div v-if="to_remove">
          You are about to {{ to_remove.is_active ? 'hide' : 'unhide' }}
          {{ to_remove.name }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import { UserHelpService } from '@/api_services/user_help_service'
import PaginateComponent from '../../common/Paginate.vue'

export default {
  data() {
    return {
      modal_reply: false,
      dialogVisible: false,
      fields: [
        'id',
        'name',
        'email',
        'category_name',
        'file',
        'status',
        'product',
        'action'
      ],
      emailed: false,
      validate: ['title', 'message'],
      items: [
        {
          id: 1,
          name: 'Dickerson Emeru',
          email: 'dickem@email.com',
          category: 'Banking',
          file: 'xjajsjs.pdf'
        },
        {
          id: 2,
          name: 'Kangaroo Shiro',
          email: 'kangshi@email.com',
          category: 'Banking',
          file: 'xjajsjs.pdf'
        },
        {
          id: 3,
          name: 'Beth Harmon',
          email: 'bharm@email.com',
          category: 'Banking',
          file: 'xjajsjs.pdf'
        },
        {
          id: 4,
          name: 'Jonathan Joestar',
          email: 'jojo@email.com',
          category: 'Banking',
          file: 'xjajsjs.pdf'
        }
      ],
      requests: [],
      selected: null,
      original: null,
      to_remove: null,
      meta: { page: 1 },
      term: '',
      is_active: 1,
      options: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Hidden' }
      ]
    }
  },
  components: { PaginateComponent },
  created: function() {
    this.getRequests()
  },
  methods: {
    removeFile(i) {
      this.selected.files.splice(i, 1)
    },
    getLink(request) {
      var files = request.files || []

      if (files.length === 1) return files[0]
      if (files.length > 1) return request.zip
      return null
    },
    showRemove(request) {
      this.to_remove = Object.assign({}, request)
      this.dialogVisible = true
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      UserHelpService.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Updated successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getRequests()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing transaction type')
          console.error('error:', error.response.data)
        })
    },
    validation(field) {
      switch (field) {
        case 'title':
          if (!this.selected.title) this.selected.title = ''
          return this.selected.title.length > 0
        case 'message':
          if (!this.selected.message) this.selected.message = ''
          return this.selected.message.length > 0
        default:
          return true
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'title')
        return 'Title is required'
      if (!this.validation(field) && field == 'message')
        return 'Message is required'
      return ''
    },
    showReplyModal(request) {
      this.getOneRequest(request.id)
    },
    getOneRequest(id) {
      UserHelpService.show(id)
        .then(result => {
          if (result.data.help_request) {
            var request = result.data.help_request
            if (request.request_reply) {
              this.selected = Object.assign({}, request.request_reply)
            } else {
              this.selected = {
                title: null,
                message: null,
                file: null,
                help_request_id: id
              }
            }
          }
          this.modal_reply = true
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching request')
          console.error('error:', error.response.data)
        })
    },
    onReply(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.isFormValid()) {
        this.$toast.error('Some fields are required')
        return false
      }
      var formData = this.getFormData(this.selected)
      UserHelpService.reply(formData)
        .then(result => {
          if (result.data.message) {
            this.$toast.success(result.data.message)
          }
          this.modal_reply = false
          this.getRequests()
        })
        .catch(error => {
          this.$toast.error('An error occured while replying to request')
          console.error('error:', error.response.data)
        })
    },
    getFormData(form) {
      var formData = new FormData()
      form.files.forEach(function(file, index) {
        formData.append('file_' + index, file)
      })
      formData.append('files_count', form.files.length)
      formData.append('title', form.title)
      formData.append('message', form.message)
      formData.append('help_request_id', form.help_request_id)
      return formData
    },
    getCategoryString(category) {
      switch (category) {
        case 1:
          return 'Banking'
        case 2:
          return 'Merchant Services'
        case 3:
          return 'Direct Debit'
        case 4:
          return 'Payment Gateway'
        case 5:
          return 'IVR and Call Masking'
        case 6:
          return 'Innovation FS'
        default:
          return ''
      }
    },
    getRequests() {
      this.reset()
      UserHelpService.get({
        params: {
          page: this.meta.page,
          term: this.term,
          is_active: this.is_active
        }
      })
        .then(result => {
          if (result.data.user_help_requests) {
            this.requests = result.data.user_help_requests
            this.meta = result.data.meta
          }
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching requests')
          console.error('error:', error.response.data)
        })
    },
    search() {
      this.page = 1
      this.getRequests()
    },
    reset() {
      this.original = null
      this.selected = null
      this.requests = []
    },
    isFormValid() {
      var self = this
      var response = true
      this.validate.forEach(function(field) {
        if (!self.validation(field)) response = false
      })

      return response
    }
  }
}
</script>

<style lang="scss" scoped></style>