<template>
  <span>{{string}}</span>
</template>

<script>

export default {
    name: 'PaginateComponent',
    props: ['amount', 'decimal'],
    data() {
        return {
            string: ''
        }
    },
    created: function() {
      this.string = this.toCurrency(this.amount)
    },
    methods: {
      toCurrency(amount, decimals = 4) {
        if (this.decimal) decimals = this.decimal
        if (!amount) return ""
        let prefix = "";
        if (amount < 0) prefix += "-"
        return prefix+'£ '+Math.abs(amount).toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    }
}
</script>

<style lang="scss" scoped></style>
