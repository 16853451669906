<template>
  <div class="p-4">
    <div>
      <div class="el-input-group el-input-group--small mb-4 mt-4" style="width: 300px;">
        <el-row :gutter="20">

          <el-col >
            <el-input
              placeholder="Search here..."
              v-model="searchTerm"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>
    </div>
    <form @submit="onSubmit" @reset="onClose" v-loading="loading">
      <div class="row">
        <div class="col-md-3" v-for="company in filteredCompanies" :key="company.id">
          <div class="h-100 pb-4">
            <div class="card mt-1 h-100 text-center">
              <div class="card-header bg-gradient-success py-2">
                <h6 class="text-white" >{{ company.name }}</h6>
              </div>
              <div class="card-footer py-1 bg-gradient-light flex-grow-1 d-flex align-items-center">
                <a href="#" class="btn btn-sm bg-gradient-dark my-2 mx-auto" @click="selectCompany(company)">Select</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import { Company } from '@/api_services/company'
import { User } from '@/api_services/user'
import store from '@/store/index'


export default {
  data() {
    return {
        companies: [],
        selected: null,
      searchTerm: '',
        loading: false
    }
  },
  created: function() {
    this.selected = this.$store.state.savedUser
    this.getCompanies()
  },
  methods: {
    getCompanies() {
      this.companies = []
      Company.get({params: {per_page: 999}})
        .then(result => {
          if(result.data.companies) {
            this.companies = result.data.companies
          }
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching companies')
          console.error('error:', error.response.data)
        })
    },
    onClose() {
        this.$emit('update')
    },
    selectCompany(company) {
      this.selected.company_id = company.id
      this.onSubmit()
    },
    onSubmit(evt) {
      this.loading = true
      if (evt) evt.preventDefault()
      User.updateCompany(this.selected).then(result => {
        this.$toast.success('Saved successfully')
        store.commit('saveUser',result.data.user);

        location.reload();
      })
      .catch(error => {
        this.$toast.error('An error occured while saving a user company')
        console.error('error:', error.response.data)
        this.loading = false
      })
    },
  },
  computed: {
    filteredCompanies() {
      return this.companies?.filter(c => c.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    }
  }
}
</script>

<style scoped>
  .selected-box {
    background-color: #cedeff
  }
</style>
