import api from './api'

const API = '/api/supplier'

export const ProductSupplier = {
  get: function(params) {
    return api.get(API, params)
  },
  findByName: function(name) {
    return api.get(API + '/' + name + '/name')
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  saveSupplierDetails: function(params) {
    return api.create(API+'/supplier_detail', params)
  },
  saveSupplierBiddingModel: function(params) {
    return api.create(API+'/supplier_bidding_model', params)
  },
  saveSupplierContactDetails: function(params) {
    return api.createFormData(API+'/supplier_contact_details', params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
