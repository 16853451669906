<template>
  <div>
    <sidebar-content-component v-if="!is_website_bcp"></sidebar-content-component>
    <bcp-sidebar-content v-if="is_website_bcp"></bcp-sidebar-content>
  </div>
</template>

<script>
import BcpSidebarContent from './BcpSidebarContent.vue'
import SidebarContentComponent from './SidebarContentComponentNew.vue'

export default {
  components: { SidebarContentComponent, BcpSidebarContent },
  name: 'SidebarComponent',
  data() {
    return {
      hide_sidebar: false,
      is_website_bcp: parseInt(process.env.VUE_APP_IS_BCP)
    }
  },
  methods: {
    toggle() {
      document.body.classList.toggle('sb-sidenav-toggled')
      document.getElementsByClassName('menu-label').toggle('sb-sidenav-toggled')
      this.hide_sidebar = true
      localStorage.setItem('hide-sidebar', this.hide_sidebar)
    }
  }
}
</script>

<style lang="css" scoped>

</style>
