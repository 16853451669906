<template>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h3>Section II. Object</h3>
                <h4 class="col-md-12">II.1. Scope of the procurement</h4>
                <div class="col-md-6">
                    <br/><label>Title</label>
                    <div :class="{ 'has-error-field': v.form.procurement_title.$invalid }">
                        <el-input :label="'Title'" v-model="form.procurement_title"></el-input>
                    </div>
                    <div v-if="v.form.procurement_title.$invalid" class="error-text" >Title is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Reference Number (optional)</label>
                    <el-input :label="'Reference Number (optional)'" v-model="form.procurement_reference_number"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>II.1.2. Main CPV Code</label>
                    <br/><label style="color: #A9A9A9">Must start with 0 to 44 or 48 for suppliers contracts, 45 for works, or 49 to 98 for services</label>
                    <div :class="{ 'has-error-field': v.form.procurement_cpv_code.$invalid }">
                        <el-select v-model="form.procurement_cpv_code" filterable clearable>
                            <el-option v-for="(description, code) in cpv_code_choices[preliminary.contract_type]" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.procurement_cpv_code.$invalid" class="error-text" >CPV code is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>II.1.3. Type of contract</label>
                    <br/><label style="color: #A9A9A9">You can change the contract type in the preliminary questions</label>
                    <p>{{ contractTypeTranslation(preliminary.contract_type) }}</p>
                </div>
                <div class="col-md-12" v-if="form.procurement_cpv_code">
                    <br/><label>Supplementary Code (optional)</label>
                        <el-select v-model="form.procurement_supplementary_code" filterable clearable multiple>
                            <span v-for="(parts, header) in supplementary_code_choices">
                                <el-option-group :label="header">
                                    <el-option-group v-for="(sub_parts, sub_header) in parts" :label="sub_header">
                                        <el-option v-for="(description, code) in sub_parts" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                                    </el-option-group>
                                </el-option-group>
                            </span>
                            <el-option  :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                </div>
                <div class="col-md-12">
                    <br/><label>II.1.4. Short Description</label>
                    <div :class="{ 'has-error-field': v.form.procurement_short_description.$invalid }">
                        <el-input type="textarea" :rows="5" :label="'Short Description'" v-model="form.procurement_short_description"></el-input>
                    </div>
                    <div v-if="v.form.procurement_short_description.$invalid" class="error-text" >Short Description is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>II.1.5. Estimated total value (excluding VAT) (optional)</label>
                    <el-input :label="'For example, 200000 or 417709.28. For framework agreements or dynamic purchasing systems - estimated total maximum value ofor the entire duration of the framework agreement or dynamic purchasing system.'" v-model="form.procurement_estimated_total_value"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>Currency</label>
                    <div :class="{ 'has-error-field': v.form.procurement_currency.$invalid }">
                        <el-select v-model="form.procurement_currency" filterable clearable>
                            <el-option v-for="(full_name, abbrev) in currency_choices" :key="abbrev" :value="abbrev" :label="abbrev + ': ' + full_name">{{ abbrev + ': ' + full_name }}</el-option>
                        </el-select>
                    </div>
                    <div v-if="v.form.procurement_currency.$invalid" class="error-text" >Currency is required</div>
                </div>
                <div class="col-md-4">
                    <br/><label>II.1.6. Information about lots</label>
                    <br/><label>This contract is divided into lots.</label><br/>
                    <div :class="{ 'has-error-field': v.form.procurement_divided_into_lots.$invalid }">
                        <el-radio-group v-model="form.procurement_divided_into_lots">
                            <el-radio :label="1" :value="1">Yes</el-radio>
                            <el-radio :label="0" :value="0">No</el-radio>
                        </el-radio-group>
                    </div> 
                    <div v-if="v.form.procurement_divided_into_lots.$invalid" class="error-text" >Lot division is required</div>
                </div>
                <div v-if="form.procurement_divided_into_lots == 1" class="row">
                    <div class="col-md-4">
                        <br/><label>Quantity of lots</label>
                        <div :class="{ 'has-error-field': v.form.procurement_quantity_of_lots.$invalid }">
                            <el-input v-model="form.procurement_quantity_of_lots" type="number"></el-input>
                        </div>
                        <div v-if="v.form.procurement_quantity_of_lots.$invalid" class="error-text" >Quantity of lots is required</div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Maximum number of lots that may be awarded to one tenderer (optional)</label>
                        <el-input v-model="form.procurement_max_awarded_lots"></el-input>
                    </div>
                    <div class="col-md-8">
                        <br/><label>The contracting authority reserves the right to award contracts combining the following lots or groups of lots (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.procurement_right_to_award_contracts"></el-input>
                    </div>
                </div>
                <div v-if="form.procurement_divided_into_lots == 0" class="row">
                    <div class="col-md-6">
                        <br/><label>II.2. Description</label>
                        <br/><label>II.2.2. Additional CPV codes (optional)</label>
                        <el-select v-model="form.procurement_cpv_code_addl" filterable clearable>
                            <el-option v-for="(description, code) in cpv_code_choices[preliminary.contract_type]" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12" v-if="form.procurement_cpv_code_addl">
                    <br/><label>Supplementary Code</label>
                        <el-select v-model="form.procurement_supplementary_code_addl" filterable clearable multiple>
                            <span v-for="(parts, header) in supplementary_code_choices">
                                <el-option-group :label="header">
                                    <el-option-group v-for="(sub_parts, sub_header) in parts" :label="sub_header">
                                        <el-option v-for="(description, code) in sub_parts" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                                    </el-option-group>
                                </el-option-group>
                            </span>
                            <el-option  :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                    </div>
                    <div class="col-md-6">
                        <br/><label>II.2.3. Place of performance</label>
                        <br/><label>NUTS codes</label>
                        <div :class="{ 'has-error-field': v.form.procurement_nuts_code.$invalid }">
                            <el-select v-model="form.procurement_nuts_code" filterable clearable>
                                <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                    <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                        <span style="float: left">{{ c_values.name }}</span>
                                        <span class="secondary-selection">
                                            {{ c_abbrev }}
                                        </span>
                                    </el-option>
                                        <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                            <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                                <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                    <span style="float: left">{{ c1_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c1_abbrev }}
                                                    </span>
                                                </el-option>
                                                <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                    <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                        <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                            <span style="float: left">{{ c2_values.name }}</span>
                                                            <span class="secondary-selection">
                                                                {{ c2_abbrev }}
                                                            </span>
                                                        </el-option>
                                                            <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                                <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                    <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                        <span style="float: left">{{ c3_values.name }}</span>
                                                                        <span class="secondary-selection">
                                                                            {{ c3_abbrev }}
                                                                        </span>
                                                                    </el-option>
                                                                    <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                        <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                            <span style="float: left">{{ c4_values.name }}</span>
                                                                            <span class="secondary-selection">
                                                                                {{ c3_abbrev }}
                                                                            </span>
                                                                        </el-option>
                                                                    </el-option-group>
                                                                </span>
                                                            </el-option-group>
                                                    </span>
                                                </el-option-group>
                                            </span>
                                    </el-option-group>
                                </el-option-group >
                            </el-select>
                        </div>
                        <div v-if="v.form.procurement_nuts_code.$invalid" class="error-text" >NUTS Code is required</div>
                    </div>
                    <div class="col-md-6">
                        <br/><label>Main site or place of performance (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.procurement_main_site_of_performance"></el-input>
                    </div>
                    <div class="col-md-6">
                        <br/><label>II.2.4 Description of the procurement</label>
                        <div :class="{ 'has-error-field': v.form.procurement_description.$invalid }">
                            <el-input type="textarea" :rows="3" v-model="form.procurement_description"></el-input>
                        </div>
                        <div v-if="v.form.procurement_description.$invalid" class="error-text" >Description is required</div>
                    </div>
                    
                    <div v-if="procurement_stage == FATNoticeTypes.F02.value">
                        
                    </div>

                    <div class="col-md-12">
                        <br/><label>Additional information (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.procurement_addl_description"></el-input>
                    </div>
                </div>
                <div class="col-md-12">
                    <br/><label>II.3. Estimated date of publication of contract notice</label>
                    <br/>
                    <div :class="{ 'has-error-field': v.form.procurement_estimated_date_contract_notice.$invalid }">
                        <el-date-picker v-model="form.procurement_estimated_date_contract_notice" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
                    </div>
                    <div v-if="v.form.procurement_estimated_date_contract_notice.$invalid" class="error-text" >Estimated date of publication is required</div>
                </div>

            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
    
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf
     } from '@vuelidate/validators'
     import * as moment from 'moment'

 
     const SECTION_TWO = 2;
     
     export default {
         props: ['user', 'procurement', 'saved_form', 'preliminary', 'cpv_code_choices', 'currency_choices', 'supplementary_code_choices', 'nuts_code_choices', 'procurement_stage', 'is_not_submitted'],
         data() {
             return {
                form: {
                    procurement_title: null,
                    procurement_reference_number: null,
                    procurement_cpv_code: null,
                    procurement_short_description: null,
                    procurement_estimated_total_value: null,
                    procurement_currency: null,
                    procurement_divided_into_lots: null,
                    procurement_supplementary_code: [],
                    procurement_supplementary_code_addl: [],
                    procurement_estimated_date_contract_notice: null,
                    procurement_quantity_of_lots: null,
                    procurement_max_awarded_lots: null,
                    procurement_right_to_award_contracts: null,
                    procurement_cpv_code_addl: null,
                    procurement_nuts_code: null,
                    procurement_description: null,
                    procurement_addl_description: null,
                    procurement_main_site_of_performance: null,
                    options: [],
                    loading: false
                }
            }
         },
         setup: () => ({
             v: useVuelidate()
          }),
          validations() {
         let rules =  {
             form: {
                procurement_title: {
                required,
                $autoDirty: true
                },
                procurement_cpv_code: {
                required,
                $autoDirty: true
                },
                procurement_short_description: {
                required,
                $autoDirty: true
                },
                procurement_divided_into_lots: {
                required,
                $autoDirty: true
                },
                procurement_currency: {
                required,
                $autoDirty: true
                },
                procurement_quantity_of_lots: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 1}),
                    $autoDirty: true
                },
                procurement_estimated_date_contract_notice: {
                    required,
                    $autoDirty: true
                },
                procurement_nuts_code: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0}),
                    $autoDirty: true
                },
                procurement_description: {
                    required: requiredIf(function () {return this.form.procurement_divided_into_lots == 0}),
                    $autoDirty: true
                },
            }
         }

        return rules
        },
        mounted()  {
            if(this.saved_form) this.form = this.saved_form
            else {
                this.form.procurement_title = this.procurement.name
                this.form.procurement_short_description = this.procurement.description
            }
        },
        computed: {
        ...mapState(['FATNoticeTypes', 'FATFormParts']),
        },
        methods: {
            saveForm() {
                if(this.form.procurement_divided_into_lots != 1) this.form.procurement_quantity_of_lots = null

                let formattedDate = moment(
                    this.form.procurement_estimated_date_contract_notice
                ).format('YYYY-MM-DD')

                this.form.procurement_estimated_date_contract_notice = formattedDate
                this.$emit('save', SECTION_TWO, this.form)
            },
            contractTypeTranslation(type) {
                switch (type) {
                    case 0: return 'Works'
                    case 1: return 'Supplies'
                    case 2: return 'Services'
                }
            },
         }
        }
 </script>
 