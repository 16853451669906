<template>
  <div class="form-group" :class="formClassName">
    <label v-if="label">{{ showLabel(field) }}</label>
    <input
      v-if="type != 'upload' && type != 'textarea'"
      v-model="model"
      :type="type"
      :placeholder="field"
      class="form-control"
      :class="inputClassName"
    />

    <textarea
      v-if="type == 'textarea'"
      rows="3"
      class="form-control"
      v-model="model"
      :placeholder="field"
    >
    </textarea>

    <file-upload
      :label="uploadText"
      :drag="drag"
      :max-size="this.maxSize"
      :multiple="multiple"
      :fileTypes="fileTypes"
      @change="setFiles($event)"
      v-if="type == 'upload'"
    ></file-upload>
    <small class="form-text text-danger input-help" v-if="error && required">{{
      error
    }}</small>
  </div>
</template>

<script>
import FileUpload from '../FileUpload/FileUpload.vue'
export default {
  props: {
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    field: {
      type: String,
      required: false,
      default: null
    },
    value: {
      required: true
    },
    type: {
      default: 'text',
      required: false
    },
    drag: {
      type: Boolean,
      default: true
    },
    confirm: {
      type: Boolean,
      default: false
    },
    password: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    },
    nullable: {
      type: Boolean,
      default: false
    },
    maxSize: {
      default: 20000000
    },
    uploadText: {
      type: String,
      default: null
    },
    fileTypes: {
      type: Array,
      default: () => [
        'jpg',
        'png',
        'jpeg',
        'pdf',
        'xlsx',
        'xls',
        'doc',
        'docx',
        'xlsm'
      ]
    }
  },
  components: {
    FileUpload
  },
  watch: {
    model: function() {
      this.check()
    },
    require: function() {
      this.check()
    }
  },
  data() {
    return {
      error: null,
      model: null
    }
  },
  created: function() {
    let obj = Object.assign({}, { value: this.value })
    this.model = obj.value
  },
  computed: {
    formClassName() {
      if (!this.required) {
        return ''
      }
      return this.isValid() ? 'has-success' : 'has-danger'
    },
    inputClassName() {
      if (!this.required) {
        return ''
      }
      return this.isValid() ? 'is-valid' : 'is-invalid'
    }
  },
  methods: {
    showLabel(s) {
      if (typeof s !== 'string') return s
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    check() {
      if (typeof this.model === 'undefined') this.model = null
      this.isValid()
      this.$emit('input', this.model)
    },
    setFiles(files) {
      this.model = files
    },
    isValid() {
      let valid = false

      if (this.type == 'text' || this.type == 'textarea') {
        if (this.model && this.model.length > 0) valid = true
      }

      if (this.type == 'email') {
        valid = this.$store.state.isEmailValid(this.model)
      }

      if (this.type == 'password') {
        const lengthPattern = /.{16,}/
        const capitalLetterPattern = /[A-Z]/
        const numberPattern = /[0-9]/
        const specialCharacterPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/

        valid =
          lengthPattern.test(this.model) &&
          capitalLetterPattern.test(this.model) &&
          numberPattern.test(this.model) &&
          specialCharacterPattern.test(this.model)
        // valid = this.model && this.model.length > 5
        // if (this.confirm) valid = this.model == this.password
      }

      if (this.type == 'upload') {
        valid = this.model ? true : false
      }

      // if (!this.required) valid = true

      this.error = null
      if (!valid && this.required) {
        this.error = this.field + ' is required'
        if (this.type == 'email')
          this.error = this.field + ' is not a valid email'
        if (this.type == 'password') {
          this.error = this.confirm
            ? 'password mismatch'
            : this.field +
              ' must be at least 16 characters long, contain at least one uppercase letter, one number, and one special character.'
        }
      }

      if (!this.required) valid = true

      let data = {
        required: this.required,
        valid: valid,
        type: this.type,
        field: this.field,
        value: valid ? this.model : null
      }

      this.$emit('update', data.value)
      return valid
    }
  }
}
</script>
