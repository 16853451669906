import api from './api'

const API = '/api/card_imports'

export const CardImport = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  reset: function(params) {
    return api.update(API+'/'+params.id+'/reset', params)
  },
  hold: function(params) {
    return api.update(API+'/'+params.id+'/hold', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id)
  }
}
