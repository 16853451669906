<template>
    <div class="mt-3 flex items-center text-sm" :class="if_disable ? 'hide' : ''">
        <el-radio-group :disabled="disable_input" @change="change" v-model="selected" class="ml-4">
            <el-radio border :value="1" >Yes</el-radio>
            <el-radio border :value="0" >No</el-radio>
        </el-radio-group>
        <div v-if="data.answer_label">
            <el-text>{{ data.answer_label }}</el-text>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    props: ['data'],
    data() {
      return {
        selected: null,
        if_disable: false,
        disable_input: false
      }
    },
    watch: {
        'data.disabled': {
            deep: true,
            handler() {
                this.if_disable = this.data.disabled
            }
        },
    },
    created: function() {
        this.if_disable = this.data.disabled

        if(this.data.if_admin_view){
            this.disable_input = true
        }

        if(this.data.answer){
            this.selected = parseInt(this.data.answer.answer)

            if(this.data.trigger_question_code){
                if(this.data.answer.answer == "1"){
                    this.$emit('change', {
                        question_code: this.data.code,
                        code: this.data.trigger_question_code,
                        value: this.data.answer.answer,
                        in_create: 1
                    })
                }
            }
        }
    },
    methods: {
        change(value){
            this.$emit('change', {
                question_code: this.data.code,
                code: this.data.trigger_question_code,
                value: value,
                in_create: 0
            })
        }
    }
  }
  </script>
  