<template>
  <div>
    <div style="margin: 50px;text-align:center">
      <b-icon
        icon="exclamation-triangle-fill"
        scale="2"
        variant="danger"
        style="width: 80px; height: 80px;"
      ></b-icon>
    </div>
    <div
      style="font-size: 30px;
    color: #e85353;text-align: center;"
    >
      Page Not Found
    </div>
    <div style="text-align:center">
      <a href="/login">Login</a> | <a href="/register">Register</a> | <a href="/supplier-hub/signup">Supplier Hub Register</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFoundComponent',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
