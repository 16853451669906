<template>
  <div style="padding: 10px">
    <table class="table align-items-center">
      <thead>
        <tr>
          <td></td>
          <td
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Unit
          </td>
          <td
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Direction
          </td>
          <td
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Value
          </td>
          <td
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Mandatory
          </td>
          <td
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            <div>Importance</div>
            <div>(Out of 10)</div>
          </td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(spec, i) in specs" :key="spec.id">
          <td class="text-xs">{{ spec.specification.description }}</td>
          <td class="text-xs">
            <span v-if="spec.specific_unit">({{ spec.specific_unit }})</span>
          </td>
          <td class="text-xs">
            <div class="form-group">
              <select
                class="form-select form-select-sm"
                v-model="spec.condition"
                v-if="spec.response != 'yes' && spec.response != 'no'"
              >
                <option value="<">Max</option>
                <option value="=">Equal</option>
                <option value=">">Min</option>
              </select>
            </div>
          </td>
          <td>
            <div class="form-group">
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="spec.response"
                placeholder=""
                maxlength="2"
                size="2"
                v-if="spec.response != 'yes' && spec.response != 'no'"
              />

              <select
                class="form-select form-select-sm"
                v-model="spec.response"
                v-if="spec.response == 'yes' || spec.response == 'no'"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

            </div>
          </td>
          <td>
            <div class="form-group">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input-sm"
                  v-model="spec.required"
                  :value="true"
                  :unchecked-value="false"
                  @input="updateSpec(i)"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="form-group">
              <select class="form-select form-select-sm" v-model="spec.weighing"
                ><option v-for="imp in importance" :key="imp">{{
                  imp
                }}</option></select
              >
            </div>
          </td>

          <td>
            <a @click="onDelete(i)">
              <i class="fa-solid fa-delete-left text-warning"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['form_specs'],
  data() {
    return {
      specs: [],
      importance: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    }
  },
  created: function() {
    this.specs = this.form_specs ? this.form_specs.slice() : []
  },
  watch: {
    specs: {
      handler: function (after, before) {
        this.$emit('update', this.specs)
      },
      deep: true
    }
  },
  methods: {
    updateSpec(i) {

    },
    onDelete(i) {
      this.specs.splice(i, 1)
      
    }
  }
}
</script>

<style scoped></style>
