<template>
  <el-form label-position="top" @submit.prevent="submit">
    <div class="row">
      <div class="col-md-3">
        <el-form-item label="Preferred Start Date *" prop="call_off_start_date"
          :error="v.form.call_off_start_date && v.form.call_off_start_date.$errors.length ? 'Start date is required' : ''">
          <el-date-picker v-model="form.call_off_start_date" type="date" :clearable="false"
            :editable="false"></el-date-picker>
        </el-form-item>
      </div>
      <div class="col-md-3">
        <el-form-item label="Contract Period" prop="name">
          <el-input-number v-model="form.contract_period" style="width: 100%;"></el-input-number>
        </el-form-item>
      </div>
      <div class="col-md-12"></div>
      <div class="col-md-3">
        <el-form-item label="Extension Period 1 (Optional)" prop="name">
          <el-input-number v-model="form.extension_period" style="width: 100%;"></el-input-number>
        </el-form-item>
      </div>
      <div class="col-md-3">
        <el-form-item label="Extension Period 2 (Optional)" prop="name">
          <el-input-number v-model="form.extension_period_2" style="width: 100%;"></el-input-number>
        </el-form-item>
      </div>
      <div class="col-md-3">
        <el-form-item label="Extension Period 3 (Optional)" prop="name">
          <el-input-number v-model="form.extension_period_3" style="width: 100%;"></el-input-number>
        </el-form-item>
      </div>
      <div class="col-md-3">
        <el-form-item label="Extension Period 4 (Optional)" prop="name">
          <el-input-number v-model="form.extension_period_4" style="width: 100%;"></el-input-number>
        </el-form-item>
      </div>

      <div class="col-md-6" v-if="form.call_off_status == 2">
        <div>Supplier has already signed</div>
      </div>
      <!--<div class="col-md-6" v-if="form.call_off_status != 1 && form.call_off_status != 2 && form.call_off_status < 6">
        <el-form-item label="" prop="name">
          <el-checkbox v-model="form.want_supplier_to_sign_first" label="I want supplier to sign first" size="large"
            :checked="form.want_supplier_to_sign_first" />
        </el-form-item>
      </div>-->

      <div class="row col-md-12" v-if="showBuyerForm()">
        <div class="col-md-5">
          <el-form-item label="Signed Date *" prop="buyer_signed_date"
            :error="v.form.buyer_signed_date && v.form.buyer_signed_date.$errors.length ? 'Signed date is required' : ''">
            <el-date-picker v-model="form.buyer_signed_date" type="date" :clearable="false"
              :editable="false"></el-date-picker>
          </el-form-item>

          <br>

          <a class="h6 fw-bold text-decoration-underline" href="javascript:" @click="instruction_modal = true">
            Please read instructions/guidelines&nbsp;<i style="color:blue" class="fa fa-info-circle"></i></a>
        </div>

        <div class="col-md-12">
        
          <hr>
          <el-tabs type="border-card">
            <el-tab-pane label="Signature" v-if="showSignature()">
              <div class="row" v-if="!file">
                <div class="col-md-3">
                  <el-form-item label="Title" prop="name">
                    <el-input v-model="form.buyer_title" style="width: 100%;"></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-9">
                  <el-form-item label="Name" prop="buyer_name"
                    :error="v.form.buyer_name.$errors.length ? 'Name is required' : ''">
                    <el-input v-model="form.buyer_name" style="width: 100%;"></el-input>
                  </el-form-item>
                </div>

                <div class="col-md-12">
                  <el-form-item label="Signature" prop="buyer_signature"
                    :error="v.form.buyer_signature.$errors.length ? 'Signature is required' : ''">
                    <el-input v-model="form.buyer_signature" style="width: 100%;font-size: 50px;height: 85px;"
                      class="buyer-signature karumbi-font-lg"></el-input>
                  </el-form-item>
                </div>

                <div class="col-md-6">
                  <el-form-item label="" prop="accept_signature"
                    :class="v.form.accept_signature.$errors.length ? 'checkbox-error' : ''">
                    <el-checkbox v-model="form.accept_signature"
                      label="Please click on this to acknowledge it as a signature for the purpose of this call-off."
                      size="large" :checked="form.accept_signature" />
                  </el-form-item>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Upload Signed PDF" :disabled="!showUpload()">
              <div v-if="showUpload()">
                <el-alert type="error" v-if="form.call_off_approval && form.call_off_approval.status == 2">
                  Your document has not been accepted due to the following reasons:<br>
                  <div style="font-weight: bold;">{{
                    form.call_off_approval.remarks }}</div>
                  Please upload the document again with the necessary corrections.
                </el-alert>

                <call-off-signature-upload @upload="uploadFile($event)"></call-off-signature-upload>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <br>

    <el-button type="primary" plain @click="submit">Submit</el-button>
  </el-form>

  <el-dialog
    v-model="instruction_modal"
    title="Instructions for Completing the Signature Process"
    width="60%"
  >
    
    <div class="card-body">
      <p><b>Signature Form Selection:</b></p>
      <ul>
        <li class="lh-lg">If you have selected the Signature Form option, please ensure that the other party also uses the same form to sign the call-off document. This maintains consistency and validity in the signing process.</li>
      </ul>
      <br>
      <p><b>Uploading a Signed Document:</b></p>
      <ul>
        <li class="lh-lg">In case you opt for the Upload a Signed Document method, the other party is required to upload a signed copy of the document to complete the call-off.</li>
        <li class="lh-lg">Important Note: All uploaded documents are subject to review by our framework managers. The document will undergo an evaluation process before it is either approved or rejected. Please ensure that the document meets all required standards and guidelines to facilitate a smooth approval process.</li>
      </ul>
      <p>Please follow these instructions carefully to ensure a seamless and efficient signature process. If you have any questions or require further assistance, feel free to reach out.</p>
    </div>
  
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="instruction_modal = false">Close</el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script>
import {
  ProductSearchResult
} from '@/api_services/product_search_result'
import * as moment from 'moment'

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  sameAs
} from '@vuelidate/validators'

import CallOffSignatureUpload from './CallOffSignatureUpload.vue'

export default {
  components: {
    CallOffSignatureUpload
  },
  props: {
    product_search_result: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      instruction_modal:false,
      form: {
        call_off_start_date: null,
        buyer_name: null
      },
      file: null
    }
  },
  watch: {
    product_search_result: function () {
      this.form = Object.assign({}, this.product_search_result)
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: this.getValidForm()
    }
  },
  created: function () {
    if (this.product_search_result){
      this.form = Object.assign({}, this.product_search_result)
      this.form.want_supplier_to_sign_first = false
    } 
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        this.validate()
      },
      deep: true
    }
  },
  methods: {
    showSignature() {
      if (!this.form.call_off_approval) return true
      if (this.file) return false
      return false
    },
    showUpload() {
      if (this.form.call_off_status == 0) return true
      if (this.form.call_off_approval && this.form.call_off_approval.final_signature == 'buyer' && this.form.call_off_approval.status == 2) return true
      return (this.form.call_off_approval && this.form.call_off_status == 2)
    },
    async validate(submit) {
      const result = await this.v.$validate()
      if (!result) return

      if (submit) {
        this.file ? this.$emit('upload', this.getFormData()) : this.$emit('submit', this.form)
      }
    },
    getValidForm() {
      if (this.form.call_off_status == 3) return {}
      if ((this.form.want_supplier_to_sign_first && this.form.call_off_status < 2) || this.file) {
        return {
          call_off_start_date: {
            required,
            $autoDirty: true
          },
          buyer_signed_date: {
            required,
            $autoDirty: true
          },
        }
      }
      return {
        call_off_start_date: {
          required,
          $autoDirty: true
        },
        buyer_signed_date: {
          required,
          $autoDirty: true
        },
        buyer_name: {
          required,
          $autoDirty: true
        },
        buyer_signature: {
          required,
          $autoDirty: true
        },
        accept_signature: {
          required,
          $autoDirty: true,
          checked: sameAs(true)
        }
      }
    },
    showBuyerForm() {
      if (this.form.call_off_approval && this.form.call_off_approval.final_signature == 'buyer' && this.form.call_off_approval.status == 2) return true
      if (this.form.want_supplier_to_sign_first && this.form.call_off_status < 2) return false
      if (this.form.call_off_status == 3 || this.form.call_off_status == 4 || this.form.call_off_status == 1) return false
      return this.form.call_off_status == 0 || this.form.call_off_status == 2 || !this.form.want_supplier_to_sign_first
    },
    submit() {
      this.validate(true)
    },
    uploadFile(file) {
      this.file = file
    },
    getFormData() {
      var formData = new FormData()
      console.log(this.file)
      formData.append('file', this.file.file)
      formData.append('file_name', this.file?.name)
      formData.append('id', this.form.id)

      let formattedStartDate = moment(
        this.form.call_off_start_date
      ).format('YYYY-MM-DD')

      let formattedSignedDate = moment(
        this.form.buyer_signed_date
      ).format('YYYY-MM-DD')


      formData.append('buyer_signed_date', formattedSignedDate)
      formData.append('call_off_start_date', formattedStartDate)
      formData.append('contract_period', this.form.contract_period || 0)
      formData.append('extension_period', this.form.extension_period || 0)
      formData.append('extension_period_2', this.form.extension_period_2 || 0)
      formData.append('extension_period_3', this.form.extension_period_3 || 0)
      formData.append('extension_period_4', this.form.extension_period_4 || 0)
      formData.append('want_supplier_to_sign_first', this.form.want_supplier_to_sign_first ? 1 : 0)

      return formData
    }
  }
}
</script>

<style scoped>

</style>
