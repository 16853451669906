<template>
  <div>
    <h2 class="text-white">Card Type Terms</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-8">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="table-responsive">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term" placeholder="Search">
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="search()">Search</button>
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="create()">Add</button>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Term</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!card_type_terms.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="card_type in card_type_terms" :key="card_type.id">
                    <td>{{ card_type.term }}</td>
                    <td>{{ card_type.description }}</td>
                    <td>
                      <a class="link me-3" @click="showEdit(card_type)" v-tooltip="'Edit'" v-allowed="'card_types,update'">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(card_type)" v-tooltip="'Delete'" v-allowed="'card_types,delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination v-model:meta="meta" v-on:update="getCardTypeTerms()"/>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="original">
        <CardTypeTermForm
            v-on:close_form="closeForm"
            :key="selected.id"
            :card_type_terms_data="selected"
          ></CardTypeTermForm>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove {{ to_remove.card_type }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import { CardTypeTerm } from '@/api_services/card_type_term'
import CardTypeTermForm from './CardTypeTermForm.vue'

export default {
  components:{
    CardTypeTermForm
  },
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      card_type_terms:[],
      fields: ['id', 'term','description','action'],
      selected: {
        term: null,
        description: null
      },
      original: null,
      validate: ['term', 'description'],
      to_remove: null,
      term: null,
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  created: function() {
    this.getCardTypeTerms()
  },
  methods: {
    closeForm(value){
      if(value == "save") this.getCardTypeTerms()
      
      this.original = null
      this.selected = null
    },
    search() {
      this.meta.page = 1
      this.getCardTypeTerms()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      CardTypeTerm.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getCardTypeTerms()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing categry')
          console.error('error:', error.response.data)
        })
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
    },
    getCardTypeTerms() {
      this.isLoading = true
      CardTypeTerm.get({params: {term: this.term, page: this.meta.page} })
        .then(result => {
          if(result.data.card_type_terms)
            this.card_type_terms = result.data.card_type_terms
            this.meta = result.data.meta
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching card type terms')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {term: null, description: null}
      this.selected = Object.assign({}, this.original);
    }
  },
  computed: {
    
  }
}
</script>
