<template>
    <div class="card">
        <div class="card-header">
            <el-alert effect="dark" type="info" show-icon> {{message}}
                <p>This section will allow us to submit a procurement request to "Find A Tender" that coincides with the stage the procurement is currently at.</p>
                <p>Note: If you are unable to complete all of the forms, make sure to complete the section you are filling out and click on Save (and go to next section) so you can revisit at any time.</p>
            </el-alert>
        
            <el-alert effect="dark" type="warning" show-icon> 
                <h6>Form Status: 
                    <span class="badge badge-warning">{{ checkAllFormStatus }}</span>
                </h6>
            </el-alert>
        </div>
        <div class="card-body">
            <a @click="step != 0 ? step = 0 : step = null">
                <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                    <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Preliminary Questions</span>
                        <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.preliminary) }}</span>
                </el-card>
            </a>
            <div class="row" v-if="checkIncludesForm(FATFormParts.PRELIMINARY) && step == 0">
                <preliminary-form :procurement_stage="procurementStage" :saved_form="forms.preliminary" :user="savedUser" :is_not_submitted="(procurement.status == ProcurementProjectStatuses.CODE_PRE_PIN)" @save="saveForm"></preliminary-form>
            </div>

            <div v-if="forms.preliminary">
                <a @click="step != 1 ? step = 1 : step = null">
                    <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                        <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Section One</span>
                        <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.section_one) }}</span>
                    </el-card>
                </a>
                <div class="row" v-if="checkIncludesForm(FATFormParts.SECTION_ONE) && step == 1">
                    <section-one-form :key="step" :is_not_submitted="(procurement.status == ProcurementProjectStatuses.CODE_PRE_PIN)" :procurement_stage="procurementStage" :saved_form="forms.section_one" :user="savedUser" :procurement="procurement" :nuts_code_choices="static_data.nuts_code" :country_choices="static_data.countries" @save="saveForm"></section-one-form>
                </div>
                
                <a @click="step != 2 ? step = 2 : step = null">
                    <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                        <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Section Two</span>
                        <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.section_two) }}</span>
                    </el-card>
                </a>
                <div class="row" v-if="checkIncludesForm(FATFormParts.SECTION_TWO)  && step == 2">
                    <section-two-form :is_not_submitted="(procurement.status == ProcurementProjectStatuses.CODE_PRE_PIN)" :procurement_stage="procurementStage" :preliminary="forms.preliminary" :nuts_code_choices="static_data.nuts_code" :saved_form="forms.section_two":user="savedUser" :procurement="procurement" @save="saveForm" :currency_choices="static_data.currencies" :cpv_code_choices="static_data.cpv_code" :supplementary_code_choices="static_data.supplementary_code"></section-two-form>
                </div>

                <div v-if="forms.section_two" style="margin-left: 2em;">
                    <div v-for="(lot, index) in forms.section_two.procurement_quantity_of_lots" :key="lot.id">
                        <a @click="step_division != index ? step_division = index : step_division = null">
                            <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                                <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Section Two: Lot {{ index + 1 }}</span>
                                <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.section_two_lots ? (forms.section_two_lots[index] !== undefined ? forms.section_two_lots[index] : 0) : 0) }}</span>
                            </el-card>
                        </a>
                        <div v-if="static_data && index == step_division">
                            <division-of-lots :lot="lot" :saved_form="forms.section_two_lots ? (forms.section_two_lots[index] !== undefined ? forms.section_two_lots[index] : null) : null" :is_not_submitted="(procurement.status == ProcurementProjectStatuses.CODE_PRE_PIN)" :preliminary="forms.preliminary" :nuts_code_choices="static_data.nuts_code" :cpv_code_choices="static_data.cpv_code" :supplementary_code_choices="static_data.supplementary_code" :section_two_id="forms.section_two.id" @save="saveForm"></division-of-lots>
                        </div>
                    </div>
                </div>
                
                <!-- <a @click="step != 3 ? step = 3 : step = null">
                    <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                        <h6 class="m-0 text-white">Section Three</h6>
                    </el-card>
                </a> -->
                <!-- <div class="row" v-if="step == 3">
                    <section-three-form :key="step" :saved_form="forms.section_three" :user="savedUser" :procurement="procurement" @save="saveForm"></section-three-form>
                </div> -->
                
                <a @click="step != 3 ? step = 3 : step = null">
                    <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                        <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Section Four</span>
                        <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.section_four) }}</span>
                    </el-card>
                </a>
                <div class="row" v-if="checkIncludesForm(FATFormParts.SECTION_FOUR) && step == 3">
                    <section-four-form :is_not_submitted="(procurement.status == ProcurementProjectStatuses.CODE_PRE_PIN)" :procurement_stage="procurementStage" :key="step" :saved_form="forms.section_four" :user="savedUser" :procurement="procurement" @save="saveForm"></section-four-form>
                </div>

                <!-- <a @click="step != 5 ? step = 5 : step = null">
                    <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                        <h6 class="m-0 text-white">Section Five</h6>
                    </el-card>
                </a>
                <div class="row" v-if="step == 5">
                    <section-five-form :user="savedUser" :procurement="procurement" @save="saveForm"></section-five-form>
                </div> -->

                <a @click="step != 4 ? step = 4 : step = null">
                    <el-card shadow="never" style="background: #337ecc" class="mb-2 p-0 border-0"  >
                        <span class="m-0 text-white" style="font-size: 20px; font-weight: 700;">Section Six</span>
                        <span class="m-0 text-white" style="float: right; font-size: 15px; font-weight: 700;">{{ checkEachFormStatus(forms.section_six) }}</span>
                    </el-card>
                </a>
                <div class="row" v-if="checkIncludesForm(FATFormParts.SECTION_SIX) && step == 4">
                    <section-six-form :is_not_submitted="(procurement.status == ProcurementProjectStatuses.CODE_PRE_PIN)" :procurement_stage="procurementStage" :key="step" :saved_form="forms.section_six" :user="savedUser" :procurement="procurement" @save="saveForm"></section-six-form>
                </div>

                <div class="row" v-if="procurement.status == ProcurementProjectStatuses.CODE_PRE_PIN">
                    <span class="col-md-3 mb-3"><el-button type="success" :disabled="!validated" @click="publishPin()">Publish PIN</el-button></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FindATender } from '@/api_services/find_a_tender'
import Swal from 'sweetalert2';

import PreliminaryForm from './preliminary_questions/PriorInformationNoticePrelimComponent'
import SectionOneForm from './section_one/PriorInformationNoticeOneComponent'
import SectionTwoForm from './section_two/PriorInformationNoticeTwoComponent'
import DivisionOfLots from './section_two/DivisionOfLots';
import SectionThreeForm from './section_three/PriorInformationNoticeThreeComponent'
import SectionFourForm from './section_four/PriorInformationNoticeFourComponent'
import SectionFiveForm from './section_five/PriorInformationNoticeFiveComponent'
import SectionSixForm from './section_six/PriorInformationNoticeSixComponent'

export default {
    data(){
        return {
            index: 0,
            step: null,
            step_division: null,
            form_id: null,
            static_data: null,
            forms: {
                preliminary: null,
                section_one: null,
                section_two: null,
                section_two_lots: [],
                section_three: null,
                section_four: null,
                section_five: null,
                section_six: null,
            },
            all_form: null
        } 
    },
    props: ['procurement', 'procurement_type'],
    components: {
        PreliminaryForm,
        SectionOneForm,
        SectionTwoForm,
        DivisionOfLots,
        SectionThreeForm,
        SectionFourForm,
        SectionFiveForm,
        SectionSixForm
    },
    computed: {
        ...mapState(['savedUser', 'ProcurementProjectStatuses', 'FATNoticeTypes', 'FATFormParts']),
        validated() {
            let validated = false;
            
            switch(this.procurementStage) {
                case this.FATNoticeTypes.F01.value:
                    if(this.forms.preliminary && this.forms.preliminary.id
                        && this.forms.section_one && this.forms.section_one.id
                        && this.forms.section_two && this.forms.section_two.id
                        && this.forms.section_four && this.forms.section_four.id
                    ) {
                        if(this.forms.section_two.procurement_quantity_of_lots) {
                            if(this.forms.section_two_lots && this.forms.section_two.procurement_quantity_of_lots == this.forms.section_two_lots.length) {
                                validated = true;
                            }     
                        }
                        else validated = true;
                    }
                    break;
                case this.FATNoticeTypes.F02.value:
                    if(this.forms.preliminary && this.forms.preliminary.id
                        && this.forms.section_one && this.forms.section_one.id
                        && this.forms.section_two && this.forms.section_two.id
                        && this.forms.section_four && this.forms.section_four.id
                        && this.forms.section_six && this.forms.section_six.id
                    ) validated = true;
                    break;
                case this.FATNoticeTypes.F03.value:
                    if(this.forms.preliminary && this.forms.preliminary.id
                        && this.forms.section_one && this.forms.section_one.id
                        && this.forms.section_two && this.forms.section_two.id
                        && this.forms.section_four && this.forms.section_four.id
                        && this.forms.section_five && this.forms.section_five.id
                        && this.forms.section_six && this.forms.section_six.id
                    ) validated = true;
                    break;
            }

            console.log("validated", validated);

            return validated
        },
        procurementStage() {
            switch(this.procurement.status) {
                case this.ProcurementProjectStatuses.CODE_PENDING:
                case this.ProcurementProjectStatuses.CODE_PRE_PIN:
                case this.ProcurementProjectStatuses.CODE_SUBMITTED:
                case this.ProcurementProjectStatuses.CODE_ACTIVE:
                case this.ProcurementProjectStatuses.CODE_PIN_ARCHIVED:
                case this.ProcurementProjectStatuses.CODE_DOC_FINALIZE:
                    return this.FATNoticeTypes.F01.value
                case this.ProcurementProjectStatuses.CODE_ITT_PENDING:
                case this.ProcurementProjectStatuses.CODE_ITT_SUBMITTED:
                case this.ProcurementProjectStatuses.CODE_ITT:
                case this.ProcurementProjectStatuses.CODE_MARKING_STAGE:
                    return this.FATNoticeTypes.F02.value
                case this.ProcurementProjectStatuses.CODE_IAR:
                    return this.FATNoticeTypes.F03.value;
                default: "Status does not exist"
            }
        },
        checkAllFormStatus() {
            if(this.all_form) {
                switch (this.all_form.overall_form_status) {
                    case 0: return "Not Started"
                    case 1: return "In Progress"
                    case 2: return "Submitted"
                    case 3: return "Published"
                    case 9: return "Error"
                }
            }
            
            else return 'Not Started'
        }
    },
    created() {
        this.getAllForms()
        this.getStaticData()
    },
    methods: {
        saveForm(form_type, form_values) {
                let procurement_type = this.procurement_type ? this.procurement_type : 0
                form_values.form_type = form_type;
                form_values.form_status = 1; // 1 is completed
                form_values.procurement_type = procurement_type
                form_values.procurement_project_id = this.procurement.id 
                form_values.procurement_forms_id = this.form_id
                
                FindATender.create(procurement_type, form_values).then(result => {
                    this.$toast.success('Saved successfully')
                    if(form_type == 2.1) this.step_division++
                    else this.step++
                    this.getAllForms();
                })
        },
        getAllForms() {
            FindATender.getAllForms(this.procurement.id, {}).then(result => {
                console.log('result data', result.data)

                if(result.data.procurement_project_all_form != null) {
                    this.forms = result.data
                    this.all_form = result.data.procurement_project_all_form;
                    console.log('forms', this.forms)
                }

                else this.step = 0;
            })
        },
        publishPin() {
            if (this.all_form.id && this.validated) {
                Swal.fire({
                title: 'Submit PIN Publish Request?',
                text: 'Do you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.isConfirmed) {
                    this.globalState.loading = true

                    this.$emit('close-dialog')
                    FindATender.submitToTender(this.all_form.id).then(result => {
                        this.globalState.loading = true

                        this.form.status = this.ProcurementProjectStatuses.CODE_SUBMITTED

                        ProcurementProjects.update(this.selected.id, this.form)
                        .then(result => {
                            this.$toast.success('Pin request submitted')
                            this.$emit('close-dialog');
                        })
                        .catch(error => {
                            console.log(error)
                        })
                        .finally(() => {
                            this.globalState.loading = false
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.globalState.loading = false
                    })
                }
                });
            }
        },
        getStaticData() {
            FindATender.getStaticData().then(result => {                
                this.static_data = result.data
            })
        },
        checkIncludesForm(form) {          
            switch(this.procurementStage) {
                case this.FATNoticeTypes.F01.value:
                    return this.FATNoticeTypes.F01.parts.includes(form)
            }

            return false
        },
        checkEachFormStatus(form_type) {
            if(form_type && "id" in form_type) {
                switch(form_type.form_status) {
                    case 0: return "DRAFT"
                    case 1: return "SAVED"
                }
            }
            
            return "DRAFT"
        }
    }
}
</script>