<template>

  <div>
 
  <div class="multisteps-form" v-if="!supplier.has_completed_information && !$store.state.isUserABuyer()">
    <!--progress bar-->
    <div class="row mt-4">
      <div class="mx-auto my-5 col col-lg-8">
        <div class="multisteps-form__progress">
          <button class="multisteps-form__progress-btn" :class="step === 1 ? activeClass : ''"  @click="changeStep(1)">
            <span class="text-white">Company Details</span>
          </button>
          <button v-if="!$store.state.isUserABuyer()" class="multisteps-form__progress-btn" :class="step === 2 ? activeClass : ''"  @click="changeStep(2)" :disabled="!supplier.supplier_detail">
            <span class="text-white">Company Bidding Model</span>
          </button>
          <button v-if="!$store.state.isUserABuyer()" class="multisteps-form__progress-btn" :class="step === 3 ? activeClass : ''"  @click="changeStep(3)" :disabled="!supplier.supplier_bidding_model">
            <span class="text-white">Company Contact Details</span>
          </button>
        </div>
      </div>
    </div>
  </div>

    <div class="row" v-if="supplier.has_completed_information && !$store.state.isUserABuyer()">
      <el-card class="mt-4 mb-2 border-0 col-md-4 offset-md-4 text-center" v-if="supplier.supplier_detail && !editing">
        <b-link @click="changeStep(1)">Company Details</b-link>
      </el-card>

      <el-card class="mb-2 border-0 col-md-4 offset-md-4 text-center" v-if="supplier.supplier_bidding_model && !editing && !$store.state.isUserABuyer()">
        <b-link @click="changeStep(2)">Company Bidding Model</b-link>
      </el-card>

      <el-card class="border-0 col-md-4 offset-md-4 text-center" v-if="supplier.supplier_contact_detail && !editing && !$store.state.isUserABuyer()">
        <b-link @click="changeStep(3)">Company Contact Details</b-link>
      </el-card>
    </div>
    
    <div class="row">
      <div class="col-md-12">
        <supplier-detail-component  @save="update($event)" v-if="step == 1" :selected="supplier.supplier_detail" :editing="editing"></supplier-detail-component>
        <supplier-bidding-model-component  @save="update($event)" v-if="step == 2 && !$store.state.isUserABuyer()" :selected="supplier.supplier_bidding_model" :editing="editing"></supplier-bidding-model-component>
        <supplier-contact-details-component  @save="update($event)" v-if="step == 3 && !$store.state.isUserABuyer()" :selected="supplier.supplier_contact_detail" :editing="editing"></supplier-contact-details-component>
      </div>
      
    </div>

    <div class="col-md-4 offset-md-4">
      <b-button type="button"  @click="close()" v-if="step == 0 && supplier.has_completed_information" style="margin-top: 10px">Close</b-button>
    </div>

  </div>
  
</template>

<script>
import SupplierBiddingModelComponent from './SupplierBiddingModelComponent.vue'
import SupplierContactDetailsComponent from './SupplierContactDetailsComponent.vue'
import SupplierDetailComponent from './SupplierDetailComponent.vue'

export default {
  components: { SupplierDetailComponent, SupplierBiddingModelComponent, SupplierContactDetailsComponent },
  props: {
    goStep1: {
      type: Boolean,
      default: false
    },
    supplier: {
      type: Object
    }
  },
  data() {
    return {
      step: 0,
      activeClass: "js-active position-relative",
      editing: false
    }
  },
  watch: {
    supplier() {
      if (this.step > 0) this.init()
    }
  },
  created: function() {
    this.init()
  },
  methods: {
    changeStep(step) {
      this.step = step
      this.editing = true
    },
    init() {
      if (this.supplier) {
        if ((!this.supplier.supplier_detail || Object.keys(this.supplier.supplier_detail).length == 0) || this.$store.state.isUserABuyer()) {
          this.step = 1
        }else if ((!this.supplier.supplier_bidding_model || Object.keys(this.supplier.supplier_bidding_model).length == 0) && !this.$store.state.isUserABuyer()) {
          this.step = 2
        } else if ((!this.supplier.supplier_contact_detail || Object.keys(this.supplier.supplier_contact_detail).length == 0) && !this.$store.state.isUserABuyer()) {
          this.step = 3
        }
        
        this.copyFields()
        if(this.goStep1) this.changeStep(1)
      }
    },
    copyFields() {
      if (this.supplier.supplier_detail) {
          this.supplier.supplier_detail.email = this.supplier.email
          this.supplier.supplier_detail.phone = this.supplier.phone
          this.supplier.supplier_detail.e_catalog = this.supplier.e_catalog
        }
    },
    close() {
      this.$emit('update', {supplier: this.supplier})
    },
    update(supplier) { 
      if(!supplier) {
        this.close()
      } 
      else{
        if (this.editing) {
          this.editing = false
          this.step = 0
          this.$emit('update', {supplier: supplier, all_info: true})
        } else {
          this.$emit('update', ({supplier: supplier}) )    
        }

        this.copyFields()
      }
    }
  }
}
</script>
