<template>
    <div>
      <h2 class="text-white">Supplier Questionaire Result Submissions</h2>
      <div style="text-align: left;" class="row">
        <div class="col-md-12">
          <div class="card my-3">
            
            <div class="card-body px-0 pb-2">
              <div class="table-response">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <div class="bg-white border-radius-lg d-flex me-2">
                    <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term" placeholder="Search">
                    <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="search()">Search</button>
                  </div>
                </nav>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Supplier</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Verifier</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Submitted</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!submissions.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else v-for="submission in submissions" :key="submission.id">
                      <td class="text-sm"><span class="text-wrap">{{ submission.supplier?.name }}</span></td>
                      <td class="text-sm"><span class="text-wrap">{{ 
                        (submission.verification_status == QaSubmissionStatuses.STATUS_PENDING ? "Pending" : "") +
                        (submission.verification_status == QaSubmissionStatuses.STATUS_VERIFYING ? "Verifying" : "") +
                        (submission.verification_status == QaSubmissionStatuses.STATUS_APPROVED ? "Verified" : "") +
                        (submission.verification_status == QaSubmissionStatuses.STATUS_REJECTED ? "Rejected" : "")

                       }}</span></td>
                      <td class="text-sm">{{ !submission?.verifier?.name ? "No verifier yet" : submission?.verifier?.name }}</td>
                      <td class="text-sm"><span class="text-wrap">{{ $filters.datetimeFormatFilter(submission.created_at) }}</span></td>
                      <td class="text-sm" style="width:1%">
                        <a class="link me-3" @click="manage(submission)" v-tooltip="'Manage'">
                          <i class="fas fa-eye text-secondary"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="getSubmissions()"/>
            </div>
          </div>
  
        </div>
        
      </div>
  
      <el-dialog
          id="modal-manage"
          v-model="dialogVisible"
          width="70%"
        >
          <SQuestionForm
            :load_type="1"
            :supplier_id="selected_supplier_id"
            :selected_submission="selected"
            @save="afterSave"
          ></SQuestionForm>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="dialogVisible = false">Close</el-button>
            </div>
          </template>
        </el-dialog>
  
  
    </div>
  </template>
  
  <script>
  
  import { EvaluationResultSubmission } from '@/api_services/evaluation_result_submissions'
  import { mapState } from 'vuex'
  import SQuestionForm from '../sq_questions/SQuestionForm.vue' 
  
  export default {
    components:{ SQuestionForm },
    data() {
      return {
        dialogVisible: false,
        isLoading: true,
        submissions:[],
        selected: null,
        original: null,
        validate: ['description','unit'],
        to_remove: null,
        term: null,
        meta: {
          page: 1, total: 0, pages: 1
        },
        selected_supplier_id: null
      }
    },
    created: function() {
      this.getSubmissions()
    },
    methods: {
      afterSave(){
        this.dialogVisible = false
        this.getSubmissions()
      },
      search() {
        this.meta.page = 1
        this.getSubmissions()
      },
      selectedName(selected) {
        return selected.id ? 'Edit '+selected.original_description: 'New'
      },
      manage(submission) {
        this.selected = Object.assign({}, submission);
        this.selected_supplier_id = submission.supplier_id
        this.dialogVisible = true
      },
      getSubmissions() {
        this.isLoading = true
        EvaluationResultSubmission.get({params: {page: this.meta.page, search_value: this.term}})
          .then(result => {
            if(result.data.qa_submissions)
              this.submissions = result.data.qa_submissions
            console.log(this.submissions)
              //this.meta = result.data.meta
          })
          .catch( error => {
            this.$toast.error('An error occured while fetching submissions')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      
    },
    computed: {
      ...mapState(['QaSubmissionStatuses']),
      validateAll() {
        var self = this
        var response = true
        this.validate.forEach(function(field){
          if (!self.validation(field)) response = false;
        })
  
        return response;
      }
    }
  }
  </script>
  