import api from './api'

const API = '/api/suppliers'

export const Supplier = {
  showByCategory: function(params) {
    return api.get(API + '/category?category='+params.category)
  },
  getApi: function() {
    return API
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  createDuplicate: function(id) {
    return api.update(API + '/' + id + ' /duplicate', {})
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
