<template>
    <div class="card my-3">
            <div class="card-header">
              <h5>{{ selectedName(selected) }}</h5>
            </div>
            <div class="card-body">
              <form @submit.prevent="onSubmit" @reset="onCancel">
                <div class="form-group">
                  <label
                    id="name"
                    label-for="input-name"
                    >Name</label
                  >
                  <input
                    type="text"
                    id="input-name"
                    v-model="selected.name"
                    :class="{ 'is-invalid': v.selected.name.$errors.length, 'form-control': true }"
                  />
                  <div v-if="v.selected.name.$errors.length" class="error-text">
                            Name is required</div>
                </div>
                <div class="form-group">
                  <label
                    id="code"
                    label-for="input-code"
                    >Code</label
                  >
                  <input
                    type="text"
                    id="input-code"
                    :class="{ 'is-invalid': v.selected.code.$errors.length, 'form-control': true }"
                    v-model="selected.code"
                  />
                  <div v-if="v.selected.code.$errors.length" class="error-text">
                            Code is required</div>
                </div>
                <button
                  type="submit"
                  class="btn bg-gradient-success"
                >
                  Save
                </button>
                <button type="reset" class="btn bg-gradient-secondary">
                  Cancel
                </button>
              </form>
            </div>
          </div>
</template>
    
<script>

import { Role } from '@/api_services/role_service'

import { useVuelidate } from '@vuelidate/core'
import {  required } from '@vuelidate/validators'

export default {
    setup: () => ({
        v: useVuelidate()
    }),
    props: {
        roles: {
            type: Object,
            required: false
        },
        original: {
            type: String,
            required: false
        },
    },
    data() {
        return{
            selected:{
                code: null,
                name: null,
                id: null
            },
            show_permissions: false,
            edit: false,
        }
    },
    validations() {
        return {
            selected: {
                name: { 
                required,
                $autoDirty: true
                },
                code: { 
                required,
                $autoDirty: true
                },
            }
        }
    },
    watch: {
        itt_framework: function () {
            this.selected = Object.assign({}, this.roles)
        },
    },
    created: function () {
        if (this.roles) this.selected = Object.assign({}, this.roles)
    },
    methods: {
        async onSubmit(evt) {
            evt.preventDefault()

            const result = await this.v.$validate()
            if (!result) return

                evt.preventDefault()
                var action = this.selected.id
                    ? Role.update(this.selected)
                    : Role.create(this.selected)
                action
                    .then(result => {
                    this.$toast.success('Saved successfully')
                    this.$emit('close_form', "save")
                    })
                    .catch(error => {
                    this.$toast.error('An error occured while saving a role')
                    console.error('error:', error.response.data)
                    })
        },
        selectedName(selected) {
            return selected.id ? 'Edit ' + selected.name : 'New'
        },
        reset() {
            this.original = null
            this.selected = null
            this.edit = false
            this.show_permissions = false
        },
        onCancel(evt) {
            evt.preventDefault()
            this.$emit('close_form', "close")
        },
    }
}
</script>
    
<style></style>
    