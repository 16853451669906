<template>
  <div>
    <el-card header="Persons of Significant Control" style="text-align: left;" class="my-3 border-0">

      <b-form>
        <div class="row">
          <div class="col-md-12">
            <b-form-group id="name" label="Name" label-for="input-name" valid-feedback=""
              :invalid-feedback="invalidFeedback('name')">
              <b-form-input id="input-name" v-model="form.name" :state="validation('name')" trim></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group id="date_of_birth" label="Date of birth" label-for="input-date_of_birth" valid-feedback=""
              :invalid-feedback="invalidFeedback('date_of_birth')">
              <!-- <b-form-datepicker id="input-date_of_birth" v-model="form.date_of_birth" class="mb-2"
                :state="validation('date_of_birth')"></b-form-datepicker> -->
              <input type="date" class="form-control" id="registration_date" v-model="form.date_of_birth" trim>

            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group id="nationality" label="Nationality" label-for="input-nationality" valid-feedback=""
              :invalid-feedback="invalidFeedback('nationality')">
              <b-form-input id="input-nationality" v-model="form.nationality" :state="validation('nationality')"
                trim></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group id="country_state" label="Country, state or part of the UK where the PSC usually lives"
              label-for="input-country_state" valid-feedback="" :invalid-feedback="invalidFeedback('country_state')">
              <b-form-input id="input-country_state" v-model="form.country_state" :state="validation('country_state')"
                trim></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group id="service_address" label="Service address" label-for="input-service_address"
              valid-feedback="" :invalid-feedback="invalidFeedback('service_address')">
              <b-form-input id="input-service_address" v-model="form.service_address"
                :state="validation('service_address')" trim></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-12">
            <b-form-group id="join_date"
              label="The date he or she became a PSC in relation to the company (for existing companies the 6 April 2016 should be used)"
              label-for="input-join_date" valid-feedback="" :invalid-feedback="invalidFeedback('join_date')">
              <!-- <b-form-datepicker id="input-join_date" v-model="form.join_date" class="mb-2"
                :state="validation('join_date')"></b-form-datepicker> -->
              <input type="date" class="form-control" id="join_date" v-model="form.join_date" trim>

            </b-form-group>
          </div>


          <div class="col-md-12">
            <b-form-group id="conditions" label="Which conditions for being a PSC are met" label-for="input-conditions"
              valid-feedback="" :invalid-feedback="invalidFeedback('conditions')">
              <!-- <b-form-textarea id="input-conditions" v-model="form.conditions" :state="validation('conditions')"
                trim></b-form-textarea> -->
              <select id="enterprise_typee" class="form-select" v-model="form.conditions">
                <option value="Over 25% up to (and including) 50%">Over 25% up to (and including) 50%</option>
                <option value="More than 50% and less than 75%">More than 50% and less than 75%</option>
                <option value="75% or more">75% or more</option>
              </select>
            </b-form-group>
          </div>

        </div>

        <div style="text-align:center">
          <b-button type="button" variant="info" :disabled="!validateAll" @click="save()">Save</b-button>
          <!-- <b-button type="close"  @click="close()">Close</b-button> -->
        </div>
      </b-form>

    </el-card>

  </div>
</template>

<script>


export default {
  props: ['selected'],
  data() {
    return {

      validate: [
        'name','nationality','country_state',
        'service_address'
      ],
      form: {
        'name':null,'date_of_birth':null,'nationality':null,'country_state':null,
        'service_address':null, 'join_date':null, 'conditions':null
      }
    }
  },
  watch: {
    form() {

    }
  },
  created: function() {
    if (this.selected) this.form = Object.assign({}, this.selected);
  },
  methods: {
    invalidFeedback(field) {
      return ""
    },
    save() {
      this.$emit('save', this.form)
    },
    close() {
      this.$emit('save', null)
    },
    validation(field) {
      switch(field) {
        case 'name':
          return this.$store.state.isStringValid(this.form.name);
        // case 'date_of_birth':
        //   return this.$store.state.isDateValid(this.form.date_of_birth);
        case 'nationality':
          return this.$store.state.isStringValid(this.form.nationality);
        case 'country_state':
          return this.$store.state.isStringValid(this.form.country_state);
        case 'service_address':
          return this.$store.state.isStringValid(this.form.service_address);
        // case 'join_date':
        //   return this.$store.state.isDateValid(this.form.join_date);
        // case 'conditions':
        //   return this.$store.state.isStringValid(this.form.conditions);
        default:
          return true;
      }
    },
  },
  computed: {
    validateAll() {
      var self = this
      var response = true

      this.validate.forEach(function(field){
        if (!self.validation(field)) {
           response = false;
        }
      })

      return response;
    }
  }
}
</script>
