<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-header p-0 mx-3 mt-3">
            <h4>Further Competition: Uploaded Statements</h4>
            <p>Congratulations on making it to the Further Competition stage of the Framework procurement process!</p>
            <p>Here you will find the statements supplied by the buyer to enable you to generate your final personalised proposals for them if they decide to proceed with the procurement process.</p>
            <p>These statements have been provided by the buyer for the purpose of the further competition process, aiming to empower each of their qualifying suppliers to offer the most competitive and compelling proposals.</p>
            <p>By reviewing these uploaded statements, you can gain further valuable insights into the buyer's requirements, preferences, and specific needs and utilise this information to determine your final offer.</p>
            <p>We encourage you to review the statements thoroughly, leveraging the provided information to refine your proposals and provide the best possible value to the buyer.</p>
          </div>
          <div class="px-0 card-body">
            <div>
              <el-row :gutter="20">
                <el-col :span="5" :offset="18">
                  <el-input
                    placeholder="Search products"
                    v-model="term"
                    class="input-with-select"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="search()"
                    ></el-button>
                  </el-input>
                </el-col>
              </el-row>

              <div >
                <table
                  class="table align-items-center mb-0 "
                  style="margin: 10px"
                >
                  <thead>
                    <tr>
                      <td
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Product Name
                      </td>
                      <td
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Category
                      </td>
                      <td
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Buyer Name
                      </td>
                      <td
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Company
                      </td>
                      <td
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Upload Date
                      </td>
                      <td
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Status
                      </td>
                      <td
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Attachment Files
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!results.length">
                      <td v-if="loading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else v-for="result in results" :key="result.id">
                      <td class="text-sm">
                        <span>{{ result.product.name }}</span>
                      </td>
                      <td class="text-sm">
                        <span>{{ result.user_help.category_name }}</span>
                      </td>
                      <td class="text-sm">
                        <span>{{ result.buyer_name }}</span>
                      </td>
                      <td class="text-sm">
                        <span>{{ result.buyer_company_name }}</span>
                      </td>
                      <td class="text-sm">
                        <span class="badge badge-info">{{
                          result.user_help.upload_date
                        }}</span>
                      </td>
                      <td class="text-sm">
                        <el-checkbox
                          label="Processed"
                          border
                          :checked="result.is_file_processed"
                          @change="updateStatus(result, $event)"
                        ></el-checkbox>
                      </td>
                      <td class="text-sm">
                        <a
                          v-if="result.user_help.files.length > 1"
                          :href="result.user_help.zip"
                          target="_blank"
                          ><i class="fa-solid fa-file-zipper"></i> Download</a
                        >
                        <a
                          v-if="result.user_help.files.length == 1"
                          :href="result.user_help.files[0]"
                          target="_blank"
                          ><i class="fa-solid fa-file-arrow-down"></i> Download</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getResults()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductSearchResult } from '@/api_services/product_search_result'

export default {
  data() {
    return {
      loading: true,
      results: [],
      selected: null,
      original: null,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  created: function() {
    this.getUser()
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.is_final) return ''
      if (!item.is_final) return 'table-info'
    },
    getUser() {
      let user = this.$store.state.savedUser
      if (!user.company) {
        if (user.is_admin) {
          this.no_company = true
          let self = this
          setTimeout(function() {
            self.$router.push('/')
          }, 3000)
        } else {
          this.getResults()
        }
      } else {
        this.getResults()
      }
    },
    search() {
      this.meta.page = 1
      this.getResults()
    },
    updateStatus(result, status) {
      this.loading = true
      ProductSearchResult.updateProcessedStatus(result.id, {
        is_file_processed: status
      })
        .then(result => {
          result.status = status
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    getResults() {
      this.loading = true
      this.results = []
      ProductSearchResult.getDetailedCostComparison({
        params: { page: this.meta.page, term: this.term }
      })
        .then(result => {
          if (result.data.results) this.results = result.data.results
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching products')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
