<template>
  <b-sidebar
      id="sidebar-border"
      aria-labelledby="sidebar-border-title"
      sidebar-class=""
      bg-variant="dark" text-variant="light"
      shadow
      backdrop
      backdrop-variant="dark"
      style="border-color: #343a40 !important;display:none"
    >
      <template v-slot>
        <div class="">
          <div style="padding: 10px;background-color: #1e548a;">
              <h5 id="sidebar-border-title" style="font-size: 15px;margin-top: 5px;margin-left: 10px;"> CPRAS Procurement Platform</h5>
          </div>
          <nav class="mb-3" style="font-size:14px">
            <b-nav vertical id="sidebar">
              <b-nav-item 
                v-if="isViewable('dashboard')"
                href="/main" 
                :class="{ 'active-nav': isActive('dashboard') }"
              ><b-icon icon="bricks"></b-icon>  Dashboard
              </b-nav-item>

                <b-nav-item
                  v-if="isViewable('cost_data_report')"
                  v-b-toggle.collapse-1
                  :class="{ 'nav-collapse-active' : isActive('card_transactions') }"
                >
                <b-icon icon="bricks"></b-icon> 
                Card Transactions <b-icon class="font-12" icon="caret-down-fill" style="float:right"></b-icon>
                
                </b-nav-item>

                <b-collapse id="collapse-1" class="nav-collapse" :visible="isActive('card_transactions')">
                  <b-nav-item
             
                    v-if="isViewable('cost_data')"
                    href="/main/card_transactions" 
                    :class="{ 'active-nav': isActive('card_transactions') }"
                  ><b-icon icon="bricks"></b-icon>  Cost Data and Reports
                  </b-nav-item>
                  <b-nav-item
                      v-if="isViewable('cost_data')"  
                      href="/main/card_types" 
                      :class="{ 'active-nav': isActive('card_types') }"
                    ><b-icon icon="card-list" ></b-icon> Card Types
                    </b-nav-item>
                    <b-nav-item
                    v-if="isViewable('cost_data')"  
                    href="/main/card_type_terms" 
                    :class="{ 'active-nav': isActive('card_type_terms') }"
                  ><b-icon icon="book-half" ></b-icon> Card Type Terminologies
                  </b-nav-item>
                  <b-nav-item
                    v-if="isViewable('cost_data')"  
                    href="/main/card_imports" 
                    :class="{ 'active-nav': isActive('card_imports') }"
                  ><b-icon icon="cloud-upload" ></b-icon> Card Imports
                  </b-nav-item>
                </b-collapse> 

                  <b-nav-item 
                    v-if="isViewable('companies')"  
                    href="/main/companies" 
                    :class="{ 'active-nav': isActive('companies') }"
                  ><b-icon icon="building" ></b-icon> Companies
                  </b-nav-item>

                  
              <b-nav-item
                v-if="isViewable('settings')"
                href="/main/merchant_terminologies" 
                :class="{ 'active-nav': isActive('merchant_terminologies') }"
              ><b-icon icon="card-checklist"></b-icon> Merchant Terminologies
              </b-nav-item>

              <b-nav-item
                v-if="isViewable('settings')"
                href="/main/global_transaction_types" 
                :class="{ 'active-nav': isActive('global_transaction_types') }"
              ><b-icon icon="card-checklist"></b-icon> Global Transaction Types
              </b-nav-item>

              <b-nav-item
                v-if="isViewable('settings')"
                href="/main/authorization_fees" 
                :class="{ 'active-nav': isActive('authorization_fees') }"
              ><b-icon icon="card-checklist"></b-icon> Authorization Fees
              </b-nav-item>

              <b-nav-item 
                v-if="isViewable('suppliers')" 
                href="/main/suppliers" 
                :class="{ 'active-nav': isActive('suppliers_list') }"
              ><b-icon icon="collection-fill"></b-icon> Suppliers
              </b-nav-item>
             
              <b-nav-item 
                href="/main/help" 
                :class="{ 'active-nav': isActive('help_component') }"
              ><b-icon icon="question-circle-fill"></b-icon> Help
              </b-nav-item>
              <b-nav-item 
                v-if="isViewable('requests')" 
                href="/main/help_requests" 
                :class="{ 'active-nav': isActive('help_requests_component') }"
              ><b-icon icon="calendar2-check-fill" ></b-icon> Requests
              </b-nav-item>

              <b-nav-item 
                v-if="isViewable('products')"  
                href="/main/products" 
                :class="{ 'active-nav': isActive('products') }"
              ><b-icon icon="basket" ></b-icon> Products
              </b-nav-item>

              <b-nav-item 
                v-if="isViewable('product_rfq')"  
                href="/main/product_rfq" 
                :class="{ 'active-nav': isActive('product_rfq') }"
              ><b-icon icon="basket" ></b-icon> Product RFQ
              </b-nav-item>

              <b-nav-item 
                v-if="isViewable('products')"  
                href="/main/categories" 
                :class="{ 'active-nav': isActive('categories') }"
              ><b-icon icon="border-width" ></b-icon> Categories
              </b-nav-item>

              <b-nav-item 
                v-if="isViewable('supplier')"  
                href="/main/supplier" 
                :class="{ 'active-nav': isActive('supplier') }"
              ><b-icon icon="border-width" ></b-icon> Supplier Account
              </b-nav-item>

              <b-nav-item 
                v-if="isViewable('company')"  
                href="/main/company" 
                :class="{ 'active-nav': isActive('company') }"
              ><b-icon icon="border-width" ></b-icon> Company Account
              </b-nav-item>

               <b-nav-item 
                v-if="isViewable('supplier')" 
                href="/main/supplier_specifications" 
                :class="{ 'active-nav': isActive('supplier') }"
              ><b-icon icon="collection-fill"></b-icon> Supplier Specs
              </b-nav-item>

               <b-nav-item 
               v-if="isViewable('settings')"  
               v-b-toggle.collapse-settings
                :class="{ 'nav-collapse-active' : isActive('users') }"
              ><b-icon icon="person-fill" ></b-icon> Settings  <b-icon class="font-12" icon="caret-down-fill" style="float:right"></b-icon>
              </b-nav-item>
  
              <b-collapse id="collapse-settings" class="nav-collapse" :visible="isActive('users')">
                  <b-nav-item

                    v-if="isViewable('users')"  
                    href="/main/users" 
                    :class="{ 'active-nav': isActive('users') }"
                  ><b-icon icon="person-fill" ></b-icon> Users
                  </b-nav-item>
                  <b-nav-item 
                    v-if="isViewable('roles')"  
                    href="/main/roles" 
                    :class="{ 'active-nav': isActive('roles') }"
                  ><b-icon icon="person-badge"></b-icon> Roles
                  </b-nav-item>

                  <b-nav-item 
                    v-if="isViewable('permissions')"  
                    href="/main/permissions" 
                    :class="{ 'active-nav': isActive('permissions') }"
                  ><b-icon icon="person-badge"></b-icon> Permissions
                  </b-nav-item>

                  <b-nav-item

                    v-if="isViewable('settings')"  
                    href="/main/specifications" 
                    :class="{ 'active-nav': isActive('specifications') }"
                  ><b-icon icon="person-fill" ></b-icon> Global Specifications
                  </b-nav-item>

              </b-collapse>
              

            </b-nav>
          </nav>
        </div>
      </template>
        <template #footer>
          <div v-if="user" class="d-flex bg-dark align-items-center px-3 py-2">
            <router-link to="/logout">
              <b-button variant="danger" size="sm">  <b-icon icon="box-arrow-right"></b-icon> Logout</b-button>
            </router-link>
          </div>
      </template>
      
    </b-sidebar>
</template>

<script>

export default {
  data() {
    return {
      route_name: null
    }
  },
  watch: {
    $route(to, from) {
        this.route_name = to.name
    }
  },
  created: function() {
      this.route_name = this.$route.name
  },
  computed: {
    user () {
      return this.$store.state.savedUser // get state
    }
  }, 
  methods: {
    isActive(name) {
        return this.route_name === name
    },
    isViewable(name) {
      var response = false
      if (this.user) {    
        if (this.user.permissions) {
          this.user.permissions.forEach(function(permission) {
          if (permission.action === 'VIEW' && permission.module.toLowerCase() === name) {
            response = true
          }
        })
        }    
        
      }
      
      return response
    }
  }
}
</script>


<style scoped>
    #sidebar .nav-item:hover {
        background-color: #3b444a;
    }

    #sidebar .nav-item a {
        color: #c7c7c7;
    }

    #sidebar .nav-item{
        padding: 5px;
    }

    .active-nav {
        background: #242e38;
    }

    .active-nav a {
        color: #2a89ff !important;
    }

    .padding-left-20 {
      padding-left: 20px !important
    }

    .nav-collapse li{
      padding-left: 20px !important
    }

    .font-12 {
      font-size: 12px !important;
    }

    .nav-collapse-active {
      background: #1e548a;
    }
</style>