<template>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h3>Section II.2.</h3>
                <div class="col-md-6">
                    <br/><label>II.2.1. Lot title (optional)</label>
                    <el-input :label="'Title'" v-model="form.lot_title"></el-input>
                </div>
                <div class="col-md-6">
                    <br/><label>Lot number</label>
                    <div :class="{ 'has-error-field': v.form.lot_number.$invalid }">
                        <el-input :label="'Title'" v-model="form.lot_number"></el-input>
                    </div>
                </div>
                <div class="col-md-6">
                    <br/><label>II.2.2. Additional CPV Codes</label>
                    <div :class="{ 'has-error-field': v.form.lot_cpv_code.$invalid }">
                        <el-select v-model="form.lot_cpv_code" filterable clearable>
                            <div v-for="categories in cpv_code_choices">
                                <el-option v-for="(description, code) in categories" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                            </div>
                        </el-select>
                    </div>
                    <div v-if="v.form.lot_cpv_code.$invalid" class="error-text" >CPV code is required</div>
                </div>
                <div class="col-md-6" v-if="form.lot_cpv_code">
                    <br/><label>Supplementary Code (optional)</label>
                        <el-select v-model="form.lot_supplementary_code" filterable clearable multiple>
                            <span v-for="(parts, header) in supplementary_code_choices">
                                <el-option-group :label="header">
                                    <el-option-group v-for="(sub_parts, sub_header) in parts" :label="sub_header">
                                        <el-option v-for="(description, code) in sub_parts" :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                                    </el-option-group>
                                </el-option-group>
                            </span>
                            <el-option  :label="code + ': ' + description" :value="code">{{ code + ': ' + description }}</el-option>
                        </el-select>
                </div>
                <div class="col-md-12">
                        <br/><label>II.2.3. Place of performance</label>
                        <br/><label>NUTS codes</label>
                        <div :class="{ 'has-error-field': v.form.lot_nuts_code.$invalid }">
                            <el-select v-model="form.lot_nuts_code" filterable clearable>
                                <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                                    <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                        <span style="float: left">{{ c_values.name }}</span>
                                        <span class="secondary-selection">
                                            {{ c_abbrev }}
                                        </span>
                                    </el-option>
                                        <el-option-group v-if="'parts' in c_values" :key="c_values.name" :label="'Parts of ' + c_values.name" style="margin-left: 15px">
                                            <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                                <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                                    <span style="float: left">{{ c1_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c1_abbrev }}
                                                    </span>
                                                </el-option>
                                                <el-option-group v-if="'parts' in c1_values" :key="c1_values.name" :label="'Parts of ' + c1_values.name" style="margin-left: 15px">
                                                    <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                        <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                            <span style="float: left">{{ c2_values.name }}</span>
                                                            <span class="secondary-selection">
                                                                {{ c2_abbrev }}
                                                            </span>
                                                        </el-option>
                                                            <el-option-group v-if="'parts' in c2_values" :key="c2_values.name" :label="'Parts of ' + c2_values.name" style="margin-left: 15px">
                                                                <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                                    <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                        <span style="float: left">{{ c3_values.name }}</span>
                                                                        <span class="secondary-selection">
                                                                            {{ c3_abbrev }}
                                                                        </span>
                                                                    </el-option>
                                                                    <el-option-group v-if="'parts' in c3_values" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                        <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                            <span style="float: left">{{ c4_values.name }}</span>
                                                                            <span class="secondary-selection">
                                                                                {{ c3_abbrev }}
                                                                            </span>
                                                                        </el-option>
                                                                    </el-option-group>
                                                                </span>
                                                            </el-option-group>
                                                    </span>
                                                </el-option-group>
                                            </span>
                                    </el-option-group>
                                </el-option-group >
                            </el-select>
                        </div>
                        <div v-if="v.form.lot_nuts_code.$invalid" class="error-text" >NUTS Code is required</div>
                    </div>
                <div class="col-md-6">
                        <br/><label>Main site or place of performance (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.lot_main_site_of_performance"></el-input>
                    </div>
                    <div class="col-md-6">
                        <br/><label>II.2.4 Description of the procurement</label>
                        <div :class="{ 'has-error-field': v.form.lot_description.$invalid }">
                            <el-input type="textarea" :rows="3" v-model="form.lot_description"></el-input>
                        </div>
                        <div v-if="v.form.lot_description.$invalid" class="error-text" >Description is required</div>
                    </div>
                    <div class="col-md-12">
                        <br/><label>II.2.14. Additional information (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.lot_addl_description"></el-input>
                    </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button class="mb-3" type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
    
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf
     } from '@vuelidate/validators'
     import * as moment from 'moment'

 
     const SECTION_TWO_LOTS = 2.1;
     
     export default {
         props: ['user', 'procurement', 'saved_form', 'preliminary', 'cpv_code_choices', 'supplementary_code_choices', 'nuts_code_choices', 'procurement_stage', 'is_not_submitted', 'section_two_id'],
         data() {
             return {
                form: {
                    form: {
                        fat_section_two_questions_id: null,
                        lot_title: null,
                        lot_number: null,
                        lot_cpv_code: null,
                        lot_supplementary_code: [],
                        lot_nuts_code: null,
                        lot_main_site_of_performance: null,
                        lot_description: null,
                        lot_addl_description: null
                    }
                }
            }
         },
         setup: () => ({
             v: useVuelidate()
          }),
          validations() {
         let rules =  {
             form: {
                lot_number: {
                required,
                $autoDirty: true
                },
                lot_cpv_code: {
                required,
                $autoDirty: true
                },
                lot_nuts_code: {
                    required,
                    $autoDirty: true
                },
                lot_description: {
                    required,
                    $autoDirty: true
                },
            }
         }

        return rules
        },
        mounted()  {
            if(this.saved_form) this.form = this.saved_form
            else { this.form.fat_section_two_questions_id = this.section_two_id
            }
        },
        computed: {
        ...mapState(['FATNoticeTypes', 'FATFormParts']),
        },
        methods: {
            saveForm() {
                if(this.form.lot_divided_into_lots != 1) this.form.lot_quantity_of_lots = null

                let formattedDate = moment(
                    this.form.lot_estimated_date_contract_notice
                ).format('YYYY-MM-DD')

                this.form.lot_estimated_date_contract_notice = formattedDate

                this.form.fat_section_two_questions_id = this.section_two_id
                console.log('section two id: ', this.section_two_id)
                this.$emit('save', SECTION_TWO_LOTS, this.form)
            },
            contractTypeTranslation(type) {
                switch (type) {
                    case 0: return 'Works'
                    case 1: return 'Supplies'
                    case 2: return 'Services'
                }
            },
         }
        }
 </script>
 