<template>
    <div class="mb-2 flex items-center text-sm" :class="if_disable ? 'hide' : ''">
        <el-input autosize type="textarea" @input="change" :disabled="if_disable" class="mt-2" v-model="input" placeholder="Answer here..." />
        <div v-if="data.answer_label">
            <el-text>{{ data.answer_label }}</el-text>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    props: ['data'],
    data() {
      return {
        input: null,
        if_disable: false
      }
    },
    watch: {
        'data.disabled': {
            deep: true,
            handler() {
                this.if_disable = this.data.disabled
            }
        },
    },
    created: function() {
        this.if_disable = this.data.disabled

        if(this.data.answer){
            this.input = this.data.answer.answer
        }
    },
    methods: {
        change(value){
            this.$emit('change', {
                question_code: this.data.code,
                value: value
            })
        }
    }
  }
  </script>
  