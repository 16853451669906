<template>
  <div>
    <div v-if="$store.state.isViewable('search_products')">
      <router-view></router-view>
    </div>
    <not-allowed v-else></not-allowed>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created: function() {
    if (this.$route.name == 'SearchSolution') {
      this.$router.push({ name: 'SearchSolutionsList' })
    }
  },
  methods: {}
}
</script>
