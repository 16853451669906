<template>
  <div style="">

  
    <file-upload
      :id="name"
      class="btn btn-sm btn-outline-dark mb-0 dropzonefileupload"
      :extensions="extensions"
      :drop="drop"
      :multiple="multiple"
      :size="size"
      :maximum="max_files"
      @input="updateFile"
      :ref="name"
    >
      <i class="fas fa-file-circle-plus me-2"></i>
      {{ label }}
    </file-upload>
    <div>
      <ul id="file_list">
        <li
          v-for="(file, i) in files"
          :key="i"
          style="font-size: 12px;padding: 5px"
        >
          {{ file.name }}
          <a
            class="link ms-1 me-3"
            @click.prevent="remove(i)"
            v-tooltip="'Remove'"
          >
            <i class="fas fa-x text-danger"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import $ from 'jquery'

export default {
  emits: ['input'],
  props: {
    files: null,
    name: {
      type: String,
      default: 'upload'
    },
    existing_files: {
      type: Array,
      required: false,
      default: () => []
    },
    extensions: {
      type: String,
      required: false,
      default: 'pdf, jpg, jpeg, png'
    },
    clean: {
      type: Number,
      required: false,
      default: 0
    },
    url: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Drop or Select'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    drop: {
      type: Boolean,
      required: false,
      default: true
    },
    max_files: {
      type: Number,
      required: false,
      default: 5
    },
    size: {
      type: Number,
      required: false,
      default: 8000000 // 8 mb
    }
  },
  components: {
    FileUpload
  },
  watch: {
    clean: {
      handler(newValue) {
        this.files = []
      },
      deep: true
    },
    existing_files: function () {
      this.files = JSON.parse(JSON.stringify(this.existing_files))
    }
  },
  data() {
    return {
      files: JSON.parse(JSON.stringify(this.existing_files)),
      current_files: [],
    }
  },
  methods: {
    updateFile(items) {
      this.listFiles(items.target.files)  
      this.$emit('input', this.files)
    },
    remove(index) {
      this.files.splice(index, 1)
      this.$emit('input', this.files)
      //this.$refs.name.input();

      this.listFiles(this.files)
    },
    listFiles(items) {
    
      let self = this
      this.files = []
      for(var x = 0; x <= items.length - 1; x++){
        self.files.push(items[x])
      }

      //console.log(this.files)
      
    }
  }
}
</script>
