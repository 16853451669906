<template>
  <div>
    <form>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Description</label>
            <div>{{ form.product_specification.original_description }}</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Response <span v-if="form.product_specification.supplier_lot_spec_value?.spec?.type != 'choice'">({{
              form.product_specification.supplier_lot_spec_value.spec.unit }})</span></label><br />
            <el-switch v-model="form.response" size="small" active-text="Yes" inactive-text="No" active-value="yes"
              inactive-value="no" v-if="form.product_specification.supplier_lot_spec_value?.spec?.type == 'choice'" />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>Notes</label><br />
            <el-input type="textarea" v-model="form.notes" placeholder="Provide some notes"></el-input>
          </div>
        </div>
      </div>

      <hr />
      <div>
        <el-button @click="onSubmit()" class="mr-2" type="success">Save</el-button>
        <el-button @click="onCancel()" class="mr-2">Close</el-button>
      </div>
    </form>
  </div>
</template>

<script>

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'
import { ProcProdSpecification } from '@/api_services/proc_prod_specification'

export default {
  props: ['object'],
  data() {
    return {
      form: {},
      loading: false
    }
  },
  watch: {
    object: {
      deep: true,
      handler() {
        this.form = Object.assign({}, this.object)
      }
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
      }
    }
  },
  created: function () {
    this.form = Object.assign({}, this.object)
  },
  methods: {
    onSubmit() {
      this.loading = true
      ProcProdSpecification.update(this.form)
        .then(result => {
          this.$toast.success('Saved successfully')
          this.$emit('close', this.form)
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Error saving')
        })
        .finally(() => {
          this.loading = false
        })
    },
    onCancel() {
      this.$emit('close', null)
    },
  },
}
</script>
