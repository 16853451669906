<template>
  <div>
    <h2 class="text-white">Manage Authorization Fees</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-6">
        <div class="card my-3">
          <div class="card-body">
            <div class="table-responsive">
              <button @click="create()" class="btn bg-gradient-secondary btn-sm float-end">Add</button>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Authorisation fee amount</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!authorization_fees.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="auth_fee in authorization_fees" :key="auth_fee.id">
                    <td>{{ auth_fee.amount.toFixed(2) }}</td>
                    <td>
                      <a class="me-3" @click="showEdit(auth_fee)" v-tooltip="'Edit'">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(auth_fee)" v-tooltip="'Delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <el-card header="Authorization Fees" class="my-3">

          <b-nav-form style="float:right">
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search" v-model="term"></b-form-input>
            <b-button size="sm" @click="create()" class="mr-2" style="margin-left: 5px">Add</b-button>
          </b-nav-form>

            <b-table :items="authorization_fees" :fields="fields" striped responsive="sm">
                <template #cell(action)="row">
                  <b-button size="sm" @click="showEdit(row.item)" class="mr-2">Edit</b-button>
                  <b-button size="sm" @click="showRemove(row.item)" class="mr-2" ref="showRemove">Delete</b-button>
                </template>

              </b-table>

        </el-card> -->
      </div>
      <div class="col-md-6" v-if="original">
        <div class="card my-3">
          <div class="card-body">
            <h5>{{ selectedName(original) }}</h5>
            <form @submit="onSubmit" @reset="onCancel">
              <div class="form-group">
                <label for="">authorisation fee amount</label>
                <input type="number" class="form-control form-control-sm" step="0.0000" v-model="selected.amount">
              </div>
              <button type="submit" class="btn bg-gradient-success btn-sm">Save</button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">Cancel</button>
            </form>
          </div>
          
        </div>
          <!-- <el-card :header="selectedName(original)" class="my-3">
            <b-form  @submit="onSubmit" @reset="onCancel">
              <b-form-group id="amount" label="Amount" label-for="input-amount"
              >
                <b-form-input id="input-amount" v-model="selected.amount" type="number" trim step="0.0001"></b-form-input>
              </b-form-group>

              <b-button type="submit" >Submit</b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-form>
          </el-card> -->
      </div>
    </div>

    <el-dialog
        id="modal-remove"
        v-model="dialogVisible"
        title="Confirm Delete?"
        width="500"
        :before-close="handleClose"
      >
        <div v-if="to_remove">
          You are about to remove {{ to_remove.id }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>

  </div>
</template>

<script>

import { AuthorizationFee } from '@/api_services/authorization_fee'

export default {
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      authorization_fees:[],
      fields: ['id', 'amount','action'],
      selected: null,
      original: null,
      to_remove: null,
      term: null,
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  created: function() {
    this.getAuthFees()
  },
  methods: {
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      AuthorizationFee.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getAuthFees()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing auth fee')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit '+selected.id: 'New'
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
    },
    getAuthFees() {
      this.isLoading = true
      AuthorizationFee.get({params: {}})
        .then(result => {
          if(result.data.authorization_fees)
            this.authorization_fees = result.data.authorization_fees
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching auth fees')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onSubmit(evt) {
        evt.preventDefault()
        var action = this.selected.id ? AuthorizationFee.update(this.selected.id, this.selected) : AuthorizationFee.create(this.selected)
        action.then(result => {
          this.$toast.success('Saved successfully')
          this.getAuthFees()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving auth fee')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {name: null, email: null}
      this.selected = Object.assign({}, this.original);
    }
  },
}
</script>
