<template>
    <div>
      <a-transfer
        v-model:target-keys="transfer"
        :data-source="data"
        :disabled="disabled"
        :show-search="showSearch"
        :filter-option="filterOption"
        :show-select-all="false"
        @change="onChange"
        :operations="[right_middle_button_text, left_middle_button_text]"
        :titles="[left_title, right_title]"
      >
        <template
          #children="{
            direction,
            filteredItems,
            selectedKeys,
            disabled: listDisabled,
            onItemSelectAll,
            onItemSelect,
          }"
        >
          <a-table
            :row-selection="
              getRowSelection({
                disabled: listDisabled,
                selectedKeys,
                onItemSelectAll,
                onItemSelect,
              })
            "
            :row-class-name="(_record, index) => (_record.disabled ? 'disabled_row' : null)"
            :columns="direction === 'left' ? leftColumns : rightColumns"
            :data-source="filteredItems"
            size="small"
            :style="{ pointerEvents: listDisabled ? 'none' : null }"
            :custom-row="
              ({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !selectedKeys.includes(key));
                },
              })
            "
          />
        </template>

        <template #footer="{ direction }">
            <div class="row mt-1 ms-1" v-if="direction === 'left'">
                <div class="col-md-5 p-0 m-0 mb-1">
                    <el-select class="float-start" v-model="left_sector_filter" placeholder="Filter By Sector" >
                        <el-option value="" label="All" />
                        <el-option value="Public" label="Public" />
                        <el-option value="Private" label="Private" />
                    </el-select>
                </div>
                <div class="col-md-5 p-0 m-0 mb-1">
                    <el-select class="float-end" v-model="left_type_filter" placeholder="Filter By Type" >
                        <el-option value="" label="All" />
                        <el-option value="Supplier" label="Supplier" />
                        <el-option value="Buyer" label="Buyer" />
                    </el-select>
                </div>
            </div>
            <div class="row mt-1 ms-1" v-if="direction === 'right'">
                <div class="col-md-5 p-0 m-0 mb-1">
                    <el-select class="float-start" v-model="right_sector_filter" placeholder="Filter By Sector" >
                        <el-option value="" label="All" />
                        <el-option value="Public" label="Public" />
                        <el-option value="Private" label="Private" />
                    </el-select>
                </div>
                <div class="col-md-5 p-0 m-0 mb-1">
                    <el-select class="float-end" v-model="right_type_filter" placeholder="Filter By Type" >
                        <el-option value="" label="All" />
                        <el-option value="Supplier" label="Supplier" />
                        <el-option value="Buyer" label="Buyer" />
                    </el-select>
                </div>
                <div v-if="right_button_text" :class="'col-md-2'">
                    <el-button @click="handleClick" :disabled="selected_data.length == 0" class="transfer-footer" type="success">{{ right_button_text }}</el-button>
                </div>
            </div>
            </template>
      </a-transfer>
    </div>
  </template>

<script>

import { filterOption } from 'ant-design-vue/es/vc-mentions/src/util';

    export default {
        props: {
            transfer_data: {
                required: true,
            },
            filter_placeholder: {
                required: true,
            },
            left_title: {
                required: true,
            },
            right_title: {
                required: true,
            },
            right_button_text: {
                required: false,
            },
            model_data: {
                required: false,
                default: []
            },
            left_middle_button_text: {
                required: false,
                default: "Remove"
            },
            right_middle_button_text: { 
                required: false,
                default: "Select"
            },
            option_format: {
                required: false,
                default: "normal_list"
            },
            show_export_type: {
                required: false,
                default: false
            },
        },
        watch: {
            left_sector_filter: {
                handler(newValue) {
                    this.customFilter()
                },
                deep: true
            },
            left_type_filter: {
                handler(newValue) {
                    this.customFilter()
                },
                deep: true
            },
            right_sector_filter: {
                handler(newValue) {
                    this.customFilter()
                },
                deep: true
            },
            right_type_filter: {
                handler(newValue) {
                    this.customFilter()
                },
                deep: true
            },
            transfer_data: {
                handler(newValue) {
                    let self = this
                    this.data = []
                    this.transfer_data.forEach(function(row){
                        self.data.push({
                            key: row.id,
                            label: row.name,
                            disabled: row?.disabled,
                            company_name: row?.company_name,
                            email: row?.email,
                            sector: row?.sector,
                            type: row?.type
                        })
                    })
                },
                deep: true
            },
            model_data: {
                handler(newValue) {
                    this.transfer = this.model_data
                },
                deep: true
            },
        },
        data() {
            const generateData = _ => {
                const data = [];
                this.transfer_data.forEach(function(row){
                    data.push({
                        key: row.id,
                        label: row.name,
                        disabled: row?.disabled,
                        company_name: row?.company_name,
                        email: row?.email,
                        sector: row?.sector,
                        type: row?.type
                    });
                });

                return data;
            }
            const leftTableColumns = [
                {
                    dataIndex: 'label',
                    title: 'Name',
                },
                {
                    dataIndex: 'company_name',
                    title: 'Company',
                },
                {
                    dataIndex: 'sector',
                    title: 'Sector',
                },
                {
                    dataIndex: 'type',
                    title: 'Type',
                },
            ];
            const rightTableColumns = [
                {
                    dataIndex: 'label',
                    title: 'Name',
                },
                {
                    dataIndex: 'company_name',
                    title: 'Company',
                },
                {
                    dataIndex: 'sector',
                    title: 'Sector',
                },
                {
                    dataIndex: 'type',
                    title: 'Type',
                },
            ];
            return {
                left_sector_filter: "",
                right_sector_filter: "",
                left_type_filter: "",
                right_type_filter: "",
                export_type: "excel",
                selected_data: [],
                data: generateData(),
                transfer: this.model_data,

                disabled: false,
                showSearch: true,
                leftColumns: leftTableColumns,
                rightColumns: rightTableColumns,
            };
        },
        methods: {
            customFilter(){
                this.data = this.generateData()
                let selected = this.transfer
                let left_sector_filter = this.left_sector_filter
                let right_sector_filter = this.right_sector_filter
                let left_type_filter = this.left_type_filter
                let right_type_filter = this.right_type_filter

                this.data = this.data.filter(function( obj ) {
                    return (((left_sector_filter != "" ? obj["sector"] == left_sector_filter : true) && (left_type_filter != "" ? obj["type"] == left_type_filter : true)) || selected.includes(obj.key)) && (((right_sector_filter != "" ? obj["sector"] == right_sector_filter : true) && (right_type_filter != "" ? obj["type"] == right_type_filter : true)) || !selected.includes(obj.key))
                }) 
            },
            generateData(){
                const data = [];
                this.transfer_data.forEach(function(row){
                    data.push({
                        key: row.id,
                        label: row.name,
                        disabled: row?.disabled,
                        company_name: row?.company_name,
                        email: row?.email,
                        sector: row?.sector,
                        type: row?.type
                    });
                });

                return data;
            },
            handleClick(){
                this.$emit("handle_click", this.export_type)
            },
            filterOption(inputValue, item){
                return item.label.indexOf(inputValue) !== -1 || 
                    item.company_name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
                    item.sector.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
                    item.type.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            },
            onChange(value){
                this.selected_data = value
                this.$emit("update_value", value)
            },
            getRowSelection({
                disabled,
                selectedKeys,
                onItemSelectAll,
                onItemSelect,
            }){
                return {
                    getCheckboxProps: (item) => ({
                        disabled: disabled || item.disabled,
                    }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                        .filter(item => !item.disabled)
                        .map(({ key }) => key);
                        onItemSelectAll(treeSelectedKeys, selected);
                    },
                    onSelect( {key}, selected ) {
                        onItemSelect(key, selected);
                    },
                    selectedRowKeys: selectedKeys,
                };
            },
        }
    }
</script>
<style>
.el-transfer-panel{
    width: 35% !important;
    height: 100% !important;
}

.el-transfer-panel__item, .el-transfer-panel__header{
    margin-left: 0px !important;
}
.ant-transfer-list-footer{
    padding-right:3px !important;
    padding-bottom:4px !important
}
.disabled_row{
    background-color: rgba(0, 0, 0, 0.04) !important
}
</style>