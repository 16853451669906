import api from './api'

const API = '/api/product_searches'

export const UserProductSearch = {
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  show: function(id) {
    return api.get(`${API}/${id}`)
  },
  update: function(id, params) {
    return api.update(`${API}/${id}`, params)
  },
  delete: function(id) {
    return api.delete(`${API}/${id}`)
  },
}
