<template>
    <Form @submit="submit">
      <div class="card">
        <div class="card-header pb-0 border-bottom">
          <h5 class="card-title">
            Import {{ !kind ? 'Official Specifications':'Official Social Values' }}
          </h5>
          <p>Please upload an .xlsx file. You can use the below template for the importing.<br>

            <el-button-group>
              <el-link class="me-3" type="success" link 
              :href="!kind ? '/template/import_specifications_template_official.xlsx' : '/template/import_social_values_template_official.xlsx'">Sample Excel
                Template</el-link>
            </el-button-group>
            </p>
        </div>
        <div class="card-body">
          <div class="row">
              <div class="col-md-12">
                <dropzone label="Drop or select file" :files="form.files"
                :max_files="1" :clean="clean" :multiple="false" extensions="'xlsx,xls'"
                 @input="setFile($event)"></dropzone>
               
              </div>
         
            </div>

          <div style="text-align: right;">
            <el-button type="primary" native-type="submit" size="small" :disabled="!form.files">Submit</el-button>
          </div>
        </div>
      </div>
    </Form>

    <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="false" v-model="error_modal" width="50%">
      <ul class="p-4 list-group">
        <template v-for="(message,i) in error_message" :key="i">
          <li v-if="message != '' " class="list-group-item"><i class="text-danger fa fa-info-circle"></i>&nbsp;{{ message
            }}</li>
        </template>
      </ul>
    </el-dialog>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { SupplierhubSpec } from '@/api_services/supplierhub_spec'
import ArgonInput from '../../../common/Input/ArgonInput.vue'
import Dropzone from '@/components/common/Dropzone'

export default {
  components: {
    ArgonInput, Form, Field, ErrorMessage, Dropzone
  },
  props:
     {
      
    spec: {
      type: Object,
      required: false
    },
    template: {
      type: String,
      required: false
    },
    lot: {
      type: Object,
      required: false
    },
    kind: {
      type: Number,
      required: false
    },
  },
  data() {
    return {
      clean: 1,
      error_message: [],
      error_modal: false,
      form: {
        files: []
      },
      loading: false
    }
  },
  beforeUnmount() {
    this.form = {}
  },
  watch: {
    spec: function () {
      this.form = Object.assign({}, this.spec)
    }
  },
  created: function () {
    if (this.spec) this.form = Object.assign({}, this.spec)
  },
  methods: {
    setFile(files) {
      this.form.files = files
    },
    getFiles(files) {
      if(files.length > 0){
        let newFiles = []
        files.forEach(file => {
          newFiles.push(file.raw)
        });
        this.form.files = newFiles
      }
    },
    getForm() {
      let formData = new FormData()
      formData.append('file', this.form.files[0])
      formData.append('lot_id', this.lot.id)
      formData.append('kind', this.kind)
      return formData
    },
    submit() {
      this.$loading = true

       SupplierhubSpec.import(this.getForm())
        .then(result => {
          if(result.data.status == 1){
            this.$toast.success('Spec names has been imported')
            this.$emit('close', true)
          }
          else{
            this.$toast.error('Failed to import')
            this.error_message = result.data.message.split(',')
            this.error_modal = true
          }

          this.form.files = null
          this.clean++
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
