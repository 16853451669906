<template>
  <div>
    <el-select :size="size" filterable v-model="selected_framework" placeholder="Search framework..." style="width: 100%;">
      <el-option v-for="(item, i) in framework_options" :key="i" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>

import { IttFramework } from '@/api_services/itt_framework'

export default {
  props: ['itt_framework_id', 'size', 'class', 'archived', 'joined'],
  data() {
    return {
        selectedvalues: [],
        selected_framework: [],
        itt_frameworks: [],
    }
  }, 
  watch: {
    selected_framework: {
       handler(newValue) {
        this.selectedvalues = newValue
        this.$emit('update', newValue)
       },
      deep: true
    }

  },
  created: function() {
    
    this.selected_framework = null
    if(this.itt_framework_id){
        this.selected_framework = this.itt_framework_id.toString()
    }
    
    this.getITTFrameworks()
  },
  computed: {
    framework_options() {
      return this.itt_frameworks?.map(c => { return { value: c.id.toString(), label: c.name } } ) || []
    },
  },
  methods: {
    getITTFrameworks() {

        this.itt_frameworks = []
        
        let params = {
            term: "",
            page: 1,
            per_page: 100000
        }
      this.globalState.loading = true

      if (this.archived) params.status = 0
      if (this.joined) params.joined = true
        IttFramework.get({ params: params })
            .then(result => {
            if (result.data.itt_frameworks)
                this.itt_frameworks = result.data.itt_frameworks
            })
            .catch(() => {})
            .finally(() => {
            this.globalState.loading = false
            })
    },
  }
}
</script>
