<template>
  <el-form label-position="top" @submit.prevent="submit" v-loading="loading">
    <div class="row">
      <div class="col-md-5">{{ form.spec.name }}</div>
      <div class="col-md-1"> {{ form.spec.type }} <span v-if="form.spec.type != 'choice'">
          | {{ form.spec.kind ?
          $store.state.getSocValTypeUnit(form.spec.type, form.spec.unit) :
          $store.state.getSpecTypeUnit(form.spec.type,form.spec.unit)
          }}</span>
      </div>
      <div class="col-md-1">
        <el-tag type="success" v-if="form.submit">Submitted</el-tag>
        <!-- {{ directionLabel }} {{ form.spec.type != 'choice' ? form.spec.threshold_value : '' }} -->
      </div>
      <div class="col-md-3">
        <el-form-item label="" prop="value">
          <el-input v-model="form.value" type="number" v-if="form.spec.type != 'choice'"></el-input>

          <el-select v-model="form.value" v-else>
            <el-option :value="1" label="Yes"></el-option>
            <el-option :value="0" label="No"></el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="" prop="value">
          <el-input type="textarea" placeholder="Leave some notes" v-model="form.notes"></el-input>
        </el-form-item>
      </div>
      <div class="col-md-1">
        <el-form-item label="" prop="Action">
          <el-button native-type="submit" type="primary" plain>Save</el-button>
          <div style="color: #dc3545;font-size: 10px;" v-if="unsaved">UNSAVED</div>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { SupplierLotSpecValue } from '@/api_services/supplier_lot_spec_value'

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

export default {
  props: {
    spec: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      form: {
        value: 0
      },
      loading: false,
      unsaved: false,
      first_load: true
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
      
      }
    }
  },
  watch: {
    spec: function () {
      if (this.spec) this.form = Object.assign({}, this.spec)
    },
    form: {
      handler(newValue) {
        if (!this.first_load) this.unsaved = true
      },
      deep: true
    },
  },
  created: function () {
    if (this.spec) this.form = Object.assign({}, this.spec)
  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.loading = true

      SupplierLotSpecValue.update(this.form)
        .then(result => {
          this.form.submit = true
          this.unsaved = false
          this.loading = false
          this.$toast.success('Saved')
        })
        .catch(error => {
          this.unsaved = true
        })
        .finally(() => {
          this.loading = false
          this.first_load = false
        })
    },
    handleClose() {
      this.$emit('close')
    }
  },
  computed: {
    directionLabel() {
      if (this.form.spec.type == 'choice') {
        return this.form.threshold_value == 1 ? 'Yes':'No'
      }
      if (!this.form.spec) return ""
      if (this.form.spec.direction == '=') return " equal to "
      if (this.form.spec.direction == '<=') return " less or equal to "
      if (this.form.spec.direction == '>=') return " greater or equal to "
      return ""
    }
  },
}
</script>

<style></style>
