<template>
  <div style="text-align:left">
    <!-- <el-form @submit.prevent="onSubmit" label-position="top">
      <div class="row">
        <div class="col-md-3">
          <el-form-item
            id="card-type"
            label="Title"
            label-for="input-title"
          >
            <el-input
              id="input-title"
              v-model="selected.title"
              trim
              type="text"
              placeholder="Mr or Mrs"
            ></el-input>

            <div class="input-errors" v-for="error of v$.selected.title.$errors" :key="error.$uid">
              <div class="error-text">{{ error.$message }}</div>
            </div>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item
            id="card-type"
            label="Customer Name"
            label-for="input-title"
          >
            <el-input
              id="input-name"
              v-model="selected.name"
              trim
              type="text"
              placeholder=""
            ></el-input>

            <div class="input-errors" v-for="error of v$.selected.name.$errors" :key="error.$uid">
              <div class="error-text">{{ error.$message }}</div>
            </div>
          </el-form-item>
        </div>

        <div class="col-md-12">
          <el-form-item
            id="card-type"
            label="Address"
            label-for="input-address"
          
          >
            <el-input
              id="input-address"
              v-model="selected.address"
              trim
              type="text"
              placeholder=""
            ></el-input>

            <div class="input-errors" v-for="error of v$.selected.address.$errors" :key="error.$uid">
              <div class="error-text">{{ error.$message }}</div>
            </div>
          </el-form-item>
        </div>

        <div class="col-md-4">
          <el-form-item
            id="card-type"
            label="Signed Date"
            label-for="input-call_off_start_date"
          >
            <input
              ref="datePickerInput"
              id="input-date"
              class="form-control"
              v-model="selected.date"
              trim
              type="date"
            />
          </el-form-item>

          <div class="input-errors" v-for="error of v$.selected.date.$errors" :key="error.$uid">
              <div class="error-text">{{ error.$message }}</div>
            </div>
        </div>

        <div class="col-md-12">
            <b-form-checkbox
              id="checkbox-1"
              v-model="selected.signature"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
            >
              click this to use name as a signature for this agreement
            </b-form-checkbox>
        </div>
      </div>
      <b-button type="submit" >Download</b-button>
      <br>
      <strong
        >Please UPLOAD the SIGNED FILE after DOWNLOADING this generated agreement.</strong
      >
    </el-form> -->
  </div>
</template>

<script>
import * as moment from 'moment'
import { User } from '@/api_services/user'
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup () {
      return { v$: useVuelidate({scope: false}) }
    },
  data() {
    return {
      selected: {
        signature: null,
        date: null,
        title: null,
        name: null,
        address: null
      },
      validate: ['date', 'name', 'signature', 'address'],
      datePickerRef: 'datePickerInput'
    }
  },
  validations() {
      return {
        selected: {
          title: {
            required,
            $autoDirty: true
          },
          name: {
            required,
            $autoDirty: true
          },
          date: {
            required,
            $autoDirty: true
          },
          address: {
            required,
            $autoDirty: true
          },
        },
      }
    },
  created: function () {
    this.selected.name = this.savedUser.name
    this.selected.address = this.savedUser.company.complete_address
    this.downloadForm()
  },
  mounted() {
    this.$nextTick(() => {
      const options = {
        dateFormat: 'd/m/Y',
        onValueUpdate: selectedDates => {
          this.selected.call_off_start_date = moment(selectedDates[0]).format(
            'DD/MM/YYYY'
          )
        }
      }
      const dateInput = this.$refs.datePickerInput

      if (dateInput) {
        flatpickr(dateInput, options)
      }
    })
  },
  methods: {
    validation(field) {
      switch (field) {
        case 'date':
          if (!this.selected.date) this.selected.date = ''
          return moment(this.selected.date, 'DD/MM/YYYY', true).isValid()
        case 'name':
          if (!this.selected.name) this.selected.name = ''
          return this.selected.name.length > 0
        case 'address':
          if (!this.selected.address) this.selected.address = ''
          return this.selected.address.length > 0
        case 'signature':
          return this.selected.signature
        default:
          return true
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'date') return 'Date is required'
      if (!this.validation(field) && field == 'name') return 'Name is required'
      if (!this.validation(field) && field == 'address')
        return 'Address is required'
      if (!this.validation(field) && field == 'signature')
        return 'Signature is required'
      return ''
    },
    async onSubmit(evt) {
      evt.preventDefault()

      const result = await this.v$.$validate()
      if (!result) return

      const formattedStartDate = moment(
        this.selected.date,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD')

      this.selected.signed_date = formattedStartDate
    },
    downloadForm() {
      this.globalState.loading = true
      User.generateFaca(this.selected)
        .then(result => {
          var fileURL = window.URL.createObjectURL(
            new Blob([result.data], {
              type: 'application/pdf'
            })
          )
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            'framework_access_agreement_with_details.pdf'
          )
          document.body.appendChild(fileLink)
          fileLink.click()
          
        })
        .catch(() => {})
        .finally(() => {
          this.globalState.loading = false
           this.$emit('download', true)
        })
    }
  },
  computed: {
    ...mapState(['savedUser']),
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field) {
        if (!self.validation(field)) {
          response = false
        }
      })
      return response
    },
   
  },
}
</script>
