import api from './api'

const API = '/api/procurement_project_supplier'

export const ProcurementProjectSupplier = {
  get: function(params) {
    return api.get(API, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  import: function(params) {
    return api.createFormData(API + "/import", params)
  },
  export: function(params) {
    return api.getBlob(API+"/export", params)
  },
}
