<template>
  <div>
    <not-allowed  v-if="!$store.state.isViewable('comparison_tool')"></not-allowed>
    <div v-else>

    <h2 class="text-white">Merchant Services Reports</h2>
    <div style="text-align: left;" class="row">
      <div class="col">
        <div class="card my-3">
          <div class="card-header"><h5>Reports</h5></div>
          <div class="px-0 pb-2 card-body">
            <div class="table-responsive">
              <div class="mx-2 float-end">
                <button class="btn bg-gradient-secondary"><a class="link-white" :href="'/merchant-services/?origin=/main/merchant-transactions-report'">Create New Report</a></button>
                <button class="btn bg-gradient-secondary" @click="getReports()"><i class="fas fa-solid fa-arrow-rotate-right text-white fa-refresh" ></i></button>
              </div>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Code</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prospect Name</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Modified</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!reports.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="report in reports" :key="report.id">
                    <td>{{ report.code }}</td>
                    <td>{{ report.prospect_name }}</td>
                    <td>{{ report.updated_at }}</td>
                    <td style="width: 1%">
                      <a :href="report.pdf_link" target="_blank" class="link me-3" v-tooltip="'Download Report'">
                        <i class="fa-solid fa-download text-secondary">
                      </i></a>
                      <a class="link me-3" :href="'/merchant-services/'+report.code+'?origin=/main/merchant-transactions-report'" v-tooltip="'Edit Transaction Details'">
                          <i class="fas fa-user-edit text-secondary"></i>
                        </a>
                        <a class="link" @click="showRemove(report)" v-tooltip="'Delete'">
                          <i class="fas fa-trash text-secondary"></i>
                        </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getReports()"/>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
    <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    </div>
  </div>
</template>

<script>

import { MerchantTransaction } from '@/api_services/merchant_transaction'

export default {
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      fields: ['code','prospect_name','date_modified','action'],
      reports: [],
      selected: null,
      original: null,
      to_remove: null,
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  created: function() {
    this.getReports();
  },
  methods: {
    showEdit(report) {
      this.$router.push({ path: report.code+'/transaction_types'})
    },
    search() {
      this.meta.page = 1
      this.getReports()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      MerchantTransaction.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getReports()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing report')
          console.error('error:', error.response.data)
        })
    },

    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    getReports() {
      this.isLoading = true
      this.reports = []
      MerchantTransaction.get()
        .then(result => {
          this.reports = result.data.collection
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching reports')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = { name: null }
      this.selected = Object.assign({}, this.original);
    },
  }
}
</script>
