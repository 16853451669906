import api from './api'

const API = '/api/sq_questions'

export const SqQuestion = {
  get: function(params) {
    return api.get(API, params)
  },
  saveAnswers: function(id, params) {
    return api.update(API+'/save_answers/'+id, params)
  },
  saveResults: function(id, params) {
    return api.update(API+'/save_results/'+id, params)
  },
  saveAnswersWithFiles: function(id, params) {
    return api.createFormData(API+'/save_answers/'+id, params)
  },
  saveTableAnswersWithFiles: function(id, params) {
    return api.createFormData(API+'/save_table_answers/'+id, params)
  },
  removeFile: function(params) {
    return api.delete(API+'/remove_file/'+params.id, params)
  },
  removeFileFromTable: function(params) {
    return api.delete(API+'/remove_file_from_table/'+params.id, params)
  }
}
