<template>
  <el-upload class="upload-demo" ref="upload" :drag="drag" action="https://jsonplaceholder.typicode.com/posts/"
    :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleOnChange" v-model:file-list="fileList"
    :show-file-list="showList" :auto-upload="false" :limit="limit" :on-exceed="handleExceed" :multiple="multiple">
    <div v-if="drag">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Drop file{{ multiple ? "s":'' }} here or <em>click to upload</em>
      </div>
    </div>
    <div v-else>
      <el-button size="small" type="primary" plain slot="trigger"><i class="fas fa-file-circle-plus me-2"></i> {{ label
        }}</el-button>
    </div>
    <div class="el-upload__tip" slot="tip">
      Accepted file formats are {{ showFileTypes() }} files with a size less than {{ showMaxSize() }}

    </div>
  </el-upload>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      file: null,
      files: [],
      limit: 10
    }
  },
  watch: {
    fileList: {
      handler: function(newVal) {
        this.update()
      },
      deep: true
    },
    currentFiles: function () {
      // this.fileList = JSON.parse(JSON.stringify(this.currentFiles))
    }
    // currentFiles: {
    //   handler: function(newVal) {
    //     if (this.currentFiles)
    //       this.fileList = JSON.parse(JSON.stringify(this.currentFiles))
    //   },
    //   deep: true
    // }
  },
  created: function() {
    this.fileList = JSON.parse(JSON.stringify(this.currentFiles))
    if (!this.multiple) this.limit = 1
  },
  props: {
    currentFiles: {
      type: Array,
      default: () => []
    },
    fileTypes: {
      type: Array,
      default: () => [
        'jpg',
        'png',
        'jpeg',
        'pdf',
        'xlsx',
        'xls',
        'doc',
        'docx',
        'xlsm'
      ]
    },
    maxSize: {
      default: 20000000
    },
    multiple: {
      type: Boolean,
      description: 'Whether accepts multiple files',
      default: true
    },
    empty: {
      type: Boolean,
      description: 'Always empty the list after emit',
      default: false
    },
    drag: {
      type: Boolean,
      description: 'Whether draggable',
      default: true
    },
    showList: {
      type: Boolean,
      description: 'Show file list',
      default: true
    },
    label: {
      type: String,
      default: 'Click to upload'
    }
  },
  methods: {
    handleExceed(data) {
      this.fileList = data
    },
    showFileTypes() {
      return this.fileTypes.join('/')
    },
    showMaxSize() {
      if (this.maxSize < 10000000) {
        //means kb
        return this.maxSize / 100000 + ' kb'
      }
      return this.maxSize / 1000000 + ' mb'
    },
    handlePreview(ob) {},
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.update()
    },
    handleOnChange(file, fileList) {
      this.fileList = !this.multiple ? [file] : fileList
      this.update()
    },
    update() {
      let files = []

      let error = false
      let self = this

      this.fileList.forEach(file => {
        if (file.size > this.maxSize && !error) {
          error = true
          self.$toast.error('File exceeds ' + self.showMaxSize())
        }
        let fileType = file.name ? file.name.split('.')[1] : ''
        if (!self.fileTypes.includes(fileType) && !error) {
          error = true
          self.$toast.error(fileType + ' is not acceptable type')
        }
      })

      if (error) {
        this.fileList = []
        return false
      }

      this.fileList.forEach(file => {
        files.push(file)
      })
      this.$emit('change', files)
    }
  }
}
</script>
<style></style>
