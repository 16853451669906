import api from './api'

const API = '/api/application_histories'

export const ApplicationHistory = {
  get: function(params) {
    return api.get(API, {params})
  },
  load_histories: function(params) {
    return api.get(API + "/load_histories", {params})
  },
  load_categories: function(params) {
    return api.get(API + "/load_categories", {params})
  },
  exportHistories: function(type, params) {
    return api.get(API+"/export_histories/" + type, params)
  },
}
