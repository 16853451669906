<template>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h3 class="col-md-12">Section IV. Procedure</h3>
                <h4 class="col-md-12">IV.1. Description</h4>
                <div class="col-md-12">
                    <label>IV.1.1. Procedure type</label>
                    <br/>Procedure type in preliminary questions
                </div>
                <!-- <div class="col-md=12">
                    <br/><label>IV.1.3. Information about a framework agreement or a dynamic purchasing system</label>                    
                    <br/><el-checkbox v-model="form.involves_framework_agreement" :checked="form.involves_framework_agreement">The procurement involves the establishment of a framework agreement (optional)</el-checkbox>
                    <br/><el-checkbox v-model="form.involves_dynamic_purchasing_system" :checked="form.involves_dynamic_purchasing_system">The procurement involves the setting up of a dynamic purchasing system (optional)</el-checkbox>
                </div>
                <div v-if="form.involves_framework_agreement">
                    <div class="col-md-6">
                        <br/><label>How many operators will be included in the framework agreement?</label>
                        <br/><div :class="{ 'has-error-field': v.form.operators.$invalid }">
                                <el-radio-group v-model="form.operators">
                                    <el-radio border :value=0 label="0">Framework agreement with a single operator</el-radio>
                                    <el-radio border :value=1 label="1">Framework agreement with several operators</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.operators.$invalid" class="error-text" >Operator selection is required</div>
                    </div>
                    <div v-if="form.operators == 1" div class="col-md-6">
                        <br/><label>Envisaged maximum number of participants to the framework agreement (optional)</label>
                        <div :class="{ 'has-error-field': v.form.number_of_participants.$invalid }">
                            <el-input v-model="form.number_of_participants"></el-input>
                        </div>
                        <div v-if="v.form.number_of_participants.$invalid" class="error-text" >Number of participants is required</div>
                    </div>
                </div>
                <div class="col-md=12">
                    <br/><label>IV.1.6. Information about electronic auction</label>                    
                    <br/>
                        <el-checkbox v-model="form.electronic_auction_used" :checked="form.electronic_auction_used">The procurement involves the setting up of a dynamic purchasing system (optional)</el-checkbox>
                </div> -->
                <div class="col-md-4">
                    <h4>IV.1.8. Information about the Government Procurement Agreement (GPA)</h4>
                    <div :class="{ 'has-error-field': v.form.covered_by_gpa.$invalid }">
                        <el-radio-group v-model="form.covered_by_gpa">
                            <el-radio border :label="1" :value="1">Yes</el-radio>
                            <el-radio border :label="0" :value="0">No</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.covered_by_gpa.$invalid" class="error-text" >GPA selection is required</div>
                </div>
                <!-- <div class="col-md-12">
                    <h4>IV.2. Administrative information</h4>
                    <label>Search for your notice (optional)</label>
                    <el-input v-model="form.search_notice" :label="'Can include words from the notice title or the publication reference'"></el-input>
                </div> -->
                <!-- CHECK LINKED NOTICE LINK??? -->

                
                <!-- <span class="col-md-6"><br/><label class="col-md-12">IV.2.2. Time limit for receipt of expression of interest</label>
                    <div :class="{ 'has-error-field': v.form.time_limit_expression_of_interest.$invalid }">
                        <el-date-picker v-model="form.time_limit_expression_of_interest"></el-date-picker>
                    </div>
                </span>
                <span class="col-md-6"><br/><label>Time (optional)</label><el-input v-model="form.time_limit_expression_of_interest_time" :label="'Time (optional)'"></el-input></span>

                <div class="col-md-6">
                    <br/><label>IV.2.4. Languages in which tenders or requests to participate may be submitted</label>
                    LANGUAGE SELECTION???
                </div>
                <div class="col-md-6">
                    <br/><label>IV.2.5. Schedule date for start of award procedures</label>
                    <br/>
                    <div :class="{ 'has-error-field': v.form.schedule_date_award_procedures.$invalid }">
                        <el-date-picker v-model="form.schedule_date_award_procedures"></el-date-picker>
                    </div>
                    <div v-if="v.form.schedule_date_award_procedures.$invalid" class="error-text" >Schedule date is required</div>
                </div> -->
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf
     } from '@vuelidate/validators'

     const SECTION_FOUR = 4
 
     export default {
         props: ['user', 'procurement_id', 'saved_form', "is_not_submitted"],
         data() {
             return {
                form: {
                    covered_by_gpa: null,
                    search_notice: null,
                    time_limit_expression_of_interesst: null,
                    time_limit_expression_of_interest_time: null,
                    schedule_date_award_procedures: null,
                    involves_dynamic_purchasing_system: null,
                    involves_framework_agreement: null,
                    electronic_auction_used: null,
                    operators: null,
                    number_of_participants: null,
                }
             }
         },
        setup: () => ({
             v: useVuelidate()
          }),
        mounted()  {
            if(this.saved_form) this.form = this.saved_form
        },
          validations() {
            let rules = {
                form: {
                    covered_by_gpa: {
                        required,
                        $autoDirty: true
                    }
                    // time_limit_expression_of_interest: {
                    //     required,
                    //     $autoDirty: true
                    // },
                    // schedule_date_award_procedures: {
                    //     required,
                    //     $autoDirty: true
                    // },
                    // operators: {
                    //     required: requiredIf(function () {return this.form.involves_framework_agreement}),
                    //     $autoDirty: true
                    // },
                    // number_of_participants: {
                    //     required: requiredIf(function () {return this.form.operators == 1}),
                    //     $autoDirty: true
                    // },
                }
            }

         return rules
        },
        methods: {
                saveForm() {
                    if(!this.form.involves_framework_agreement) this.form.operators = null
                    if(this.form.operators != 1) this.form.number_of_participants = null

                    this.$emit('save', SECTION_FOUR, this.form)
                }
            }
     }
 </script>
 