<template>
  <div>
    <div v-if="user">
      <a v-if="has_companies" href="#" @click.prevent="selectCompany()" :class="className">
        {{ user.company ? user.company.name : 'No Company' }}
        <span class="small ms-1">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>
        </span>
      </a>
      <span v-else>
        {{user.company ? user.company.name : 'No Company'}}
      </span>
    </div>

    <el-drawer id="modal-remove" size="90%" v-model="showCompanyModal" title="Select Company" :append-to-body="true"
      :close-on-press-escape="false">
      <select-company-component @update="closeSelectCompanyModal"></select-company-component>
    </el-drawer>

    <el-drawer size="100%" v-model="showBcp" title="" :append-to-body="true" :close-on-click-modal="false"
      direction="btt" :show-close="false" :close-on-press-escape="false">
      <company-bcp-form @close="closeBcp" :show_skip="true" @skip="closeBcp"></company-bcp-form>
    </el-drawer>

    <div v-if="no_company">
      <p class="my-2">
        You are not assigned to any company. Please contact the administrator. Redirecting to the dashboard ...
      </p>
    </div>

  </div>
</template>

<script>

import SelectCompanyComponent from './select_company_component.vue'
import Swal from 'sweetalert2'
import CompanyBcpForm from '../company_bcp/CompanyBcpForm.vue'

export default {
  components: {
    SelectCompanyComponent,
    CompanyBcpForm,
  },
  data() {
    return {
      company: null,
      is_admin: true,
      no_company: false,
      showCompanyModal: false,
      companies: [],
      has_companies: false,
      showBcp: false,
      user: null
    }
  },
  props: {
    className: {
      type: String,
      required: false
    }
  },
  created: function () {
    this.user = this.$store.state.savedUser
    this.has_companies = this.user.has_companies
    this.company = this.user.company

    if (!this.company) {
      if (!this.user.is_admin || (this.user.is_admin && this.$router.currentRoute.name !== 'companies')) {
        if (this.$router.currentRoute.name != 'Home') {
          this.no_company = true
          let self = this
          setTimeout(function () {
            self.no_company = true;
            self.redirectToDashboard();
          }, 3000);
        }

      }

    } else {
      if (this.user.apply_bcp_on_sign_up && (this.user.bcp_status == null || this.user.bcp_status == 2)) {
        this.showBcp = true
      }
    }
    console.log(this.user)
  },
  computed: {
    shouldShowDrawer() {
      // Check if the user is an admin or has more than one assigned company
      return this.isAdminOrHasMultipleCompanies;
    },
    isAdminOrHasMultipleCompanies() {
      this.user = this.$store.state.savedUser;
      const isAdmin = this.user.is_admin;
      const userCompaniesCount = this.userCompanies.length;

      // Admin without a specific company can access all companies
      return isAdmin || userCompaniesCount > 1;
    },
    userCompanies() {
      let user = this.$store.state.savedUser;
      return user.companies || [];
    },
  },
  methods: {
    closeBcp() {
      this.showBcp = false
      this.user.apply_bcp_on_sign_up = false
      this.$store.commit('saveUser', this.user)
    },
    redirectToDashboard() {
      Swal.fire({
        icon: 'warning',
        title: 'Attention',
        text: 'You are not assigned to any company. Please contact the administrator.',
        timer: 3000,
        timerProgressBar: true,
        onClose: () => {
          this.no_company = false
          this.$router.push('/')
        },
      });
    },
    selectCompany() {
      if (this.has_companies) this.showCompanyModal = true
    },
    closeSelectCompanyModal() {
      this.showCompanyModal = false
    }
  }
}
</script>

<style scoped>

</style>
