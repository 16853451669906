<template>
<form @submit.prevent="submit">

  <div class="form-group">
    <div :class="{ 'has-error-field': v.form.name.$invalid }">
      <label>Message</label>
      <QuillEditor theme="snow" v-model:content="form.name" contentType="html" />
    </div>
    <div v-if="v.form.name.$invalid" class="error-text">Comment is required</div>
  </div>

  <el-button native-type="submit" type="primary" plain>Save</el-button>
</form>
</template>

<script>
import {
  SupplierhubSpec
} from '@/api_services/supplierhub_spec'
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

export default {
  props: {
    spec: {
      type: Object,
      required: true
    },
    comment: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      form: {
        name: null
      },
      loading: false,
      editorOptions: {
        placeholder: 'Enter your comment',
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // Font styling options
          ],
        },
      },
    }
  },
  beforeUnmount() {
    this.form = {}
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        name: {
          required,
          $autoDirty: true
        },
      }
    }
  },
  watch: {
    spec: function () {
      this.form = Object.assign({}, this.comment)
    },
    'form.name': function () {
      if (this.form.name == '<p><br></p>') this.form.name = null
    }
  },
  created: function () {
    if (this.comment) this.form = Object.assign({}, this.comment)
  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.$loading = true

      SupplierhubSpec.addComment(this.spec.id, this.form)
        .then(result => {
          this.$toast.success('Comment has been submitted')
          this.form.name = " "
          this.$emit('close', result.data.comment)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
