<template>
  <el-form label-position="top" @submit.prevent="submit" v-loading="loading">
    <div class="row">
      <div class="col-md-4">{{ form.name }} </div>
      <div class="col-md-2"> {{ form.type }} | {{ spec.kind == 1 ? $store.state.getSocValTypeUnit(form.type, form.unit)
        :
        $store.state.getSpecTypeUnit(form.type, form.unit) }}
      </div>
      <div class="col-md-2">

        <el-form-item label="" prop="body">
          <el-select v-model="form.direction">
            <el-option value="=" label="Equal"></el-option>
            <el-option value="<=" label="Less than or equal" v-if="form.type != 'choice'"></el-option>
            <el-option value=">=" label="Greater than or equal" v-if="form.type != 'choice'"></el-option>
          </el-select>
          <div v-if="v.form.direction.$invalid" class="error-text">Type is required</div>
        </el-form-item>
      </div>
      <div class="col-md-2">
        <el-form-item label="" prop="threshold_value">
          <el-input v-model="form.threshold_value" type="number" v-if="form.type != 'choice'"></el-input>

          <el-select v-model="form.threshold_value" v-else>
            <el-option :value="1" label="Yes"></el-option>
            <el-option :value="0" label="No"></el-option>
          </el-select>

        </el-form-item>
      </div>
      <div class="col-md-2">
        <el-form-item label="" prop="Action">
          <el-button native-type="submit" type="primary" text>Save</el-button>

          <el-popconfirm title="Are you sure to delete this?" @confirm="handleDelete()">
            <template #reference>
              <el-button native-type="button" type="danger" plain text><i
                  class="fa fa-trash"></i></el-button>
            </template>
          </el-popconfirm>


        </el-form-item>
        <div style="color: #dc3545;font-size: 10px;margin-left: 10px;" v-if="unsaved">UNSAVED</div>

      </div>
    </div>
  </el-form>
</template>

<script>
import { SupplierhubSpec } from '@/api_services/supplierhub_spec'

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

export default {
  props: {
    spec: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      form: {
        min: 0,
        max: 0,
        amount: 0,
        band: null,
        volume_type: 1,
        contract_length: {
          option: 1
        }
      },
      loading: false,
      unsaved: false
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        direction: {
          required,
          $autoDirty: true
        },
      }
    }
  },
  watch: {
    spec: function () {
      if (this.spec) this.form = Object.assign({}, this.spec)
      if (!this.form.direction) this.form.direction = '='
      if (!this.form.threshold_value) this.form.threshold_value = 0
    },
    form: {
      handler(newValue) {
        this.unsaved = true
      },
      deep: true
    },
  },
  created: function () {
    if (this.spec) this.form = Object.assign({}, this.spec)
    if (!this.form.direction) { 
      this.form.direction = '='
    }
    if (!this.form.threshold_value) {
      this.form.threshold_value = 0
    }

  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.loading = true

      SupplierhubSpec.update(this.form)
        .then(result => {
          this.unsaved = false
          this.loading = false
          this.$toast.success('Saved')
        })
        .catch(error => {
          this.unsaved = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDelete() {
      this.loading = true
      SupplierhubSpec.delete(this.form.id, 'pin')
        .then(result => {
          this.unsaved = false
          this.loading = false
          this.$toast.success('Deleted')
          this.handleClose()
        })
        .catch(error => {
          this.unsaved = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.$emit('close', true)
    }
  }
}
</script>

<style></style>
