<template>
  <div v-if="monthly_collection">
     <div class="row">
       <div class="col-md-4">
        <!-- {{ lot2_monthly_projected_savings[0].savings * multiplier }} -->
        <mini-statistics-card
          
          title="Total Transactions"
          description=""
          :value="$filters.numberFormatFilter(sumAllTransactionCounts())"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-success',
            shape: 'rounded-circle'
          }"
        />

        <mini-statistics-card
          v-if="lot2_monthly_projected_savings && lot2_monthly_projected_savings.length"
          title="Projected Savings"
          description=""
          :value="$filters.currencyFilter(savingsProjection)"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle'
          }"
        />
        <mini-statistics-card
          v-if="lot2_monthly_projected_savings && lot2_monthly_projected_savings.length"
          title="Projected fees(without VAT)"
          description=""
          :value="$filters.currencyFilter(savingsProjection * 0.2)"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle'
          }"
        />
        <mini-statistics-card
          title="Total Gross Savings"
          description=""
          :value="$filters.currencyFilter(monthly_collection.total_savings_for_label)"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-info',
            shape: 'rounded-circle'
          }"
        />
        <mini-statistics-card
          title="CPRAS Fees (without VAT)"
          description=""
          :value="$filters.currencyFilter(monthly_collection.cpras_fees)"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-primary',
            shape: 'rounded-circle'
          }"
        />
        <mini-statistics-card
          title="NET Savings"
          description=""
          :value="$filters.currencyFilter(monthly_collection.net_savings)"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-success',
            shape: 'rounded-circle'
          }"
        />

        <mini-statistics-card
          title="Unvalidated"
          description=""
          :value="monthly_collection.unvalidated"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle'
          }"
        />
       </div>
       <div class="col-md-8">
        <div class="card p-3 bg-gradient-light">
         <BarChart id="chart4" :chartData="generateBarChartDataFromSummary(monthly_collection.summary)"  />
        </div>
       </div>

     </div>
     <div class="row">
       <div class="col-md-12">
        <div class="card my-3">
          <h5 class="text-center" style="font-weight: 900">Cost/Savings Data</h5>
          <div class="table-responsive">
            <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Card Type</th>
              <th class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">Value of Transactions</th>
              <th class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">Prior Costs</th>
              <th class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">Actual Costs</th>
              <th class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">Savings</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in monthly_collection.summary" :key="data.card_type" >
                <td class="text-sm font-weight-bold mb-0">{{ data.card_type }}</td>
                <td class="align-middle text-center text-xs">{{ $store.state.formattedAmount(data.sum_processed_transaction_amount) }}</td>
                <td class="align-middle text-center text-xs">
                  <span v-if="data.card_type != 'UNVALIDATED'">{{ $store.state.formattedAmount(data.sum_old_charge) }}</span>
                </td>
                <td class="align-middle text-center text-xs">
                  <span v-if="data.card_type != 'UNVALIDATED'">{{ $store.state.formattedAmount(data.sum_actual_cost) }}</span>
                </td>
                <td class="align-middle text-center text-xs">
                  <span v-if="data.card_type != 'UNVALIDATED'">{{ $store.state.formattedAmount(data.savings) }}</span>
                </td>
              </tr>
                <tr style="font-weight: 900;">
                <td>Total</td>
                <td class="align-middle text-center text-lg" ><span class="badge badge-success">{{ $store.state.formattedAmount(monthly_collection.sum_processed_transaction_amount) }}</span></td>
                <td class="align-middle text-center text-lg" ><span class="badge badge-success">{{ $store.state.formattedAmount(monthly_collection.sum_old_charge) }}</span></td>
                <td class="align-middle text-center text-lg" ><span class="badge badge-success">{{ $store.state.formattedAmount(monthly_collection.sum_actual_cost) }}</span></td>
                <td class="align-middle text-center text-lg" ><span class="badge badge-success">{{ $store.state.formattedAmount(monthly_collection.total_savings) }}</span></td>
              </tr>

            </tbody>
          </table>
          </div>

        </div>

       </div>
       <div class="col-md-6">
        <div class="card my-3">
          <div class="table-responsive">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cost per £100 Processed</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prior Cost</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actual Cost</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Saving</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in monthly_collection.cost_per_processed" :key="data.name">
                  <td class="text-sm font-weight-bold mb-0">{{ data.name }}</td>
                  <td class="align-middle text-center text-xs">{{ data.prior_cost ? data.prior_cost.toFixed(2): '0.00' }}</td>
                  <td class="align-middle text-center text-xs">{{ data.actual_cost ? data.actual_cost.toFixed(2): '0.00' }}</td>
                  <td class="align-middle text-center text-xs">{{ data.savings ? data.savings.toFixed(2): '0.00' }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

       </div>
       <div class="col-md-6">
        <div class="card my-3">
          <div class="table-responsive">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Cost per Avg Txn</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prior Cost</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actual Cost</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Saving</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in monthly_collection.cost_per_average" :key="data.card_type" >
                  <td class="text-sm font-weight-bold mb-0">{{ data.card_type }}</td>
                  <td class="align-middle text-center text-xs">{{ data.prior_cost_over_trans ? data.prior_cost_over_trans.toFixed(2) : '0.00'  }}</td>
                  <td class="align-middle text-center text-xs">{{ data.actual_cost_over_trans ? data.actual_cost_over_trans.toFixed(2) : '0.00'}}</td>
                  <td class="align-middle text-center text-xs">{{ data.saving_per_transaction ? data.saving_per_transaction.toFixed(2) : '0.00' }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

       </div>
     </div>
  </div>
</template>

<script>


import BarChart from '@/components/BarChart'
import MiniStatisticsCard from '@/components/common/MiniStatisticsCard'
import { Company } from '@/api_services/company'



export default {
    props: ['monthly_collection' , 'company_id', 'multiplier'],
    components: { BarChart, MiniStatisticsCard },
  data() {
    return {
        cost_per_processed: [],
        lot_savings_projections: [],
        lot2_monthly_projected_savings: null,
    }
  },
  mounted() {
    this.getCompany(); // Call getCompany() when the component is mounted
  },
  computed: {
    savingsProjection() {
      return this.lot2_monthly_projected_savings[0].savings * this.multiplier
    }
  },
  methods: {
      sumAllTransactionCounts(){
        let sum = 0
        this.monthly_collection.summary.forEach(data => {
          sum += data.count
        })
        return sum
      },
      getCompany() {
        Company.show(this.company_id)
          .then(result => {
            let lotSavings = JSON.parse(result.data.company.lot_savings_projections)  || []
            
            // Checking if lot_savings_projections is not empty
            if (lotSavings.length > 0) {
              this.lot_savings_projections = lotSavings;

              //Filtering and mapping data with name as "BFF Lot 2" to a new property
              this.lot2_monthly_projected_savings = this.lot_savings_projections
                .filter(item => item.name === "BFF Lot 2")
                .map(item => {
                  return {
                    name: item.name,
                    savings: item.savings
                  };
                });

            } else {
              // Handle case when lot_savings_projections is empty
              this.$toast.info('No lot savings projections available.');
            }
          })
          .catch( error => {
            this.$toast.error('An error occured while fetching company')
            console.error('error:', error.response.data)
          })
    },
    generateBarChartDataFromSummary(summary) {
      let labels = []
      let savings = [];
      let actual_cost_data = [];
      let prior_cost_data = [];
      summary.forEach(data => {
        labels.push(data.card_type)
        actual_cost_data.push(data.sum_actual_cost)
        prior_cost_data.push(data.sum_old_charge)
        // labels.push("")
        // savings.push(data.sum_old_charge)
        // savings.push(data.sum_actual_cost)
      });

      return this.generateBarChart('Cost Data Summary of Savings', labels, actual_cost_data, prior_cost_data)
    },
    generateBarChart(title, labels,actualCostData, priorCostData) {

        var actualBackground = [];
        var priorBackground = [];

        actualCostData.forEach(function(data){
          actualBackground.push('#3a0ca3')
        })
        priorCostData.forEach(function(data){
          priorBackground.push('#f72585')
        })

        return {
          labels:labels,
          datasets: [{
            label: "Actual Cost",
            barThickness: 30,
            maxBarThickness: 70,
            borderWidth: 1,
            backgroundColor: actualBackground,
            // borderColor: ['rgba(255,99,132,1)','rgba(54, 162, 235, 1)','rgba(75, 192, 192, 1)','rgba(244, 208, 63, 1)'],
            pointBorderColor: '#ffd166',
            data: actualCostData
          },
          {
            label: "Prior Costs",
            barThickness: 30,
            maxBarThickness: 70,
            borderWidth: 1,
            backgroundColor: priorBackground,
            // borderColor: ['rgba(255,99,132,1)','rgba(54, 162, 235, 1)','rgba(75, 192, 192, 1)','rgba(244, 208, 63, 1)'],
            pointBorderColor: '#ffd166',
            data: priorCostData
          }
          ]
        }
    },
  }
}
</script>

<style scoped>

</style>
