<template>
  <div>
    <el-dialog v-model="showForm" width="50%">
      <div>
        <proc-prod-cost-detail-form :cost_detail="selected" @close="getItems"
          @save="getItems($event)"></proc-prod-cost-detail-form>
      </div>
    </el-dialog>

    <div class="table-responsive" v-loading="loading">
      <h5>{{ price_cost.band }} {{ type == 'usage' ? 'Usage Costs' : 'Setup Costs' }}</h5>
      <el-table class="table-striped" header-row-class-name="text-primary" :data="list" style="width: 100%">
        <el-table-column :min-width="50" class-name="td-actions" label="Description">
          <template v-slot="props">
            {{ props.row.description }}
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Transaction Volume">
          <template v-slot="props">
            {{ props.row.transaction_volume }}
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Transaction Value">
          <template v-slot="props">
            {{ props.row.transaction_value }}
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Rate %">
          <template v-slot="props">
            {{ props.row.rate_percent }} %
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="PPT">
          <template v-slot="props">
            {{ props.row.ppt }}
          </template>
        </el-table-column>

        <el-table-column :min-width="50" class-name="td-actions" label="Total Cost">
          <template v-slot="props">
            {{ props.row.cost }}
          </template>
        </el-table-column>

        <el-table-column :min-width="50" class-name="td-actions" label="Action">
          <template v-slot="props">
            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Edit</span>
              </template>

              <el-button class="mb-1" v-tooltip="'Edit'" size="small" @click="handleEdit(props.row)"
                v-if="$helpers.isVisible('PRODUCT_PRICE_COST', 'UPDATE')"><i class="fa fa-pencil"></i></el-button>
            </el-tooltip>

            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Delete</span>
              </template>

              <el-button class="mb-1" v-tooltip="'Delete'" size="small" @click="handleDelete(props.row)"
                v-if="$helpers.isVisible('PRODUCT_PRICE_COST', 'DELETE')"><i class="fa fa-trash"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <div style="text-align: center;">
        <el-tooltip :enterable="false" placement="top-start">
          <template #content>
            <span>Create</span>
          </template>

          <el-button class="mb-1" size="small" @click="handleNew()"
            v-if="$helpers.isVisible('PRODUCT_PRICE_COST', 'UPDATE')"><i class="fa fa-plus"></i></el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>

import { ProcProdCostDetail } from '@/api_services/proc_prod_cost_detail'
import ProcProdCostDetailForm from './ProcProdCostDetailForm.vue'

export default {
  components: {
    ProcProdCostDetailForm
  },
  props: ['price_cost', 'type'],
  data() {
    return {
      list: [],
      loading: false,
      selected: null,
      showForm: false
    }
  },
  watch: {
    type: {
      deep: true,
      handler() {
        this.getItems()
      }
    },
    price_cost: {
      deep: true,
      handler() {
        this.getItems()
      }
    }
  },
  created: function () {
    this.getItems()
  },
  methods: {
    handleNew() {
      this.selected = { proc_prod_price_cost_id: this.price_cost.id, type: this.type }
      this.showForm = true
    },
    handleEdit(cost_detail) {
      this.selected = Object.assign({}, cost_detail)
      this.showForm = true
    },
    handleDelete(cost_detail) {
      this.loading = true

      ProcProdCostDetail.delete(cost_detail.id).then(result => {
        this.$toast.success("Cost detail removed")
        this.list = result.data.items
      })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })
    },
    getItems(list) {
      console.log(this.price_cost)
      this.showForm = false
      this.list = this.type == 'setup' ? this.price_cost.setup_cost_details : this.price_cost.usage_cost_details
      if (list) this.list = list
    }
  }
}
</script>
