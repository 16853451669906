<template>
  <div v-if="transaction_type">
    <div class="card my-3">
      <div class="card-header"><h5>{{ transaction_type.name }}</h5></div>
      <div class="card-body">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Min</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Max</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Rate</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">PPT</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
            </tr>
          </thead>
          <RangeComponent v-for="(range, index) in transaction_type_ranges"
            :key="range.id"
            :index="index"
            :range="range"
            @save="save(range)"
            @remove="remove(range, index)">
          </RangeComponent>
        </table>
        <button type="button" class="btn bg-gradient-secondary btn-sm" @click="addRow()">Add</button>
      </div>
    </div>
  </div>
</template>

<script>

import { TransactionTypeRange } from '@/api_services/transaction_type_range'
import RangeComponent from './range_component'

export default {
  data() {
    return {
      fields: ['id', 'min','max', 'rate', 'ppt_amount'],
      transaction_type_ranges: [],
      selected: null,
      original: null,
      validate: ['name', 'min','max', 'rate'],
      to_remove: null,
      supplier_id: null
    }
  },
  components: { RangeComponent },
  props: ['transaction_type'],
  created: function() {
    if (this.transaction_type) {
      this.getRanges()
    }
  },
  watch: {
      transaction_type: function() {
          this.getRanges()
      }
  },
  methods: {
    addRow() {
      this.transaction_type_ranges.push({
        min: 0, max: 0, rate: 0, ppt_amount: 0,
        transaction_type_id: this.transaction_type.id,
        supplier_id: this.transaction_type.supplier_id
      })
    },
    save(range) {
      range.supplier_id = this.transaction_type.supplier_id
      var action = range.id ? TransactionTypeRange.update(range) : TransactionTypeRange.create(range)
        action.then(result => {
          this.$toast.success('Saved successfully')
          this.getRanges()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving transaction type range')
          console.error('error:', error.response.data)
        })
    },
    remove(range, index) {
      if (range.id) {
        TransactionTypeRange.delete({transaction_type_id: this.transaction_type.id, supplier_id: this.transaction_type.supplier_id, id: range.id})
        .then(result => {
          if (result.data.transaction_type_ranges)
             this.$toast.success('Range removed from transaction type')
             this.getRanges()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving transaction type range')
          console.error('error:', error.response.data)
        })
      } else {
        this.transaction_type_ranges.splice(index, 1)
        this.$toast.success('Range removed from transaction type')
      }
    },
    getRanges() {
      this.reset()
      TransactionTypeRange.get({transaction_type_id: this.transaction_type.id, supplier_id: this.transaction_type.supplier_id})
        .then(result => {
          if (result.data.transaction_type_ranges)
            this.transaction_type_ranges = result.data.transaction_type_ranges
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching transaction type ranges')
          console.error('error:', error.response.data)
        })
    },
    reset() {
      this.original = null
      this.selected = null
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>
