<template>
  <div class="container-fluid" v-loading="loading">
    <div v-if="not_found || loading" style="height: 500px;">
      <h4 v-if="not_found">Product not found</h4>
    </div>
    <div v-if="selected">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow border h-100 p-5">
            <h4>Product Details</h4>
            <div class="row">
              <div class="col-6">
                <div class="sticky-image-container">
                  <div class="row">
                    <div class="col-sm-3" v-if="getProductImages(selected).length > 1">
                      <div
                        class="blur-shadow-avatar"
                        v-for="(image, i) in getProductImages(selected)"
                        :key="image.id"
                      >
                        <img
                          v-if="i < 5"
                          :src="image"
                          @click="enlargeImage(image)"
                          class="avatar avatar-xxl shadow"
                          style="margin-bottom: 5px"
                        />
                      </div>
                    </div>
                    <div :class="getProductImages(selected).length > 1 ? 'col-sm-6' : 'col-sm-12'">
                      <img
                        style="max-width: 430px; max-height: 700px"
                        class="border-radius-sm rounded-3 shadow-lg img-fluid"
                        :src="
                          selected.primary_image
                            ? selected.primary_image.absolute_path
                            : $store.state.no_image
                        "
                      />
                    </div>
                  </div>
                  
                </div>
              </div>
              <div class="col-6 p-2">
                <h3>{{ selected.name }}</h3>
                <p class="card-title text-sm" style="font-weight: 900">
                  Supplier: {{ selected.product_supplier.name }}
                </p>
                <hr />
                <div class="product-info">
                  <p>
                    Framework Price:
                    <span style="color:#B12704; font-weight: 900;"
                      >{{ formattedNumber(selected.prices) }}
                    </span>
                  </p>
                  <p v-if="selected.website">
                    Website: <a :href="selected.website" target="_blank">{{ selected.website }}</a>
                  </p>
                </div>
                <hr />
                <div>
                  <div style="font-weight:900"><h5>About this Product</h5></div>
                  <div v-html="selected.short_description"></div>
                  <div v-html="selected.description">
                  </div>
                  <div v-if="selected.product_description_attachments.length">
                    <h6>Attachment(s) : </h6>
                    <table>
                      <tr v-for="attachment in selected.product_description_attachments" :key="attachment.id">
                        <td>
                          <a
                            class="link-primary text-sm"
                            :href="attachment.absolute_path"
                            target="_blank"
                            >{{ attachment.name }}
                            <i class="fa-solid fa-file-lines"></i
                          ></a>
                        </td>
                      </tr>
                    </table>
                    <span
                      v-for="attachment in selected.product_description_attachments"
                      :key="attachment.id"
                    >
                      
                    </span>
                  </div>
                  <hr>
                </div>
                <!-- <div v-if="itt_res_files">
                  <div style="font-weight:900"><h5>Invitation To Tender Response File</h5></div>
                  <div v-for="file in itt_res_files" :key="file.id">
                    <a
                        class="link-primary text-sm"
                        :href="file.link"
                        target="_blank"
                        >{{file.file_name}}
                        <i class="fa-solid fa-file-lines"></i
                      ></a>
                  </div>
                  <hr>
                </div> -->
                
                <div class="case-studies-section" v-if="selected.case_studies">
                  <h4 class="orange-color">Case Studies</h4>
                  <div v-html="selected.case_studies"></div>
                  <div v-if="selected.product_case_studies_attachments.length">
                    <h6>Attachment(s): </h6>
                    <table>
                      <tr v-for="attachment in selected.product_case_studies_attachments" :key="attachment.id">
                        <td>
                          <a
                            class="link-primary text-sm"
                            download
                            :href="attachment.absolute_path"
                            target="_blank"
                            >{{ attachment.name}}
                            <i class="fa-solid fa-file-lines"></i
                          ></a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <hr />
                </div>

                <div class="testimonials-section" v-if="selected.testimonials">
                  <h4 class="orange-color">Testimonials</h4>
                  <div v-html="selected.testimonials"></div>
                  <div v-if="selected.product_testimonial_attachments.length">
                    <h6>Attachment(s) </h6>
                    <table>
                      <tr v-for="attachment in selected.product_testimonial_attachments" :key="attachment.id">
                        <td>
                          <a
                            class="link-primary text-sm"
                            download
                            :href="attachment.absolute_path"
                            target="_blank"
                            >{{ attachment.name }}
                            <i class="fa-solid fa-file-lines"></i
                          ></a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              
                <div
                  class="supporting-links-section"
                  v-if="selected.supporting_links.length"
                >
                  <h5 class="orange-color">Supporting Links</h5>
                  <ul>
                    <li v-for="link in selected.supporting_links" :key="link">
                      <el-link type="success" :href="link" target="_blank">{{
                        link
                      }}</el-link>
                    </li>
                  </ul>
                </div>

                <hr />
                <div class="row p-2">
                  <div class="col-md-12">
                    <selected-product-view-specifications-list :product="selected"></selected-product-view-specifications-list>
                  </div>
                  <div class="col-md-12">
                    <selected-product-view-social-values-list :product="selected"></selected-product-view-social-values-list>
                  </div>
                </div>
              </div>
              
            </div>

            
          </div>
        </div>
        <div></div>
      </div>

      <!-- <Modal
        :backdrop="true"
        :title="''"
        :close="true"
        size="lg"
        v-model="modalUserHelper"
        @show="onShowModal"
        @hidden="onHiddenModal"
      >
        <help-component
          v-if="selected"
          :product="selected"
          :category_id="category_id"
          :buyer="true"
        ></help-component>
      </Modal> -->

      <Modal
        id="modalImage"
        :backdrop="true"
        :title="''"
        :close="true"
        v-model="modalImage"
      >
        <div v-if="enlarged_image">
          <div class="image-container">
            <img :src="enlarged_image" class="img-fluid" alt="Enlarged Image">
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { Product } from '@/api_services/product'
import Modal from '../../common/Modal.vue'
import SelectedProductViewSpecificationsList from './SelectedProductViewSpecificationsList.vue'
import SelectedProductViewSocialValuesList from './SelectedProductViewSocialValuesList.vue'
// import helpComponent from '@/components/pages/help/help_component.vue'
import $ from 'jquery'

export default {
  components: { Modal, SelectedProductViewSpecificationsList, SelectedProductViewSocialValuesList },
  props: ['category_id', 'volume', 'product_id'],
  data() {
    return {
      enlarged_image: null,
      selected: null,
      user_quantity: 0,
      not_found: false,
      cost_data_link: null,
      itt_res_files: [],
      social_values: [],
      modalUserHelper: null,
      modalImage: null,
      loading: true
    }
  },
  watch: {
    product_id: function () {
      this.getProduct()
    }
  },
  created: function() {
    if (this.category_id) {
      this.cost_data_link = this.$store.state.default_categories_link[
        this.category_id
      ]
    }
    this.getProduct()
  },
  computed: {
    total: function() {
      if (this.selected) {
        return (
          this.selected.prices *
          (this.user_quantity / this.selected.quantity)
        ).toFixed(4)
      }
      return 0
    }
  },
  methods: {
    getSVValue(social_value) {
      if(social_value.social_value.type == 1) {
        return (social_value.value == 1) ? 'Yes' : 'No'
      }

      return social_value.value
    },
    showUserHelper() {
      $("#modal-user-helper").modal("show")
    },
    forComparison() {
      let for_comparison = false

      this.selected.categories.forEach(function(category) {
        if (category.for_comparison) for_comparison = true
      })

      return for_comparison
    },
    enlargeImage(image) {
      this.enlarged_image = image
      $("#modalImage").modal("show")
    },
    getProductImages(product) {
      var images = []
      product.product_images.forEach(function(image) {
        images.push(image.absolute_path)
      })

      return images
    },
    getImagePath(product) {
      if (product.file_path) return product.absolute_path
      return require('@/assets/product.png')
    },
    getProduct() {
      this.not_found = false
      this.loading = true
      this.selected = null
      Product.show(this.product_id, { volume: this.volume })
        .then(result => {
          if (result.data.product) {
            this.selected = result.data.product
            this.social_values = result.data.product.product_social_values
            this.itt_res_files = result.data.product.itt_response_files
          } else {
            this.not_found = true
          }
        })
        .catch(error => {
          this.not_found = true
          this.$toast.error('An error occured while fetching products')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        } )
    },
    joinedCategories(categories) {
      let string = ''
      categories.forEach(category => {
        string += string.length == 0 ? category.name : ', ' + category.name
      })
      return string
    },
    formattedNumber(value) {
      var roundedNumber = Math.round(value)
      return roundedNumber.toLocaleString()
    }
  }
}
</script>

<style scoped>

.sticky-image-container {
  position: sticky;
  top: 0;
  height: 100vh; /* Adjust the height as per your requirement */
  overflow-y: auto;
  padding: 10px;
}
.image-container {
  top: 0;
  overflow-y: auto;
  padding: 10px;
}
.custom-tag {
  font-size: 12px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 5px;
  border-radius: 8px;
  margin: 3px;
}

.total-cost {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #f44336;
}

.light-blue-color {
  color: #53a5d0;
}

.product-info {
  font-size: 12px;
  color: #404040;
}

.orange-color {
  color: #f5b371;
}

.gray-color {
  color: #404040;
}

.custom-font-size {
    font-size: 10px;
  }
</style>
