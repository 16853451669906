<template>
  <router-link class="text-white" :to="this.link">
    <span class="text-sm text-white text-bold">&#8592; {{ label }}</span>
  </router-link>
</template>

<script>

export default {
    name: 'BackComponent',
    props: ['label','link'],
    data() {
        return {
      
        }
    },
    methods: {
      goTo() {
        this.$router.push(this.link)
      }
    }
}
</script>

<style lang="scss" scoped></style>
