<template>
  <div>
    <el-dialog v-model="showForm" width="50%" :before-close="getPriceCosts">
      <div v-if="selected">
        <proc-prod-price-cost-form :price_cost="selected" @close="getPriceCosts"></proc-prod-price-cost-form>
      </div>
    </el-dialog>

    <el-dialog v-model="showCostDetails" width="50%" :before-close="getPriceCosts" :append-to-body="true">

      <div v-if="price_cost">
        <proc-prod-cost-details-list :type="cost_type" :price_cost="price_cost"
          v-if="cost_type == 'usage'"></proc-prod-cost-details-list>
        <proc-prod-cost-details-list :type="cost_type" :price_cost="price_cost"
          v-if="cost_type == 'setup'"></proc-prod-cost-details-list>

      </div>
    </el-dialog>

    <div class="pb-0 card-header">
      <div class="d-sm-flex">
        <div>
          <h5 class="mb-0">Price Costs</h5>

          <p class="mb-0 text-sm"></p>
        </div>
        <div class="my-auto mt-4 ms-auto mt-sm-0">
          <div class="my-auto ms-auto">

          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive" v-loading="loading">
      <el-table class="table-striped" header-row-class-name="text-primary" :data="proc_prod_price_costs"
        style="width: 100%">
        <el-table-column :min-width="50" class-name="td-actions" label="Band">
          <template v-slot="props">
            {{ props.row.product_price_cost.band }}
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Transaction Value">
          <template v-slot="props">
            {{ props.row.transaction_value }}
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Total Usage Cost">
          <template v-slot="props">
            {{ props.row.total_usage_cost }}
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Total Setup Cost">
          <template v-slot="props">
            {{ props.row.total_setup_cost }}
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Usage Costs">
          <template v-slot="props">
            <el-button plain size="small" type="primary" @click="handleShowCostDetails(props.row, 'usage')">({{
              props.row.usage_cost_details.length ?
              props.row.usage_cost_details.length : 'None' }}) View</el-button>
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="Setup Costs">
          <template v-slot="props">
            <el-button plain size="small" @click="handleShowCostDetails(props.row, 'setup')" type="primary">
              ({{ props.row.setup_cost_details.length ?
              props.row.setup_cost_details.length : 'None' }})
              View</el-button>
          </template>
        </el-table-column>
        <el-table-column :min-width="50" class-name="td-actions" label="">
          <template v-slot="props">
            <div v-if="props.row.pin_price_cost" type="success">
              <el-tooltip :enterable="false" placement="top-start">
                <template #content>
                  <span>Edit</span>
                </template>

                <el-button class="mb-1" v-tooltip="'Edit'" size="small" @click="handleEdit(props.row)"
                  v-if="$helpers.isVisible('PRODUCT_PRICE_COST', 'UPDATE')"><i class="fa fa-pencil"></i></el-button>
              </el-tooltip>
            </div>

            <div v-else>
              <el-tooltip :enterable="false" placement="top-start">
                <template #content>
                  <span>Edit</span>
                </template>

                <el-button class="mb-1" v-tooltip="'Edit'" size="small" @click="handleEdit(props.row)"
                  v-if="$helpers.isVisible('PRODUCT_PRICE_COST', 'UPDATE')"><i class="fa fa-pencil"></i></el-button>
              </el-tooltip>

              <!-- <el-tooltip :enterable="false" placement="top-start">
                <template #content>
                  <span>Delete</span>
                </template>

                <el-button class="mb-1" v-tooltip="'Delete'" size="small" @click="handleDelete(props.row)"
                  v-if="$helpers.isVisible('PRODUCT_PRICE_COST', 'DELETE')"><i class="fa fa-trash"></i></el-button>
              </el-tooltip> -->
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- <div style="text-align: center;">
        <el-tooltip :enterable="false" placement="top-start">
          <template #content>
            <span>Create</span>
          </template>

          <el-button class="mb-1" size="small" @click="handleNew()"
            v-if="$helpers.isVisible('PRODUCT_PRICE_COST', 'UPDATE')"><i class="fa fa-plus"></i></el-button>
        </el-tooltip>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ProcProdPriceCost } from '@/api_services/proc_prod_price_cost'
import ProcProdPriceCostForm from './ProcProdPriceCostForm.vue'
import ProcProdCostDetailsList from './ProcProdCostDetailsList.vue'

export default {
  components: { ProcProdPriceCostForm, ProcProdCostDetailsList },
  props: ['product_search_result'],
  data() {
    return {
      proc_prod_price_costs: [],
      list: [],
      loading: false,
      cost_type: null,
      price_cost: null,
      selected: null,
      showForm: false,
      showCostDetails: false
    }
  },
  created: function () {
    this.getPriceCosts()
  },
  methods: {
    handleEdit(cost_price) {
      this.selected = Object.assign({}, cost_price)
      this.showForm = true
    },
    handleDelete(cost_price) {
      this.loading = true

      ProcProdPriceCost.delete(cost_price.id).then(result => {
        this.$toast.success("Cost price removed")
        this.getPriceCosts()
      })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })
    },
    handleNew() {
      // this.selected = { product_id: this.product.id }
      // this.showForm = true
    },
    handleShowCostDetails(price_cost, cost_type) {
      this.price_cost = Object.assign({}, price_cost)
      this.cost_type = cost_type
      this.showCostDetails = true
    },
    getPriceCosts() {
      this.showForm = false
      this.loading = true
      this.showCostDetails = false
      this.proc_prod_price_costs = []
      this.cost_type = null
      ProcProdPriceCost.get({
        product_search_result_id: this.product_search_result.id,
        term: this.term,
        page: 1
      })
        .then(result => {
          if (result.data.proc_prod_price_costs)
            this.proc_prod_price_costs = result.data.proc_prod_price_costs
        })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>