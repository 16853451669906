import axios from 'axios'
import { LocalStorageService } from '@/services/LocalStorageService'
import store from '../store/index'
import api from "./axios";

const HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization' : null,
  "Access-Control-Allow-Methods": "*",
  "Access-Control-Allow-Origin": "*"
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  withCredentials: false,
  headers: HEADERS,
  timeout: 10000,

})


export default {
  getHeader() {
    return store.state.headers
  },
  getFormDataHeader() {
    return store.state.formDataHeaders
  },
  getHeaderForPdf() {
    return store.state.formDataHeaders
  },
  createFormData(url, params) {
    return api.post(url, params, {headers: this.getFormDataHeader()})
  },
  updateFormData(url, params) {
    return api.post(url, params, {headers: this.getFormDataHeader()})
  },
  create(url, params) {
    return api.post(url, params, {headers: this.getHeader()})
  },
  download(url, data) {
    if (!data) data = {}
    return api.get(url, {params: data.params, headers: this.getHeader(), responseType: 'blob'})
  },
  get(url, data) {
    if (!data) data = {}
    return api.get(url, {params: data.params, headers: this.getHeader()})
  },
  getBlob(url, data) {
    if (!data) data = {}
    return api.get(url, {params: data.params, headers: this.getHeader(),  responseType: 'blob'})
  },
  update(url, params) {
    return api.put(url, params, {headers: this.getHeader()})
  },
  delete(url, params) {
    return api.delete(url, {params: params, headers: this.getHeader()})
  },
  methods: {
    getDelete() {
      console.log('getDelete test')
    }
  }
}
