<template>
  <form v-if="reply" class="d-inline" @submit.prevent="onSubmit">
    <div class="modal-body">
      <div class="mb-4">
        <div v-if="reply.message" class="mb-2 text-secondary text-sm">
          <i>&ldquo;{{reply.message}}&rdquo;</i>
        </div>
        <div v-if="reply.file_name" class="text-xs">
          <span class="text-xxs"><i class="fa-solid fa-paperclip me-1"></i></span>{{ reply.file_name }}
        </div>
      </div>
      <div class="mb-3">
        <textarea rows="3" v-model="reasonForSharing" class="form-control" placeholder="Provide a reason for sharing..."></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="$emit('close_modal', true)" :disabled="isSending">Cancel</button>
      <button type="submit" class="btn btn-success" :disabled="isSending">{{isSending ? 'Sending...' : 'Send'}}</button>
    </div>
  </form>
</template>

<script>
  import { ProductSearchMessageApi } from '@/api_services/product_search_message'
  import Swal from 'sweetalert2'

  export default {
    props: {
      reply: Object,
      buyerName: String
    },
    data() {
      return {
        isSending: false,
        reasonForSharing: null
      }
    },
    watch: {
      reply() {
        this.reasonForSharing = null
      }
    },
    methods: {
      onSubmit() {
      
        if(!this.reasonForSharing || this.reasonForSharing == ""){
          Swal.fire({
            title: 'Warning!',
            text: "Please provide reason for sharing.",
            icon: 'warning',
            confirmButtonClass: 'btn btn-success',
            buttonsStyling: false
          })
        }
        else{
          this.isSending = true
          const params = { reason_for_sharing: this.reasonForSharing, buyer_name: this.buyerName }
          ProductSearchMessageApi.shareReply(this.reply.id, params)
            .then(result => {
              this.$toast.success('Sharing message to other suppliers...')
              this.$emit('update')
            })
            .catch(error => {
              this.$toast.error('An error occured while sharing supplier message')
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSending = false
            })
        }
      }
    }
  }
</script>
