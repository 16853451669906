<template>
  <el-form label-position="top" @submit.prevent="submit" v-loading="loading">

    <el-form-item label="Name *" prop="name" :error="v.form.name.$errors.length ? 'Name is required' : ''">
      <el-input v-model="form.name" :rows="2" type="textarea"></el-input>
    </el-form-item>

    <el-form-item label="Type *" prop="type" :error="v.form.type.$errors.length ? 'Type is required' : ''">
      <el-select v-model="form.type" @change="updateUnits">
        <el-option v-for="(type,key) in getTypes()" :key="key" :value="type.value" :label="type.text">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Unit *" prop="name" :error="v.form.unit.$errors.length ? 'Unit is required' : ''">
      <el-select v-model="form.unit">
        <el-option v-for="(item, key) in units" :key="key" :value="item.value" :label="item.text">
        </el-option>
      </el-select>
    </el-form-item>

    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Threshold *" prop="direction"
          :error="v.form.direction.$errors.length ? 'Direction is required' : ''">
          <el-select v-model="form.direction">
            <el-option value="=" label="Equal to"></el-option>
            <el-option value="<=" label="Less than or equal to" v-if="form.type != 'choice'"></el-option>
            <el-option value=">=" label="Greater than or equal to" v-if="form.type != 'choice'"></el-option>
          </el-select>
        </el-form-item>

      </div>
      <div class="col-md-6">
        <el-form-item label="-" prop="threshold_value">
          <el-input v-model="form.threshold_value" type="number" v-if="form.type != 'choice'"></el-input>

          <el-select v-model="form.threshold_value" v-else>
            <el-option :value="1" label="Yes"></el-option>
            <el-option :value="0" label="No"></el-option>
          </el-select>

        </el-form-item>
      </div>
    </div>

    <el-button native-type="submit" type="primary" plain>Save</el-button>
  </el-form>
</template>

<script>
import { SupplierhubSpec } from '@/api_services/supplierhub_spec'
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['savedUser']),
  },
  props: {
    spec: {
      type: Object,
      required: false
    },
    lot: {
      type: Object,
      required: true
    },
    if_pin_notice: {
      type: Number,
      required: false
    },
    pin_notice_id: {
      type: Number,
      required: false
    },
    is_deadline_passed: {
      type: Number,
      required: false
    },
    kind: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      form: {
        name: null,
        type: null,
        unit: null
      },
      units: [],
      loading: false,
    }
  },
  watch: {
    spec: function () {
      this.form = Object.assign({}, this.spec)
    },
    'form.name': function () {
      if (this.form.name == '<p><br></p>') this.form.name = null
    },
    'form.type': function () {
      this.checkFormType()
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        name: {
          required,
          $autoDirty: true
        },
        type: {
          required,
          $autoDirty: true
        },
        unit: {
          required,
          $autoDirty: true
        },
        direction: {
          required,
          $autoDirty: true
        },
      }
    }
  },
  created: function () {
    if (this.spec) this.form = Object.assign({}, this.spec)
  },
  methods: {
    checkFormType() {
      this.updateUnits()
      if (this.form.type == 'choice') {
        if (!this.form.id) {
          this.form.threshold_value = 1
          this.form.direction = '='
          this.form.unit = 'yes/no'
        }
      } else {
        if (!this.form.id) {
          this.form.unit = null
          this.form.threshold_value = 0
          if (this.units.length == 1) {
            this.form.unit = this.units[0].value
          }
        }
      }
    },
    getTypes() {
      return this.kind ? this.$store.state.SocValTypes : this.$store.state.SpecTypes
    },
    updateUnits() {
      this.units = []
      let type = this.form.type

      if (this.kind) {
        if (type && this.$store.state.SocValTypes[type]) {
          this.units = this.$store.state.SocValTypes[type].units
        }
      } else {
        if (type && this.$store.state.SpecTypes[type]) {
          this.units = this.$store.state.SpecTypes[type].units
        }
      }
     
  
    },
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.loading = true

      if (this.if_pin_notice == 1) {
        if (!this.is_deadline_passed) {
          if (this.savedUser.is_supplier_hub_admin || this.$store.state.isUserABuyer()) {
            this.form.status = 1
          }
          else {
            this.form.status = 0
          }
        }
        else {
          if (this.$store.state.isUserAnOriginator() || this.savedUser.is_supplier_hub_admin || this.$store.state.isUserABuyer()) {
            this.form.status = 1
          }
        }
        this.form.if_pin_notice = this.if_pin_notice
      }

      this.form.supplierhub_lot_id = this.lot.id
      this.form.kind = this.kind

      let request = this.form.id
        ? SupplierhubSpec.update(this.form)
        : SupplierhubSpec.create(this.form)

      request
        .then(result => {
          this.$toast.success( (this.kind ? 'Social value':'Specification') + ' has been submitted')
          this.$emit('close', true)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style></style>
