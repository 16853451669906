<template>
  <div>
    <h2>Manage Product RFQ</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-12">
        <el-card header="RFQ" class="my-3 border-0">

          <b-nav-form style="float:right">
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search" v-model="term"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="button" @click="search()">Search</b-button>
            <b-button size="sm"   class="mr-2" style="margin-left: 5px" :href="'list/new/view'">Add</b-button>
          </b-nav-form>

            <b-table :items="buyer_product_rfqs" :fields="fields" striped responsive="sm">
              <template #cell(buyer)="row" >
                <div>{{ row.item.buyer.name }} </div>
              </template>
              <template #cell(email)="row" >
                <div>{{ row.item.buyer.email }} </div>
              </template>

              <template #cell(question)="row" >
                <div>{{ row.item.question }} </div>
              </template>

              <template #cell(reply)="row" >
                <div>{{ row.item.reply }} </div>
              </template>

              <template #cell(action)="row">
                <b-button style="margin-right: 10px" @click="openModal(row.item)">View</b-button>
                <!-- <b-button :href="'mailto: '+row.item.buyer.email" target="_blank" style="margin-right: 10px">View</b-button> -->
                <!-- <b-button size="sm" @click="showRemove(row.item)" class="mr-2" ref="showRemove">Delete</b-button> -->
              </template>

            </b-table>

            <pagination :meta="meta" v-on:update="getProductRfqs()"/>
        </el-card>
      </div>
 
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      id="modal-view"
      v-model="view_modal"
      :title="selected ? 'Viewing product '+selected.product_name : ''"
      width="70%"
    >
      <div v-if="selected">
        <p>Buyer: {{ selected.buyer.name }}</p>
        <p>Email: {{ selected.buyer.email }} <a :href="'mailto: '+selected.buyer.email" target="_blank">(Send an email)</a></p>

        <b-button target="_blank" :href="'/search-solution/'+selected.product_id" variant="primary" size="sm" style="margin-right: 10px">View product details</b-button>

        <hr>
        <div v-for="question_answer in selected.question_answers" :key="question_answer.id">
           <div><strong>Question: {{ question_answer.question }}</strong></div>
           <div style="padding-left: 10px">Answer: {{ question_answer.answer }}</div>
           <hr>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="hideModal">Close</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>


import { BuyerProductRfq } from '@/api_services/buyer_product_rfq'


export default {
  data() {
    return {
      view_modal: false,
      dialogVisible: false,
      buyer_product_rfqs: [],
      selected: null,
      fields: ['id', 'product_name' ,'buyer','date_submitted', 'action'],
      term: '',
      to_remove: null,
      meta: {
        page: 1, total: 0, pages: 1
      },
    }
  },
  created: function() {
    this.getProductRfqs()
  },
  methods: {
    openModal(object) {
        this.selected = Object.assign({}, object);
        this.view_modal = true
    },
    hideModal() {
      this.view_modal = false
    },
    search() {
      this.meta.page = 1
      this.getProductRfqs()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      // Product.delete(this.to_remove)
      //   .then(result => {
      //     this.$toast.success('Removed successfully')
      //     this.$nextTick(() => {
      //       this.dialogVisible = false
      //     })
      //     this.getProducts()
      //   })
      //   .catch(error => {
      //     this.$toast.error('An error occured while removing a user')
      //     console.error('error:', error.response.data)
      //   })
    },
    // selectedName(selected) {
    //   return selected.id ? 'Edit '+selected.name: 'New'
    // },
    showRemove(type) {
        // this.to_remove = Object.assign({}, type);
        // this.dialogVisible = true
    },
    showEdit(type) {
      // this.selected = Object.assign({}, type);
      // this.original = Object.assign({}, type);

      // this.selected_categories = []
      // var self = this
      // this.selected.categories.forEach(function(category){
      //   self.selected_categories.push(category.name)
      // })

    },
    getProductRfqs() {
      // this.reset()
      BuyerProductRfq.get({params: {term: this.term, page: this.meta.page}})
        .then(result => {
          if(result.data.product_rfqs)
            this.buyer_product_rfqs = result.data.product_rfqs
            this.meta = result.data.meta
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching product rfqs')
          console.error('error:', error.response.data)
        })
    },
  }
}
</script>