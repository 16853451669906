import api from './api'

const API = '/api/notifications'

export const Notification = {
  get: function(params) {
    return api.get(API, params)
  },
  latestNotification: function(params) {
    return api.get(API+'/latest-notification', params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id)
  }
}
