<template>
 <div>
 </div>
</template>

<script>
import Swal from 'sweetalert2';
import {
  watch, 
  inject
} from 'vue';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      swalLoadingScreen: null,
    }
  },
  methods: {
    showLoadingScreen() {
      this.swalLoadingScreen = Swal.fire({
        title: props.message || 'Retrieving data',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        html: `
            <div class="spinner-border text-info" role="status"></div>
            <div class="spinner-border text-success" role="status"></div>
            <div class="spinner-border text-secondary" role="status"></div>
            <div>` + (props.content || 'Please wait while we are processing your request...') + `</div>`,
      });
    },
    hideLoadingScreen() {
      if (this.swalLoadingScreen) {
        this.swalLoadingScreen.close();
        this.swalLoadingScreen = null;
        Swal.close()
      }

    }
  },
  setup(props) {
    const globalState = inject('globalState');

    const showLoading = () => {
      Swal.fire({
        title: props.message || 'Retrieving data',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        html: `
            <div class="spinner-border text-info" role="status"></div>
            <div class="spinner-border text-success" role="status"></div>
            <div class="spinner-border text-secondary" role="status"></div>
            <div>` + (props.content || 'Please wait while we are processing your request...') + `</div>`,
      });
    }
    
    watch(() => props.loading, (newValue, oldValue) => {
       console.log("Loading triggered")
      newValue ? showLoading() : Swal.close()
    });

    return {
      globalState
    };
  },
}
</script>

<style scoped>
.loading-bg {
  position: fixed;
  left: 0;
  top: 0;
  background: #1d1c1c;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: .5;
}
</style>
