<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for="tags-component-select"
          :invalid-feedback="invalidFeedback('supplier')"
          :state="validation('supplier')">Current Supplier</label>
          <select name="" id="" :state="validation('supplier')"
            v-model="selected_supplier" class="form-select form-select-sm">
            <option v-for="current_supplier in current_suppliers_selection" :key="current_supplier" :value="current_supplier">{{ current_supplier }}</option>
          </select>
        </div>  
      </div>
      <div class="col-md-2" style="padding-left: 5px">
        <!-- <a @click="$emit('remove')" v-if="index != 0">
          <i class="fa-solid fa-trash-can text-danger"></i>
        </a> -->
        <button class="btn bg-gradient-danger btn-sm" size="sm" @click="$emit('remove')" v-if="index != 0" >Remove</button>
      </div>       
    </div>
    <div class="card-body px-0 pb-2">
      <b-tabs content-class="mt-3">
        <b-tab title="Regular Transactions" active>
          <merchant-transactions-component v-model:items="transactions" :category="0" @update="transactions = $event;"></merchant-transactions-component>
        </b-tab>
        <b-tab title="Premium Transactions">
          <merchant-transactions-component v-model:items="transactions" :category="1" @update="transactions = $event">
          </merchant-transactions-component>
        </b-tab>
        <b-tab title="Account Fees">
          <merchant-transactions-component v-model:items="transactions" :category="2" @update="transactions = $event">
          </merchant-transactions-component>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { Supplier } from '@/api_services/suppliers'
import { MerchantTransaction } from '@/api_services/merchant_transaction'
import MerchantTransactionsComponent from './MerchantTransactionsComponent.vue'

export default {
  components: { MerchantTransactionsComponent },
  props: ['index', 'transactions_by_supplier', 'current_suppliers_selection'],
  data() {
    return {
      loading: false,
      code: null,
      selected_supplier: this.supplier ? this.supplier : null,
      transactions: [],
      transaction_count: 0,
      suppliers: [],
      validate: ['supplier'],
      has_error: false
    }
  },

  created: function() {
    if (this.transactions_by_supplier) {
       this.selected_supplier = this.transactions_by_supplier.supplier
        this.transactions = this.transactions_by_supplier.transactions
    }
   
  },
  watch:  {
    transactions: {
        handler: function(newValue) {
            this.$emit('update', {supplier: this.selected_supplier, transactions: this.transactions})
        },
        deep: true
    },
    selected_supplier() {
      this.$emit('update', {supplier: this.selected_supplier, transactions: this.transactions})
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      if (!this.transactions.length) response = false;
      this.transactions.forEach(function(transaction){
        if (!transaction.terminology) {
          response = false;
        }
      })

      return response;
    }
  },
  methods: {
    validation(field) {
      switch(field) {
        case 'supplier':
          return this.selected_supplier != null
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'supplier') return "Supplier is required"
      if (!this.validation(field) && field == 'annual_value') { return "Confirmed Annual Value is required" }
      return ""
    },
    getSuppliers() {
      var self = this
      this.suppliers = []
      Supplier.get({params: {category: 2}}).then(result => {
          if (result.data.suppliers) {
            this.suppliers = result.data.suppliers
          }
        })
        .catch(error => {
          console.error('error:', error)
          this.$toast.error('An error occured while fetching suppliers')
      })
    }
  }
}
</script>
