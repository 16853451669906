<template>
    <div class="text-wrap container">
        <h4>Welcome to the Invitation to tender archive for the Financial Services and Payments Framework.</h4>
        <p></p>
        <p>We (CPRAS) are managing this Framework for the numerous UK Councils who are currently seeking to review the provision of their financial services and payment processing requirements. To this end, suppliers of the following services are invited to respond to the Tender Invitation (ITT):</p>
        <p></p>
        <ul>
            <li>Income Management Systems</li>
            <li>Banking</li>
            <li>Merchant Services</li>
            <li>Call Masking & IVR</li>
            <li>Open Banking Payments</li>
            <li>Open Banking Data Services</li>
            <li>Procurement Card Services</li>
        </ul>

        <p>This is the platform through which the ITT will be managed. Here you will find all the key documents and information that you need to respond to the ITT. The submission deadline has passed, these will be evaluated and successful bidders will be notified of their place on the Framework. Bidders who fail to satisfy the exclusion criteria will similarly be notified of this result.
        </p>

        <p>This unique and innovative Framework has already gained interest from c. 70 additional UK Councils to our six Contracting Authorities:</p>
        <ul>
            <li>Newark and Sherwood District Council</li>
            <li>Gedling Borough Council</li>
            <li>Rushcliffe Borough Council</li>
            <li>Broxtowe Borough Council</li>
            <li>Adur and Worthing Councils</li>
            <li>Arun District Council</li>
        </ul>
        <p>The key requirements are that the SQ response form and the Supplier Response (excel file) should be completed in full and submitted through this platform. Note: The Excel form has 2 tabs per Lot which require completion as well as the red-coloured tabs which should also be completed for each Bid.</p>
        <p></p>
        <p>Instructions are posted throughout the platform, however, if you have any questions or technical difficulties, there is a Q&A function and a support email address which will be closely monitored through the ITT process.</p>
        <p>Thank you for engaging with the Framework and we look forward to working with you on this exciting opportunity</p>
    </div>
</template>

<script>

export default {
    name: 'SupplierHubWelcome'
}
</script>