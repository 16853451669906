
<template>
    <div class="card">
        <div class="card-body">
            <div class="ms-0 ps-0 form-check">
                <label class="me-3">{{ label }}</label>
                <el-switch
                    @change="triggerChange"
                    size="large"
                    v-model="toggled"
                    inline-prompt
                    :active-icon="check_icon"
                    :inactive-icon="close_icon"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: gray"
                />
            </div>
        </div>
    </div>
    
  </template>
  
  <script>
  import { Check, Close } from '@element-plus/icons-vue'
  import { shallowRef } from 'vue'

  export default {
    props: ['value', 'label'],
    data() {
      return {
        toggled: false,
        check_icon: shallowRef(Check),
        close_icon: shallowRef(Close)
      }
    },
    created(){
        this.toggled = !!parseInt(this.value)
    },
    methods: {
        triggerChange(value){
            this.$emit("toggle", value)
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.toggled = !!parseInt(newValue)
            },
            deep: true
        }
    }
  }
  </script>