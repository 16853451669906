<template>
  <div>
    <div class="pb-0 card-header">
      <div class="d-sm-flex">
        <div>
          <h5 class="mb-0">Specifications/Requirements</h5>
          <p class="mb-0 text-sm"></p>
        </div>
        <div class="my-auto mt-4 ms-auto mt-sm-0">
          <div class="my-auto ms-auto">

          </div>
        </div>
      </div>
    </div>

    <el-table class="table-striped" header-row-class-name="text-primary" :data="proc_prod_specifications"
      v-loading="loading" style="width: 100%">
      <el-table-column :min-width="150" class-name="td-actions" label="Description">
        <template v-slot="props">
          {{ props.row.product_specification.specification.original_description }} <br>
          <p v-if="props.row.notes" style="font-size: 12px;margin-top: 10px" class="notes-text">
            Notes: {{
            props.row.notes
            }}</p>
        </template>
      </el-table-column>
      <el-table-column :min-width="50" class-name="td-actions" label="Response">
        <template v-slot="props">

          <div v-if="props.row.product_specification.supplier_lot_spec_value">
            <el-tag v-if="props.row.product_specification.supplier_lot_spec_value.spec.type == 'choice'"
              :type="props.row.response == 'yes' || props.row.response == 1 ? 'success' : 'warning'">
              {{ props.row.response == 1 || props.row.response == 0 ? (props.row.response == 1 ? 'YES' : 'NO') :
              props.row.response
              }}

            </el-tag>
            <el-tag v-else type="success">
              {{ props.row.response }} {{
              $store.state.getSpecTypeUnit(props.row.product_specification.supplier_lot_spec_value.spec.type,
              props.row.product_specification.supplier_lot_spec_value.spec.unit) }}
            </el-tag>
          </div>
          <el-tag type="success" v-else>
            {{ props.row.response }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column :min-width="30" class-name="td-actions" label="Action"
        v-if="$store.state.isAllowed('PRODUCT_SPECIFICATION', 'UPDATE')">
        <template v-slot="props">
          <el-tooltip :enterable="false" placement="top-start">
            <template #content>
              <span>Edit Response</span>
            </template>
            <el-button class="mb-1" type="primary" plain size="small" @click="handleEdit(props.row)">
              <i class="fa fa-edit"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <br>

    <pagination :meta="meta" v-on:update="getSpecifications()" />

    <el-dialog title="Specification" v-model="showForm" width="30%" :before-close="handleClose" :append-to-body="true">
      <div>
        <proc-prod-specification-form :object="selected" v-if="showForm"
          @close="getSpecifications($event)"></proc-prod-specification-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { ProcProdSpecification } from '@/api_services/proc_prod_specification'
import ProcProdSpecificationForm from './ProcProdSpecificationForm.vue'

export default {
  props: ['product_search_result'],
  components: {ProcProdSpecificationForm },
  data() {
    return {
      proc_prod_specifications: [],
      loading: false,
      showForm: false,
      selected: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  watch: {
    product_search_result: {
      deep: true,
      handler() {
        this.getSpecifications()
      }
    }
  },
  created: function () {
    this.getSpecifications()
  },
  methods: {
    handleEdit(spec) {
      this.showForm = true
      this.selected = Object.assign({}, spec)
    },
    handleClose() {
      this.showForm = false
      this.selected = null
    },
    getSpecifications() {
      this.loading = true
      this.proc_prod_specifications = []
      this.handleClose()

      ProcProdSpecification.get({
        params: {
          product_search_result_id: this.product_search_result.id,
          term: this.term,
          page: this.meta.page
        }
      })
        .then(result => {
          if (result.data.proc_prod_specifications)
            this.proc_prod_specifications = result.data.proc_prod_specifications
          this.meta = result.data.meta
        })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
