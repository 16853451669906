<template>
    <div class="card my-3">
          <div class="card-header"><h5>{{ selectedName(selected) }}</h5></div>
          <div class="card-body">
            <form @submit.prevent="onSubmit" @reset="onCancel">

              <div class="form-group">
                <label label-for="input-name" >Term</label>
                <input type="text" id="input-name" v-model="selected.term" 
                :class="{ 'is-invalid': v$.selected.term.$errors.length, 'form-control form-control-sm': true }">

                <div class="input-errors" v-for="error of v$.selected.term.$errors" :key="error.$uid">
                  <div class="error-text">Term is required!</div>
                </div>
              </div>

              <div class="form-group">
                <label label-for="card-description" >Description</label>
                <input type="text" id="card-description" v-model="selected.description" 
                :class="{ 'is-invalid': v$.selected.description.$errors.length, 'form-control form-control-sm': true }">

                <div class="input-errors" v-for="error of v$.selected.description.$errors" :key="error.$uid">
                  <div class="error-text">Description is required!</div>
                </div>
              </div>

              <button type="submit" class="btn bg-gradient-success btn-sm">Save</button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">Cancel</button>
            </form>
          </div>
        </div>
</template>
    
<script>

import { CardTypeTerm } from '@/api_services/card_type_term'

import { useVuelidate } from '@vuelidate/core'
import {  required } from '@vuelidate/validators'

export default {
    setup () {
        return { v$: useVuelidate() }
    },
    props: {
        card_type_terms_data: {
            type: Object,
            required: false
        },
        original: {
            type: String,
            required: false
        },
    },
    data() {
        return{
            selected:{
                term: null,
                id: null,
                description: null
            }
        }
    },
    validations() {
        return {
        selected: {
            term: {
            required,
            $autoDirty: true
            },
            description: {
            required,
            $autoDirty: true
            }
        },
        }
    },
    watch: {
        card_type_terms_data: function () {
            this.selected = Object.assign({}, this.card_type_terms_data)
        },
    },
    created: function () {
        if (this.card_type_terms_data) this.selected = Object.assign({}, this.card_type_terms_data)
    },
    methods: {
        async onSubmit(evt) {
            evt.preventDefault()

            const result = await this.v$.$validate()
            if (!result) return

            var action = this.selected.id ? CardTypeTerm.update(this.selected.id, this.selected) : CardTypeTerm.create(this.selected)
            action.then(result => {
                this.$toast.success('Saved successfully')
                this.$emit('close_form', "save")
            })
            .catch(error => {
                this.$toast.error('An error occured while saving a card type term')
                console.error('error:', error.response.data)
            })
        },
        selectedName(selected) {
            return selected.id ? 'Edit ' + selected.name : 'New'
        },
        reset() {
            this.selected = null
        },
        onCancel(evt) {
            evt.preventDefault()
            this.$emit('close_form', "close")
        },
    }
}
</script>
    
<style></style>
    