
<template>
   <div style="text-align: center">
    <el-transfer
        ref="el_transfer"
        filterable
        v-model="transfer"
        :filter-placeholder="filter_placeholder"
        :data="data"
        :filter-method="filterMethod"
        :titles="[left_title, right_title]"
        :button-texts="[left_middle_button_text, right_middle_button_text]"
        @change="handleChange"
    >

    <template #default="{ option }">
        <el-tooltip
            :enterable="false"
            v-if="option_format == 'jaas_invite'"
            class="box-item"
            effect="dark"
            :content="
                'Email: <b>'+ option.email +'</b>'
            "
            placement="left"
            raw-content
        >
        <span>{{ option.label }}</span>

        </el-tooltip>
        <span v-else>{{ option.label }}</span>
    </template>

    <template class="p-3" v-if="right_button_text" #right-footer>
        <div class="row mt-1 ms-1">
            <div v-if="show_export_type" class="col-md-9 p-0 m-0">
                <el-select class="float-end" v-model="export_type" placeholder="Select Export Type" >
                    <el-option value="excel" label="Export as Excel" />
                    <el-option value="csv" label="Export as CSV" />
                </el-select>
            </div>
            <div :class="show_export_type ? 'col-md-3' : 'col-md-12'">
                <el-button @click="handleClick" :disabled="selected_data.length == 0" class="transfer-footer" type="success">{{ right_button_text }}</el-button>
            </div>
        </div>
    </template></el-transfer>
   </div>
    
</template>

  <script>
  export default {
    props: {
        transfer_data: {
            required: true,
        },
        filter_placeholder: {
            required: true,
        },
        left_title: {
            required: true,
        },
        right_title: {
            required: true,
        },
        right_button_text: {
            required: false,
        },
        model_data: {
            required: false,
            default: [0]
        },
        left_middle_button_text: {
            required: false,
            default: "Remove"
        },
        right_middle_button_text: {
            required: false,
            default: "Select"
        },
        option_format: {
            required: false,
            default: "normal_list"
        },
        show_export_type: {
            required: false,
            default: false
        },
    },
    data() {
        const generateData = _ => {
            const data = [];
            this.transfer_data.forEach(function(row){
                data.push({
                    key: row.id,
                    label: row.name,
                    disabled: row?.disabled,
                    company_name: row?.company_name,
                    email: row?.email
                });
            });
            return data;
        }
        return {
            export_type: "excel",
            selected_data: [],
            data: generateData(),
            transfer: this.model_data
        }
    },
    watch: {
        transfer_data: {
            handler(newValue) {
                let self = this
                this.data = []
                this.transfer_data.forEach(function(row){
                    self.data.push({
                        key: row.id,
                        label: row.name,
                        disabled: row?.disabled,
                        company_name: row?.company_name,
                        email: row?.email
                    })
                })
            },
            deep: true
        },
        model_data: {
            handler(newValue) {
                this.transfer = this.model_data
            },
            deep: true
        },
    },
    methods: {
        filterMethod(query, item){
            return item.label.toLowerCase().includes(query.toLowerCase())
        },
        handleChange(value, direction, movedKeys) {
            this.selected_data = value
            this.$emit("update_value", value)
        },
        handleClick(){
            this.$emit("handle_click", this.export_type)
        }
    }
  }
  </script>

<style>
.el-transfer-panel{
    width: 35% !important;
    height: 100% !important;
}

.el-transfer-panel__item, .el-transfer-panel__header{
    margin-left: 0px !important;
}
.transfer-footer{
    float: right !important;
    margin-right:3px !important
}
</style>