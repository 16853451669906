<template>
  <div class="mt-3 px-4">
    <div class="card-group">
      <BankingCard />
      <MerchantServiceCard />
      <DirectDebitCard />
    </div>
    <div class="card-group mt-4">
      <PaymentGatewayCard />
      <IVRCallMaskingCard />
      <InnovationFSCard />
    </div>
  </div>
</template>

<script>
  import BankingCard from '@/components/card/BankingCard.vue'
  import MerchantServiceCard from '@/components/card/MerchantServiceCard.vue'
  import DirectDebitCard from '@/components/card/DirectDebitCard.vue'
  import PaymentGatewayCard from '@/components/card/PaymentGatewayCard.vue'
  import IVRCallMaskingCard from '@/components/card/IVRCallMaskingCard.vue'
  import InnovationFSCard from '@/components/card/InnovationFSCard.vue'

  export default {
    components: {
      BankingCard,
      MerchantServiceCard,
      DirectDebitCard,
      PaymentGatewayCard,
      IVRCallMaskingCard,
      InnovationFSCard,
    }
  }
</script>

<style scoped>
  ::v-deep .stretched-link {
    font-size: 6rem;
  }
</style>
