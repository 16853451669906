<template>
  <div>
    <!-- <el-dialog
    title="FACA details"
    :visible.sync="show_generate_faca"
    width="30%"
    :before-close="handleClose">
    <div>
     
    </div>
  </el-dialog> -->
 


    <div v-if="savedUser && savedUser.company.faca_status != 1">
      <el-alert
      class="mb-3"
        :type="savedUser.is_faca_mandatory || mandatory ? 'error' : 'warning'"
      >
        <h5>FRAMEWORK ACCESS AGREEMENT</h5>

        <div v-if="savedUser.company.faca_status == 2">
          OUR FRAMEWORK MANAGERS DECIDED NOT TO APPROVE THE FRAMEWORK ACCESS
          AGREEMENT THAT YOU HAVE UPLOADED. <br />
          <div>
            REASON: <strong>{{ savedUser.company.faca_remarks }}</strong>
          </div>
        </div>

        <div v-if="savedUser && savedUser.company.faca_status == 0">
          {{ message }}
        </div>
      </el-alert>

      <el-card v-if="savedUser.company.faca_status != 0">
        <div class="row mt-3">
          <div class="col-md-12" style="font-size: 12px;">
            <div v-if="[2, 3].includes(savedUser.company.faca_status)">
              <p>
                Please find below the Framework Access Agreement for your review
                and signature.
              </p>
              <p>
                If you have any questions, please do not hesitate to contact our
                dedicated support team at
                <a href="mailto:purchasers.support@cpras.co."
                  >purchasers.support@cpras.co.uk</a
                >.
              </p>
              <!-- <div>
                <el-link
                  type="primary"
                  target="_blank"
                  :href="savedUser.company.faca_template"
                  download
                >
                  <i class="fas fa-file"></i> FRAMEWORK ACCESS AGREEMENT
                  PDF</el-link
                >
              </div> -->
              <br>
              <el-button @click="onShowGenerateFaca()" type="primary">DOWNLOAD THE FRAMEWORK ACCESS AGREEMENT PDF</el-button>
              <hr />
            </div>

            <div class="row">
              <div class="col-md-5">
                <argon-input
                  type="upload"
                  @input="getFiles($event)"
                  :value="file"
                ></argon-input>
                <el-button
                  @click="upload()"
                  type="primary"
                  :disabled="!file || loading"
                  >{{ loading ? 'Uploading' : 'Upload' }}</el-button
                >
              </div>
              <div class="col-md-7" v-if="show_generate_faca">
                <generate-faca @download="show_generate_faca = false"></generate-faca>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import ArgonInput from '../../common/Input/ArgonInput.vue'
import { mapState } from 'vuex'
import { User } from '@/api_services/user'
import Swal from 'sweetalert2'
import GenerateFaca from '../Faca/GenerateFaca.vue'

export default {
  components: { ArgonInput, GenerateFaca },
  props: ['mandatory'],
  data() {
    return {
      file: null,
      loading: false,
      show_generate_faca: false,
      message:
        'We have received your Framework Access Document. Our Framework Managers will let you know once the review is completed. Thank you!'
    }
  },
  methods: {
    onShowGenerateFaca() {
      this.show_generate_faca = true
    },
    handleClose() {
      this.show_generate_faca = false
    },
    getFiles(files) {
      if (files.length) this.file = files[0].raw
    },
    upload() {
      let formData = new FormData()
      formData.append('file', this.file)

      this.loading = true
      User.uploadFaca(formData)
        .then(result => {
          this.$toast.success('Uploaded successfully')
          this.$store.commit('saveUser', result.data.user)
          this.$emit('uploaded', true)
          this.success()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: 'Success!',
        text: this.message,
        icon: 'success',
        confirmButtonClass: 'btn btn-success',
        buttonsStyling: false
      })
    }
  },
  computed: mapState(['savedUser'])
}
</script>
