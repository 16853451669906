<template>
  <div>
    <div class="pb-0 card-header">
      <div class="d-sm-flex">
        <div>
          <h5 class="mb-0">Social Value Components</h5>
          <p class="mb-0 text-sm"></p>
        </div>

      </div>
    </div>

    <el-table class="table-striped" header-row-class-name="text-primary" :data="proc_prod_social_values"
      v-loading="loading" style="width: 100%">
      <el-table-column :min-width="150" class-name="td-actions" label="Description">
        <template v-slot="props">
          {{ props.row.product_social_value.social_value.original_description }}
          <p v-if="props.row.notes" style="font-size: 12px;margin-top: 10px;" class="notes-text">Notes: {{
            props.row.notes
            }}</p>
        </template>
      </el-table-column>
      <el-table-column :min-width="50" class-name="td-actions" label="Response">
        <template v-slot="props">
          <div v-if="props.row.product_social_value.supplier_lot_spec_value">
            <el-tag v-if="props.row.product_social_value.supplier_lot_spec_value.spec.type == 'choice'"
              :type="props.row.value ? 'success' : 'warning'">
              {{ props.row.value ? 'Yes' : 'No' }}
            </el-tag>
            <el-tag v-else type="success">
              {{ props.row.value }} {{
              $store.state.getSocValTypeUnit(props.row.product_social_value.supplier_lot_spec_value.spec.type,
              props.row.product_social_value.supplier_lot_spec_value.spec.unit) }}
            </el-tag>

            <span class="modified">{{ props.row.is_response_modified ? '(Modified)' : '' }}</span>

          </div>
          <el-tag type="success" v-else>
            {{ props.row.value }}
          </el-tag>

        </template>
      </el-table-column>

      <el-table-column :min-width="30" class-name="td-actions" label="Action"
        v-if="$store.state.isAllowed('PRODUCT_SOCIAL_VALUE', 'UPDATE')">
        <template v-slot="props">
          <el-tooltip :enterable="false" placement="top-start">
            <template #content>
              <span>Edit Response</span>
            </template>
            <el-button class="mb-1" type="primary" plain size="small" @click="handleEdit(props.row)">
              <i class="fa fa-edit"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>

    </el-table>
    <br>

    <pagination :meta="meta" @update="getSocialValues()" />

    <el-dialog title="Social Values" v-model="showForm" width="30%" :before-close="handleClose">
      <div>
        <proc-prod-social-value-form :object="selected" v-if="showForm"
          @close="getSocialValues($event)"></proc-prod-social-value-form>

      </div>
    </el-dialog>

  </div>
</template>

<script>
import { ProcProdSocialValue } from '@/api_services/proc_prod_social_value'
import ProcProdSocialValueForm from './ProcProdSocialValueForm.vue'

export default {
  components: { ProcProdSocialValueForm },
  props: ['product_search_result'],
  data() {
    return {
      proc_prod_social_values: [],
      loading: false,
      selected: null,
      showForm: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  created: function () {
    this.getSocialValues()
  },
  methods: {
    handleClose() {
      this.showForm = false
      this.selected = null
    },
    handleEdit(spec) {
      this.showForm = true
      this.selected = Object.assign({}, spec)
    },
    getSocialValues() {
      this.loading = true
      this.proc_prod_social_values = []
      this.handleClose()
      ProcProdSocialValue.get({
        product_search_result_id: this.product_search_result.id,
        term: this.term,
        page: this.meta.page
      })
        .then(result => {
          if (result.data.proc_prod_social_values)
            this.proc_prod_social_values = result.data.proc_prod_social_values
          this.meta = result.data.meta
        })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
