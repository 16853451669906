<template>
  <div>
    <el-drawer title="" v-model="showLot" direction="rtl" :destroy-on-close="true" :append-to-body="true" size="90%"
      :before-close="getLots">
      <supplierhub-lot-page :lot="selected" v-if="selected" :pin_notice_id="pin_notice_id"
        :itt_framework="itt_framework" :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice" :pin_notice="pin_notice"
        :allowCreate="false" :joined="!checkIfNotJoined() "></supplierhub-lot-page>
    </el-drawer>

    <el-drawer title="" v-model="showView" direction="rtl" size="70%" :destroy-on-close="true" :append-to-body="true">
      <pin-lot-specs-list :kind="kind" :lot="selected" v-if="selected" :action="lot_action"></pin-lot-specs-list>
    </el-drawer>

    <el-drawer title="" v-model="showScore" direction="rtl" size="70%" :destroy-on-close="true" :append-to-body="true">
      <pin-lot-supplier-specs-score-list :kind="kind" :lot="selected"
        v-if="selected"></pin-lot-supplier-specs-score-list>
    </el-drawer>

    <el-drawer title="" v-model="showSpecValues" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true">
      <pin-lot-supplier-specs-list :kind="kind" :lot="selected" v-if="selected"></pin-lot-supplier-specs-list>
    </el-drawer>

    <el-drawer title="" v-model="showPricingSetting" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true" :before-close="handleClose">
      <pin-pricing-setting-form :pin_notice="pin_notice" :lot="selected" :pricing="selected.pin_pricing_setting"
        v-if="selected" @close="handleClose"></pin-pricing-setting-form>
    </el-drawer>

    <el-drawer v-model="showPricingCosts" :showClose="true" size="70%" :with-header="true" direction="rtl"
      :destroy-on-close="true" :append-to-body="true">
      <div class="container">
        <pin-supplier-costs-list :lot="selected" :pin_notice="pin_notice"></pin-supplier-costs-list>
      </div>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="pin_notice">
      <div class="col-md-12">
        <div class="my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>Framework Lots</h4>
              <div v-html="pin_notice.spec_description"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <el-button plain type="primary" @click="getLots()" size="small"
                  style="margin-left: 10px;float: right;">Refresh</el-button>
              </div>
            </div>

            <div class="table-responsive" style="max-height: 500px;" v-loading="loading">
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-sm font-weight-bolder">
                      Name
                    </th>
                    <th class="text-uppercase text-secondary text-sm font-weight-bolder" style="width: 200px">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!lots.length && !loading">
                    <td colspan="2" class="small-note">No data found</td>
                  </tr>
                  <tr v-for="lot in lots" :key="lot.id">
                    <td class="text-sm">
                      {{ lot.name }}
                    </td>
                    <td>

                      <el-tooltip :enterable="false" placement="top-start">
                        <template #content>
                          <span>View Detals</span>
                        </template>
                        <el-button size="small" type="primary" @click="handleShow(lot)">View Details</el-button>

                      </el-tooltip>

                    </td>
                  </tr>
                  <tr>
                    <td v-if="loading">Loading....</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getLots()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SupplierhubLot
} from '@/api_services/supplierhub_lot'
import {
  mapState
} from 'vuex'

import Swal from 'sweetalert2'
import PinLotSpecsList from './PinLotSpecsList.vue'
import PinLotSupplierSpecsList from './PinLotSupplierSpecsList.vue'
import PinLotSupplierSpecsScoreList from './PinLotSupplierSpecsScoreList.vue'
import PinPricingSettingForm from './PinPricingSettingForm.vue'
import PinSupplierCostsList from './PinSupplierCostsList.vue'
import SupplierhubLotPage from '../SuppliersHub/SupplierhubLots/SupplierhubLotPage.vue'

export default {
  components: { PinLotSpecsList, PinLotSupplierSpecsList, PinLotSupplierSpecsScoreList, PinPricingSettingForm, PinSupplierCostsList, SupplierhubLotPage },
  props: {
    lot_action: {
      type: String,
      required: false,
      default: ''
    },
    pin_notice: {
      type: Object,
      required: false,
      default: null
    },
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
  },
  data() {
    return {
      itt_framework_id: null,
      pin_notice_id: null,
      showAdd: false,
      showImport: false,
      lots: [],
      term: null,
      status: 1,
      loadung: false,
      selected: null,
      showView: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      kind: 0,
      showSpecValues: false,
      showScore: false,
      showPricingSetting: false,
      showPricingCosts: false,
      showLot: false
    }
  },
  watch: {
    status: function () {
      this.getLots()
    }
  },
  computed: {
    ...mapState(['savedUser']),
    this_user() {
      return this.$store.state.savedUser
    }
  },
  created: function () {
    if (this.itt_framework) this.itt_framework_id = this.itt_framework.id
    this.getLots()
  },
  methods: {
    checkIfNotJoined() {
      if (
        this.itt_framework &&
        !this.itt_framework.if_user_joined &&
        this.itt_framework?.user?.id != this.this_user.id &&
        this.this_user?.company?.id != this.itt_framework?.company?.id
      ) {
        return true
      }
      return false
    },
    handleShow(lot) {
      this.selected = Object.assign({}, lot)
      this.showLot = true
    },
    handleSupplierCosts(lot) {
      this.showPricingCosts = true
      this.selected = Object.assign({}, lot)
    },
    handleClose() {
      this.showPricingSetting = false
      this.getLots()
    },
    handleShowPricing(lot) {
      this.showPricingSetting = true
      this.selected = Object.assign({}, lot)
    },
    forSupplierValue() {
      return this.itt_framework && this.itt_framework.if_user_joined && !this.itt_framework.is_archived && this.itt_framework.project_status == 'ITT' && this.$store.state.isUserASupplier()
    },
    handleSupplierSpecsView(lot, kind) {
      this.selected = Object.assign({}, lot)
      this.showSpecValues = true
      this.kind = kind
    },
    handleView(lot, kind) {
      this.selected = Object.assign({}, lot)
      this.kind = kind

      if (this.lot_action == 'read') this.showScore = true
      else this.showView = true
    },
  
    search() {
      this.meta.page = 1
      this.getLots()
    },
    getLots() {
      this.showAdd = false
      this.showLot = false
      let params = {
        page: this.meta.page,
        term: this.term,
        pin_notice_id: this.pin_notice?.id,
        if_pin_notice: 1
      }
      this.loading = true
      SupplierhubLot.get({
          params: params
        })
        .then(result => {
          if (result.data.lots) this.lots = result.data.lots
          this.meta = result.data.meta
          this.loading = false
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching lots')
          console.error('error:', error.response.data)
        })
        .finally(() => {})
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
