<template>
    <div class="card-body">
        <el-card>
            <div class="row">
                <h3 class="col-md-12">Section III. Legal, economic, financial and technical information</h3>
                <div class="col-md-12">
                    <h4>III.1. Conditions for participation</h4>
                    <label>III.1.1. Suitability to pursue the professional activity, including requirements relating to to enrollment on professional or trade registers</label>
                    <br/><label>List and brief description of conditions (optional)</label>
                    <el-input type="textarea" :rows="3" v-model="form.conditions_for_participation_description" :label="'List and brief description of conditions (optional)'"></el-input>
                </div>
                <div class="col-md-12">
                    <br/><label>III.1.2.Economic and financial standing</label>
                        <el-checkbox v-model="form.economic_and_financial_standing" :checked="form.economic_and_financial_standing">Selection criteria as stated in the procurement documents (optional)</el-checkbox>
                </div>
                <div  v-if="form.economic_and_financial_standing != true">
                    <div class="col-md-6">
                        <label>List and brief description of conditions (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.selection_criteria_description_economic" :label="'List and brief description of conditions (optional)'"></el-input>
                    </div>
                    <div class="col-md-6" v-if="form.economic_and_financial_standing != true">
                        <label>Minimum level(s) of standards possibly required(optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.minimum_level_of_standard_economic" :label="'Minimum level(s) of standards possibly required(optional)'"></el-input>
                    </div>
                </div>
                <div class="col-md-12">
                    <br/><label>III.1.3.Technical and professional ability</label>
                    <br/><div :class="{ 'has-error-field': v.form.technical_and_professional_ability.$invalid }">
                            <el-checkbox v-model="form.technical_and_professional_ability" :checked="form.technical_and_professional_ability">Selection criteria as stated in the procurement documents (optional)</el-checkbox>
                        </div>
                        <div v-if="v.form.technical_and_professional_ability.$invalid" class="error-text" >Selection is required is required</div>
                </div>
                <div  v-if="form.technical_and_professional_ability != true">
                    <div class="col-md-6">fffffffffffffffffffffffffffffffffffffffffff
                        <label>List and brief description of conditions (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.selection_criteria_description_technical" :label="'List and brief description of conditions (optional)'"></el-input>
                    </div>
                    <div class="col-md-6">
                        <label>Minimum level(s) of standards possibly required (optional)</label>
                        <el-input type="textarea" :rows="3" v-model="form.minimum_level_of_standard_technical" :label="'Minimum level(s) of standards possibly required(optional)'"></el-input>
                    </div>
                </div>
                <div class="col-md-12">
                    <br/><label>III.1.5. Information about reserved contracts</label>
                        <el-checkbox v-model="form.reserved_to_sheltered_workshops" :checked="form.reserved_to_sheltered_workshops">The contract is reserved to sheltered workshops and economic operators aiming at the social and professional integration of disabled or disadvantaged persons (optional)</el-checkbox>
                </div>
                <div class="col-md-12">
                    <el-checkbox v-model="form.restricted_to_framework_sheltered_employment_programme" :checked="form.restricted_to_framework_sheltered_employment_programme">The execution of the contract is restricted to the framework of sheltered employment programme (optional)</el-checkbox>
                </div>
                <h4 class="col-md-12">III.2. Conditions related to the contract</h4>
                <div class="col-md-12">
                    <label>III.2.2. Contract performance conditions (optional)</label>
                    <el-input type="textarea" :rows="3" v-model="form.contract_performance_conditions"></el-input>
                </div>
                <div class="col-md-12">
                    <br/><label>III.2.3. Information about staff responsible for the performance of the contract</label>
                    <br/>
                        <el-checkbox v-model="form.obligation_to_indicate_name_and_qualification" :checked="form.obligation_to_indicate_name_and_qualification" :label="'Obligation to indicate the names and professional qualifications of the staff assigned to performing the contract (optional)'">Obligation to indicate the names and professional qualifications of the staff assigned to performing the contract (optional)</el-checkbox>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, requiredIf
     } from '@vuelidate/validators'

     const SECTION_THREE = 3;
 
     export default {
         props: ['user', 'procurement_id', 'saved_form', 'is_not_submitted'],
         data() {
             return {
                form: {
                    conditions_for_participation_description: null,
                    economic_and_financial_standing: false,
                    technical_and_professional_ability: false,
                    reserved_to_sheltered_workshops: false,
                    restricted_to_framework_sheltered_employment_programme: null,
                    contract_performance_conditions: null,
                    minimum_level_of_standard_economic: null,
                    selection_criteria_description_economic: null,
                    minimum_level_of_standard_technical: null,
                    selection_criteria_description_technical: null,
                    obligation_to_indicate_name_and_qualification: false
                }
            }
         },
        setup: () => ({
             v: useVuelidate()
          }),
        validations() {
            
        },
        mounted()  {
            if(this.saved_form) this.form = this.saved_form
        },
        methods: {
            saveForm() {
                this.$emit('save', SECTION_THREE, this.form)
            }
        }

 
     }
 </script>
 