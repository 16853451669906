import api from './api'

const API = '/api/procurement-product-social-values'

export const ProcProdSocialValue = {
  get: function(params) {
    return api.get(API, {params: params})
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
