<template>
  <div>
    <el-drawer title="Annual Estimated Final Price/Cost" v-model="showUpdatePrice" direction="rtl">
      <result-update-final-price :result="selected" v-if="selected" @update="close()"></result-update-final-price>
    </el-drawer>

    <el-dialog title="Override" v-model="showOverride" direction="rtl" width="80%" style="width: 80% !important"
      :before-close="getResults">
      <override-procurement-items :product_search_result="selected" v-if="selected"></override-procurement-items>
    </el-dialog>

    <el-dialog title="Message Attachments" v-model="showMessageAttachments" width="40%"
      :before-close="onHideMessageAttachments">
      <div>
        <procurement-message-attachments v-if="selected"
          :message_attachments="selected.message_attachments"></procurement-message-attachments>
      </div>
    </el-dialog>

    <div style="text-align: left;" class="row">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-header p-0 mx-3 mt-3 position-relative z-index-1">
            <h4>My Further Competition</h4>
          </div>
          <div class="text-sm p-4">
            <p>
              Welcome to the "My Further Competition" page of the CPRAS Procurement Platform. This page serves as a
              centralised hub for you to manage your products during the 10-day Further Competition period initiated by
              potential clients after your products have qualified in their initial Product Search. This page will
              provide a convenient overview of your results and allow you to ensure that all necessary information is
              up-to-date and accurate.
            </p>
            <p>
              In the first instance, please review the provisional report available in the table below to see how you
              scored against the potential client’s search criteria.
            </p>
            <p>Within the 10-day Further Competition period, you can update your product or service listing to tailor
              your offer and improve your score, <b>however, please note that any changes to your product listing will
                be applied to all potential clients’ results</b>. You will have the ability to make revisions until the
              Further Competition period is completed.</p>
            <p>You may also send correspondence to potential clients through this page. Please note that this
              communication will be continually monitored by the Framework Managers.</p>
            <p>Before the end of the Further Competition period, you must add your updated estimate for your total
              annual charge based on the information provided by the client, including any additional fees. You can do
              this by clicking on the coins icon in the table.</p>
            <p>A final report will then be generated and you will be informed via email of the outcome of the Further
              Competition period.</p>
            <p>If you have any questions, please contact our dedicated support team via the link on your Dashboard or
              Sidebar.</p>
          </div>
          <div class="px-0 pb-2 card-body">
            <nav class="navbar navbar-expand-lg top-0 z-index-3 w-100 shadow-none my-3 navbar-transparent">
              <form>
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term"
                    placeholder="Search" />
                  <button class="btn bg-gradient-secondary btn-sm my-1 me-1" @click="search()">
                    Search
                  </button>
                </div>
              </form>
            </nav>
            <div class="table-responsive">
              <table class="table table-hover table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      style="min-width: 250px">
                      Product
                    </td>
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Buyer
                    </td>
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Date
                    </td>
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Final Date for Amendment
                    </td>
                    <!-- <td
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Volume
                    </td> -->
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Provisional Report
                    </td>

                    <!--<td
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Add Client documentation
                    </td>-->
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Further Competition<br> Uploaded Documents
                    </td>

                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      INITIAL ANNUAL ESTIMATE
                    </td>

                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      UPDATED ANNUAL FINAL ESTIMATE
                    </td>
                    <!-- <td
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Messages<br />Attachments
                    </td> -->
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Final Report
                    </td>
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Notes
                    </td>
                    <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Action
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!results.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%" class="text-xs">No further competition</td>
                  </tr>
                  <tr v-else v-for="result in filteredResults" :key="result.id">
                    <td class="text-sm text-wrap">
                      <span>{{ result.product.name }}</span>
                    </td>
                    <td class="text-sm text-wrap">
                      <span>{{ result.submitted_by }}</span>
                    </td>
                    <td class="text-sm">
                      <span>{{ new Date(result.date).toLocaleDateString('en-GB') }}</span>
                    </td>
                    <td class="text-sm">
                      <span>{{ result.amendment_date }}</span>
                    </td>

                    <td class="text-sm">
                      <div>
                        <span class="text-sm" v-if="!result.initial_report_file">Generating...</span>
                        <div v-else>
                          <span v-if="!isClickable(result)" class="text-sm disabled">
                            <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                            File Attachment
                          </span>

                          <a v-else :href="result.initial_report_file" target="_blank" class="link me-3"
                            v-tooltip="'Download Report'">
                            <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                            File Attachment
                          </a>

                          <div v-if="result.buyer_additional_documents?.length">
                            <a class="link" @click="handleShowAdditionalDocuments(result)">
                              <i class="fa-solid fa-file-arrow-down text-secondary"></i> Additional Documents</a>
                          </div>
                        </div>
                      </div>

                    </td>

                    <td class="text-sm">
                      <div v-if="result.user_help && result.user_help.files.length">
                        <a :href="result.user_help.files[0]" target="_blank" class="link me-3" v-tooltip="'Download'">
                          <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                          Download
                        </a>
                      </div>
                    </td>
                    <td class="text-sm">
                      {{ formattedNumber(result.price) }}
                    </td>

                    <td class="text-sm">
                      <span>{{
                        formattedNumber(result.final_price)
                        }}</span>
                    </td>

                    <td class="text-wrap">

                      <div v-if="!result.is_direct_award">
                        <span v-if="result.is_final" class="text-sm text-success">
                          <a :href="result.report_file" target="_blank" class="link me-3" v-if="isClickable(result)"
                            v-tooltip="'Download Report'">
                            <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                            File Attachment
                          </a>
                          <span v-else class="text-sm disabled">
                            <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                            File Attachment
                          </span>
                        </span>
                        <span v-if="!result.is_final" class="text-sm disabled">Not Available Yet</span>
                      </div>
                      <!-- <span v-if="result.is_direct_award" class="text-sm disabled">Direct Award</span> -->
                    </td>
                    <td style="text-align: center;">
                      <a class="link" v-if="result.additional_comments" @click="handleShowNotes(result)">View</a>
                      <span v-else>Not Available</span>
                    </td>

                    <td class="text-sm" style="width: 1%">

                      <span class="mr-5">
                        <a v-if="isClickable(result) && !result.is_final" class="link me-2" v-tooltip="'Override'"
                          @click="handleOveride(result)">
                          <i class="fa-solid fa-clone">
                          </i></a>

                        <span v-else class="disabled mr-5">
                          <i class="fa-solid fa-clone">
                          </i>
                        </span>
                      </span>

                      <a v-if="result.message_attachments && isClickable(result) " class="link me-2 mr-5"
                        @click="onViewMessageAttachments(result)" v-tooltip="'View message attachment files'">
                        <i class="fa-solid fa-paperclip">
                        </i></a>

                      <span class="mr-5">

                        <a v-if="isClickable(result) " class="link me-2" @click="showMessage(result.id)"
                          v-tooltip="'Message'">
                          <el-badge :value="correspondent_count" class="correspondent_notification"
                            :hidden="correspondent_count == 0">
                            <i class="cursor-pointer fas fa-comment-dots "></i>
                          </el-badge>
                        </a>

                        <span v-else class="disabled mr-5">
                          <i class="fa-solid fa-comment-dots text-secondary"></i>
                        </span>
                      </span>


                      <span v-allowed="'products, delete'" class="mr-5">

                        <a v-if="isClickable(result)" class="link" @click="showRemove(result)" v-tooltip="'Delete'">
                          <i class="fas fa-trash text-secondary"></i>
                        </a>

                        <span v-else class="disabled">
                          <i class="fas fa-trash text-secondary"></i>
                        </span>
                      </span>


                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getResults()" />
          </div>
        </div>
      </div>
    </div>

    <el-dialog id="modal-remove" v-model="dialogVisible" title="Are you sure?" width="500">
      <div v-if="to_remove">
        You are about to remove data no. {{ to_remove.id }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog id="modal-reply" v-model="replyDialog" title="Are you sure?" width="500">
      <div v-if="selected">
        You have the option to reply with your best offer by providing the new
        price and a message
        <hr />
        <b-form-group id="input-group-1" label="Final price offer:" label-for="input-1" description="">
          <b-form-input id="input-1" v-model="selected.price" type="number" placeholder="0.00"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-1" label="Message:" label-for="input-1" description="">
          <b-form-textarea id="input-1" v-model="selected.reply_message" rows="3" max-rows="6"
            placeholder=""></b-form-textarea>
        </b-form-group>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="replyDialog = false">Cancel</el-button>
          <el-button type="primary" @click="sendReply">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="addStatementModal" title="Are you sure?" width="500">
      <product-search-result-add-statement :product_search_result="original"
        @update="getResults()"></product-search-result-add-statement>
    </el-dialog>

    <el-drawer title="Message" v-model="isShowMessage" direction="rtl">
      <message-box v-if="isShowMessage" :product-search-result-id="messageResultId" role="supplier"
        @close="onCloseMessageBox($event)"></message-box>
    </el-drawer>

    <el-dialog width="500" v-model="showAdditionalDocuments" title="Additional Documents" centered>
      <div v-if="selected">
        <div v-for="document, i in selected.buyer_additional_documents" :key="i">
          <el-link :underline="true" :href="document.url" target="_blank"> {{ document.file_name }} </el-link>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="500" v-model="showNotes" title="Additional Comments" centered>
      <div v-if="selected">
        <p class="small-note"> {{ selected.additional_comments }}
        </p>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  ProductSearchResult
} from '@/api_services/product_search_result'
import Modal from '@/components/common/Modal'
import ProductSearchResultAddStatement from './ProductSearchResultAddStatement.vue'
import MessageBox from '../product_search_messages/MessageBox.vue'
import ResultUpdateFinalPrice from './ResultUpdateFinalPrice.vue'
import ProcurementMessageAttachments from '../product_search_queries/ProcurementMessageAttachments.vue'
import OverrideProcurementItems from './OverrideProcurementItems.vue'

export default {
  data() {
    return {
      correspondent_count: this.$store.state.new_message_count,
      replyDialog: false,
      dialogVisible: false,
      isLoading: true,
      results: [],
      fields: [
        'id',
        'product',
        'buyer',
        'date',
        'final_date_for_amendment',
        'price',
        'volume',
        'report',
        'status',
        'action'
      ],
      addStatementModal: null,
      selected: null,
      original: null,
      validate: ['file'],
      to_remove: null,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      no_company: false,
      isShowMessage: false,
      messageResultId: null,
      showUpdatePrice: false,
      showMessageAttachments: false,
      showAdditionalDocuments: false,
      showNotes: false,
      showOverride: false
    }
  },
  components: {
    Modal,
    ProductSearchResultAddStatement,
    MessageBox,
    ResultUpdateFinalPrice,
    ProcurementMessageAttachments,
    OverrideProcurementItems
  },
  created: function () {
    this.$store.commit('newNotifications', true)
    
    this.getUser()
    if (this.$route.query.search_id) this.showMessage(this.$route.query.search_id)
  },
  watch: {
    isShowMessage: function () {
      if (!this.isShowMessage) {
        if (this.$route.query.search_id) this.$router.replace({
          query: null
        })
      }
    },
    $route: function () {
      if (this.$route.query.search_id) this.showMessage(this.$route.query.search_id)
    },
    '$store.state.new_message_count':{
      handler(value){
        this.correspondent_count = value
      },
      deep:true
    }
  },
  methods: {
    handleClose() {
      this.showOverride = false
    },
    handleOveride(result) {
      this.showOverride = true
      this.selected = Object.assign({}, result)
    },
    handleShowNotes(result) {
      this.showNotes = true
      this.selected = Object.assign({}, result)
    },
    handleShowAdditionalDocuments(result) {
      this.showAdditionalDocuments = true
      this.selected = Object.assign({}, result)
    },
    isClickable(result) {
      if (result.has_detailed_ccr && !result.user_help) return false

      return true
    },
    onViewMessageAttachments(result) {
      this.selected = Object.assign({}, result)
      this.showMessageAttachments = true
    },
    onHideMessageAttachments() {
      this.selected = null
      this.showMessageAttachments = false
    },
    close() {
      this.selected = null
      this.showUpdatePrice = false
      this.getResults()
    },
    showAddStatementModal(obj) {
      this.original = Object.assign({}, obj)
      this.addStatementModal = true
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.is_final) return ''
      if (!item.is_final) return 'table-info'
    },
    getUser() {
      let user = this.$store.state.savedUser
      if (!user.company) {
        if (user.is_admin) {
          this.no_company = true
          let self = this
          setTimeout(function () {
            self.$router.push('/')
          }, 3000)
        } else {
          this.getResults()
        }
      } else {
        this.getResults()
      }
    },
    search() {
      this.meta.page = 1
      this.getResults()
    },
    sendReply(evt) {
      evt.preventDefault()

      ProductSearchResult.reply({
          price: this.selected.price,
          reply_message: this.selected.reply_message,
          id: this.selected.id
        })
        .then(result => {
          this.$toast.success(result.data.message)
          this.$nextTick(() => {
            this.replyDialog = false
          })
          this.getResults()
        })
        .catch(error => {
          this.$toast.error('An error occured while sending your reply')
          console.error('error:', error.response.data)
        })
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      ProductSearchResult.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getResults()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing product inquery')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit ' + selected.name : 'New'
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    showReply(obj) {
      this.selected = Object.assign({}, obj)
      this.replyDialog = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type)
      this.original = Object.assign({}, type)
    },
    showMessage(id) {
      this.messageResultId = id
      this.isShowMessage = true
    },
    onCloseMessageBox(isReloadList) {
      if (isReloadList) {
        this.getResults()
      }
    },
    getResults() {
      this.isLoading = true
      this.addStatementModal = false
      this.handleClose()
      ProductSearchResult.get({
          params: {
            page: this.meta.page
          }
        })
        .then(result => {
          if (result.data.results) this.results = result.data.results
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching products')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    formattedNumber(value) {
      var roundedNumber = Math.round(value)
      return roundedNumber.toLocaleString()
    }
  },
  computed: {
    filteredResults() {
      let list = []
      this.results.forEach(function (result) {
        if (result.is_visible_from_supplier) list.push(result)
      })

      return list
    },
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function (field) {
        if (!self.validation(field)) response = false
      })

      return response
    }
  }
}
</script>

<style scoped>
.disabled-link {
  cursor: not-allowed;
  color: gray;
  pointer-events: none;
}
</style>
