import api from './api'

const API = '/api/questions'

export const Question = {
  get: function(params) {
    return api.get(API, params)
  },
  myQuestions: function(params) {
    return api.get(API + '/my-questions', params)
  },
  publicQuestions: function(params) {
    return api.get(API + '/public-questions', params)
  },
  create: function(form) {
    return api.createFormData(API, form)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  answer: function(id, params) {
    return api.update(API + '/' + id + '/answer', params)
  },
  update: function(id, params) {
    return api.update(API + '/' + id, params)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id, params)
  }
}
