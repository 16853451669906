<template>
<div>
  <form @submit.prevent="submit">
    <div class="form-group">
      <div class="p-3 text-justify text-sm">
        <b>Attention: Please note that the CPRAS Framework Managers are actively monitoring this conversation. We kindly remind you to exercise responsibility and professionalism with your messages, keep all discussions within this platform and refrain from sharing any personal or sensitive information. For the purpose of fair competition, please also be mindful that messages may be shared with competing suppliers if they are believed to be of shared interest. Thank you for your cooperation. If you have any questions or concerns, please contact the CPRAS support team</b>
      </div>
      <label>To</label>
      <div :class="{ 'has-error-field': v.form.recipients.$invalid }">
        <el-select v-model="form.recipients" multiple placeholder="Select" style="width: 100%;">
          <el-option v-for="(item, i) in options" :key="i" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div v-if="v.form.recipients.$invalid" class="error-text">Recipient is required</div>
    </div>
    <div class="form-group">
      <label>Message</label>
      <div :class="{ 'has-error-field': v.form.message.$invalid }">
        <el-input type="textarea" name="body" v-model="form.message" placeholder="Message" :rows="5"></el-input>
      </div>
      <div v-if="v.form.message.$invalid" class="error-text">Message is required</div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>Attachments</label>
        <argon-input :multiple="true" type="upload" @input="getFiles($event)" :value="files"></argon-input>
      </div>
    </div>
    <el-button native-type="submit" type="primary" plain>Send</el-button>
  </form>
</div>
</template>

<script>
import {
  ProductSearchMessageApi
} from '@/api_services/product_search_message'
import {
  BuyerSearchQuery
} from '@/api_services/buyer_search_query'
import ArgonInput from '../../common/Input/ArgonInput.vue'

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

const more = (value) => value?.length > 0

export default {
  components: {
    ArgonInput
  },
  props: {
    query: Object
  },
  data() {
    return {
      buyer_search_query: null,
      options: [],
      form: {
        recipients: [],
        message: null
      },
      files: [],
      file_name: null
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        message: {
          required,
          $autoDirty: true
        },
        recipients: {
          required,
          $autoDirty: true
        },
      }
    }
  },
  watch: {
    query: function () {
      if (this.query) this.getBuyerSearchQuery()
    },
  },
  created: function () {
    if (this.query) this.getBuyerSearchQuery()
  },
  methods: {
    getFiles(files) {
      this.file_name = null
      this.files = []
      if (files.length) {
        this.files = [files[0].raw]
        this.file_name = files[0].name
      }
    },
    getForm() {
      let formData = new FormData()
      if (this.form.id) formData.append('id', this.form.id)
      formData.append('message', this.form.message)
      formData.append('recipients', this.form.recipients)

      if (this.files.length) {
        formData.append('file', this.files[0])
        formData.append('file_name', this.file_name)
      }

      return formData
    },
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.globalState.loading = true
      ProductSearchMessageApi.sendToAll(this.getForm())
        .then(result => {
          this.$toast.success('Message sent')
          this.$emit('sent', true)
        })
        .catch(error => {

        })
        .finally(() => {
          this.globalState.loading = false
        })
    },
    getBuyerSearchQuery() {
      this.globalState.loading = true
      BuyerSearchQuery.show(this.query.id)
        .then(result => {
          this.options = []
          this.form.recipients = []
          let self = this
          if (result.data.buyer_search_query) {
            this.buyer_search_query = result.data.buyer_search_query
            result.data.buyer_search_query.product_search_results.forEach(
              result => {
                self.options.push({
                  value: result.id,
                  label: result.product.name + ' - ' + result.product.supplier.name
                })
                self.form.recipients.push(result.id)
              }
            )
          }
        })
        .catch(error => {
          this.enablePageNotFound()
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.globalState.loading = false
        })
    }
  }
}
</script>
