<template>
  <div class="card">
    <div class="card-body">
      <form @submit="onSubmit" @reset="onCancel">
        <argon-input
          type="text"
          :value="selected.name"
          field="name"
          :required="true"
          :label="true"
          @update="updateField('name', $event)"
        ></argon-input>
        <argon-input
          type="email"
          :value="selected.email"
          field="email"
          :required="true"
          :label="true"
          @update="updateField('email', $event)"
        ></argon-input>
        <argon-input
          type="password"
          :value="selected.password"
          field="password"
          :required="requirePassword"
          :nullable="true"
          :label="true"
          @input="checkValue('password', $event)"
          @update="updateField('password', $event)"
        ></argon-input>

        <label class="text-sm">Status</label>
        <div class="form-group">
          <el-radio-group v-model="selected.status">
            <el-radio-button :label="0">Pending</el-radio-button>
            <el-radio-button :label="1">Active</el-radio-button>
            <el-radio-button :label="2">Inactive</el-radio-button>
          </el-radio-group>
        </div>

        <button
          type="submit"
          class="btn bg-gradient-success btn-sm"
          :disabled="!isValid"
        >
          Save
        </button>
        <button
          type="reset"
          class="btn bg-gradient-secondary btn-sm"
          @click="onCancel()"
        >
          Cancel
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { User } from '@/api_services/user'
import ArgonInput from '@/components/common/Input/ArgonInput.vue'

export default {
  components: { ArgonInput },
  props: ['user'],
  data() {
    return {
      validate: ['name', 'email'],
      validFields: {
        name: false,
        email: false,
        password: true
      },
      selected: {
        name: null,
        email: null,
        password: null,
        status: 0
      },
      requirePassword: true
    }
  },
  watch: {
    user: function() {
      this.reset()
      if (this.user) {
        this.selected = {
          id: this.user.id,
          name: this.user.name,
          email: this.user.email,
          password: "",
          status: this.user.status
        }
      }
    }
  },
  created: function() {
    this.reset()
    if (this.user) {
      this.selected = {
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        password: "",
        status: this.user.status
      }
    }
  },
  methods: {
    checkValue(name, value) {
      if (name == 'password') {
        if (!value) {
          this.validate = ['name', 'email']
          this.validFields.password = true
        } else {
          this.validate = ['name', 'email', 'password']
        }
        this.requirePassword = value ? true : false
      }
    },
    updateField(name, newItem) {
      this.validFields[name] = newItem ? true : false
      this.selected[name] = newItem
    },
    onSubmit(evt) {
      evt.preventDefault()
      var action = this.selected.id
        ? User.update(this.selected)
        : User.create(this.selected)
      action
        .then(result => {
          this.$toast.success('Saved successfully')
          this.reset()
          this.$emit('update', true)
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a user')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
      if (evt) evt.preventDefault()
      this.reset()
      this.$emit('update', false)
    },
    reset() {
      this.selected = {
        name: null,
        email: null,
        password: null,
        status: 0
      }
    }
  },
  computed: {
    isValid() {
      let valid = true
      for (var i in this.validFields) {
        if (!this.validFields[i]) valid = false
      }

      return valid
    }
  }
}
</script>
