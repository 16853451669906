import api from './api'

const API = '/api/procurement_project_participants'

export const ProcurementProjectParticipants = {
  joinParticipant: function(id, params) {
    return api.update(API+'/join_participant/'+id, params)
  },
  loadParticipants: function(id, params) {
    return api.get(API+'/load_participants/'+id, params)
  },
  loadSupplierCompanyParticipants: function(id, params) {
    return api.get(API+'/load_supplier_company_participants/'+id, params)
  },
  leaveParticipant: function(id, params) {
    return api.update(API+'/leave_participant/'+id, params)
  },
  loadLeaveRequest: function(params) {
    return api.get(API+'/load_leave_request', params)
  },
  sendPartitipantsAnInvitation: function(params) {
    return api.create(API + "/send_invitation", params)
  },
  uploadSupplierParticipantFiles: function(params) {
    return api.createFormData(API + "/upload_supplier_participant_files", params)
  },
  removeSupplierParticipantFile: function(params) {
    return api.delete(API+'/remove_supplier_participant_file/'+params.id, params)
  },
  loadInvitations: function(id, params) {
    return api.get(API+'/load_invitations/'+id, params)
  },
}
