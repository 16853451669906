<template>
  <div>
    <not-allowed v-if="!$store.state.isViewable('roles')"></not-allowed>

    <div v-else>
      <h2 class="text-white">Manage Permissions</h2>
      <div style="text-align: left;" class="row">
        <div class="col-md-8">
          <el-card header="Permissions" class="my-3 border-0">
            <b-table
              :items="permissions"
              :fields="fields"
              striped
              responsive="sm"
            >
              <template #cell(name)="row">
                {{ row.item.name }}
                <el-link @click="onEdit(row.item)" style="font-size: 10px;" v-tooltip="'Edit Permission'"
                  ><i class="fa fa-pencil"></i
                ></el-link>
              </template>
              <template #cell(actions)="row">
                <!-- <b-form-tags
                  input-id="tags-state-event"
                  v-model="row.item.actions"
                  tag-variant="primary"
                  placeholder="Enter new action separated by space"
                  separator=" "
                  @tag-state="onTagState"
                ></b-form-tags> -->
                <div class="text-wrap">
                  <el-tag
                    v-for="(tag, index) in row.item.actions"
                    :key="index"
                    class="m-1"
                    type="primary"
                    size="large"
                    effect="dark"
                  >
                    {{ tag }}
                  </el-tag>
                </div>
                
              </template>

              <template #cell(action)="row">
                <a @click="showRemove(row.item)" v-tooltip="'Delete Permission'">
                  <i class="fa-solid fa-trash"></i>
                </a>
                <!-- <b-button
                  size="sm"
                  @click="showRemove(row.item)"
                  class="mr-2"
                  ref="showRemove"
                  >Delete</b-button
                > -->
              </template>
            </b-table>
          </el-card>

          <el-dialog
            v-model="dialogVisible"
            title="Confirm Delete?"
            width="500"
            :before-close="handleClose"
          >
            <div v-if="to_remove">
              You are about to remove {{ to_remove.name }} permissions, Proceed?
            </div>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="danger" @click="remove">
                  Confirm
                </el-button>
              </div>
            </template>
          </el-dialog>

        </div>
        <div class="col-md-4">
          <new-permission-component
            @save="getPermissions()"
            :permission="selected"
          ></new-permission-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Role } from '@/api_services/role_service'
import NewPermissionComponent from './NewPermissionComponent.vue'
import $ from 'jquery'

export default {
  components: { NewPermissionComponent },
  data() {
    return {
      original_permissions: [],
      permissions: [],
      fields: ['name', 'actions', 'action'],
      validTags: [],
      invalidTags: [],
      duplicateTags: [],
      to_remove: null,
      selected: null,
      dialogVisible: false
    }
  },
  watch: {
    permissions: {
      deep: true,
      handler() {
        this.checkForRemovedPermission()
        this.checkForNewPermission()
      }
    }
  },
  computed: {
    upCaseActions(actions) {
      let array = []
      actions.forEach(function(action) {
        array.push(action.toUpperCase())
      })
      return array
    }
  },
  created: function() {
    this.getPermissions()
  },
  methods: {
    removeTag(index, tagList) {
      tagList.splice(index, 1);
    },
    onEdit(permission) {
      this.selected = Object.assign({}, permission)
    },
    showRemove(object) {
      this.to_remove = { name: object.name }
      this.dialogVisible = true

    },
    checkInput() {
      console.log('checkInput test')
    },
    addPermission(name, action) {
      Role.addPermission({ action: action, name: name })
        .then(result => {
          this.$toast.success('Added')
          this.original_permissions = JSON.parse(
            JSON.stringify(this.permissions)
          )
        })
        .catch(error => {
          this.$toast.error('An error occured while adding permission')
          console.error('error:', error.response.data)
          this.permissions = JSON.parse(
            JSON.stringify(this.original_permissions)
          )
        })
    },
    removePermission(name, action) {
      Role.removePermission({ action: action, name: name })
        .then(result => {
          this.$toast.success('Removed')
          this.original_permissions = JSON.parse(
            JSON.stringify(this.permissions)
          )
        })
        .catch(error => {
          this.$toast.error('An error occured while removing permission')
          console.error('error:', error.response.data)
          this.permissions = JSON.parse(
            JSON.stringify(this.original_permissions)
          )
        })
    },
    checkForNewPermission() {
      var self = this
      var permissions = this.original_permissions
      this.permissions.forEach(function(permission, i) {
        permission.actions.forEach(function(action) {
          if (permissions[i] && !permissions[i].actions.includes(action)) {
            self.addPermission(permission.name, action)
          }
        })
      })
    },
    checkForRemovedPermission() {
      var self = this
      var permissions = this.permissions
      this.original_permissions.forEach(function(permission, i) {
        permission.actions.forEach(function(action) {
          if (permissions[i] && !permissions[i].actions.includes(action)) {
            self.removePermission(permission.name, action)
          }
        })
      })
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      Role.removeAllPermissions(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getPermissions()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing a user')
          console.error('error:', error.response.data)
        })
    },
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    },
    collectPermissions(permissions) {
      this.permissions = []
      for (var p in permissions) {
        this.permissions.push({
          name: p,
          actions: permissions[p]
        })
      }

      this.original_permissions = JSON.parse(JSON.stringify(this.permissions))
    },
    getPermissions() {
      this.permissions = []
      this.selected = null
      Role.getPermissions({})
        .then(result => {
          if (result.data.permissions)
            this.collectPermissions(result.data.permissions)
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching permissions')
          console.error('error:', error.response.data)
        })
    }
  }
}
</script>
