<template>
  <div class="container h-100" style="margin-top: 100px">
    <loading :loading="loading"></loading>
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div v-if="message">
          <h3>{{ message }}</h3>
          <hr>
          <a href="/">Home</a>
        </div>
        <el-card class="border-0" sub-title="" v-else>

          <!-- <signup-user-type-component :selected="form" @success="nextStep($event)" v-if="step == 0"></signup-user-type-component> -->
          <!-- <signup-supplier-information-component :selected="form" @success="nextStep($event)" v-if="step == 1 && form.role_name == 'supplier'"></signup-supplier-information-component> -->
          <signup-account-form-component :selected="form" @success="nextStep($event)" v-if="step != 4"></signup-account-form-component>
          <!-- <signup-company-information-component :selected="form" @success="nextStep($event)" v-if="step == 3"></signup-company-information-component> -->
          <b-form  @submit="onSubmit" v-if="step == 4">
            <h4 class="signup-h4">Final Step</h4>
              <b-form-checkbox
                id="checkbox-1"
                v-model="terms"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
                @change="showTermsModal()"
              >
                I agree all statements in Terms of Service
              </b-form-checkbox>
              <br>
              <b-button type="button" variant="info" @click="back()">Back</b-button> <b-button type="submit" variant="info"  :disabled="!validateAll">Create Account</b-button>
          </b-form>
        </el-card>
        
      </div>
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="Terms and Conditions"
      width="600"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
    >
      <div style="height: 500px;overflow:auto">
        <terms-and-condition-component @close="hideTermsModal()"></terms-and-condition-component>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="hideTermsModal">
            Close
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import { Authenticate } from '@/api_services/authenticate'
import TermsAndConditionComponent from './TermsAndConditionComponent.vue'
import SignupAccountFormComponent from './SignupAccountFormComponent.vue'
// import SignupUserTypeComponent from './SignupUserTypeComponent.vue'
// import SignupSupplierInformationComponent from './SignupSupplierInformationComponent.vue'
// import SignupCompanyInformationComponent from './SignupCompanyInformationComponent.vue'

export default {
  data() {
    return {
      dialogVisible: false,
      terms: false,
      loading: false,  
      message: null,
      options: [
        { value: 'supplier', text: 'Supplier' },
        { value: 'buyer', text: 'Buyer' }
      ],
      validate: ['name', 'email','password','confirm_password','company_name'],
      form: {
        // company_name: null,
        position_name: null,
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        company_name: null,
        role_name: 'supplier',
        supplier: {
          name: '',
          description: '',
          phone: '',
          email: '',
          posta: ''
        },
        company: {
          name: '', contact_person: '', dba_name: '', address: '',city: '', state: '', zip: ''
        }
      },
      step: 0
    }
  },
  components: {TermsAndConditionComponent, SignupAccountFormComponent
  // , SignupUserTypeComponent, SignupSupplierInformationComponent, SignupCompanyInformationComponent
  },
  created: function() {
    
  },
  methods: {
    back() {
      this.step = 3
      // this.nextStep({next: false, form: this.form})
    },
    nextStep(object) {
      // this.step = object.next ? this.step+1 : this.step-1
      // if (this.step == 1 && object.form.role_name != 'supplier') {
      //   this.step = object.next ? this.step = 2 : this.step = 0
      // }

      this.form = object.form
      this.step = 4
    },
    showTermsModal(evt) {
      if (evt) evt.preventDefault()
      if (this.terms) this.dialogVisible = true
    },
    hideTermsModal() {
      this.$bvModal.hide('modal-term')
    },
    onSubmit(evt) {
        evt.preventDefault()
        this.dialogVisible = false
        this.loading = true
        if (this.form.company_name) {
          this.form.company.name = this.form.company_name
          this.form.supplier.name = this.form.company_name
        }
        if (this.form.position_name) this.form.company.position_name = this.form.position_name
        Authenticate.register(this.form).then(result => {
          this.message = result.data.message
          this.hideTermsModal()
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.error('error:', error.response.data)
        })
    },
  },
  computed: {
    validateAll() {
      return this.terms;
    }
  }
}
</script>