<template> 
      <div v-for="setting in selected_guidance_setting">
       <el-card shadow="never" style="background: #b3e19d" class="mb-2 p-0 border-0" >
          <el-text v-html="setting.title_label"></el-text>
          <hr>
          <el-text v-html="setting.pass_answer_label"></el-text>  
          <hr>
          <el-text v-html="setting.fail_answer_label"></el-text>
      
      </el-card>
      </div>
  </template>
  
  <script>

  export default {
    props: ['guidance_setting'],
    data() {
      return {
        selected_guidance_setting: null,
      }
    },
    watch: {
      guidance_setting: {
            deep: true,
            handler() {
                this.selected_guidance_setting = this.guidance_setting
            }
        },
    },
    created: function() {
        this.selected_guidance_setting = this.guidance_setting
    },
    methods: {
    }
  }
  </script>
  