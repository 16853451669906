<template>
  <div>
    <el-drawer title="" v-model="showAdd" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-spec-form @close="getSpecs()" :lot="lot" :pin_notice_id="pin_notice_id" :kind="kind"
        :if_pin_notice="if_pin_notice" :is_deadline_passed="itt_framework.is_deadline_passed" :spec="selected"></supplierhub-spec-form>
    </el-drawer>

    <el-drawer title="" v-model="showImport" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-spec-import-form @close="getSpecs()" :kind="kind" :lot="lot"
        :template="import_template"></supplierhub-spec-import-form>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="itt_framework">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>Framework {{ lot_name }} {{ !kind ? ' Specifications':' Social Values'}}</h4>
              <div v-html="itt_framework.spec_description"></div>
              <div v-if="kind == 1">
                <div
                  v-if="supplierhub_lot && supplierhub_lot.social_values_added_status != 2 && supplierhub_lot.social_values_added_message">
                  <el-alert :closable="false">
                    {{ supplierhub_lot.social_values_added_message }}
                  </el-alert>
                </div>

              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <el-alert v-if="savedUser.is_supplier_hub_admin && supplierhub_import" type="success"
                  style="margin-bottom: 10px;">
                  <div>{{ supplierhub_import.message }}</div>
                </el-alert>

                <el-button @click="getSpecs()" style="margin-left: 10px;"> <i class="fa fa-refresh" style="margin-right: 5px;"></i>
                  Refresh</el-button>
                  
                <el-button @click="handleAddToms" plain type="primary" :disabled="loading"
                  v-if="allowToms()">
                  {{ loading ? 'Please wait' : 'Load TOMS Social Values' }}
                </el-button>

              </div>
              <div class="col-md-6">
                <nav class="navbar shadow-none navbar-transparent float-end">
                  <el-form :inline="true" class="demo-form-inline" @submit.prevent="getSpecs($event)">
                    <el-form-item>
                    
                      <el-button
                        v-if="
                          (itt_framework.is_deadline_passed && $store.state.isUserAnOriginator() && !itt_framework.is_archived) ||
                                    (!itt_framework.is_deadline_passed && !itt_framework.is_archived && savedUser.is_supplier_hub_admin) 
                                    
                        "
                        plain
                        type="warning"
                        @click="handleShowImport()"
                        style="margin-left: 20px;"
                        ><i class="fa-solid fa-upload"></i>Import</el-button
                      >
                      <el-button type="primary" @click="create()" v-if="allowCreate()"><i class="fa-solid fa-plus"></i>
                        {{
                        $store.state.isUserAnOriginator()
                        ? 'Add '
                        : 'Suggest'
                        }}
                      </el-button>


                    </el-form-item>
                  </el-form>
                </nav>
              </div>
            </div>

            <div class="table-responsive" style="max-height: 500px;">
              <div style="padding-left: 20px;text-align: left;">
                <el-switch v-if="if_pin_notice == 1" style="display: block" v-model="status" active-color="#13ce66"
                  inactive-color="#adadad" :active-text="!kind ? 'Official Specifications':'Official Social Values'"
                  :active-value="1" :inactive-value="0"
                  :inactive-text="!kind ? 'Proposed Specifications' :'Proposed Social Values'">
                </el-switch>
              </div>
              <table class="table table-fluid align-items-center mb-0" v-loading="loading">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-sm font-weight-bolder">
                      {{
                      status
                      ? 'Framework '
                      : 'Suggested '
                      }} {{ !kind ? ' Specifications':' Social Values'}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="spec in specs" :key="spec.id">
                    <td class="text-sm">
                      <supplierhub-spec-view :is_deadline_passed="itt_framework.is_deadline_passed"
                        :if_user_joined="itt_framework.if_user_joined" :spec="spec"
                        :is_parent_archive="itt_framework.is_archived" :if_pin_notice="if_pin_notice"
                        :itt_framework="itt_framework" @close="getSpecs()" @edit="handleEdit(spec)"></supplierhub-spec-view>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="loading">Loading....</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getSpecs()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SupplierhubSpec } from '@/api_services/supplierhub_spec'
import SupplierhubSpecView from './SupplierhubSpecView.vue'
import SupplierhubSpecForm from './SupplierhubSpecForm.vue'
import { mapState } from 'vuex'
import SupplierhubSpecImportForm from './SupplierhubSpecImportForm.vue'
import { SupplierhubLot } from '@/api_services/supplierhub_lot'
import Swal from 'sweetalert2'

export default {
  components: {
    SupplierhubSpecView,
    SupplierhubSpecForm,
    SupplierhubSpecImportForm
  },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    lot: {
      type: Object,
      required: true,
      default: {
        name: "",
        id: 0
      }
    },
    if_pin_notice: {
      type: Number,
      required: false
    },
    pin_notice_id: {
      type: Number,
      required: false
    },
    kind: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      selected: null,
      company_ids: [],
      is_deadline_passed: false,
      lot_name: null,
      showAdd: false,
      showImport: false,
      specs: [],
      term: null,
      status: 1,
      loading: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      import_template: null,
      supplierhub_import: null,
      supplierhub_lot: null
    }
  },
  watch: {
    status: function () {
      this.getSpecs()
    }
  },
  computed: {
    ...mapState(['savedUser']),
  },
  created: function() {
    if (this.lot && this.lot.is_archived) {
      this.status = 1
    } else {
      this.status = this.savedUser.is_supplier_hub_admin ? 1 : 0
    }
    
    this.getSpecs()
    if(this.lot != null){
      this.lot_name = this.lot.name
    }

    for (const [key, value] of Object.entries(this.savedUser.companies)) {
      this.company_ids.push(value.id)
    }
  },
  methods: {
    handleAddToms() {
      Swal.fire({
            title: 'Load TOMs Social Values?',
            text: 'By clicking continue, you are about to load TOMs social values. Proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              
              this.loading = true
              SupplierhubLot.addToms(this.lot.id)
                .then(result => {
                  this.$toast.success('Generating Social Values')
                  this.getSpecs()
                })
                .catch(() => { })
                .finally(() => {
                  this.loading = false
                })
              
            }
          });
    },
    allowToms(){
      if (this.kind != 1) return false
      if (!this.$store.state.isUserAnOriginator()) return false
    
      return this.allowCreate()
    },
    allowCreate() {        
      if (this.itt_framework && this.if_pin_notice) {
        let allowed_statuses = ['PRE', 'ATC']
        if (this.itt_framework.procurement_project && (this.$store.state.isUserAnOriginator() || this.$store.state.isUserASupplier()) && allowed_statuses.includes(this.itt_framework.procurement_project.status)) {
          return true
        }
      }

      let project = this.itt_framework.procurement_project
      if (project && project.status == 'ITT') return false
    
      let condition = (this.itt_framework.is_deadline_passed && this.$store.state.isUserAnOriginator() && !this.itt_framework.is_archived) ||
        ((!this.itt_framework.is_deadline_passed && !this.itt_framework.is_archived && this.savedUser.is_supplier_hub_admin) &&
          (this.$store.state.isUserASupplier() && this.if_pin_notice == 1) || (!this.$store.state.isUserASupplier())
        )
      if (this.itt_framework && this.if_pin_notice) {
        if (this.itt_framework.read_only_from_status) return false
      }
      return condition
    },
    checkIfNotJoined(){
      if(
        !this.itt_framework.if_user_joined && 
        this.itt_framework?.user?.id != this.savedUser.id &&
        this.savedUser?.company?.id != this.itt_framework?.company?.id &&
        !this.company_ids.includes(this.itt_framework?.company?.id)
      ){
        return true
      }
      return false
    },
    getSupplierHubLot(){
      this.loading = true
      SupplierhubLot.show(9)
        .then(result => {
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleShowImport() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to import!",
          icon: 'warning',
        })
        return false
      }

      this.showImport = true
    },
    handleClose() {
      this.showImport = false
    },
    create() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to add a spec!",
          icon: 'warning',
        })
        return false
      }
      this.showAdd = true
      this.selected = null
    },
    handleEdit(spec){
      this.showAdd = true
      this.selected = Object.assign({}, spec)
    },
    search() {
      this.meta.page = 1
      this.getSpecs()
    },
    getSpecs() {
      this.showAdd = false
      this.showImport = false
      this.selected = null
      let params = {
        page: this.meta.page,
        term: this.term,
        itt_framework_id: this.itt_framework?.id,
        status: this.status,
        lot_id: this.lot.id,
        if_pin_notice: this.if_pin_notice,
        kind: this.kind
      }
      this.loading = true
      SupplierhubSpec.get({
        params: params
      })
        .then(result => {
          this.import_template = result.data.import_template
          this.supplierhub_import = result.data.supplierhub_import
          if (result.data.specs) this.specs = result.data.specs
          this.meta = result.data.meta
          this.loading = false
          this.supplierhub_lot = result.data.lot
        })
        .catch(error => {
     
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
