import helpers from './mixins/helper'


const allowedDirective = {
  mounted(el, binding) {
    const list = binding.value.split(',');
    if (!helpers.isAllowed(list[0].replace(/\s/g, ''), list[1].replace(/\s/g, ''))) {
      el.style.display = 'none';
    }
  }
};

export default allowedDirective;
