<template>
  <div>
    <h2 class="text-white">Global Specifications</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-8">
        <div class="card my-3">
          <!-- <div class="card-header"><h5>Specifications/Requirements</h5></div> -->
          <div class="card-body px-0 pb-2">
            <div class="table-response">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term" placeholder="Search">
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="search()">Search</button>
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="create()">Add</button>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">code</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">description</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">unit</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!specifications.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else v-for="specification in specifications" :key="specification.id">
                    <td class="text-sm"><span class="text-wrap">{{ specification.description }}</span></td>
                    <td class="text-sm"><span class="text-wrap">{{ specification.original_description }}</span></td>
                    <td class="text-sm">{{ $store.state.getUnitLabel(specification.unit) }}</td>
                    <td class="text-sm" style="width:1%">
                      <a class="link me-3" @click="showEdit(specification)" v-tooltip="'Edit'">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(specification)" v-tooltip="'Delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getSpecifications()"/>
          </div>
        </div>

          <!-- <b-nav-form style="float:right">
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search" v-model="term"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="button" @click="search()">Search</b-button>
            <b-button size="sm" @click="create()" class="mr-2" style="margin-left: 5px">Add</b-button>
          </b-nav-form> -->  
      </div>
      <div class="col-md-4" v-if="original">
        <div class="card my-3">
          <div class="card-header">
            <text-with-show-more :string="selectedName(original)" :length="100"></text-with-show-more>
          </div>
          <div class="card-body">
            <form @submit="onSubmit" @reset="onCancel">
              <div class="form-group">

                <label 
                  :invalid-feedback="invalidFeedback('description')"
                  :state="validation('description')">
                  Description
                </label>
                <textarea class="form-control" v-model="selected.original_description" :state="validation('description')" trim></textarea>
              </div>
              <div class="form-group">
                <label 
                  :invalid-feedback="invalidFeedback('unit')"
                  :state="validation('unit')">
                  Unit
                </label>
                <select class="form-select" v-model="selected.unit" :state="validation('unit')" >
                  <option v-for="unit in $store.state.units" :key="unit.id" v-bind:value="unit.value">{{ unit.text }}</option>
                </select>
              </div>
              <button type="submit" class="btn bg-gradient-success btn-sm">Save</button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        id="modal-remove"
        v-model="dialogVisible"
        title="Confirm Delete?"
        width="500"
        :before-close="handleClose"
      >
        <div v-if="to_remove">
          You are about to remove {{ to_remove.name }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>


  </div>
</template>

<script>

import { Specification } from '@/api_services/specification'
import $ from 'jquery'

export default {
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      specifications:[],
      fields: ['id', 'description','unit','action'],
      selected: null,
      original: null,
      validate: ['description','unit'],
      to_remove: null,
      term: null,
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  created: function() {
    this.getSpecifications()
  },
  methods: {
    search() {
      this.meta.page = 1
      this.getSpecifications()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      Specification.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getSpecifications()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing spec')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit '+selected.original_description: 'New'
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
    },
    getSpecifications() {
      this.isLoading = true
      Specification.get({params: {page: this.meta.page}})
        .then(result => {
          if(result.data.specifications)
            this.specifications = result.data.specifications
            this.meta = result.data.meta
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching specifications')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    validation(field) {
      switch(field) {
        case 'description':
          if (!this.selected.original_description) this.selected.original_description = ""
          return this.selected.original_description.length > 0
        case 'unit':
          return this.selected.unit > 0
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'description') return "Description is required"
      if (!this.validation(field) && field == 'unit') return "Unit is required"
      return ""
    },
    onSubmit(evt) {
        evt.preventDefault()
        var action = this.selected.id ? Specification.update(this.selected.id, this.selected) : Specification.create(this.selected)
        action.then(result => {
          this.$toast.success('Saved successfully')
          this.getSpecifications()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a spec')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {original_description: null, unit: null}
      this.selected = Object.assign({}, this.original);
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>
