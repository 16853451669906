
<template>
  <span style="font-size: 20px;" class="spinner-grow text-info" role="status" v-if="loading">
    <span class="sr-only">Loading...</span>
  </span>
</template>

<script>
export default {
  props: ['loading'],
  data() {
    return {}
  },
  methods: {
    
  }
}
</script>