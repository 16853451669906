<template>
  <div class="card bg-gradient-info mx-3">
    <div class="card-header bg-gradient-info text-center"><h5 class="text-white">Payment Gateway</h5></div>
    <div class="card-body text-center">
      <router-link to="/payment-gateway" class="stretched-link text-light">
        <i class="fa-solid fa-credit-card"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
