<template>
  <div>
    <h2 class="text-white">Product Categories</h2>
    <div class="row">
      <div class="col-md-6">
        <div class="card my-3">
          <!-- <div class="card-header"><h4>Product Categories</h4></div> -->
          <div class="card-body px-0 pb-2">
            <div class="table-responsive">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term"
                    placeholder="Search" />
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="search()">
                    Search
                  </button>
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="create()">
                    Add
                  </button>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr class="text-sm">
                    <th>Name</th>
                    <th>Framework</th>
                    <th>Type</th>
                    <th>Kind</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!categories.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="category in categories" :key="category.id">
                    <td>{{ category.name }}</td>
                    <td>{{ (category.itt_framework ? category.itt_framework.name : "No framework") }}</td>
                    <td>{{ category.type == 0 ? 'Simple' : 'Complex' }}</td>
                    <td>{{ category.kind == 0 ? 'Special' : 'Ordinary' }}</td>
                    <td style="width: 1%">
                      <a v-allowed="'settings, update'" class="link me-3" @click="showCategorySpecs(category)"
                        v-tooltip="'Specifications'">
                        <i class="fa-solid fa-glasses text-secondary"></i>
                      </a>
                      <a v-allowed="'settings, update'" class="link me-3" @click="showCategoryCharges(category)"
                        v-tooltip="'Additional Charges'">
                        <i class="fa-solid fa-sterling-sign text-secondary"></i>
                      </a>
                      <a v-allowed="'settings, update'" class="link me-3" @click="showSocialValues(category)"
                        v-tooltip="'Social Values'">
                        <i class="fa-regular fa-heart text-secondary"></i>
                      </a>
                      <a class="link me-3" @click="showEdit(category)" v-tooltip="'Edit'">
                        <i class="fa-solid fa-pen-to-square text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(category)" v-tooltip="'Delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6" v-if="show_category_specs && selected">
        <category-specifications-component :category="selected"></category-specifications-component>
      </div>

      <div class="col-md-6" v-if="show_category_charges && selected">
        <category-charges-component :category="selected"></category-charges-component>
      </div>

      <div class="col-md-6" v-if="show_social_values && selected">
        <category-social-values-list :category="selected"></category-social-values-list>
      </div>

      <div class="col-md-6" v-if="!show_category_specs && original">
        <CategoriesForm v-on:close_form="closeForm" :key="selected.id" :categories="selected"></CategoriesForm>
      </div>
    </div>

    <el-dialog id="modal-remove" v-model="dialogVisible" title="Are you sure?" width="500">
      <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { Category } from '@/api_services/category'
import CategorySpecificationsComponent from './CategorySpecificationsComponent.vue'
import CategoryChargesComponent from './CategoryChargesComponent.vue'
import CategorySocialValuesList from './CategorySocialValuesList.vue'
import CategoriesForm from './CategoriesForm.vue'

export default {
  components: {
    CategorySpecificationsComponent,
    CategoryChargesComponent,
    CategorySocialValuesList,
    CategoriesForm
  },
  
  data() {
    return {
      dialogVisible: false,
      isLoading : true,
      categories: [],
      fields: ['id', 'name', 'action'],
      selected: null,
      original: null,
      validate: ['name'],
      to_remove: null,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      show_social_values: false,
      show_category_specs: false,
      show_category_charges: false
    }
  },
  created: function() {
    this.getCategories()
  },
  methods: {
    closeForm(value){
      if(value == "save") this.getCategories()
      
      this.original = null
      this.selected = null
    },
    showCategorySpecs(category) {
      this.show_category_specs = true
      this.show_category_charges = false
      this.show_social_values = false
      this.selected = Object.assign({}, category)
    },
    showCategoryCharges(category) {
      this.show_category_charges = true
      this.show_category_specs = false
      this.show_social_values = false
      this.selected = Object.assign({}, category)
    },
    showSocialValues(category) {
      this.show_social_values = true
      this.show_category_specs = false
      this.show_category_charges = false
      this.selected = Object.assign({}, category)
    },
    search() {
      this.meta.page = 1
      this.getCategories()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      Category.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getCategories()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing categry')
          console.error('error:', error.response.data)
        })
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type)
      this.original = Object.assign({}, type)
      this.show_category_specs = false
    },
    getCategories() {
      this.isLoading = true
      Category.get({ params: { term: this.term } })
        .then(result => {
          if (result.data.categories) this.categories = result.data.categories
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching categories')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = { id: null, name: null, email: null , kind: 0, type: 0}
      this.selected = Object.assign({}, this.original)
    }
  },
  computed: {

  }
}
</script>
