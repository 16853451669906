<template>
    <div>
      <h2 class="text-white">My Saved Searches</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="card my-3">

            <div class="card-body px-0 pb-2">
              <div class="card-body table-responsive">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <div class="bg-white border-radius-lg d-flex me-2">
                    <input
                      type="text"
                      name=""
                      id=""
                      class="form-control form-control-sm border-1 ps-3"
                      v-model="term"
                      placeholder="Search"
                    />
                    <button
                      class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                      @click="search()"
                    >
                      Search
                    </button>
                  </div>
                </nav>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr class="text-sm">
                      <th>Label</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!saved_searches.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No saved searches</td>
                    </tr>
                    <tr
                      v-else
                      class="text-sm"
                      v-for="saved_search in saved_searches"
                      :key="saved_search.id"
                    >
                      <td><a v-tooltip="'Click me'" :href="saved_search.url">{{ saved_search.label }}</a></td>
                      <td>{{ this.$filters.formattedDateWithDash(saved_search.created_at) }}</td>
                      <td style="width: 1%">
                        <a
                          class="link"
                          @click="showRemove(saved_search)"
                          v-tooltip="'Delete'"
                        >
                          <i class="fas fa-trash text-secondary"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <pagination :meta="meta" v-on:update="getSearches()" />
              </div>
            </div>
          </div>
        </div>
  
      </div>
  
      <el-dialog
        id="modal-remove"
        v-model="dialogVisible"
        title="Are you sure?"
        width="500"
      >
        <div v-if="to_remove">
          You are about to remove {{ to_remove.label }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>
  
    </div>
  </template>
  
  <script>
 import { UserProductSearch } from '@/api_services/user_product_search'
  
  export default {
    data() {
      return {
        dialogVisible: false,
        isLoading : true,
        saved_searches: [],
        to_remove: null,
        term: null,
        meta: {
          page: 1,
          total: 0,
          pages: 1
        },
      }
    },
    created: function() {
      this.getSearches()
    },
    methods: {
      search() {
        this.meta.page = 1
        this.getSearches()
      },
      showRemove(type) {
        this.to_remove = Object.assign({}, type)
        this.dialogVisible = true
      },
      remove() {
        UserProductSearch.delete(this.to_remove.id)
          .then(result => {
            this.$toast.success('Search deleted successfully')
            this.getSearches()
            this.dialogVisible =  false
          })
          .catch(error => {
            this.$toast.error('An error occured while removing the search')
            console.error('error:', error.response.data)
          })
      },
      getSearches() {
        this.isLoading = true
        const { page } = this.meta

        UserProductSearch.get({ params: { page, label: this.term } })
          .then(result => {
            if (result.data.user_product_searches) this.saved_searches = result.data.user_product_searches
            this.meta = result.data.meta
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching categories')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
    computed: {
  
    }
  }
  </script>