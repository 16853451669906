<template>
    <div class="card">
        <div class="card-body">
    <h4>{{ error_message }}</h4><br>
    <el-button @click="$router.push('/main/meetings')" type="primary"><i class="fa fa-arrow-left"></i>&nbsp;Back to List of Meetings</el-button>
    </div></div>
  </template>
  
  <script>
    import { Jaas } from '@/api_services/jaas'
  
    export default {
        
      data() {
        return {
          link: null,
          error_message: ""
        }
      },
      watch: {
        error_message: {
            handler(newValue) {
                this.error_message = newValue
            },
            deep: true
        },
      },
      created: function() {
            if (this.$route.params.link) {
                this.addUserToMeeting(this.$route.params.link)
            } else {
                alert('Link not found')
            }
        },
      methods: {
        addUserToMeeting(link){
            this.globalState.loading = true

            Jaas.addUserToMeeting( link,{})
            .then(result => {
                  if(result?.data?.success){
                    this.$router.push('/main/meetings?target=' + btoa(result.data.meeting_id))
                  }
                  else{
                    this.$toast.error(result?.data?.error)
                    this.error_message = result?.data?.error
                  }
                })
                .catch(error => {
                  this.$toast.error(error.response.data.error)
                  console.error('error:', error.response.data)

                  this.error_message = error.response.data.error
                })
                .finally(() => {
                  this.globalState.loading = false
                })
        }
      }
    }
  </script>
  