<template>
  <li class="nav-item">
    <router-link class="nav-link" :to="to" active-class="active">
      <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
        <slot name="icon"></slot>
      </div>
      <span class="nav-link-text ms-1">{{text}}</span>
    </router-link>
  </li>
</template>
<script>

export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>
