<template>
  <div>
    <loading :loading="loading"></loading>
    <page-not-found v-if="!supplier"></page-not-found>
    <div v-if="supplier && !loading">
      <div class="banner" style="text-align:left;position:relative;">
        <div style="height: 300px;overflow:hidden">
          <b-img style="width: 100%" fluid :src="supplier.banner_path || $store.state.default_banner"></b-img>
        </div>
        
        <b-img  rounded="circle" alt="Circle image" :src="supplier.absolute_logo_path || $store.state.no_image" style="position: absolute;left: 15px;bottom:-50px;height: 200px;width: 200px;"></b-img>
       
      </div>
      <div style="text-align:left;margin-top: 10px;">
       
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-10">
            <el-card class="border-0" header="Basic Information" header-bg-variant="info text-white">
              <h3 >{{supplier.name}}</h3>
              <hr>
              <div><b-icon icon="geo" class=" bg-info" variant="light"></b-icon> Address: {{ supplier.complete_address }}</div>
              <div><b-icon icon="slash-circle" class=" bg-info" variant="light"></b-icon> Website: {{ supplier.website || 'No Website' }}</div>
              <div><b-icon icon="vector-pen" class=" bg-info" variant="light"></b-icon> e-catalog: {{ supplier.e_catalog || 'No Catalog'}}</div>
              <div><b-icon icon="pie-chart" class=" bg-info" variant="light"></b-icon> Postal: {{ supplier.postal || 'No Postal address'}}</div>
              <div><b-icon icon="telephone-fill" class=" bg-info" variant="light"></b-icon> {{ supplier.mobile || 'No Mobile number'}}</div>
              <div><b-icon icon="envelope" class=" bg-info" variant="light"></b-icon> {{ supplier.email || 'No Email'}}</div>
              <br>
            </el-card>
            
            <el-card class="border-0" header="Company Details" style="text-align:left" header-bg-variant="info text-white">
          <table class="table table-striped">
            <tbody>

            <tr>
              <td>Office Address</td>
              <td>{{ (supplier.supplier_detail.office_address) ? supplier.supplier_detail.office_address : "N/A" }}</td>
            </tr>
            <tr>
              <td>Trading Status</td>
              <td v-if="!supplier.supplier_detail.other_trading_status">{{ (supplier.supplier_detail.trading_status) ? supplier.supplier_detail.trading_status : "N/A" }}</td>
              <td v-else>{{ supplier.supplier_detail.other_trading_status }}</td>
            </tr>
            <tr>
              <td>Registration Date</td>
              <td>{{ (supplier.supplier_detail.registration_date) ? supplier.supplier_detail.registration_date : "N/A" }}</td>
            </tr>
            <tr>
              <td>Company Registration #</td>
              <td>{{ (supplier.supplier_detail.company_registration_number) ? supplier.supplier_detail.company_registration_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Charity Registration #</td>
              <td>{{ (supplier.supplier_detail.charity_registration_number) ? supplier.supplier_detail.charity_registration_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Head Office DUNS #</td>
              <td>{{ (supplier.supplier_detail.head_office_duns_number) ? supplier.supplier_detail.head_office_duns_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Registered VAT #</td>
              <td>{{ (supplier.supplier_detail.registered_vat_number) ? supplier.supplier_detail.registered_vat_number : "N/A" }}</td>
            </tr>
            <tr v-if="supplier.supplier_detail.is_registered_with_trade_register">
              <td>Relevant Registration Numbers</td>
              <td>{{ supplier.supplier_detail.registered_vat_number_relevant_details }}</td>
            </tr>
            <tr v-if="supplier.supplier_detail.is_it_legal_requirement">
              <td>Legal Requirement Details</td>
              <td>{{ supplier.supplier_detail.is_it_legal_requirement_additional_details }}</td>
            </tr>
            <tr>
              <td>Trading Names</td>
              <td>{{ (supplier.supplier_detail.trading_names) ? supplier.supplier_detail.trading_names : "N/A" }}</td>
            </tr>
            <tr>
              <td>Enterprise Type</td>
              <td>{{ (supplier.supplier_detail.enterprise_type) ? supplier.supplier_detail.enterprise_type : "N/A" }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="container-fluid" v-if="supplier.supplier_detail.pscs">
                  <table v-for="psc in supplier.supplier_detail.pscs" :key="psc.id">
                    <tbody>
                      <tr>
                      <td colspan="2">Details of Persons of Significant Control (PSC) </td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{{ (psc.name) ? psc.name : "N/A" }}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>{{ (psc.date_of_birth) ? psc.date_of_birth : "N/A" }}</td>
                    </tr>
                    <tr>
                      <td>Nationality</td>
                      <td>{{ (psc.nationality) ? psc.nationality : "N/A" }}</td>
                    </tr>
                    <tr>
                      <td>Country, state</td>
                      <td>{{ (psc.country_state) ? psc.country_state : "N/A" }}</td>
                    </tr>
                    <tr>
                      <td>Service Address</td>
                      <td>{{ (psc.service_address) ? psc.service_address : "N/A" }}</td>
                    </tr>
                    <tr>
                      <td>PSC Join Date</td>
                      <td>{{ (psc.join_date) ? psc.join_date : "N/A" }}</td>
                    </tr>
                    <tr>
                      <td>PSC Condition(s) met</td>
                      <td>{{ (psc.conditions) ? psc.conditions : "N/A" }}</td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">Details of immediate parent company </td>
            </tr>
            <tr>
              <td>Full name of the immediate parent company</td>
              <td>{{ (supplier.supplier_detail.i_parent_company_name) ? supplier.supplier_detail.i_parent_company_name : "N/A" }}</td>
            </tr>
            <tr>
              <td>Registered Office Address</td>
              <td>{{ (supplier.supplier_detail.i_parent_company_office_address) ? supplier.supplier_detail.i_parent_company_office_address : "N/A" }}</td>
            </tr>
            <tr>
              <td>Registered Office Website Address</td>
              <td>{{ (supplier.supplier_detail.i_parent_company_website_address) ? supplier.supplier_detail.i_parent_company_website_address : "N/A" }}</td>
            </tr>
            <tr>
              <td>Registration #</td>
              <td>{{ (supplier.supplier_detail.i_parent_company_registration_number) ? supplier.supplier_detail.i_parent_company_registration_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Head Office DUNS #</td>
              <td>{{ (supplier.supplier_detail.i_parent_company_duns_number) ? supplier.supplier_detail.i_parent_company_duns_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Head Office VAT #</td>
              <td>{{ (supplier.supplier_detail.i_parent_company_vat_number) ? supplier.supplier_detail.i_parent_company_vat_number : "N/A" }}</td>
            </tr>
            <tr>
              <td colspan="2">Details of ultimate parent company </td>
            </tr>
            <tr>
              <td>Full name of the ultimate parent company</td>
              <td>{{ (supplier.supplier_detail.u_parent_company_name) ? supplier.supplier_detail.u_parent_company_name : "N/A" }}</td>
            </tr>
            <tr>
              <td>Registered Office Address</td>
              <td>{{ (supplier.supplier_detail.u_parent_company_office_address) ? supplier.supplier_detail.u_parent_company_office_address : "N/A" }}</td>
            </tr>
            <tr>
              <td>Registered Office Website Address</td>
              <td>{{ (supplier.supplier_detail.u_parent_company_website_address) ? supplier.supplier_detail.u_parent_company_website_address : "N/A" }}</td>
            </tr>
            <tr>
              <td>Registration #</td>
              <td>{{ (supplier.supplier_detail.u_parent_company_registration_number) ? supplier.supplier_detail.u_parent_company_registration_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Head Office DUNS #</td>
              <td>{{ (supplier.supplier_detail.u_parent_company_duns_number) ? supplier.supplier_detail.u_parent_company_duns_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Head Office VAT #</td>
              <td>{{ (supplier.supplier_detail.u_parent_company_vat_number) ? supplier.supplier_detail.u_parent_company_vat_number : "N/A" }}</td>
            </tr>
            </tbody>
          </table>
        </el-card>
        <el-card class="border-0" header="Bidding Model" style="text-align:left" header-bg-variant="info text-white">
          <table class="table table-striped">
            <tbody>
              <tr>
              <td>Are you bidding as the lead contact for a group of economic operators?</td>
              <td>{{ (supplier.supplier_bidding_model.are_you_lead_contact) ? "yes" : "no" }}</td>
            </tr>
            <tr>
              <td>Name of group of economic operators</td>
              <td>{{ (supplier.supplier_bidding_model.name_of_group_economic_operators) ? supplier.supplier_bidding_model.name_of_group_economic_operators : "N/A" }}</td>
            </tr>
            <tr>
              <td>Proposed legal structure if the group of economic operators intends to form a named single legal entity prior to signing a contract, if awarded. If you do not propose to form a single legal entity, please explain the legal structure.</td>
              <td>{{ (supplier.supplier_bidding_model.proposed_legal_structure) ? supplier.supplier_bidding_model.proposed_legal_structure : "N/A" }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="container-fluid" v-if="supplier.supplier_bidding_model.are_you_using_subcontractor">
                  <div v-if="supplier.supplier_bidding_model.subcontractors">
                    <table v-for="subcontractor in supplier.supplier_bidding_model.subcontractors" :key="subcontractor.id">
                      <tbody>
                        <tr>
                        <td colspan="2">Subcontractor Detail </td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{{ (subcontractor.name) ? subcontractor.name : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>Registered Address</td>
                        <td>{{ (subcontractor.registered_address) ? subcontractor.registered_address : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>Trading Status</td>
                        <td>{{ (subcontractor.trading_status) ? subcontractor.trading_status : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>Company Registration #</td>
                        <td>{{ (subcontractor.registration_number) ? subcontractor.registration_number : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>Head Office DUNS #</td>
                        <td>{{ (subcontractor.duns_number) ? subcontractor.duns_number : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>Registered VAT #</td>
                        <td>{{ (subcontractor.vat_number) ? subcontractor.vat_number : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>Type of Organisation</td>
                        <td>{{ (subcontractor.organisation_type) ? subcontractor.organisation_type : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>SME</td>
                        <td>{{ (subcontractor.sme) ? "yes" : "no" }}</td>
                      </tr>
                      <tr>
                        <td>The role each sub-contractor will take in providing the works and /or supplies e.g. key deliverables</td>
                        <td>{{ (subcontractor.role) ? subcontractor.role : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>The approximate % of contractual obligations assigned to each sub-contractor</td>
                        <td>{{ (subcontractor.contractual_percentage) ? subcontractor.contractual_percentage : "N/A" }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </el-card>
        <el-card class="border-0" header="Contact Details" style="text-align:left" header-bg-variant="info text-white">
          <table class="table table-striped">
            <tbody>
              <tr>
              <td>Contact Name</td>
              <td>{{ (supplier.supplier_contact_detail.contact_name) ? supplier.supplier_contact_detail.contact_name : "N/A" }}</td>
            </tr>
            <tr>
              <td>Organisation Name</td>
              <td>{{ (supplier.supplier_contact_detail.organisation_name) ? supplier.supplier_contact_detail.organisation_name : "N/A" }}</td>
            </tr>
            <tr>
              <td>Role</td>
              <td>{{ (supplier.supplier_contact_detail.role) ? supplier.supplier_contact_detail.role : "N/A" }}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{{ (supplier.supplier_contact_detail.phone_number) ? supplier.supplier_contact_detail.phone_number : "N/A" }}</td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td>{{ (supplier.supplier_contact_detail.email_address) ? supplier.supplier_contact_detail.email_address : "N/A" }}</td>
            </tr>
            <tr>
              <td>Postal Address</td>
              <td>{{ (supplier.supplier_contact_detail.postal_address) ? supplier.supplier_contact_detail.postal_address : "N/A" }}</td>
            </tr>
            </tbody>
          </table>
        </el-card>
          </div>
         
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { ProductSupplier } from '@/api_services/supplier'


export default {
  data() {
    return {
      loading: false,
      supplier: {}
    }
  },
  created: function() {
    let name = this.$route.params.name
    this.getSupplierByName(name)
  },
  methods: {
    getSupplierByName(name) {
       this.loading = true;
      ProductSupplier.findByName(name)
      .then(result => {
        this.loading = false;
        if (result.data.product_supplier) {
          this.supplier = result.data.product_supplier
        } else {
          this.supplier = null
        }
      })
      .catch(error => {
        this.loading = false
        this.supplier = null
        // this.$toast.error('An error occured while fetching supplier')
        console.error('error:', error.response.data)
      })
    }
  }
}
</script>
