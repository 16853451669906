<template>
    <h5 class="mb-3" v-html="selected.title"></h5>
    <div class="card">
        <div class="card-body">
            <el-timeline v-if="histories.length > 0" >
                <el-timeline-item
                placement="top"
                v-for="(history, index) in histories"
                :key="index"
                :timestamp="$filters.datetimeFormatFilter(history.created_at)"
                :hollow="true"
                :type=" index == 0 ? 'primary' : '' "
                >
                
                <!--<h6 class="mb-0">{{ history.column_name.replace('_', ' ').replace("s", "") }}</h6>-->
                <p class="p-0 mb-1">
                    {{ history.history_text }}
                </p>

                <p style="font-size:13px">By: <u>{{ history.user.name }}</u></p>

                </el-timeline-item>
            </el-timeline>

            <h5 v-else>No history found!</h5>
        </div>
    </div>
    
  </template>
  
  <script>
    import { ApplicationHistory } from '@/api_services/application_histories'
    
    export default {
      props: {
        table_name: String,
        table_id: Number,
        title_name: String,
      },
      data() {
        return {
            selected: {
                table_name: null,
                table_id: null,
                title: null
            },
            histories: [],
        }
      },
      created: function () {
        this.selected.table_name = this.table_name
        this.selected.table_id = this.table_id
        this.selected.title = this.title_name

        this.loadHistory(this.selected)

      },
      watch: {
        table_name(table_name) {
          this.selected.table_name = table_name
        },
        table_id(table_id) {
          this.selected.table_id = table_id
        },
        title_name(title_name) {
          this.selected.title = title_name
        }
      },
      computed: {
        
      },
      methods: {
        loadHistory(selected){
            this.globalState.loading = true

            ApplicationHistory.get({
                table_name: selected.table_name,
                table_id: selected.table_id,
            })
            .then(result => {
                if (result.data.histories) {
                    this.histories = result.data.histories
                }
                else{
                    this.$toast.error('No data found')
                }
            })
            .catch(error => {
                this.$toast.error('An error occured while fetching request')
                console.error('error:', error.response.data)
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      }
    }
  </script>
  