<template>
  <tr>
    <td>
      <div class="form-group">
        <input type="number" :state="validation('min')" v-model="range.min" class="form-control form-control-sm">
      </div>
    </td>
    <td>
      <div class="form-group">
        <input type="number" :state="validation('max')" v-model="range.max" class="form-control form-control-sm">
      </div>
    </td>
    <td style="width: 20%">
      <div class="form-group">
        <input type="number" class="form-control form-control-sm" v-model="range.rate"/>
      </div>
    </td>
    <td style="width: 20%">
      <div class="form-group">
        <input type="number" class="form-control form-control-sm" v-model="range.ppt_amount"/>
      </div>
        
    </td>
    <td style="width: 1%">
      <div class="form-group">
        <a class="link me-3" @click="save()" v-tooltip="'Save'">
          <i class="fa-solid fa-circle-check text-success"></i>
        </a>
        <a class="link" @click="remove()" v-tooltip="'Delete'">
          <i class="fas fa-trash text-danger"></i>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>

import { TransactionTypeRange } from '@/api_services/transaction_type_range'
export default {
  data() {
    return {
      fields: ['id','max', 'rate', 'ppt_amount'],
      validate: ['max', 'rate']
    }
  },
  props: ['range', 'index'],
  methods: {
    save(range) {
        this.$emit('save', this.range)
    },
    remove() {
        this.$emit('remove', this.index)
    },
    validation(field) {
      switch(field) {
        case 'max':
          return this.range.max ? true : false 
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'max') return "Max is required"
      return ""
    },
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>
