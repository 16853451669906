<template>
    <div>
        <div v-if="emails.length > 0" class="form-control mb-2">
            <el-tag
                v-for="(email, i) in emails"
                class="mb-1"
                :key="i"
                closable
                type="primary"
                style="margin-right: 10px"
                @close="handleClose(email)"
                :size="tag_size"
            >
                {{ email }}
            </el-tag>
        </div>
      
  
      <el-input
        class="input-new-tag mt-2"
        v-model="email"
        v-if="inputVisible"
        ref="saveTagInput"
        placeholder="Enter email here..."
        v-on:keydown.enter.prevent='handleInputConfirm'
        @blur="handleInputConfirm"
      >
      </el-input>
      <template v-else>
        <el-button type="primary" class="button-new-tag" :size="button_size" @click="showInput"
        >+ {{ add_text }}</el-button
        >
        <el-button type="success" class="button-new-tag" :size="button_size" @click="showModal = true"
          ><i class="fas fa-file-import"></i>&nbsp;Import Excel/CSV</el-button
        >
      </template>
    </div>

    <el-dialog destroy-on-close v-model="showModal" title="Upload File" width="500" >
      <form @submit="uploadFile">
        <p>Please upload an .xlsx or .csv file that contains only email addresses in a single column. Ensure there are no additional columns or data in the file before uploading</p>

        <dropzone label="Drop or select file" :max_files="1" :clean="0" :multiple="false" extensions="'xlsx,xls,csv'" @input="setFile($event)"></dropzone>
        <button type="submit" class="btn btn-success" v-loading="loading" :disabled="!form.file">
          {{ loading ? 'Saving' : 'Save' }}
        </button>
      </form>
    </el-dialog>

  </template>
  
  <script>
  import Dropzone from './Dropzone.vue'
  import { User } from '@/api_services/user'

  export default {
    emits: ['update'],
    components: {
      Dropzone
    },
    props:{
        email_data: {type: String, required: false}, 
        add_text: {
            type: String,
            required: false,
            default: "New Email"
        },
        button_size: {
            type: String,
            required: false,
            default: "small"
        },
        tag_size: {
            type: String,
            required: false,
            default: "small"
        },
    },
    data() {
      return {
        loading: false,
        showModal: false,
        form: {},
        error: null,
        emails: [],
        email: null,
        inputVisible: false
      }
    },
    watch: {
      emails: {
        handler(newValue, oldValue) {
          this.$emit('update', newValue.join(','))
        },
        deep: true
      },
      email_data() {
        if(this.email_data){
            this.email_data.split(",")
            this.emails = this.email_data.split(",")
        }
        else    
            this.emails = []
      }
    },
    created: function() {
      if(this.email_data){
        this.emails = this.email_data.split(",")
      }
    },
    methods: {
      getFormData() {
        var formData = new FormData()
        formData.append('file', this.form.file)
        formData.append('email_data', this.emails)
        return formData
      },
      uploadFile(evt) {
        evt.preventDefault()
        let formData = this.getFormData()

        this.loading = true

        User.importMultipleEmails(formData)
          .then(result => {
            if(result.data.status == 1){
              this.$toast.success('Successfully imported')
              this.emails = result.data.email_data
            }
            else{
              this.$toast.error('Failed to import')
            }

            this.showModal = false
            this.form.file = null
          })
          .catch(error => {

          }).finally(() => {
            this.loading = false
        })
      },
      setFile(files) {
        this.form.file = files[0]
      },
      addEmail() {
        this.emails.push(this.email)
      },
      handleClose(tag) {
        this.emails.splice(this.emails.indexOf(tag), 1)
      },
      showInput() {
        this.inputVisible = true 
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus()
        })
      },
      handleInputConfirm(evt) {
        if (!this.email){
            this.inputVisible = false
            return false
        } 

        this.error = null
        if (evt) evt.preventDefault()
        let email = this.email
        let isValid = this.validatedEmail(email)
        if (!isValid) {
          this.email = ''
          this.error = "Invalid Email"
          
          this.$toast.error(this.error)
          this.inputVisible = false

          let self = this
          setTimeout(function () {
            self.error = null
          }, 3000);
          return false;
        } 
        if (email) {
          if(this.emails.includes(email)){
            this.email = ''
            this.error = "Email already in the list"
            
            this.$toast.warning(this.error)
            this.inputVisible = false

            let self = this
            setTimeout(function () {
              self.error = null
            }, 3000);
            return false;

            return false
          }

          this.emails.push(email)
        }
        this.inputVisible = false
        this.email = ''
        this.error = null
  
        
      },
      validatedEmail(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return true
        }
        return false
      }
    }
  }
  </script>
  
