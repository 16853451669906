import api from './api'

const API = '/api/monthly_collections'

export const MonthlyCollection = {
  createQuerySummary: function(params) {
    return api.create(API+'/query_summary', params)
  },
  getQuerySummary: function(params) {
    return api.get(API+'/query_summary', params)
  },
  getCardTransactions: function(params) {
    return api.get(API+'/card_transactions', params)
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  reset: function(params) {
    return api.update(API+'/'+params.id+'/reset', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id)
  },
  getLatest: function(params={}) {
    return api.get(`${API}/latest`, params)
  },
}
