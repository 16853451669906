<template>
  <div>
    <h5>Additional Documents</h5>
    <file-upload :drag="true" :max-size="30000000" :multiple="true" @change="setFiles($event)" :show-list="false"
      :empty="true" label="Select Files"></file-upload>

    <transition-group name="fade" tag="ul" class="document-ul">
      <li v-for="document, i in form.buyer_additional_documents" :key="document.id" class="file-item">
        <a :href="document.url" target="_blank" :underline="true" :download="document.file_name">{{ document.file_name
          }}</a> <span style="font-size: 12px">({{ document.id ? 'OLD' : 'NEW' }})</span>
        <el-tooltip effect="dark" content="Remove" placement="top-end">
          <a style="float: right;cursor: pointer;" @click="handleRemoveFile(document, i)"><i
              class="fas fa-times"></i></a>
        </el-tooltip>
      </li>
    </transition-group>

  </div>
</template>

<script>

import FileUpload from '../../common/FileUpload/FileUpload.vue'

export default {
  components: { FileUpload },
  props: ['buyer_search_query'],
  data() {
    return {
      form: {
        buyer_additional_documents: []
      },
      uids: []
    }
  },
  watch: {
    form: {
      handler: function (newValue) {
        this.updateForm(this.form)
      },
      deep: true
    }
  },
  created: function () {
    if (this.buyer_search_query) this.form = Object.assign({}, this.buyer_search_query)
  },
  methods: {
    updateForm(form) {
      this.$emit('update', form)
    },
    handleRemoveFile(document, i) {
      this.form.buyer_additional_documents.splice(i, 1)
    },
    toBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    setFiles(files) {
      
      let self = this
      if (files.length > 0) {
        files.forEach(file => {
          if (!self.uids.includes(file.uid)) {
            self.toBase64(file.raw)
              .then(base64String => {
                self.uids.push(file.uid)
                self.buyer_search_query.buyer_additional_documents.push({
                  id: file.id,
                  file_name: file.name,
                  file: file.raw,
                  url: 'data:' + file.raw.type +';base64,' + base64String
                })

              })
              .catch(error => {
                console.error(error);
              });
          }
        }); 
      }
      this.$emit('update', this.files)
    }
  }
}
</script>

<style>

.file-item{
  padding: 3px
}

.file-item:hover{
  background-color: #eae8e8;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.document-ul {
  list-style: none;
  padding-left:0px
}

</style>
