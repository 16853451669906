import api from './api'
import { TransactionType } from './transaction_type'

const API = '/ranges'

export const TransactionTypeRange = {
  getFullPath(params) {
    return TransactionType.getFullPath(params)+'/'+params.transaction_type_id+API
  },
  get: function(params) {
    return api.get(TransactionTypeRange.getFullPath(params), params)
  },
  create: function(params) {
    return api.create(TransactionTypeRange.getFullPath(params), params)
  },
  update: function(params) {
    return api.update(TransactionTypeRange.getFullPath(params)+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(TransactionTypeRange.getFullPath(params)+'/'+params.id, params)
  }
}
