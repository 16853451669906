<template>
  <div>
    <h2>Manage Global Transaction types</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-8">
        <el-card no-body header="Global Transaction types" class="my-3 border-0">
          <b-tabs pills card vertical v-model="category">
            <b-tab
              v-for="(item, index) in categories"
              :key="index"
              :title="categories[index]"
              @click="getTransactionTypeByCategory(index)"
            >
            <b-nav-form style="float:right">
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search" v-model="term"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="button" @click="search()">Search</b-button>
            <b-button size="sm" @click="create()" class="mr-2" style="margin-left: 5px">Add</b-button>
          </b-nav-form>
            <b-table :items="global_transaction_types" :fields="fields_1" striped responsive="sm">
              <template #cell(action)="row">
                  <b-button size="sm" @click="showEdit(row.item)" class="mr-2">Edit</b-button>
                  <b-button size="sm" @click="showRemove(row.item)" class="mr-2" ref="showRemove">Delete</b-button>
                </template>
            </b-table>

            <PaginateComponent :meta="meta" v-on:update="search()"/>
            </b-tab>
          </b-tabs>
        </el-card>
      </div>

      <div class="col-md-4" v-if="original && !show_ranges">
          <el-card :header="selectedName(original)" class="my-3 border-0">
            <b-form  @submit="onSubmit" @reset="onCancel">
              <b-form-group id="name" label="Name" label-for="input-name" valid-feedback=""
                :invalid-feedback="invalidFeedback('name')"
                :state="validation('name')"
              >
                <b-form-input id="input-name" v-model="selected.name" :state="validation('name')" trim></b-form-input>
              </b-form-group>
              <b-form-group label="Category" label-for="select-category">
                <b-form-select id="select-category" v-model="selected.category" :state="validation('category')">
                  <option
                    v-for="(cat, index) in categories"
                    :key="index"
                    :value="index"
                  >{{ cat }}</option>
                </b-form-select>
              </b-form-group>
              <b-button type="submit"  :disabled="!validateAll">Submit</b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-form>
          </el-card>
      </div>
    </div>
 
    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import { GlobalTransactionType } from '@/api_services/global_transaction_type'
import PaginateComponent from '../../common/Paginate.vue'

export default {
  data() {
    return {
      dialogVisible: false,
      global_transaction_types: [],
      category: 1,
      fields: ['id', 'name','category', 'action'],
      fields_1: ['id', 'name', 'action'],
      transaction_types: [],
      selected: null,
      original: null,
      validate: ['name', 'category'],
      to_remove: null,
      supplier_id: null,
      show_ranges: false,
      term: null,
      categories: ["", "Banking", "Merchant Services", "Direct Debit", "Payment Gateway", "IVR and Call Masking", "Innovation FS"],
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  components: { PaginateComponent },
  created: function() {
    this.getTransactionTypeByCategory(this.category)
  },
  methods: {
    getCategory(type) {
      return this.categories[type.category]
    },
    search() {
      this.getTransactionTypeByCategory(this.category)
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      GlobalTransactionType.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getTransactionTypeByCategory(this.category)
        })
        .catch(error => {
          this.$toast.error('An error occured while removing transaction type')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit '+selected.name: 'New'
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
      this.show_ranges = false
    },
    showRanges(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
      this.show_ranges = true
    },
    getTransactionTypeByCategory(payload) {
      this.reset()
      GlobalTransactionType.get({params: {category: payload, term: this.term, page: this.meta.page}})
        .then(result => {
          this.global_transaction_types = result.data.global_transaction_types
          this.meta = result.data.meta
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching transaction types')
          console.log(error)
          console.error('error:', error.response.data)
        })
    },
    validation(field) {
      switch(field) {
        case 'name':
          if (!this.selected.name) this.selected.name = ""
          return this.selected.name.length > 0
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'name') return "Name is required"
      return ""
    },
    onSubmit(evt) {
        evt.preventDefault()
        var action = this.selected.id ? GlobalTransactionType.update(this.selected) : GlobalTransactionType.create(this.selected)
        action.then(result => {
          this.$toast.success('Saved successfully')
          // this.getTransactionTypes()
          this.getTransactionTypeByCategory(this.category)
        })
        .catch(error => {
          this.$toast.error('An error occured while saving transaction type')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {name: null, category: this.category}
      this.selected = Object.assign({}, this.original);
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>