<template>
  <div>
    <merchant-services-component :code="code"></merchant-services-component>
    <!-- <ComparisonTransactionComponent :code="code" :category="2"></ComparisonTransactionComponent> -->
  </div>
</template>

<script>
// import ComparisonTransactionComponent from './ComparisonTransactionComponent.vue'
import MerchantServicesComponent from '@/components/pages/cost_comparison/merchant_services/MerchantServicesComponent.vue'
export default {
  data() {
    return {
      code: null
    }
  },
  components: { MerchantServicesComponent },
  created () {
    if ( this.code && this.$route.query.code !== this.code) {
      this.$router.push({ path: '/merchant-services?code=' + this.$route.query.code })
    }

    if (this.$route.query.code) {
      this.code = this.$route.query.code
    }
  },
  methods: {
    
  },
}
</script>