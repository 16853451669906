import api from './api'

const API = '/api/categories'

export const Category = {
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  update: function(id, params) {
    return api.update(API + '/' + id, params)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id, params)
  },
  specifications: function(params) {
    return api.get(API + '/' + params.id + '/specifications', {
      params: params
    })
  },
  saveSpecification: function(params) {
    return api.create(
      API + '/' + params.category_id + '/specifications',
      params
    )
  },
  checkMissing: function(params) {
    return api.create(API + '/' + params.category_id + '/check_missing', params)
  },
  charges: function(params) {
    return api.get(API + '/' + params.id + '/additional_charges', {
      params: params
    })
  },
  saveCharge: function(params) {
    return api.create(
      API + '/' + params.category_id + '/additional_charges',
      params
    )
  },
  checkMissingAdditionalCharges: function(params) {
    return api.create(
      API + '/' + params.category_id + '/check_missing_additional_charges',
      params
    )
  },
  socialValues: function(params) {
    return api.get(API + '/' + params.id + '/social-values', {
      params: params
    })
  },
  saveSocialValue: function(params) {
    return api.create(API + '/' + params.category_id + '/social-values', params)
  },
  checkMissingSocialValues: function(params) {
    return api.create(
      API + '/' + params.category_id + '/check-missing-social-values',
      params
    )
  }
}
