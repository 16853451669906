<template>
    <ProcurementProjectsList :if_from_settings="1"></ProcurementProjectsList>
  </template>
  
  <script>
  import ProcurementProjectsList from './ProcurementProjectsList.vue'

  export default {
    components: {
        ProcurementProjectsList
    },
    data() {
      return {
        
      }
    },
    created: function() {
        
    },
    methods: {
        
    }
  }
  </script>
  