import api from './api'

const API = '/api/roles'

export const Role = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  getRolePermissions: function(id, params) {
    return api.get(API + '/' + id + '/permissions', params)
  },
  setPermission: function(id, params) {
    return api.create(API + '/' + id + '/set_permission', params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  addAllPermissions: function(params) {
    return api.create(API+'/add_all_permissions', params)
  },
  removeAllPermissions: function(params) {
    return api.create(API+'/remove_all_permissions', params)
  },
  removePermission: function(params) {
    return api.create(API+'/remove_permission', params)
  },
  addPermission: function(params) {
    return api.create(API+'/add_permission', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  getPermissions: function(params) {
    return api.get(API + '/permissions', params)
  },
}
