<template>
  <div>
    <div style="text-align: left;" class="row" v-if="lot">
      <div class="col-md-12">
        <div class="my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>{{ lot.name }}</h4>
              <div>{{ kind ? 'Social Values' : 'Specifications' }}</div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <el-button plain type="primary" @click="getSpecs()" size="small"
                  style="margin-left: 10px;float: right;">Refresh</el-button>
              </div>
            </div>

            <div class="table-responsive" style="max-height: 500px;" v-loading="loading">
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-sm font-weight-bolder">
                      <div class="row">
                        <div class="col-md-4">Name</div>
                        <div class="col-md-1">Type/Unit</div>
                        <div class="col-md-1"></div>
                        <div class="col-md-3">Value</div>
                        <div class="col-md-2">Action</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="spec in spec_values" :key="spec.id">
                    <td class="text-sm">
                      <pin-lot-supplier-spec-value-form :spec="spec"
                        @save="handleSave($event)"></pin-lot-supplier-spec-value-form>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="loading">Loading....</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getSpecs()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  mapState
} from 'vuex'

import Swal from 'sweetalert2'

import { SupplierLotSpecValue } from '@/api_services/supplier_lot_spec_value'
import PinLotSupplierSpecValueForm from './PinLotSupplierSpecValueForm.vue'

export default {
  components: { PinLotSupplierSpecValueForm },
  props: {
    lot: {
      type: Object,
      required: false,
      default: null
    },
    kind: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      spec_values: [],
      term: null,
      loadung: false,
      selected: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
    }
  },
  watch: {
    status: function () {
      this.getLots()
    }
  },
  computed: {
    ...mapState(['savedUser'])
  },
  created: function () {
    this.getSpecs()
  },
  methods: {

    handleView(lot, kind) {
      this.selected = Object.assign({}, lot)
      this.showView = true
      this.kind = kind
    },

    search() {
      this.meta.page = 1
      this.getSpecs()
    },
    handleSave(spec) {
      this.getSpecs()
    },
    getSpecs() {

      let params = {
        page: this.meta.page,
        term: this.term,
        status: this.status,
        lot_id: this.lot?.id,
        kind: this.kind
      }
      this.loading = true
      SupplierLotSpecValue.get(params)
        .then(result => {
          if (result.data.spec_values) this.spec_values = result.data.spec_values
          this.meta = result.data.meta
          this.loading = false
        })
        .catch(error => {

        })
        .finally(() => {
          this.loading = false
        })

    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
