<template>
  <div>
    <h2 class="text-white">Supplier Specifications</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-6">
        <div class="card my-3">
          <!-- <div class="card-header"><h4>Specifications/Requirements</h4></div> -->
          <div class="card-body px-0 pb-2"> 
            <div class="table-responsive">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term" placeholder="Search">
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="search()">Search</button>
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="create()">Add</button>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">description</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">unit</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!specifications.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else v-for="specification in specifications" :key="specification.id">
                    <td class="text-sm text-wrap">
                      <text-with-show-more :string="specification.original_description" :length="100"></text-with-show-more>
                      <!-- <span class="text-wrap">{{ specification.original_description }}</span> -->
                    </td>
                    <td class="text-sm">{{ $store.state.getUnitLabel(specification.unit) }}</td>
                    <td class="text-sm" style="width: 1%">
                      <a class="link me-3" @click="showEdit(specification)" v-tooltip="'Edit'">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(specification)" v-tooltip="'Delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getSpecifications()"/>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="original">
        <div class="card my-3">
          <div class="card-header"><h5>{{ selectedName(original) }}</h5></div>
          <div class="card-body">
            <form @submit.prevent="onSubmit" @reset="onCancel">

              <div class="form-group">
                <label class="form-label">{{ getLabel() }}</label>

                <search-specification-component 
                :current_desc="current_desc"
                :key="selected.id"
                v-on:set-selected-word="setSelectedWord"
                v-model:word="selected.original_description" 
                :class="{ 'is-invalid': v$.selected.original_description.$errors.length }"
                @update="checkGlobalSpec($event)"></search-specification-component>

                <div class="input-errors" v-for="error of v$.selected.original_description.$errors" :key="error.$uid">
                  <div class="error-text">{{ getLabel() }} {{ error.$message }}</div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">Unit</label>

                <select name="unit" 
                v-model="selected.unit"
                :class="{ 'is-invalid': v$.selected.unit.$errors.length, 'form-select': true }">
                  <option v-for="unit in $store.state.units" :key="unit.id" :value="unit.value">{{ unit.text }}</option>
                </select>

                <div class="input-errors" v-for="error of v$.selected.unit.$errors" :key="error.$uid">
                  <div class="error-text">Unit {{ error.$message }}</div>
                </div>
              </div>
              
              <button type="submit" class="btn bg-gradient-success btn-sm" >Save</button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import { SupplierSpecification } from '@/api_services/supplier_specification'
import SearchSpecificationComponent from '../../common/SearchSpecificationComponent.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup () {
      return { v$: useVuelidate() }
    },
  components: { SearchSpecificationComponent },
  data() {
    return {
      current_desc: "",
      dialogVisible: false,
      isLoading : true,
      specifications:[],
      fields: ['id', 'original_description','unit','action'],
      selected: null,
      original: null,
      validate: ['original_description','unit'],
      to_remove: null,
      term: null,
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  validations() {
      return {
        selected: {
          original_description: {
            required,
            $autoDirty: true
          },
          unit: {
            required,
            $autoDirty: true
          }
        },
      }
    },
  created: function() {
    this.getSpecifications()
  },
  methods: {
    setSelectedWord(value){
      this.selected.original_description = value
      this.selected.description = value
    },
    getLabel() {
      if (!this.original.id) {
        return "Description"
      } else {
        return "Edit "
      }
    },
    checkGlobalSpec(object) {
      
      if (object instanceof Object) {
          this.selected.description = object.description
          this.selected.original_description = object.original_description

          this.selected.type = object.type
        } else {
          this.selected.original_description = object
          this.selected.description = object
        }
    },
    search() {
      this.meta.page = 1
      this.getSpecifications()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      SupplierSpecification.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getSpecifications()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing spec')
          console.error('error:', error)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit ' : 'New'
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
      this.selected.company_id = this.$store.state.company_id
      this.current_desc = this.selected.original_description
    },
    getSpecifications() {
      this.isLoading = true
      SupplierSpecification.get({params: { term: this.term }})
        .then(result => {
          if(result.data.specifications)
            this.specifications = result.data.specifications
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching specifications')
          console.error('error:', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async onSubmit(evt) {
        evt.preventDefault()

        const result = await this.v$.$validate()
        if (!result) return

        var action = this.selected.id ? SupplierSpecification.update(this.selected.id, this.selected) : SupplierSpecification.create(this.selected)
        action.then(result => {
          this.$toast.success('Saved successfully')
          this.reset()
          this.getSpecifications()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a spec')
          console.log('error:', error)
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {description: null, unit: null}
      this.selected = Object.assign({}, this.original);
      this.selected.company_id = this.$store.state.company_id
      this.current_desc = ""
    }
  },
  computed: {

  }
}
</script>
