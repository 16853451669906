import api from './api'

const API = '/api/profile'

export const UserProfile = {
  show: function() {
    return api.get(API)
  },
  update: function(params) {
    return api.create(API+'/update', params)
  }
}
