<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      :controls="default_images.length > 1"
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="(image,i) in default_images" :key="i"
        caption=""
        text=""
        :img-src="image"
      ></b-carousel-slide>

  
    </b-carousel>

  </div>
</template>

<script>
  export default {
    props:['images'],
    data() {
      return {
        slide: 0,
        sliding: null,
        default_images: [require('@/assets/no_image.png')]
      }
    },
    created: function() {
      if (this.images.length) this.default_images = this.images
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    }
  }
</script>