import api from './api'

const API = '/api/supplierhub-specs'

export const SupplierhubSpec = {
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  import: function(params) {
    return api.createFormData(`${API}/import`, params)
  },
  show: function(id) {
    return api.get(`${API}/${id}`)
  },
  update: function(params) {
    return api.update(`${API}/${params.id}`, params)
  },
  getComments: function(id, params) {
    return api.get(`${API}/${id}/comments`, {params: params})
  },
  removeComment: function(id, form) {
    return api.delete(`${API}/${id}/remove-comment`, form)
  },
  addComment: function(id, form) {
    return api.create(`${API}/${id}/add-comment`, form)
  },
  approve: function(id, form) {
    return api.create(`${API}/${id}/approve`, form)
  },
  delete: function(id, type) {
    return api.delete(`${API}/${id}/${type}`)
  }
}
