<template>
  <div v-if="product">
    <h4>DIRECT AWARD</h4>
    <p>{{ this.product.name }}</p>
    <hr>
    <el-form label-position="top" @submit.prevent="submit">
      <el-form-item label="Select Reason *" prop="name"
        :error="v.form.direct_award_remarks.$errors.length ? 'Please provide a reason' : ''">
        <el-select v-model="form.direct_award_remarks" filterable>
          <el-option :value="reason" v-for="(reason,i) in DirectAwardReasons" :key="i"></el-option>
        </el-select>
      </el-form-item>

      <div class="row">
        <div class="col-sm-2">

        </div>
        <div class="col-sm-8" style="text-align: center;">
          <button type="submit" class="btn btn-block bg-gradient-warning btn-sm btn-responsive"
            @click="handleSubmitOption('pricing')" style="margin-right: 5px">
            <i class="fa-solid fa-file-arrow-down"></i> Request for Pricing
          </button>
          <button type="submit" class="btn btn-block bg-gradient-success btn-sm btn-responsive"
            @click="handleSubmitOption('direct_award')">
            <i class="fa-solid fa-file-arrow-down"></i> Direct Award
          </button>
        </div>
      </div>



      <!-- <el-button native-type="submit" type="primary" plain>Save</el-button> -->
    </el-form>
  </div>
</template>

<script>

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

import { Product } from '@/api_services/product'

export default {
  props: ['product', 'params'],
  computed: {
    ...mapState(['DirectAwardReasons']),
  }, 
  data() {
    return {
      form: {
        direct_award_remarks: ""
      },
      option: null
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        direct_award_remarks: {
          required,
          $autoDirty: true
        },
      },
    }
  },
  created: function () {
  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return
      this.form.direct_award_type = this.option
      this.form.direct_award_product_id = this.product.id

      if (this.option == 'direct_award') {
        this.confirmDirectAward()
      } else {
        this.$emit("requestPricing",this.form)
      }
    },
    confirmDirectAward() {
      Swal.fire({
        title: 'CPRAS Procurement Platform',
        text:
          'You are about to start a direct call-off for this supplier. Click "Proceed" to continue.',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.processCallOff()
        }
      })
    },
    processCallOff() {
      Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        html: `
            <div class="spinner-border text-info" role="status"></div>
            <div class="spinner-border text-success" role="status"></div>
            <div class="spinner-border text-secondary" role="status"></div>
            <div>Preparing the agreement. Please wait</div>`,
      })

      let params = this.params
      params.direct_award_remarks = this.form.direct_award_remarks
      Product.directAward(this.product.id, params)
        .then(result => {
          let buyer_search_query = result.data.buyer_search_query
          let product_search_result = result.data.product_search_result
          this.$router.push({ path: 'main/product_search_queries/list/' + buyer_search_query.id + '/view', query: { product_search_result: product_search_result.id } })
        })
        .catch(error => {
          console.log(error)
          Swal.close();  // Closes the modal
        })
        .finally(() => {
          
        })
    },
    handleSubmitOption(option) {
      this.option = option
    }
  }
}
</script>

<style scoped></style>
