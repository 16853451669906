<template>
  <div class="card bg-gradient-danger mx-3">
    <div class="card-header bg-gradient-danger text-center"><h5 class="text-white">Innovation FS</h5></div>
    <div class="card-body text-center">
      <router-link to="/innovation-fs" class="stretched-link text-light">
        <i class="fa-solid fa-terminal"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
