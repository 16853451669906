import api from './api'

const API = '/api/product_search_messages'

export const ProductSearchMessageApi = {
  firstOrCreate: (params) => {
    return api.get(`${API}/first-or-create`, {params})
  },
  get: (params) => {
    return api.get(API, {params})
  },
  update: (id, params) => {
    return api.update(`${API}/${id}`, params)
  },
  reply: (id, params) => {
    return api.createFormData(`${API}/${id}/reply`, params)
  },
  shareReply: (replyId, params) => {
    return api.create(`${API}/reply/${replyId}/share`, params)
  },
  sendToAll: (form) => {
    return api.createFormData(`${API}/send-to-all`, form)
  }, 
  exportConversationAsCSV: function(id, type, params) {
    return api.get(API+"/export_conversation/" + id + "/" + type, params)
  },
  exportConversationAsPDF: function(id, type, params) {
    return api.download(API+"/export_conversation/" + id + "/" + type, params)
  },
}
