import api from './api'

const API = '/api/pin-price-costs'

export const PinPriceCost = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(`${API}/${params.id}`, params)
  },
  updatePriceCostStatus: function(params) {
    return api.update(`${API}/update-status/${params.id}`, params)
  },
  saveWeightings: function(id, form) {
    return api.update(`${API}/${id}/save-weightings`, form)
  },
  savePricing: function(id, form) {
    return api.update(`${API}/${id}/save-pricing`, form)
  },
  delete: function(id) {
    return api.delete(API+'/'+id)
  }
}
