<template>
  <div v-loading="loading">
    <hr />
    <div style="text-align:left;margin-bottom: 10px">
      <h5>Social Values</h5>
    </div>

    <el-table class="table-striped" header-row-class-name="text-primary" :data="form.social_values" style="width: 100%">
      <el-table-column label="Name" :min-width="150">
        <template v-slot="props">
          <text-with-show-more :string="props.row.original_description ?? findOneGlobalSocialValueOriginalDescription(props.row.description)
      " :length="200"></text-with-show-more>
        </template>
      </el-table-column>

      <el-table-column :min-width="30" label="Mandatory">
        <template v-slot="props">
          <el-checkbox v-model="props.row.required" :label="props.row.required ? 'Yes' : 'No'" size="large"
            :true-value="true" :false-value="false" />
        </template>
      </el-table-column>

      <el-table-column :min-width="30" label="IMPORTANCE (OUT OF 10)">
        <template v-slot="props">
          <el-select v-model="props.row.level_of_importance">
            <el-option v-for="imp in importance" :key="imp" :value="imp">{{
              imp
              }}</el-option>
          </el-select>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import {
  Category
} from '@/api_services/category'

export default {
  props: ['selected'],
  data() {
    return {
      form: null,
      importance: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      global_social_values: [],
      loading: false
    }
  },
  watch: {
    selected: function () {
      this.form = Object.assign({}, this.selected)
    },
    form: {
      handler: function (newValue) {
        this.updateParent(this.form)
      },
      deep: true
    }
  },
  created: function () {
    if (this.selected) {
      this.form = Object.assign({}, this.selected)
    }
    this.getGlobalSocialValuesOriginalDescriptions()
  },
  methods: {
    updateParent(form) {
      this.$emit('update', form)
    },
    findOneGlobalSocialValueOriginalDescription(description) {
      let text = null
      this.global_social_values.forEach(function (sv) {
        if (sv.description == description) text = sv.original_description
      })

      return text
    },
    getGlobalSocialValuesOriginalDescriptions() {
      let ids = []

      this.form.social_values.forEach(function (spec) {
        ids.push(spec.global_social_value_id)
      })

      this.global_specifications = []
      this.loading = true
      Category.socialValues({
          id: this.form.category_id,
          per_page: 999,
          spec_ids: ids
        })
        .then(result => {
          let self = this
          result.data.social_values.forEach(function (spec) {
            self.global_social_values.push(spec)
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style></style>
