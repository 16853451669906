<template>
  <div v-loading="globalState.loading" class="container">
    <div v-if="countDown">
      <el-result icon="success" title="BCP Form Submitted" :sub-title="'Reloading page in '+countDown">
        <template #extra>
          <el-button type="primary" size="small">Go to dashboard</el-button>
        </template>
      </el-result>

    </div>
    <div v-else>
      <el-form label-position="top" @submit.prevent="submit">
        <h5>Initiate BCP activation by either signing the form or uploading a signed document.</h5>
        <el-alert type="warning" :closable="false" class="mb-3">
          If you opt to upload a signed document, it will undergo verification by management before your BCP is
          activated.
        </el-alert>
        <el-tabs type="border-card">
          <el-tab-pane label="Signature" :disabled="file">
            <div class="row" v-if="!file">
              <div class="col-md-9">
                <el-form-item label="Representative" prop="representative_name"
                  :error="v.form.representative_name.$errors.length ? 'Name is required' : ''">
                  <el-input v-model="form.representative_name" style="width: 100%;"></el-input>
                </el-form-item>
              </div>

              <div class="col-md-12">
                <el-form-item label="Signature" prop="signature"
                  :error="v.form.signature.$errors.length ? 'Signature is required' : ''">
                  <el-input v-model="form.signature" style="width: 100%;font-size: 50px;height: 85px;"
                    class="buyer-signature karumbi-font-lg"></el-input>
                </el-form-item>
              </div>

              <div class="col-md-6">
                <el-form-item label="" prop="accept_signature"
                  :class="v.form.accept_signature.$errors.length ? 'checkbox-error' : ''">
                  <el-checkbox v-model="form.accept_signature"
                    label="Please click on this to acknowledge it as a signature for the purpose of this BCP activation."
                    size="large" :checked="form.accept_signature" />
                </el-form-item>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Upload Signed PDF">
            <div>
              <company-bcp-upload @upload="uploadFile($event)"></company-bcp-upload>
            </div>
          </el-tab-pane>
        </el-tabs>

        <hr>

        <el-button native-type="submit" type="primary" plain>Save</el-button>
      </el-form>
      <div style="text-align:right" v-if="show_skip">
        <el-button plain @click=handleSkip>Skip Activation</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import {
  CompanyBcpApi
} from '@/api_services/company_bcp'

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'
import CompanyBcpUpload from './CompanyBcpUpload.vue'

import Swal from 'sweetalert2';

export default {
  components: { CompanyBcpUpload },
  props: {
    bcp_form: {
      type: Object,
      required: false
    },
    show_skip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: null,
      },
      file: null,
      countDown: 0
    }
  },
  watch: {
    bcp_form: function () {
      this.form = Object.assign({}, this.bcp_form)
    },
    'form.representative_name': function () {
      this.form.signature = this.form.representative_name
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    let form = {
      representative_name: {
        required,
        $autoDirty: true
      },
      signature: {
        required,
        $autoDirty: true
      },
      accept_signature: {
        required,
        $autoDirty: true
      }
    }

    return {
      form: this.file ? {} : form
    }
  },
  created: function () {
    if (this.bcp_form) this.form = Object.assign({}, this.bcp_form)
    else {
      if (this.$store.state.savedUser) {
        this.form.representative_name = this.$store.state.savedUser.name
        this.form.signature = this.$store.state.savedUser.name
      }
    }
  },
  methods: {
    uploadFile(file) {
      this.file = file
    },
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.globalState.loading = true
      this.globalState.message = "Saving..."

      let form = this.getFormData()

      let request = this.form.id ?
        CompanyBcpApi.update(form) :
        CompanyBcpApi.create(form)

      request
        .then(result => {
          this.$toast.success('BCP Form submitted')
          this.countDown = 3
          this.closeForm()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.globalState.loading = false
        })
    },
    async closeForm() {
      let user = this.$store.state.savedUser
      user.apply_bcp_on_sign_up = false
      this.$store.commit('saveUser', user)

      await this.$delay(1000);
      this.countDown = 2
      await this.$delay(1000);
      this.countDown = 1
      await this.$delay(1000);
      window.location.reload()
    },
    getFormData() {
      var formData = new FormData()
      if (this.file) {
        formData.append('file', this.file.file)
        formData.append('file_name', this.file?.name)
      }

      formData.append('signature', this.form.signature)
      formData.append('representative_name', this.form.representative_name)

      return formData
    },
    handleSkip() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will no longer be required to fill up this form. You can just visit the BCP page if you want to activate the BCP feature.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.skipAction()
        }
      });
    },
    skipAction() {
      this.globalState.loading = true
      this.globalState.message = "Saving..."

    
      CompanyBcpApi.skip()
        .then(result => {
          let user = this.$store.state.savedUser
          user.apply_bcp_on_sign_up = false
          this.$store.commit('saveUser', user)
          window.location.reload()
          // this.$emit('skip', true)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.globalState.loading = false
        })
    }
  }
}
</script>

<style></style>
