<template>
<div>
  <el-card>
    <h5>Granular Weightings</h5>
    <br>
    <div class="">
      <search-product-specifications :selected="form" @update="updateForm($event)"></search-product-specifications>

      <search-products-social-values :selected="form" @update="updateForm($event)"></search-products-social-values>

      <hr>
      <div class="row">

        <div class="col-md-12">
          <h5>Pricing Weighting</h5>

          <div class="form-group">
            <label for="Type">Ongoing charges weighting (Should total to 100% with other
              charges)</label>
            <el-input type="number" v-model="form.pricing_cost_weighting"></el-input>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="Type">Other charges weighting (Should total to 100% with Ongoing
              charges pricing)</label>
            <el-input type="number" v-model="form.additional_cost_weighting"></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr />
          <h5>Contract length</h5>
          <div class="form-group">
            <label for="contract_length">Prefered contract period (in Years)</label>
            <el-input type="number" v-model="form.contract_length"></el-input>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</div>
</template>

<script>
import TextWithShowMoreComponent from '../../common/TextWithShowMoreComponent.vue'
import SearchProductSpecifications from '../search_solutions/SearchProductSpecifications.vue'
import SearchProductsSocialValues from '../search_solutions/SearchProductsSocialValues.vue'
export default {
  components: {
    TextWithShowMoreComponent,
    SearchProductsSocialValues,
    SearchProductSpecifications
  },
  props: ['params'],
  data() {
    return {
      form: {
        specs: []
      },
      global_specifications: [],
      importance: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },
  created: function () {
    this.form = Object.assign({}, this.params)
  },
  watch: {
    form: {
      handler: function (newValue) {
        this.updateForm(this.form)
      },
      deep: true
    }
  },
  methods: {
    updateForm(form) {
      this.$emit('update', form)
    }
  }
}
</script>
