<template>
  <div style="padding: 10px" v-loading="loading">
    <form @submit.prevent="onSubmit()">
      <argon-input type="textarea" :value="form.question" field="Question" :required="true" :label="true"
        @update="updateField('question', $event)"></argon-input>

      <div class="row">
        <div class="col-md-12">
          <label>Attachments</label>
          <argon-input v-model="attatchments" :multiple="true" type="upload" @input="getFiles($event)"
            :value="files"></argon-input>
        </div>

      </div>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="isPrivate">
        <label class="custom-control-label" for="customCheck1">Check this if you wish to be specifically responded
          privately</label>
      </div>

      <div v-if="isPrivate == true">
        <label>Reason:</label>
        <el-input type="textarea" v-model="privateReason">

        </el-input>
      </div>
      <button type="submit" class="btn btn-info" :disabled="!isValid">
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import ArgonInput from '@/components/common/Input/ArgonInput.vue'
import { Question } from '@/api_services/question'
import Swal from 'sweetalert2'

export default {
  components: { ArgonInput },
  props: ['selected', 'personal', 'common', 'user', 'pin_notice_id', 'itt_framework_id', 'supplierhub_lot_id'],
  data() {
    return {
      loading: false,
      attatchments: null,
      validFields: {
        question: false
      },
      form: {
        question: null
      },
      isPrivate: false,
      privateReason: '',
      files: null,
      file_names: []
    }
  },
  watch: {
    selected: function() {
      if (this.selected) this.form = Object.assign({}, this.selected)
    }
  },
  created: function() {
    if (this.selected) this.form = Object.assign({}, this.selected)
  },
  methods: {
    getFiles(files) {
      let newFiles = []
      let fileNames = []

      const arr = Array.from(files);

      arr.forEach(file => {
        newFiles.push(file.raw)
        fileNames.push(file.name)
      });
      this.files = newFiles
      this.fileNames = fileNames
    },
    updateField(name, newItem) {
      this.validFields[name] = newItem ? true : false
      this.form[name] = newItem
    },
    getForm() {
      let formData = new FormData()

      let question = this.form.question
      if (this.isPrivate) {
        question += " Respond privately reason: "+this.privateReason
      }

      if (this.form.id) formData.append('id', this.form.id)
      formData.append('question', question)

      this.files.forEach(function (file, index) {
        formData.append('file_' + index, file)
      })

      formData.append('file_names', this.fileNames)
      formData.append('files_count', this.files.length)

      if(this.pin_notice_id){
        formData.append('pin_notice_id', this.pin_notice_id)
      }

      if(this.itt_framework_id){
        formData.append('itt_framework_id', this.itt_framework_id)
      }

      if (this.supplierhub_lot_id) formData.append('supplierhub_lot_id', this.supplierhub_lot_id)
      return formData
    },
    onSubmit() {
      this.loading = true
      Question.create(this.getForm())
        .then(result => {
          Swal.fire({
            title: '<strong>Thank you for submitting your question.</strong>',
            icon: 'info',
            html:
              'You will be notified via email when the Framework Managers have responded ',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,  
          })
          this.$emit('update', true)
        })
        .catch(error => {
       
        })
        .finally(res => {
          this.loading = false
        })
    }
    
    
  },
  computed: {
    isValid() {
      let valid = true
      for (var i in this.validFields) {
        if (!this.validFields[i]) valid = false
      }

      return valid
    }
  }
}
</script>
