import api from './api'

const API = '/api/supplier-hub/files'

export const SupplierHubFile = {
  get: function(params) {
    return api.get(API, params)
  },
  downloadFiles: function(params) {
    return api.get(API+'/download-multiple-files', params)
  },
  users: function(params) {
    return api.get(API + '/users', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    // return api.create(API, params)
    return api.createFormData(API, params)
  },
  update: function(params) {
    return api.update(API + '/' + params.id, params)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id +  '/' + params.type)
  }
}
