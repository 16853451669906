<template>
    <el-upload
    ref="uploadRef"
        multiple
        drag
        v-model:file-list="input_upload"
        class="upload-demo"
        :class="if_disable ? 'hide' : ''"
        :disabled="if_file_disable"
        :on-exceed="handleExceed"
        :on-change="onUploadChange"
        :before-upload="beforeUpload"
        :http-request="customHttp"
        :on-preview="onPreview"
        :before-remove="beforeRemove"
        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
    >
        <el-icon v-if="!if_file_disable" class="el-icon--upload"><upload-filled /></el-icon>
        <div v-if="!if_file_disable" class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <template #tip>
        <div class="el-upload__tip">
            documents with a size less than or equal to {{size_limit}}MB.
        </div>
        </template>
    </el-upload>
    <div v-if="data.answer_label">
      <el-text>{{ data.answer_label }}</el-text>
    </div>
  </template>
  
  <script>

import { SqQuestion } from '@/api_services/sq_questions'

  export default {
    emits :{
      uploadChange: null,
      setCarouselBodyHeight: null
    },
    props: ['data', 'if_admin_view'],
    data() {
      return {
        if_file_disable: false,
        input_upload: [],
        if_disable: false,
        size_limit: 30,
        answers: {
          path: null,
          id: null,
          target_column: null
        },
        supplier_data: this.$store.state.savedUser.company.supplier,
        if_from_max_limit: 0
      }
    },
    watch: {
        'data.disabled': {
            deep: true,
            handler() {
                this.if_disable = this.data.disabled
            }
        },
    },
    created: function() {
      if(this.if_admin_view == 1){
          this.if_file_disable = true
        }
        
        this.if_disable = this.data.disabled
        //JSON.parse(JSON.parse(item.answer.file_answer))
        if(this.data.answer){
          var files = JSON.parse(this.data.answer.file_answer)
          for(var x = 0; x <= files.length - 1; x++){
            
            this.input_upload.push({
              name: files[x].filename,
              url: process.env.VUE_APP_API_ROOT + "/storage/" + files[x].path,
              path: files[x].path
            })
          }
        }

    },
    methods: {
      beforeRemove(file, filelist){
        if(this.if_from_max_limit == 0){

          return new Promise((resolve, reject) => {
          this.$confirm('Are you sure you want to delete this file?')
            .then(_ => {
              
              this.globalState.loading = true
              var id = this.data?.answer?.id

              this.answers.path = file.path
              this.answers.id = id
              this.answers.target_column = "file_answer"

              SqQuestion.removeFile(
                this.answers
              )
              .then(result => {
                this.$toast.success(result.data.message)
                resolve(true)
              })
              .catch(error => {
                console.error('error:', error)
              })
              .finally(() => {
                this.globalState.loading = false
                this.$emit('setCarouselBodyHeight', true)
              })
            })
            .catch(_ => {
              return false
          })
        })
        }
      },
      onPreview(file){
        window.open(file.url, 'Download');
      },
      customHttp(){
        return true
      },
      beforeUpload(file, fileList){
        this.if_from_max_limit = 0
        
        const isLimit = file.size / 1024 / 1024 < this.size_limit
        if (!isLimit) {
            this.$toast.warning('The maximum file size is '+this.size_limit+'MB!')
            this.if_from_max_limit = 1
            return false
        }

        var allowedTypes = [
          'application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint,text/plain',
          'application/pdf', 'image/jpeg', 'image/png', 'image/jpg'
        ];
        if (!allowedTypes.includes(file.type)) {
          
          this.$toast.warning('Invalid file type. Please upload a document or image file only.')
          this.if_from_max_limit = 1
          return false
        }
      },
      onUploadChange(file, fileList){

        this.$emit('uploadChange', {
          question_code: this.data.code,
          fileList: fileList
        })
        this.$emit('setCarouselBodyHeight', true)
      },
      handleExceed(files, fileList) {
        this.$message.warning(`The limit is 3, you selected ${files.length + fileList.length} totally, please first remove the unwanted file`);
      },
    }
  }
  </script>
  