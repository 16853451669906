<template>
  <div class="card bg-gradient-primary mx-3">
    <div class="card-header bg-gradient-primary text-center"><h5 class="text-white">Direct Debit</h5></div>
    <div class="card-body text-center">
      <router-link to="/direct-debit" class="stretched-link text-light">
        <i class="fa-solid fa-cart-shopping"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
