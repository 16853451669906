<template>
  <div>
    <div class="card my-3">
      <div class="card-body px-0 pb-2">
        <button
          v-tooltip="'Add missing items'"
          class="btn btn-transparent shadow-none btn-lg p-2 m-2"
          type="button"
          @click="getMissingItems()"
        >
          <i class="fa-solid fa-arrows-rotate"></i>
        </button>

        <div class="table-responsive">
          <nav class="navbar shadow-none my-3 navbar-transparent">
            <div class="bg-white border-radius-lg d-flex me-2">
              <span v-if="category">{{ category.name }}</span>
            </div>
            <div class="bg-white border-radius-lg d-flex me-2">
              <input
                type="text"
                name=""
                id=""
                class="form-control form-control-sm border-1 ps-3"
                v-model="term"
                placeholder="Search"
              />
              <button
                class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                @click="search()"
              >
                Search
              </button>
            </div>
          </nav>
          <table class="table table-fluid align-items-center mb-0">
            <thead>
              <tr class="text-sm">
                <th>Name</th>
                <th>Level of Importance</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-sm"
                v-for="charge in charges"
                :key="charge.id"
              >
                <td>
                  {{ charge.name }}
                </td>
                <td style="width: 1%">
                  <select
                    class="form-control"
                    v-model="charge.level_of_importance"
                    @change="update(charge)"
                  >
                    <option :value="1">1</option>
                    <option :value="2">2</option>
                    <option :value="3">3</option>
                    <option :value="4">4</option>
                    <option :value="5">5</option>
                    <option :value="6">6</option>
                    <option :value="7">7</option>
                    <option :value="8">8</option>
                    <option :value="9">9</option>
                    <option :value="10">10</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer pt-0">
        <pagination :meta="meta" v-on:update="getSpecs()" />
      </div>
    </div>
  </div>
</template>

<script>
import { Category } from '@/api_services/category'

export default {
  props: ['category'],
  data() {
    return {
      charges: [],
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  watch: {
    category: function() {
      this.getCharges()
    }
  },
  created: function() {
    this.getCharges()
  },
  methods: {
    getMissingItems() {
      let params = { category_id: this.category.id }

      Category.checkMissingAdditionalCharges(params)
        .then(result => {
          this.search()
        })
        .catch(error => {
          this.$toast.error('An error occured while checking missing items')
          console.error('error:', error.response.data)
        })
    },
    search() {
      this.meta.page = 1
      this.getCharges()
    },
    getCharges() {
      Category.charges({
        id: this.category.id,
        per_page: 10,
        page: this.meta.page
      })
        .then(result => {
          if (result.data.charges) this.charges = result.data.charges
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error(
            'An error occured while fetching additional charges'
          )
          console.error('error:', error.response.data)
        })
    },
    update(charge) {
      let params = {
        charge_id: charge.charge_id,
        category_id: this.category.id,
        level_of_importance: charge.level_of_importance
      }

      Category.saveCharge(params)
        .then(result => {
          this.$toast.success('Saved')
        })
        .catch(error => {
          this.$toast.error('An error occured while saving')
          console.error('error:', error.response.data)
        })
    }
  }
}
</script>
