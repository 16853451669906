<template>
  <div>
    <h2 v-if="supplier">Manage BFF Rates for {{ supplier.name}}</h2>
    <p>
      <back label="Back to suppliers" link="/main/suppliers"></back>
    </p>
    <div style="text-align: left;" class="row">
      <div class="col-md-7">
        <div class="card my-3">
          <div class="card-header"><h5>BFF Rates</h5></div>
          <div class="card-body">
            <div class="table-responsive">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="term" placeholder="Search">
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="search()">Search</button>
                  <button class="btn bg-gradient-secondary my-1 me-1 btn-sm" @click="create()">Add</button>
                </div>
              </nav> 
              <table class="table table-borderless table-striped">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Card Type</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Rate %</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">PPT Decimal</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-sm" v-for="bff_rate in bff_rates" :key="bff_rate.id">
                    <td>{{ bff_rate.card_type }}</td>
                    <td>{{ bff_rate.rate }}</td>
                    <td>{{ bff_rate.ppt }}</td>
                    <td style="width: 1%">
                      <a class="link me-3" @click="showEdit(bff_rate)">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(bff_rate)">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="original">
        <div class="card my-3">
          <div class="card-header"><h5>{{ selectedName(original) }}</h5></div>
          <div class="card-body">
            <form  @submit="onSubmit" @reset="onCancel">
              <div class="form-group">
                <label
                  id="card_type" label-for="input-card_type" valid-feedback=""
                  :invalid-feedback="invalidFeedback('card_type')"
                  :state="validation('card_type')"
                >Card Type</label>
                <input type="text" class="form-control form-control-sm" v-model="selected.card_type" :state="validation('card_type')">
              </div>
              <div class="form-group">
                <label
                  id="rate" 
                  label-for="input-rate" 
                  valid-feedback=""
                >Rate (in % form)</label>
                <input type="number" step="0.0001" class="form-control form-control-sm" v-model="selected.rate" >
              </div>
              <div class="form-group">
                <label
                  id="ppt" 
                  label-for="input-ppt" 
                  valid-feedback=""
                >PPT (in decimal form)</label>
                <input type="number" step="0.0001" class="form-control form-control-sm" v-model="selected.ppt" >
              </div>
              <button type="submit" class="btn bg-gradient-success btn-sm"  :disabled="!validateAll">Save</button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">Cancel</button>
            </form>
          </div>
        </div>
      </div>

    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import { BffRate } from '@/api_services/bff_rates'
import  { Supplier } from '@/api_services/suppliers'

export default {
  data() {
    return {
      dialogVisible: false,
      fields: ['id', 'card_type','rate', 'ppt' ,'action'],
      bff_rates: [],
      selected: null,
      original: null,
      validate: ['card_type'],
      to_remove: null,
      supplier_id: null,
      term: null,
      supplier: null,
    }
  },
  created: function() {
    this.supplier_id = this.$route.params.supplier_id
    this.getSupplier()
  },
  methods: {
    getSupplier() {
        Supplier.show(this.supplier_id)
        .then(result => {
          if (result.data.supplier)
            this.supplier = result.data.supplier
            this.getBffRates()
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching supplier')
          console.error('error:', error.response.data)
        })
    },

    search() {
      this.getBffRates()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      BffRate.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getBffRates()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing rate')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit '+selected.card_type: 'New'
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
    },
    getBffRates() {
      this.reset()
      BffRate.get({supplier_id: this.$route.params.supplier_id, params: {term: this.term}})
        .then(result => {
          if (result.data.bff_rates)
            this.bff_rates = result.data.bff_rates
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching bff rates')
          console.error('error:', error.response.data)
        })
    },
    validation(field) {
      switch(field) {
        case 'card_type':
          if (!this.selected.card_type) this.selected.card_type = ""
          return this.selected.card_type.length > 0
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'card_type') return "Card type is required"
      return ""
    },
    onSubmit(evt) {
        evt.preventDefault()

        var action = this.selected.id ? BffRate.update(this.selected) : BffRate.create(this.selected)
        
        action.then(result => {
          this.$toast.success('Saved successfully')
          this.getBffRates()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving bff rate')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {card_type: null, rate: 0, supplier_id: this.supplier_id, ppt: 0}
      this.selected = Object.assign({}, this.original);
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>