<template>
  <div >
    <el-card header="Subcontractor" style="text-align: left;" class="my-3">
      <el-form @submit.prevent="save" label-position="top" data-vv-scope="form1">
        <div class="row">
          <div class="col-md-12">
            <el-form-item id="name" label="Name" label-for="input-name" valid-feedback=""
              
            ><br>
              <el-input id="input-name" v-model="form.name" 
              :class="{ 'is-invalid': v.form.name.$errors.length }" trim></el-input>

              <div class="input-errors" v-for="error of v.form.name.$errors" :key="error.$uid">
                <div class="error-text">Name {{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="registered_address" label="Registered Address" label-for="input-registered_address" valid-feedback=""
            >
              <el-input id="input-registered_address" v-model="form.registered_address" 
              :class="{ 'is-invalid': v.form.registered_address.$errors.length}" trim></el-input>
              
              <div class="input-errors" v-for="error of v.form.registered_address.$errors" :key="error.$uid">
                <div class="error-text">Registered Address {{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="trading_status" label="Trading Status" label-for="input-trading_status" valid-feedback=""
            >
              <el-input id="input-trading_status" v-model="form.trading_status" 
              :class="{ 'is-invalid': v.form.trading_status.$errors.length}" trim></el-input>

              <div class="input-errors" v-for="error of v.form.trading_status.$errors" :key="error.$uid">
                <div class="error-text">Trading Status {{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="registration_number" label="Company Registration Number" label-for="input-registration_number" valid-feedback=""
            >
              <el-input id="input-registration_number" v-model="form.registration_number" 
              :class="{ 'is-invalid': v.form.registration_number.$errors.length}" trim></el-input>

              <div class="input-errors" v-for="error of v.form.registration_number.$errors" :key="error.$uid">
                <div class="error-text">Registration Number {{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="duns_number" label="Head Office DUNS number (if applicable)" label-for="input-duns_number" valid-feedback=""
            >
              <el-input id="input-duns_number" v-model="form.duns_number" trim></el-input>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="vat_number" label="Registered VAT Number" label-for="input-vat_number" valid-feedback=""
            >
              <el-input id="input-vat_number" v-model="form.vat_number" 
              :class="{ 'is-invalid': v.form.vat_number.$errors.length}" trim></el-input>

              <div class="input-errors" v-for="error of v.form.vat_number.$errors" :key="error.$uid">
                <div class="error-text">VAT Number {{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="organisation_type" label="Type of Organisation" label-for="input-organisation_type" valid-feedback=""
            >
              <el-input id="input-organisation_type" v-model="form.organisation_type" 
              :class="{ 'is-invalid': v.form.organisation_type.$errors.length}" trim></el-input>

              <div class="input-errors" v-for="error of v.form.organisation_type.$errors" :key="error.$uid">
                <div class="error-text">Organisation Type {{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="sme" label="SME" label-for="input-sme" valid-feedback=""
              
            >
            <el-radio-group v-model="form.sme">
              <el-radio name="input-sme" :value="true" label="1">Yes</el-radio>
              <el-radio name="input-sme" :value="false" label="0">No</el-radio>
            </el-radio-group>
            
            <div class="ms-4 input-errors" v-for="error of v.form.sme.$errors" :key="error.$uid">
                <div class="error-text">Please Select Value</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="role" label="The role each sub-contractor will take in providing the works and /or supplies e.g. key deliverables" label-for="input-role" valid-feedback=""
              
            >
              <el-input id="input-role" v-model="form.role" 
              :class="{ 'is-invalid': v.form.role.$errors.length}" trim></el-input>

              <div class="input-errors" v-for="error of v.form.role.$errors" :key="error.$uid">
                <div class="error-text">Role {{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>

          <div class="col-md-12">
            <el-form-item id="contractual_percentage" label="The approximate % of contractual obligations assigned to each sub-contractor" label-for="input-contractual_percentage" valid-feedback=""
              
            >
              <el-input type="number" id="input-contractual_percentage" v-model="form.contractual_percentage" 
              :class="{ 'is-invalid': v.form.contractual_percentage.$errors.length}" trim></el-input>

              <div class="input-errors" v-for="error of v.form.contractual_percentage.$errors" :key="error.$uid">
                <div class="error-text">{{ error.$message }}</div>
              </div>
            </el-form-item>
          </div>
        </div>

        <div style="text-align:center">
          <b-button type="submit" variant="info" >Save</b-button>
          <b-button type="close"  @click="close()">Close</b-button>
        </div>
      </el-form>

    </el-card>

  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup () {
      return { v: useVuelidate() }
    },
  props: ['selected'],
  data() {
    return {
      validate: [
        'name','registered_address','trading_status','registration_number',
        'vat_number', 'organisation_type', 'sme',  'role', 'contractual_percentage'
      ],
      form: {
        'name':null,'registered_address':null,'trading_status':null,'registration_number':null,
        'duns_number':null, 'vat_number':null, 'organisation_type':null, 'sme':null,  'role':null, 'contractual_percentage':null
      }
    }
  },
  validations() {
      return {
        form: {
          name: {
            required,
            
          },
          registered_address: {
            required,
            
          },
          trading_status: {
            required,
            
          },
          registration_number: {
            required,
            
          },
          vat_number: {
            required,
            
          },
          organisation_type: {
            required,
            
          },
          role: {
            required,
            
          },
          contractual_percentage: {
            required,
            
          },
          sme: {
            required,
            
          }
        },
      }
    },
  watch: {
    form() {

    }
  },
  created: function() {
    if (this.selected) this.form = Object.assign({}, this.selected);
  },
  methods: {
    invalidFeedback(field) {
      return ""
    },
    async save(evt) {
      evt.preventDefault()
      const result = await this.v.$validate('form1')
      if (!result) return

      this.$emit('save', this.form)
    },
    close() {
      this.$emit('save', null)
    },
    validation(field) {
      switch(field) {
        case 'name':
          return this.$store.state.isStringValid(this.form.name);
        case 'registered_address':
          return this.$store.state.isStringValid(this.form.registered_address);
        case 'trading_status':
          return this.$store.state.isStringValid(this.form.trading_status);
        case 'registration_number':
          return this.$store.state.isStringValid(this.form.registration_number);
        case 'vat_number':
          return this.$store.state.isStringValid(this.form.vat_number);
        case 'organisation_type':
          return this.$store.state.isStringValid(this.form.organisation_type);
        case 'sme':
          return this.form.sme != null;
        case 'role':
          return this.$store.state.isStringValid(this.form.role);
        case 'contractual_percentage':
          return true;
        default:
          return true;
      }
    },
  },
  computed: {
    validateAll() {
      var self = this
      var response = true

      this.validate.forEach(function(field){
        if (!self.validation(field)) {
           response = false;
        }
      })

      return response;
    }
  }
}
</script>
