<template>
  <div class="" style="text-align:left">
     

      <div>
        <hr>
        <strong>Saved Search Results</strong>
        <ul>
          <li v-for="product_search in product_searches" :key="product_search.id">
          <a :href="product_search.url">{{ product_search.label }} - {{ $filters.formattedDateWithDash(product_search.created_at) }}</a><a @click="remove(product_search)" style="color:crimson;cursor: pointer;margin-left: 10px"><i class="fa fa-times"></i></a></li>
        </ul>
        <el-button @click="onClose" type="button" >Close</el-button>
      </div>
  </div>
</template>

<script>
import { UserProductSearch } from '@/api_services/user_product_search'
import moment from 'moment'

export default {
  expose: ['getUrls'],
  data() {
    return {
      product_searches: [],
      form: {
        label: null,
        url: null
      }
    }
  },
  props: ['url'],
  watch: {
    url: function () {
      console.log(this.url)
      this.form.url = this.url
      this.getUrls()
    }
  },
  created: function () {
    this.getUrls()
    // this.form = Object.assign({}, this.params)
  },
  // watch: {
  //   params: {
  //     handler(oldVal, newVal) {
  //       console.log('specs has been updated from overall', this.params.specs.length)
  //       this.form = Object.assign({}, this.params)
  //     },
  //     deep: true
  //   },
  // },
  methods: {
    onSubmit() {
      this.form.url = this.url
      UserProductSearch.create(this.form)
        .then(result => {
          this.form.label = ""
          this.getUrls()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving the url')
          console.error('error:', error.response.data)
        })
    },
    remove(product_search) {
      UserProductSearch.delete(product_search.id)
        .then(result => {
          this.getUrls()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing the url')
          console.error('error:', error.response.data)
        })
    },
    getUrls() {
      this.product_searches = []
      UserProductSearch.get()
        .then(result => {
          this.product_searches = result.data.user_product_searches
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching the urls')
          console.error('error:', error.response.data)
        })
    },
    getFullUrl(product_search) {
      if (!product_search.includes('user_product_search_id')) {
        return product_search.url+'&user_product_search_id='+product_search.id
      }
      return product_search.url
    },
    onClose() {
      this.$emit('close', true)
    }
  }
}
</script>

<style scoped>

</style>
