<template>
  <div class="card bg-gradient-warning mx-3">
    <div class="card-header bg-gradient-warning text-center"><h5 class="text-white">IVR & Call Masking</h5></div>
    <div class="card-body text-center">
      <router-link to="/ivr-call-masking" class="stretched-link text-light">
        <i class="fa-solid fa-headset"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
