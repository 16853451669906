<template>
  <el-form label-position="top" @submit.prevent="submit" v-loading="loading">
    <div class="row">

      <div class="col-md-12">
        <p>Usage Costs</p>
        <table style="width: 100%">
          <tbody>
            <tr>
            <td>
              <div class="row">
                <div class="col-md-2">Description *</div>
                <div class="col-md-2">Txn Volume</div>
                <div class="col-md-2">Txn Value</div>
                <div class="col-md-1">Rate %</div>
                <div class="col-md-1">PPT</div>
                <div class="col-md-1">Cost</div>
                <div class="col-md-1"></div>
              </div>
            </td>
          </tr>
          <tr v-for="cost_detail,i in form.usage_cost_details" :key="i">
            <td>
              <pin-supplier-cost-detail-form :approval_list="approval_list" @update="setCostDetail($event, i, 0)" :cost_detail="cost_detail"
                @delete="handleDeleteDetail(i, 0)"></pin-supplier-cost-detail-form>
            </td>
          </tr>
          <tr v-if="!approval_list">
            <td style="text-align: center">
              <el-tooltip :enterable="false" placement="top-start">
                <template #content>
                  <span>Add usage cost</span>
                </template>
                <el-button size="small" plain @click="addCostdetail(0)"><i class="fa fa-plus"></i></el-button>
              </el-tooltip>

            </td>
          </tr>
          </tbody>
        </table>
        <hr>
      </div>
      <div class="col-md-12">

        <p v-if="!show_setup && !approval_list"><el-button plain size="small" @click="handleSetup(true)">Setup Costs (optional)
          </el-button></p>
        <p v-if="show_setup && !approval_list"><el-button plain size="small" @click="handleSetup(false)">Hide Setup
            cost</el-button></p>

        <table style="width: 100%" v-if="show_setup">
          <tbody>
            <tr>
            <td>
              <div class="row">
                <div class="col-md-2">Description *</div>
                <div class="col-md-2">Txn Volume</div>
                <div class="col-md-2">Txn Value</div>
                <div class="col-md-1">Rate %</div>
                <div class="col-md-1">PPT</div>
                <div class="col-md-1">Cost</div>
                <div class="col-md-1"></div>
              </div>
            </td>
          </tr>
          <tr v-for="cost_detail, i in form.setup_cost_details" :key="i">
            <td>
              <pin-supplier-cost-detail-form :approval_list="approval_list" @update="setCostDetail($event, i, 1)" :cost_detail="cost_detail"
                @delete="handleDeleteDetail(i,1)"></pin-supplier-cost-detail-form>
            </td>
          </tr>
          <tr v-if="!approval_list">
            <td style="text-align: center">
              <el-tooltip :enterable="false" placement="top-start">
                <template #content>
                  <span>Add usage cost</span>
                </template>
                <el-button size="small" plain @click="addCostdetail(1)"><i class="fa fa-plus"></i></el-button>
              </el-tooltip>
            </td>
          </tr>
          </tbody>
        </table>
        <hr>
      </div>
      <div class="col-md-6">
        <h5>Price Cost</h5>
        <el-form-item label="Band *" prop="description" :error="v.form.band.$errors.length ? 'Band is required' : ''">
          <el-input v-model="form.band" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="Transaction Volume" prop="transaction_volume"
          v-if="pin_price_setting?.transaction_type == 'volume'">
          <el-input-number v-model="form.transaction_value"></el-input-number>
        </el-form-item>

        <el-form-item label="Transaction Value" prop="transaction_value"
          v-if="pin_price_setting?.transaction_type == 'value'">
          <el-input v-model="form.transaction_value" type="number"></el-input>
        </el-form-item>

        <el-form-item label="Contract Years" prop="contract">
          <el-select v-model="form.contract_years">
            <el-option v-for="contract_year, i in ContractYears" :key="i" :value="contract_year.value"
              :label="contract_year.text"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="!approval_list" label="" prop="Action">
          <el-button native-type="submit" type="primary" plain>Save</el-button>
        </el-form-item>
        <hr>
      </div>
      <div class="col-md-6"></div>
    </div>
  </el-form>
</template>

<script>

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'
import { mapState } from 'vuex'
import PinSupplierCostDetailForm from './PinSupplierCostDetailForm.vue'
import {
  PinPriceCost
} from '@/api_services/pin_price_cost'

export default {
  components: { PinSupplierCostDetailForm },
  computed: {
    ...mapState(['ContractYears']),
  }, 
  props: {
    approval_list: {
      type: Boolean,
      default: false
    },
    lot: {
      type: Object,
      default: null
    },
    cost: {
      type: Object,
      default: null
    },
    pin_price_setting: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      form: {
        transaction_volume: 0,
        transaction_value: 0,
        contract_years: 0,
        usage_cost_details: [],
        setup_cost_details: []
      },
      loading: false,
      show_setup: false
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        band: {
          required,
          $autoDirty: true
        },
      }
    }
  },
  watch: {
    cost: function () {
      if (this.cost) this.form = Object.assign({}, this.cost)
      if (!this.form.usage_cost_details?.length) this.addCostdetail(0)
      this.show_setup = this.form.setup_cost_details?.length > 0
    }
  },
  created: function () {
    if (this.cost) this.form = Object.assign({}, this.cost)
    if (!this.form.usage_cost_details?.length) this.addCostdetail(0)
    this.show_setup = this.form.setup_cost_details?.length > 0
  },
  methods: {
    async handleDeleteDetail(index, type) {
      let list = type == 0 ? this.form.usage_cost_details : this.form.setup_cost_details
      if (type == 0 && list.length == 1) {
        this.$toast.error("At least one usage cost detail must be retained.")
        return
      }
      list.splice(index, 1)

      let newList = Object.assign([], list);
      list = []
      await this.$delay(2000);
      (type == 0) ? this.form.usage_cost_details = newList : this.form.setup_cost_details = newList
    },
    handleSetup(option) {
      this.show_setup = option
      if (option && !this.form.setup_cost_details.length) this.addCostdetail(1)
    },
    addCostdetail(type) {
      if (type == 0) this.form.usage_cost_details.push({})
      if (type == 1) this.form.setup_cost_details.push({})

    },
    setCostDetail(cost_detail,index, type) {
      cost_detail.type = type
      if (type == 0) {
        this.form.usage_cost_details[index] = Object.assign({}, cost_detail)
      }
      if (type == 1) {
        this.form.setup_cost_details[index] = Object.assign({}, cost_detail)
      }
    },
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.form.supplierhub_lot_id = this.lot?.id

      this.loading = true
      let request = this.form.id ?
        PinPriceCost.update(this.form) :
        PinPriceCost.create(this.form)

      request 
        .then(result => {
          this.$toast.success('Price cost saved')
          this.$emit('close', this.form)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })

    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
