<template>
  <div >
    <h2 class="text-white">Merchant Services: Report preview</h2>
    <a :href="'/merchant-services/'" class="btn bg-gradient-secondary btn-sm " >Create New</a>
    <br>
    <b-spinner variant="success" type="grow" label="Spinning" v-if="loading"></b-spinner>

    <div v-if="!loading && main_transaction.id">
      <div class="row">
        <div class="mx-auto col-md-8">
          <div class="card my-3">
            <div class="card-header">
              <h6 class="mb-0 text-sm">
                <a :href="'/merchant-services/'+main_transaction.code" v-tooltip="'Edit Report'">
                  <i class="fa-regular fa-pen-to-square text-primary" style="font-size: 1.5rem"></i>
                </a>
                Report Code <span class="badge badge-warning">{{ main_transaction.code }}</span>
              </h6>
              <a :href="main_transaction.pdf_link" class="btn bg-gradient-secondary btn-sm float-end" target="_blank" download>Download Report</a>
            </div>
            <div class="card-body">
              <iframe :src="main_transaction.pdf_link+'?preview=true'" style="width:100%; min-height:2000px;" frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { MerchantTransaction } from '@/api_services/merchant_transaction'
// import Currency from '../../../common/Currency.vue'
import * as htmlToImage from 'html-to-image';

export default {
  // components: { Currency },
  data() {
    return {
      sample_cost_data_images: [],
      resize: false,
      printing: false,
      annual_summary: [],
      ratio: 0,
      loading: false,
      main_transaction: {},
      summary: null,
      annualisation_calcs: [],
      annual_fields: ['card_type', 'count','value','current_cost','new_rate','new_cost','savings'],
      monthly_analysis_fields: ['card_type', 'count','value','current_cost','new_rate','new_cost','savings'],
      account_fees_fields: ['card_type', 'transaction_charges'],
      premium_fields: ['card_type', 'transaction_charges'],
      main_details_fields: ['card_type','no_of_transactions','value_of_transactions','transaction_charges','additional_charges','general_charges','total_charges','pcr']
    }

  },
  computed: {
    savings_list: function() {
      if (!this.summary) return [];
      let data = [
        {duration: '4', savings: (this.summary.annual_summary_total_savings * 4) },
        {duration: '5', savings: (this.summary.annual_summary_total_savings * 5) },
        {duration: '6', savings: (this.summary.annual_summary_total_savings * 6) },
        {duration: '7', savings: (this.summary.annual_summary_total_savings * 7) },
        {duration: '8', savings: (this.summary.annual_summary_total_savings * 8) }
      ]
      return data
    }
  },
  created: function() {
    if (this.$route.params.code) {
      this.getOneTransaction(this.$route.params.code)
    }
  },
  methods: {
    convertToImage(element) {
      this.resize = true
      var chartContainer = document.getElementById('print-container')
      var self = this
      htmlToImage.toPng(element, {height: (element.clientHeight*1.1)})
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          chartContainer.appendChild(img)
          self.printing = true
          self.print()
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error)
        })
    },
    print () {
      var self = this
      if (this.printing) {
        setTimeout(function(){
            self.$htmlToPaper('print-area', null, () => {
            console.log('Printing completed or was cancelled!')
            self.printing = false
            self.resize = false
            $( "#print-container" ).empty()
          });
            }, 1000);
      } else {
        var node = document.getElementById('print-area')
        this.convertToImage(node)
      }
    },
    computeAnnualCalcs() {
      this.ratio =  this.summary.ratio
      this.annualisation_calcs = [
        {name: 'STATEMENT TRANSACTION VALUE', value: this.summary.statement_transaction_value},
        {name: 'CONFIRMED ANNUAL VALUE', value: this.summary.annual_value},
        {name: 'RATIO', value: this.ratio },
      ];

      var annual_data = []

      this.summary.monthly_summary.forEach(monthly => {
        annual_data.push(monthly)
      });

      annual_data.push({
        name: 'BFF CHARGES',
        annual_count: null,
        annual_value: null,
        annual_total_charges: null,
        bff_rate: null,
        annual_bff_costs: this.summary.bff_charges,
        annual_savings: -Math.abs(this.summary.bff_charges)
      })

      this.annual_summary = annual_data
    },
    getOneTransaction(code) {
      this.loading = true
      MerchantTransaction.show(code, {params: {report: true}}).then(result => {
        if (result.data.transaction) {
          this.main_transaction = result.data.transaction
        }

        if (result.data.summary) {
          this.summary = result.data.summary
          this.computeAnnualCalcs()
        }

        if (result.data.sample_cost_data_images) {
          this.sample_cost_data_images = result.data.sample_cost_data_images
        }
      })
      .catch(error => {
        this.$router.push({name: 'Merchant Services'})
      }).finally(()=>{
        this.loading = false
      });
    },
  }
}
</script>


<style scoped>
  .small-input .input-group-prepend .input-group-text {
    font-size: 10px;
  }

  .hidden_header {
    display: none !important;
  }

  .table th, .table td {
    font-size: 14px !important;
  }

  h5 {
    font-size: 15px !important;
    font-weight: bold !important;
  }

  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
  }


.print-width {
  width: 1000px;
}
</style>
