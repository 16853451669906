<template>
  <div style="text-align:left;padding: 10px">
    <el-form ref="form" :model="form" >
      <el-form-item label="Typical Annual Price/Cost">
        <el-input v-model="form.final_price" type="number"></el-input>
      </el-form-item>
      <hr />
      <div v-if="selected.charges.length ">
        <h5>Typical Additional Cost</h5>
        <el-form-item
          :label="convertString(charge.name)"
          v-for="(charge, i) in form.charges"
          :key="i"
        >
          <el-input v-model="charge.price"></el-input>
        </el-form-item>
      </div>
      <el-button type="primary" @click="save()">SUBMIT</el-button>
    </el-form>
  </div>
</template>

<script>
import { ProductSearchResult } from '@/api_services/product_search_result'

export default {
  props: ['result'],
  data() {
    return {
      selected: {},
      form: { charges: []}
    }
  },
  watch: {
    result: function () {
      this.init()
    }
  },
  created: function() {
    this.init()
  },
  methods: {
    init() {
      this.selected = Object.assign({}, this.result)
      let self = this
      if (this.selected.params.final_price_cost) {
        this.selected.params.final_price_cost.forEach(function (finalPriceCost) {
          if (self.selected.product.id == finalPriceCost.id) {
            self.form = finalPriceCost
          }
       })
      } else {
        this.form = {
          final_price: this.selected.price,
          charges: this.selected.charges
        }
      }
    },
    save() {
      ProductSearchResult.updateFinalPrice(this.selected.id, {final_price_cost: this.form })
        .then(result => {
          this.selected.params.final_price_cost = this.form
          this.$emit('update', this.selected)
          this.$toast.success('Final price has been updated')
        })
        .catch(error => {
          this.$toast.error('An error occured while submitting')
          console.error('error:', error.response.data)
        })
    },
    convertString(string) {
      const words = string.split(" ");

      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return words.join(" ")
    }
  }
}
</script>
