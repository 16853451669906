<template>
  <div>
    <h2 class="text-white">Merchant Services: Sales Comparison Report</h2>
    <button :href="'/merchant-services/'" class="btn bg-gradient-secondary btn-sm">Create New</button>
    <button v-if="origin" :href="origin" class="btn bg-gradient-secondary btn-sm" style="margin-bottom: 10px;margin-left: 5px">Back to Reports List</button>
    <br>
    <b-spinner variant="success" type="grow" label="Spinning" v-if="loading"></b-spinner>
    <div style="text-align: left;" v-else>
      <div class="card my-3">
        <div class="card-header">
          <h6 v-if="main_transaction && main_transaction.code" class="mb-0" >Viewing code {{ main_transaction.code }} </h6>
          <h6 v-else class="mb-0" >New Transaction</h6>
          <div class="col-md-3 float-end">
            <div class="input-group mb-3">
              <input type="text" class="form-control form-control-sm" v-model="code" placeholder="view by code">
              <button type="button" class="btn bg-gradient-info btn-sm mb-0" :href="'/merchant-services/'+code" :disabled="!code">view</button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label label="Comparison for" label-for="tags-component-select" :invalid-feedback="invalidFeedback('prospect')">Comparison for</label>
              <!-- <m-s-prospects-component :prospect="selected_prospect" ></m-s-prospects-component> -->
              <b-form-tags
                v-model="prospect_suppliers_display"
                id="tags-component-select"
                size="lg"
                class="mb-2"
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled }">
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        :title="tag.name"
                        :disabled="disabled"
                        variant="info"
                      >{{ tag }}</b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                    v-model="selected_prospect"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    :options="availableProspects"
                    :state="validation('prospect')"
                  >
                    <template #first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option disabled value="">Choose a partner</option>
                    </template>
                  </b-form-select>
                </template>
              </b-form-tags>
            </div>
            <div class="col-md-2">
              <label for="">Authorisation fee</label>
              <select name="" id="" class="form-select form-select-sm" v-model="main_transaction.authorization_fee">
                <option v-for="fee in fees" :key="fee.id" :value="fee.amount">{{fee.name}}</option>
              </select>
            </div>
            <div class="col-md-2">
              <label label-for="tags-fonent-select"
                :invalid-feedback="invalidFeedback('annual_value')"
                :state="validation('annual_value')"
              >Annual Card Turnover</label>
              <input type="number" v-model="main_transaction.annual_value"  class="form-control form-control-sm">
            </div>
            <div class="col-md-2">
              <label label-for="tags-fonent-select"
                :invalid-feedback="invalidFeedback('annual_transaction')">Annual Transactions</label>
              <input v-model="main_transaction.annual_transaction" type="number" class="form-control form-control-sm">
            </div>
          </div>
          <div v-if="selected_prospect">         
            <merchant-transactions-input-component 
              :index="index"
              :transactions_by_supplier="transactions_by_supplier"
              :current_suppliers_selection="current_suppliers_selection"
              v-for="(transactions_by_supplier, index) in transactions_by_suppliers" :key="index" 
              @update="saveTransactionSet($event, index)"
              @remove="removeTransactionSet(index)"
              >
            </merchant-transactions-input-component>
          </div>
          <div class="text-center">
            <button class="btn bg-gradient-secondary btn-sm" @click="addTransactionSet()" >Add new supplier</button>
            <button class="btn bg-gradient-secondary btn-sm" @click="addDuplicateTransactionSet()" :disabled="!validateAll">Add new supplier with same transaction</button>
            <button class="btn bg-gradient-secondary btn-sm" @click="save()" :disabled="!validateAll">Save and Generate Report</button>
          </div>
        </div>
      </div>
      <!-- <el-card  class="mx-auto"> -->
        <!-- <template #header>
          <div v-if="main_transaction && main_transaction.code">
            <h6 class="mb-0" style="float:left">Viewing code {{ main_transaction.code }} </h6>
          </div>
          <div v-else>
             <h6 class="mb-0" style="float:left">New Transaction</h6>
          </div>

           <b-input-group prepend="View by code" class="view-code-group" style="width: 250px;float:right">
            <b-form-input size="sm" v-model="code"></b-form-input>
            <b-input-group-append>
              <b-button variant="info" :href="'/merchant-services/'+code" :disabled="!code">View</b-button>
            </b-input-group-append>
          </b-input-group>
        </template> -->

        <!-- <div class="row">
            <div class="col-md-4">
              <b-form-group label="Comparison for" label-for="tags-component-select" :invalid-feedback="invalidFeedback('prospect')">
              <b-form-tags
                v-model="prospect_suppliers_display"
                id="tags-component-select"
                size="lg"
                class="mb-2"
                no-outer-focus
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled }">
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        :title="tag.name"
                        :disabled="disabled"
                        variant="info"
                      >{{ tag }}</b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                    v-model="selected_prospect"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    :options="availableProspects"
                    :state="validation('prospect')"
                  >
                    <template #first> -->
                      <!-- This is required to prevent bugs with Safari -->
                      <!-- <option disabled value="">Choose a partner</option>
                    </template>
                  </b-form-select>
                </template>
              </b-form-tags>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Authorization fee" label-for="tags-component-select"
              >
                <b-form-select
                    v-model="main_transaction.authorization_fee"
                    size="sm"
                    value-field="amount"
                    text-field="name"
                    :options="fees"
                  >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Annual CTO" label-for="tags-fonent-select"
                :invalid-feedback="invalidFeedback('annual_value')"
                :state="validation('annual_value')"
              >
                <b-form-input size="sm" v-model="main_transaction.annual_value" type="number" :state="validation('annual_value')"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Annual Transaction" label-for="tags-fonent-select"
                :invalid-feedback="invalidFeedback('annual_transaction')"
              >
                <b-form-input size="sm" v-model="main_transaction.annual_transaction" type="number"></b-form-input>
              </b-form-group>
            </div>
        </div> -->
        <!-- <div v-if="selected_prospect">         
          <merchant-transactions-input-component 
            :index="index"
            :transactions_by_supplier="transactions_by_supplier"
            :current_suppliers_selection="current_suppliers_selection"
            v-for="(transactions_by_supplier, index) in transactions_by_suppliers" :key="index" 
            @update="saveTransactionSet($event, index)"
            @remove="removeTransactionSet(index)"
            >
          </merchant-transactions-input-component>
        </div> -->

        <!-- <div style="text-align:center">
           <b-button size="sm" class="mr-2" @click="addTransactionSet()" >Add new supplier</b-button>
           <b-button size="sm" class="mr-2" @click="addDuplicateTransactionSet()" :disabled="!validateAll">Add new supplier with same transaction</b-button>
           <b-button size="sm" class="mr-2" @click="save()" :disabled="!validateAll">Save and Generate Report</b-button>
        </div> -->
      <!-- </el-card> -->

      
    </div>
  </div>
</template>

<script>

import { Supplier } from '@/api_services/suppliers'
import { AuthorizationFee } from '@/api_services/authorization_fee'
import { MerchantTransaction } from '@/api_services/merchant_transaction'
import MerchantTransactionsInputComponent from './MerchantTransactionsInputComponent.vue'
import { Prospect } from '@/api_services/prospects'
// import MSProspectsComponent from './MSProspectsComponent.vue'

export default {
  components: { 
    MerchantTransactionsInputComponent
    // MSProspectsComponent 
  },
  data() {
    return {
      loading: false,
      code: null,
      transaction_set_per_supplier: [{
        supplier: null, transactions: []
      }],
      transactions_by_suppliers: [
        {supplier: null, transactions: []}
      ],
      supplier_options: [],
      refresh_computed: 0,
      transactions: [],
      transaction_count: 0,
      suppliers: [],
      selected_suppliers: [],
      selected_prospect_suppliers: [],
      prospect_suppliers_display: [],
      validate: ['supplier', 'annual_value'],
      current_suppliers_selection: ["AIB Merchant", "Barclay", "CAPITA/PAY360", "CIVICAPAY", "GLOBAL PAYMENTS", "WORLDPAY"],
      // current_suppliers_selection: ["AIB Merchant", "Barclay", "CAPITA/PAY360", "CIVICAPAY", "GLOBAL PAYMENTS", "LLOYDS CARDNET", "WORLDPAY"],
      main_transaction: {
        authorization_fee: 0
      },
      selected_prospect: null,
      prospects: null,
      fees: [],
      is_form_valid: true,
      origin: null
    }
  },

  created: function() {
    if (this.$route.query.origin) {
      this.origin = this.$route.query.origin  
    }

    if (this.$route.params.code) {
      this.getOneTransaction(this.$route.params.code);
    } else {
      this.getProspects();
    }

    this.getAuthFees()
  },
  computed: {
    availableOptions() {
      this.listSupplierNames();
      return this.current_suppliers_selection.filter(opt => this.selected_suppliers.indexOf(opt) === -1)
    },
    availableProspects() {
      var prospect_options = [];
      if(this.prospects) this.prospects.forEach(function (prospect) {
        prospect_options.push(prospect.name);
      });
      
      return prospect_options;
    },
    validateAll() {
      this.refresh_computed;
      return this.isValid()
    },
  },
  watch: {
    selected_prospect() {
      this.updateProspectSuppliers();
    },
  },
  methods: {
    getAuthFees() {
      var self = this;
      this.fees = []
      AuthorizationFee.get({params: {}})
        .then(result => {
          if(result.data.authorization_fees)
            var fees = result.data.authorization_fees
            fees.forEach(function(fee){
                self.fees.push({
                  amount: fee.amount,
                  name: '£ '+ ( ( fee.amount > 0 ) ? fee.amount : '0.00' )
                })
            })
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching auth fees')
          console.error('error:', error.response.data)
        })
    },
    isValid() {
      var self = this
      var response = true
      var selected_suppliers = []
      this.listSupplierNames();
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      if (!this.transactions_by_suppliers.length) response = false;
      this.transactions_by_suppliers.forEach(function(transaction_set){
        if(!transaction_set.supplier) response = false;
        if(!transaction_set.transactions.length) response = false;
        selected_suppliers.forEach(function (supplier) {
            if(supplier === transaction_set.supplier) response = false;
        })
        selected_suppliers.push(transaction_set.supplier)
        transaction_set.transactions.forEach(function(transaction) {
  
          if (!transaction.terminology || parseFloat(transaction.transaction_charges) <= 0 || !transaction.transaction_charges) {
            response = false;
          }
        })
      })

      return response;
    },
    listSupplierNames() {
        var self = this;

        if (!this.transactions_by_suppliers) this.selected_suppliers = []
        this.transactions_by_suppliers.forEach(function(i){
        self.current_suppliers_selection.forEach(function(n){
          if (i.supplier === n) self.selected_suppliers.push(n)
        })
      })
    },
    // Prospect functions
    getProspects() {
      Prospect.get()
        .then(result => {
          if (result.data.prospects)
            this.prospects = result.data.prospects
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching prospects')
          console.error('error:', error.response.data)
        })
    },
    updateProspectSuppliers() {
      this.selected_prospect_suppliers = [];
      this.prospect_suppliers_display = [];
        
      Prospect.getProspectSuppliers({params: {name: this.selected_prospect}})
          .then(result => {
            var self = this;
              if (result.data.suppliers) {
                  result.data.suppliers.forEach(function (sup) {
                  self.selected_prospect_suppliers.push(sup.id);
                  self.prospect_suppliers_display.push(sup.name);
                })
            }
          })
    },
    // End Prospect functions 
    addTransactionSet() {
      this.transactions_by_suppliers.push({supplier: null, transactions: []})
    },
    addDuplicateTransactionSet() {
      var copyTransaction = JSON.parse(JSON.stringify(this.transactions_by_suppliers[this.transactions_by_suppliers.length - 1].transactions))
      this.transactions_by_suppliers.push({supplier: null, transactions: copyTransaction})
    },
    removeTransactionSet(index) {
      this.transactions_by_suppliers.splice(index-1, 1)
      this.refresh_computed++;
    },
    saveTransactionSet(dataSet, index) {
      var list = [];

      this.transactions_by_suppliers.forEach( (transactions_by_supplier,i) => {
         list[i] = transactions_by_supplier
      });

      list[index] = dataSet
      this.transactions_by_suppliers = []
      this.transactions_by_suppliers = list
    },
    getOneTransaction(code) {
      this.loading = true
      MerchantTransaction.show(code).then(result => {
         if (result.data.transaction) {
           this.main_transaction = result.data.transaction
           this.transactions = this.main_transaction.transaction_details
           if (result.data.transaction.prospect ) this.selected_prospect = result.data.transaction.prospect.name
           this.showTransactionDetailsFromMerchantTransaction(result.data.transactions)
         }
      })
      .catch(error => {
        this.$router.push({name: 'Merchant Services'})
      }).finally(()=>{
        this.loading = false
        this.getSuppliers()
      });
    },
    showTransactionDetailsFromMerchantTransaction(transactions) {
      var new_list = []
      transactions.forEach(transaction => {
        var obj =  {
          supplier: transaction.current_supplier_name,
          transactions: transaction.transaction_details
        }
        new_list.push(obj)
      });
      this.transactions_by_suppliers = new_list
      
    },
    validation(field) {
      switch(field) {
        case 'prospect':
          return this.selected_prospect != null
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'prospect') { return "Comparison for is required" }
      return ""
    },
    save() {
      this.transactions.forEach(function(transaction){
        transaction.terminology_id = transaction.terminology.id
      })

      this.transactions_by_suppliers.forEach( function(transaction_sets) {
          transaction_sets.transactions.forEach(function(transaction) {
            transaction.terminology_id = transaction.terminology.id
          })
      })

      this.main_transaction.transaction_details = this.transactions_by_suppliers
      this.main_transaction.prospect = this.selected_prospect
      this.main_transaction.prospect_suppliers = this.selected_prospect_suppliers
     
      var action =  MerchantTransaction.create(this.main_transaction)

      action.then(result => {
        if (result.data.code) {
          this.$router.push("/merchant-services/"+result.data.code+'/report')
        }
        
      })
      .catch(error => {
        console.error('error:', error)
        this.$toast.error('An error occured while saving transaction')
      })
    },
    getSuppliers() {
      var self = this
      this.suppliers = []
      Supplier.get({params: {category: 2}}).then(result => {
          if (result.data.suppliers) {
            this.suppliers = result.data.suppliers
            this.getProspects();
          }
        })
        .catch(error => {
          console.error('error:', error)
          this.$toast.error('An error occured while fetching suppliers')
      })
    }
  }
}
</script>


<style scoped>
  .small-input .input-group-prepend .input-group-text {
    font-size: 10px;
  }

  .view-code-group .input-group-prepend .input-group-text{
    font-size: 10px !important;
  }
</style>