<template>
  <div class="nav-wrapper position-relative">
    <ul ref="navPill" class="p-1 nav nav-pills nav-fill" role="tablist">
      <slot/>
    </ul>
  </div>
</template>
<script>
  export default {
    mounted() {
      setTimeout(() => {
        this.setDefaultValue()
        this.initNavPill()
      }, 200)

      window.addEventListener('resize', (event) => {
        const { navPill } = this.$refs
        if (navPill) this.setActivePill(navPill.querySelector('li a.active').closest('li'))
      })
    },
    props: [
      'value'
    ],
    methods: {
      setDefaultValue() {
        if (!this.$refs.navPill) return 
        const links = this.$refs.navPill.querySelectorAll('.nav-link')
        const defaultValue = this.value || links[0].getAttribute('value')
        const link = Array.from(links).find(n => n.getAttribute('value') == defaultValue.toString())
        const li = link.closest('li')
        link.classList.add('active')
        li.classList.add('default')
      },
      setActivePill(li) {
        const activeNav = li.querySelector('.nav-link').getAttribute('value')
        this.$emit('input', activeNav)
        const { navPill } = this.$refs
        if (!navPill) return
        let nodes = Array.from(li.closest('ul').children);
        let index = nodes.indexOf(li) + 1;
        const moving_div = navPill.querySelector('.moving-tab');
        let sum = 0;
        if (navPill.classList.contains('flex-column')) {
          for (let j = 1; j <= nodes.indexOf(li); j++) {
            sum += navPill.querySelector('li:nth-child(' + j + ')').offsetHeight;
          }
          moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
          moving_div.style.height = navPill.querySelector('li:nth-child(' + j + ')').offsetHeight;
        } else {
          for (let j = 1; j <= nodes.indexOf(li); j++) {
            sum += navPill.querySelector('li:nth-child(' + j + ')').offsetWidth;
          }
          moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
          moving_div.style.width = navPill.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
        }
      },
      initNavPill() {
        const { navPill } = this.$refs
        if (!navPill) return
        const moving_div = document.createElement('div');
        const first_li = navPill.querySelector('li.default .nav-link');
        const tab = first_li.cloneNode();
        tab.innerHTML = "-";

        moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
        moving_div.appendChild(tab);
        navPill.appendChild(moving_div);

        moving_div.style.padding = '0px';
        this.setActivePill(navPill.querySelector('li.default'))
        moving_div.style.transition = '.5s ease';

        const navPillLinks = navPill.querySelectorAll('.nav-link')
        navPillLinks.forEach(link => {
          const li = link.closest('li')
          link.onclick = () => {
            this.setActivePill(li)
          }
        })
      }
    }
  }
</script>
