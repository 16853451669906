import api from './api'
import { Supplier } from './suppliers'

const API = '/bff_rates'

export const BffRate = {
  getFullPath(params) {
    return Supplier.getApi()+'/'+params.supplier_id+API
  },
  get: function(params) {
    return api.get(BffRate.getFullPath(params), params)
  },
  create: function(params) {
    return api.create(BffRate.getFullPath(params), params)
  },
  update: function(params) {
    return api.update(BffRate.getFullPath(params)+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(BffRate.getFullPath(params)+'/'+params.id, params)
  }
}
