<template>
  <el-card :header="permission? 'Edit Permission '+permission.name : 'New Permission'" class="my-3 border-0">
    <b-form-group id="input-group-1" label="Name:" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="selected.name"
        type="text"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-group-Actions"
      label="Actions:"
      label-for="input-Actions"
    >
      <div class="text-wrap">
        <el-tag
          v-for="(tag, index) in selected.actions"
          :key="index"
          class="m-1"
          type="primary"
          size="large"
          effect="dark"
          closable
          @close="removeTag(index, selected.actions)"
        >
          {{ tag }}
        </el-tag>
      </div>
      <!-- <b-form-tags
        id="input-Actions"
        input-id="tags-state-event"
        v-model="selected.actions"
        tag-variant="primary"
        placeholder="Enter new action separated by space"
        separator=" "
        @tag-state="onTagState"
      ></b-form-tags> -->
    </b-form-group>
    <b-button
      type="button"
      variant="primary"
      size="sm"
      :disabled="String(selected.name).length == 0 || !selected.actions.length"
      @click="addPermission()"
      >Save</b-button
    >
  </el-card>
</template>

<script>
import { Role } from '@/api_services/role_service'

export default {
  props: ['permission'],
  data() {
    return {
      validTags: [],
      invalidTags: [],
      duplicateTags: [],
      selected: {
        name: '',
        actions: [
          'READ',
          'CREATE',
          'UPDATE',
          'DELETE',
          'SHOW',
          'PRINT',
          'VIEW',
          'DOWNLOAD',
          'EMAIL'
        ]
      }
    }
  },
  watch: {
    permission: function () {
      if (this.permission) this.selected = Object.assign({}, this.permission)
      else this.setInitialValue()
    }
  },
  methods: {
    setInitialValue() {
      this.selected = {
        name: null,
        actions: [
          'READ',
          'CREATE',
          'UPDATE',
          'DELETE',
          'SHOW',
          'PRINT',
          'VIEW',
          'DOWNLOAD',
          'EMAIL'
        ]
      }
    },
    addPermission() {
      if (this.permission) this.selected.original_name = this.permission.name
      Role.addAllPermissions(this.selected)
        .then(result => {
          this.$toast.success('Permission saved')
          this.setInitialValue()
          this.$emit('save')
        })
        .catch(error => {
          this.$toast.error('An error occured while adding permission')
          console.error('error:', error.response.data)
        })
    },

    removeTag(index, tagList) {
      tagList.splice(index, 1);
    },
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    }
  }
}
</script>
