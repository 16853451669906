<template>
  <div class="pb-2">
    <el-select
      v-model="company"
      placeholder="Type Company name"
      clearable
      filterable
      @change="handleChange"
    >
      <el-option
        v-for="option in userCompanyOptions"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </el-select>
  </div>
</template>

<script>
import { Company } from '@/api_services/company'

export default {
  props: ['user'],
  data() {
    return {
      company: null,
      companies: []
    }
  },
  created() {
    if (this.user.company) {
      this.company = this.user.company.id.toString();
    }

    this.getCompanies()
  },
  computed: {
    userCompanyOptions() {
      return this.companies.map(c => ({
        value: c.id.toString(),
        label: c.name
      }))
    }
  },
  methods: {
    getCompanies() {
      Company.get({ params: { per_page: 99999 } })
        .then(result => {
          if (result.data.companies) {
            this.companies = result.data.companies
          }
        })
        .catch(error => {
          this.$toast.error('An error occurred while fetching companies')
          console.error('error:', error.response.data)
        })
    },
    handleChange(value) {
      this.$emit('update', value)
    }
  }
}
</script>

