<template>
  <div>
    <loading :loading="loading"></loading>
    <not-allowed v-if="!$store.state.isViewable('companies')"></not-allowed>
    <div v-else>

      <el-dialog
        data-backdrop="static"
        v-model="dialogVisible"
        :title="
          selected
            ? (selected.status == 1 ? 'Approve ' : 'Reject ') +
              ' FACA for ' +
              selected.name +
              '?' 
            : ''
        "
        width="500"
        close-on-click-modal="false"
        close-on-press-escape="false"
      >
      <hr>  
      <div v-if="selected">
          <div v-if="selected.status == 1" style="padding: 10px">
            <el-button type="primary" @click="onSave()"
              >Yes, Approve It!</el-button
            >
            <el-button @click="cancel()">Close</el-button>
          </div>

          <div v-if="selected.status == 2" style="padding: 10px">
            <el-form ref="form">
              <el-form-item label="Remarks">
                <el-input
                  v-model="selected.remarks"
                  placeholder="Reason for rejecting the file"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-button type="danger" @click="onSave()"
              >Yes, Reject It!</el-button
            >
            <el-button @click="cancel()">Close</el-button>
          </div>
        </div>

      </el-dialog>


      <h2 class="text-white">Framework Access Call-off Agreement LIST</h2>
      <div style="text-align: left;" class="row">
        <div class="col-md-8">
          <div class="card my-3">
            <div class="px-0 pb-2 card-body">
              <div class="table-responsive">
                <nav
                  class="navbar shadow-none my-3 navbar-transparent float-end"
                >
                  <el-input
                    placeholder="Search companies"
                    v-model="term"
                    class="input-with-select"
                  >
                  <template #append>
                    <el-button
                      slot="append"
                      
                      @click="search()"
                    ><i class="fa fa-search"></i></el-button>
                  </template>
                  </el-input>
                  
                </nav>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Company Name
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        File
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Date Created
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Status
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!companies.length">
                      <td class="text-sm" colspan="100%">No data found</td>
                    </tr>
                    <tr v-else v-for="company in companies" :key="company.id">
                      <td class="text-sm">{{ company.name }}</td>
                      <td>
                        <a
                          :href="company.faca_file_path"
                          download
                          target="_blank"
                        >
                          <i class="fas fa-download text-secondary"></i>
                        </a>
                      </td>
                      <td class="text-sm">{{ this.$filters.datetimeFormatFilter(company.created_at) }}</td>
                      <td>
                        <el-tag type="primary" v-if="company.status == 0"
                          >New</el-tag
                        >
                        <el-tag type="success" v-if="company.status == 1"
                          >Approved</el-tag
                        >
                        <el-tag type="danger" v-if="company.status == 2"
                          >Rejected</el-tag
                        >
                      </td>
                      <td class="text-sm text-center" style="width:1%">
                        <el-button
                          v-if="company.status != 1"
                          type="success"
                          size="sm"
                          @click="update(company, 1)"
                          ><i class="fa fa-check"></i>&nbsp;Approve</el-button
                        >
                        <el-button
                          v-if="company.status != 2"
                          type="danger"
                          size="sm"
                          @click="update(company, 2)"
                          ><i class="fa fa-times"></i>&nbsp;Reject</el-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="getCompanies()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CompanyFaca } from '@/api_services/company_faca'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      dialogVisible: false,
      companies: [],
      selected: null,
      loading: true,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      showDrawer: false
    }
  },
  created: function() {
    this.getCompanies()
  },
  methods: {
    search() {
      this.meta.page = 1
      this.getCompanies()
    },
    getCompanies() {
      this.loading = true
      this.cancel()
      CompanyFaca.get({ params: { term: this.term, page: this.meta.page } })
        .then(result => {
          if (result.data.companies) this.companies = result.data.companies
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching companies')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      this.selected = null
      this.dialogVisible = false
    },
    update(company, status) {
      this.selected = Object.assign({}, company)
      this.selected.status = status
      this.dialogVisible = true
    },
    onSave() {
      this.loading = true
      CompanyFaca.update(this.selected.id, this.selected)
        .then(result => {
          this.$toast.success('Updated successfull')
          this.getCompanies()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
