<template>
  <file-upload :drag="true" :max-size="30000000" :multiple="false" @change="setFile($event)"
    label="Select File"></file-upload>
  </template>

<script>
import FileUpload from '../../common/FileUpload/FileUpload.vue'

export default {
  components: { FileUpload },
  data() {
    return {
      form: {
        file: null,
      },
    }
  },
  created: function () {
  },
  methods: {
    setFile(files) {
      this.file = null
      if (files.length > 0) {
        this.file = {
          id: files[0].id,
          name: files[0].name,
          file: files[0].raw ? files[0].raw : null
        }
      }
      this.$emit('upload', this.file)
    }
  }
}
</script>

<style></style>
