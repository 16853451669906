<template>
  <div>
    <h2 class="text-white">Global Social Values</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-6">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="table-response">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input
                    type="text"
                    name=""
                    id=""
                    class="form-control form-control-sm border-1 ps-3"
                    v-model="term"
                    placeholder="Search"
                  />
                  <button
                    class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                    @click="search()"
                  >
                    Search
                  </button>
                  <button
                    class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                    @click="create()"
                  >
                    Add
                  </button>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        code
                      </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      description
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      type
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!social_values.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr
                    v-else
                    v-for="social_value in social_values"
                    :key="social_value.id"
                  >
                  <td class="text-sm">
                        <span class="text-wrap">{{
                          social_value.description
                        }}</span>
                      </td>
                    <td class="text-sm">
                      <span class="text-wrap">{{
                        social_value.original_description
                      }}</span>
                    </td>
                    <td class="text-sm">
                      {{ $store.state.getSVTypeLabel(social_value.type) }}
                    </td>
                    <td class="text-sm" style="width:1%">
                      <a
                        class="link me-3"
                        @click="showEdit(social_value)"
                        v-tooltip="'Edit'"
                      >
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a
                        class="link"
                        @click="showRemove(social_value)"
                        v-tooltip="'Delete'"
                      >
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getSocialValues()" />
          </div>
        </div>

        <!-- <b-nav-form style="float:right">
              <b-form-input size="sm" class="mr-sm-2" placeholder="Search" v-model="term"></b-form-input>
              <b-button size="sm" class="my-2 my-sm-0" type="button" @click="search()">Search</b-button>
              <b-button size="sm" @click="create()" class="mr-2" style="margin-left: 5px">Add</b-button>
            </b-nav-form> -->
      </div>
      <div class="col-md-6" v-if="original">
        <div class="card my-3">
          <div class="card-header">
            <text-with-show-more :string="selectedName(original)" :length="100"></text-with-show-more>
            <!-- <h5>{{ selectedName(original) }}</h5> -->
          </div>
          <div class="card-body">
            <form @submit="onSubmit" @reset="onCancel">
              <div class="form-group">
                <label
                  :invalid-feedback="invalidFeedback('description')"
                  :state="validation('description')"
                >
                  Description
                </label>
                <textarea
                  class="form-control"
                  v-model="selected.original_description"
                  :state="validation('description')"
                  trim
                ></textarea>
              </div>
              <div class="form-group">
                <label
                  :invalid-feedback="invalidFeedback('type')"
                  :state="validation('type')"
                >
                  Type
                </label>
                <select
                  class="form-select"
                  v-model="selected.type"
                  :state="validation('type')"
                >
                  <option
                    v-for="sv_type in $store.state.sv_types"
                    :key="sv_type.id"
                    v-bind:value="sv_type.value"
                    >{{ sv_type.text }}</option
                  >
                  <!-- <option v-for="unit in $store.state.units" :key="unit.id" v-bind:value="unit.value">{{ unit.text }}</option> -->
                  <!-- <option value="0">Choice</option>
                    <option value="1">Value</option> -->
                </select>
              </div>
              <button type="submit" class="btn bg-gradient-success btn-sm">
                Save
              </button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
    <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { SocialValue } from '@/api_services/social_value'

export default {
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      social_values: [],
      fields: ['id', 'description', 'type', 'action'],
      selected: null,
      original: null,
      validate: ['description', 'type'],
      to_remove: null,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  created: function() {
    this.getSocialValues()
  },
  methods: {
    search() {
      this.meta.page = 1
      this.getSocialValues()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      SocialValue.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getSocialValues()
        })
        .catch(error => {
          this.$toast.error(
            'An error occured while removing social value component'
          )
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit ' + selected.original_description : 'New'
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type)
      this.original = Object.assign({}, type)
    },
    getSocialValues() {
      this.isLoading = true
      SocialValue.get({ params: { page: this.meta.page } })
        .then(result => {
          if (result.data.social_values)
            this.social_values = result.data.social_values
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error(
            'An error occured while fetching social value components'
          )
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    validation(field) {
      switch (field) {
        case 'description':
          if (!this.selected.original_description) this.selected.original_description = ''
          return this.selected.original_description.length > 0
        case 'type':
          return this.selected.type > 0
        default:
          return true
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'description')
        return 'Description is required'
      if (!this.validation(field) && field == 'type') return 'Type is required'
      return ''
    },
    onSubmit(evt) {
      evt.preventDefault()
      var action = this.selected.id
        ? SocialValue.update(this.selected.id, this.selected)
        : SocialValue.create(this.selected)
      action
        .then(result => {
          this.$toast.success('Saved successfully')
          this.getSocialValues()
        })
        .catch(error => {
          this.$toast.error(
            'An error occured while saving a social value component'
          )
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
      evt.preventDefault()
      this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = { original_description: null, type: null }
      this.selected = Object.assign({}, this.original)
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field) {
        if (!self.validation(field)) response = false
      })

      return response
    }
  }
}
</script>
