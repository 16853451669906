<template>
  <span>
    <span v-html="shortMessage" v-if="html"></span>
    <span v-if="!html">{{ shortMessage }}</span>
    <a v-if="showMoreText" @click="showAll()" class="link small" v-tooltip="!html ? string : ''">more</a>
    <a v-if="showLessText" @click="showLess()" class="link small">less</a>
  </span>
</template>

<script>
export default {
  name: 'TextWithShowMoreComponent',
  props: {
    string: String,
    length: Number,
    html: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      message: '',
      current_length: 50,
      min_length: 50,
      max_length: 9999
    }
  },
  created: function() {
    if (this.string) this.message = this.string
    if (this.length) {
      this.current_length = this.length
      this.min_length = this.length
    }
  },
  watch: {
    string() {
      this.message = this.string
    }
  },
  methods: {
    showAll() {
      this.current_length = this.max_length
    },
    showLess() {
      this.current_length = this.min_length
    }
  },
  computed: {
    shortMessage() {
      return this.message?.length > this.current_length
        ? this.message.substring(0, this.current_length) + '...'
        : this.message
    },
    showMoreText() {
      return (
        (this.string?.length || 0) > this.min_length &&
        this.message.length > this.current_length
      )
    },
    showLessText() {
      return (
        (this.string?.length || 0) > this.min_length &&
        this.message.length < this.current_length
      )
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: underline;
}
</style>
