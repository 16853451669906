<template>
  <div>
    <!-- <choice
      multiple
      required
      v-model="selected_supplier_categories"
      :options="categories"
      :config="{
        removeItemButton: true,
        searchEnabled: false,
        duplicateItemsAllowed: false,
        searchResultLimit: 2,
        shouldSort: false,
        searchFields: ['label']
      }"></choice> -->
      
       <el-select v-model="selected_supplier_categories"  placeholder="Select" style="width: 100%;">
            <el-option v-for="(item, i) in categories" :key="i" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
  </div>
</template>

<script>

// import Choice from '@/components/common/Choice'

export default {
  props: ['supplier'],
  // components: { Choice },
  data() {
    return {
      selected_supplier_categories: [],
      categories: [],
    }
  },
  watch: {
    selected_supplier_categories: {
       handler(newValue) {
        this.$emit('update', newValue)
       },
      deep: true
    }

  },
  created: function() {
    this.selected_supplier_categories = []
    let self = this
    if (!this.supplier.categories) this.supplier.categories = []
    this.supplier.categories.forEach(category => {
      self.selected_supplier_categories.push(category.toString())
    });

    setTimeout(() => {
      this.categories = this.categories?.map(c => { return { value: c.id.toString(), label: c.name } } ) || []
    },100)

    this.getCategories()
  },
  methods: {
    getCategories() {
      this.categories = [
        {
          id: 0, name: " "
        },
        {
          id: 1, name: "Banking"
        },
        {
          id: 2, name: "Merchant Services"
        },
        {
          id: 3, name: "Direct Debit"
        },
        {
          id: 4, name: "Payment Gateway"
        },
        {
          id: 5, name: "IVR and Call Masking"
        },
        {
          id: 6, name: "Innovation FS"
        }
      ]
    },
  }
}
</script>
