<template>
  <div class="container-fluid-TODO-REMOVE">
    <div class="row">
      <!-- <div class="col-md-3">
        <el-card
          :img-src="default_profile_banner"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2"
          img-height=300px
          >
          <div>
            <div class="author">
              <img class="avatar_spec" alt="..." src="@/assets/avatar.png">
              <h4 class="title">{{profile.first_name}} {{profile.last_name}}</h4>
              <br>
            </div>
          </div>
        </el-card>
      </div> -->
      <div class="col-md" style="text-align: left">
        <el-card
        shadow="never"
          class="mx-auto border-0"
          title="Edit Profile"
        >
        <template #header>
          <div class="card-header">
            <span><i class="fa fa-edit"></i> Edit Profile</span>
          </div>
        </template>
        <br>
          <el-form 
            ref="formRef"
            :model="profile"
            :rules="rules"
              name="registrationForm"
          @submit.prevent="onSubmit">
            <div class="row">
              <div class="col mb-2">
                 <el-form-item prop="first_name"
                  label=""
                >
                <el-text class="mx-1 mb-2" tag="b" size="default" for="input-first_name">First Name</el-text>
                  <el-input size="large" id="input-first_name" v-model="profile.first_name" 
                   trim></el-input>

                   <div class="input-errors" v-for="error of v$.profile.first_name.$errors" :key="error.$uid">
                    <div class="error-text">First Name {{ error.$message }}</div>
                  </div>
                </el-form-item>
              </div>
              <div class="col mb-2">
                <el-form-item
                  label=""
                >
                <el-text class="mx-1 mb-2" tag="b" size="default" for="middle_name">Middle Name</el-text>
                <el-input size="large" type="text" id="middle_name" v-model="profile.middle_name"></el-input>
              </el-form-item>
              </div>

              <div class="col mb-2">
                 <el-form-item
                 prop="last_name"
                >
                <el-text class="mx-1 mb-2" tag="b" size="default" for="input-last_name">Last Name</el-text>
                  <el-input size="large" id="input-last_name" v-model="profile.last_name" 
                  trim></el-input>

                  <div class="input-errors" v-for="error of v$.profile.last_name.$errors" :key="error.$uid">
                    <div class="error-text">Last Name {{ error.$message }}</div>
                  </div>
                </el-form-item>
              </div>
            </div>
            
            <div class="row">
              <div class="col mb-2">
                <el-form-item
                prop="street_address"
                >
                <el-text class="mx-1 mb-2" tag="b" size="default" for="street_address">Address</el-text>
                  <el-input size="large" id="input-street_address" v-model="profile.street_address" 
                   trim></el-input>

                   <div class="input-errors" v-for="error of v$.profile.street_address.$errors" :key="error.$uid">
                    <div class="error-text">Address {{ error.$message }}</div>
                  </div>
                </el-form-item>

              </div>
              <div class="col mb-2">
                <el-form-item 
                prop="city"
                >
                <el-text class="mx-1 mb-2" tag="b" size="default" for="input-city">City</el-text>
                  <el-input size="large" id="input-city" v-model="profile.city"
                  trim></el-input>

                  <div class="input-errors" v-for="error of v$.profile.city.$errors" :key="error.$uid">
                    <div class="error-text">City {{ error.$message }}</div>
                  </div>
                </el-form-item>
              </div>
              <div class="col mb-2">
                <el-form-item
                >
                <el-text class="mx-1 mb-2" tag="b" size="default" for="input-zipcode">Zip Code</el-text>
                  <el-input size="large" id="input-zipcode" v-model="profile.zipcode" 
                  trim></el-input>

                  <div class="input-errors" v-for="error of v$.profile.zipcode.$errors" :key="error.$uid">
                    <div class="error-text">Zip Code {{ error.$message }}</div>
                  </div>
                </el-form-item>
              </div>
              <!-- <div class="col">
                <el-form-item id="country" label="Country" label-for="input-country" valid-feedback=""
                  :invalid-feedback="invalidFeedback('country')"
                  :state="validation('country')"
                >
                  <el-input size="large" id="input-country" v-model="profile.country" :state="validation('country')" trim></el-input>
                </el-form-item>
              </div> -->
            </div>
            
            <div class="row">
              <div class="col mb-2">  
            <el-text class="mx-1 mb-2" tag="b" size="default" for="textarea">About Me</el-text>
            <el-input size="large"
            :rows="2"
            type="textarea"
              v-model="profile.about_me"
              id="textarea"
              class="mt-2"
            ></el-input>
            <div style="text-align: right;" >
            <el-button class="mt-4" size="large" :icon="Promotion"  native-type="submit" type="primary"><i class="fa fa-save"></i> &nbsp;Update Profile</el-button>
          </div>
        </div>
          </div>
          </el-form>


        </el-card>
      </div>
    </div>


  </div>
</template>

<script>
  import Banner from '@/assets/tmp_img.jpg'
  import { UserProfile } from '@/api_services/user_profile'
  import { ref, reactive } from "vue";
  import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

  export default {
    setup () {
      return { v$: useVuelidate({scope: false}) }
    },
    data() {
      const formRef = ref(null);

      return {
        formRef,
        default_profile_banner: Banner,
        profile: {
          first_name: null,
          last_name: null,
          middle_name: null,
          street_address: null,
          city: null,
          zipcode:null,
          country: "United Kingdom"
        },
        rules: {
          first_name: [
            { required: true, message: "First name is required", trigger: 'blur' },
          ],
          last_name: [
            { required: true, message: 'Last name is required', trigger: 'blur' },
          ],
          street_address: [
            { required: true, message: 'Street address is required', trigger: 'blur' },
          ],
          city: [
            { required: true, message: 'City is required', trigger: 'blur' },
          ],
          Country: [
            { required: true, message: 'Country is required', trigger: 'blur' },
          ],
        },
        validate: ['first_name', 'last_name', 'street_address', 'city', 'country'],
      }
    },
    validations() {
      return {
        profile: {
          first_name: {
            required,
            $autoDirty: true
          },
          last_name: {
            required,
            $autoDirty: true
          },
          street_address: {
            required,
            $autoDirty: true
          },
          city: {
            required,
            $autoDirty: true
          },
          zipcode: {
            required,
            $autoDirty: true
          },
        },
      }
    },
    created: function() {
      this.getProfile()
    },
    computed: {
      validateAll() {
        var self = this
        var response = true
        this.validate.forEach(function(field){
          if (!self.validation(field)) response = false;
        })

        return response;
      }
    },
    methods: {
      getProfile() {
        UserProfile.show({params: {}})
        .then(result => {
          console.log(result.data.profile)
          if (result.data.profile) this.profile = result.data.profile
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching roles')
          console.error('error:', error.response.data)
        })
      },
      async onSubmit(evt) {
          evt.preventDefault()

          const result = await this.v$.$validate()
          if (!result) return

          UserProfile.update(this.profile).then(result => {
            
            if (result.data.profile) this.profile = result.data.profile
            this.$toast.success('Saved successfully')
          })
          .catch(error => {
            this.$toast.error('An error occured while saving your profile')
            console.error('error:', error.response.data)
          })
      },
      validation(field) {
        switch(field) {
          case 'first_name':
            if (!this.profile.first_name) this.profile.first_name = ""
            return this.profile.first_name.length > 0
          case 'last_name':
            if (!this.profile.last_name) this.profile.last_name = ""
            return this.profile.last_name.length > 0
          case 'street_address':
            if (!this.profile.street_address) this.profile.street_address = ""
            return this.profile.street_address.length > 0
          case 'city':
            if (!this.profile.city) this.profile.city = ""
            return this.profile.city.length > 0
          case 'country':
            if (!this.profile.country) this.profile.country = ""
            return this.profile.country.length > 0
          default:
            return true;
        }
      },
      invalidFeedback(field) {
        if (!this.validation(field) && field == 'first_name') return "First name is required"
        if (!this.validation(field) && field == 'last_name') return "Last name is required"
        if (!this.validation(field) && field == 'street_address') return "Street address is required"
        if (!this.validation(field) && field == 'city') return "City is required"
        if (!this.validation(field) && field == 'Country') return "Country is required"
        return ""
      },
    }
  }
</script>

<style>
  .avatar_spec {
    width: 80px;
    height: 80px;
    background-color: gainsboro;
    margin-top: -50px;
    border: solid;
    border-radius: 50px;
    border-color: white
  }
</style>
