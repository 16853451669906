<template>
    <div id="meet"></div>
</template>

<script>
import { Jaas } from '@/api_services/jaas'

export default {
    props: ['meeting'],
    data() {
        return {
            api: null,
            token: null
        }
    },
    mounted () {
        if(this.meeting) {
            this.loadScript('https://8x8.vc/external_api.js', () => {
            if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet External API not loaded');
            this.getToken({}).then(async token => {
                this.initIframeAPI(token)
            })
        });
        }
    },
    methods: {
        async getToken (payload) {
            return await Jaas.getToken()
        },
        loadScript (src, cb) {
            const scriptEl = document.createElement('script');
            scriptEl.src = src;
            scriptEl.async = 1;
            document.querySelector('head').appendChild(scriptEl);
            scriptEl.addEventListener('load', cb);
        },
        initIframeAPI (token) {
            let tempRoom = "vpaas-magic-cookie-531872c88ecd408bb7d1939253e4413a/"+this.meeting.room_name;
            const domain = '8x8.vc';
            const options = {
                roomName: tempRoom,
                jwt: token.data,
                height: 900,
                parentNode: document.querySelector('#meet'),
                configOverwrite: {
                    toolbarButtons: ['hangup', 'microphone', 'camera', 'recording', 'desktop']
                }
            };

        //         toolbarButtons: [
        //    'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
        //    'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
        //    'livestreaming', 'etherpad', 'sharedvideo', 'shareaudio', 'settings', 'raisehand',
        //    'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
        //    'tileview', 'select-background', 'download', 'help', 'mute-everyone', 'mute-video-everyone', 'security'
        // ],

            if(document.querySelector('#meet')) this.api = new window.JitsiMeetExternalAPI(domain, options);
        }
    }
}
</script>