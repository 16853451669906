<template>
  <BarChart v-bind="barChartProps" />
</template>

<script>
import {
  Chart,
  registerables
} from 'chart.js';
import {
  BarChart,
  useBarChart
} from 'vue-chart-3';

import { ref, computed } from 'vue';

Chart.register(...registerables);

export default {
  name: "Bar Chart",
  props: ['chartData'],
  components: {
    BarChart,
  },
  setup(props) {

    const chartData = computed(() => ({
      labels: props.chartData.labels,
      datasets: props.chartData.datasets
    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData,
    });

    return { barChartProps, barChartRef };
  },
}
</script>
