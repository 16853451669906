<template>
    <el-table @expand-change="changeG" @current-change="changeG" :data="table_data" style="width: 100%" >
        <el-table-column prop="data" label="">
            <template #default="scope">
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <label>Name of customer organisation</label>
                        <el-input @input="inputChange" type="textarea"  v-model="scope.row.data.name" placeholder="Answer here.." />
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>Point of contact in the organisation</label>
                        <el-input @input="inputChange" type="textarea" v-model="scope.row.data.point" placeholder="Answer here.." />
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>Position in the organisation</label>
                        <el-input @input="inputChange" type="textarea" v-model="scope.row.data.position" placeholder="Answer here.." />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <label>E-mail address</label>
                        <el-input @input="inputChange" type="textarea"  v-model="scope.row.data.email" placeholder="Answer here.." />
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>Description of contract</label>
                        <el-input @input="inputChange" type="textarea"  v-model="scope.row.data.description" placeholder="Answer here.." />
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>Contract Start date</label><br>
                        <el-date-picker
                            @change="inputChange"
                            v-model="scope.row.data.start_date"
                            type="date"
                            placeholder="Start date"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <label>Contract completion date</label><br>
                        <el-date-picker
                            @change="inputChange"
                            v-model="scope.row.data.completion_date"
                            type="date"
                            placeholder="Completion date"
                        />
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>Estimated contract value</label><br>
                        <el-input-number @input="inputChange" v-model="scope.row.data.value" :controls="false" />
                    </div>
                    <div class="col-md-4 mb-2">
                        <label>Optional File(s)</label><br>
                        <el-upload
                                multiple
                                v-model:file-list="scope.row.data.temp_optional_file"
                                class="upload-demo"
                                :on-exceed="handleExceed"
                                :on-change="onUploadChange"
                                :before-upload="beforeUpload"
                                :http-request="customHttp"
                                :on-preview="onPreview"
                                :before-remove="beforeRemove"
                                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                            >
                            <el-button type="primary">Click to upload</el-button>
                                <template #tip>
                                <div class="el-upload__tip">
                                    documents with a size less than or equal to {{size_limit}}MB.
                                </div>
                                </template>
                            </el-upload>
                    </div>
                </div>
                <hr style="border-top: 2px dashed red">
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="Action" width="100" >
        <template #default="scope">
            <el-button
            v-if="scope.$index !== 0 && !if_not_visible"
            type="danger"
            class="me-4"
            @click.prevent="deleteRow(scope.$index)"
            >
            Remove
            </el-button>
        </template>
        </el-table-column>
    </el-table>
    <el-button v-if="!if_not_visible" type="success" class="mt-4" style="width: 100%" @click="addItem">
        <i class="fa fa-plus"></i>&nbsp;Add New Contract
    </el-button>
  </template>
  
  <script>

import { SqQuestion } from '@/api_services/sq_questions'

  export default {
    emits :{
        tableChange: null,
        setCarouselBodyHeight: null
    },
    props: ['data', 'answer', 'if_admin_view'],
    data() {
      return {
        if_from_max_limit: 0,
        size_limit: 30,
        table_data: null,
        if_disable: false,
        answers: {
          path: null,
          id: null,
          target_column: null
        },
        if_not_visible: false
      }
    },
    watch: {
        'data.disabled': {
            deep: true,
            handler() {
                this.if_disable = this.data.disabled
            }
        },
        table_data:{
            deep: true,
            handler() {
                this.$emit('setCarouselBodyHeight', this.table_data)
            }
        }
    },
    created: function() {
        if(this.if_admin_view == 1){
          this.if_not_visible = true
        }
        
        this.if_disable = this.data.disabled

        if(this.answer){
            
            this.table_data = this.answer.table_answer

            /*if(typeof this.table_data == "string"){
                this.table_data = JSON.parse(this.table_data)
            }*/

            this.table_data.forEach(key => {
                if(key.data.optional_file){

                    var files = key.data.optional_file
                    for(var x = 0; x <= files.length - 1; x++){
                        
                        key.data.temp_optional_file.push({
                            name: files[x].filename,
                            url: process.env.VUE_APP_API_ROOT + "/storage/" + files[x].path,
                            path: files[x].path
                        })
                    }

                }
            })
        }
        else{
            this.table_data = [
                {
                    data: {
                        table_code: this.data.code + "-" + 0, 
                        name: "",
                        point: "",
                        position: "",
                        email: "",
                        description: "",
                        start_date: "",
                        completion_date: "",
                        value: 0,
                        optional_file: [],
                        temp_optional_file: []
                    },
                }
            ]
        }
        this.setCarouselBodyHeight()
    },
    methods: {
        incrementTableCode(table_code) {
            var count = table_code.match(/\d*$/);
            return table_code.substr(0, count.index) + (++count[0]);
        },
        beforeRemove(file, filelist){
            if(this.if_from_max_limit == 0){

            return new Promise((resolve, reject) => {
            this.$confirm('Are you sure you want to delete this file?')
                .then(_ => {
                
                    this.globalState.loading = true
                    var id = this.data.answer.id

                    this.answers.path = file.path
                    this.answers.id = id
                    this.answers.target_column = "table_answer"
                    
                    SqQuestion.removeFileFromTable(
                        this.answers
                    )
                    .then(result => {
                        this.$toast.success(result.data.message)
                        resolve(true)
                    })
                    .catch(error => {
                        console.error('error:', error)
                    })
                    .finally(() => {
                        this.globalState.loading = false
                        this.$emit('setCarouselBodyHeight', true)
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.globalState.loading = false
                    return false
                })
            })
        }
      },
      onPreview(file){
        window.open(file.url, 'Download');
      },
      customHttp(){
        return true
      },
      beforeUpload(file, fileList){
        this.if_from_max_limit = 0
        
        const isLimit = file.size / 1024 / 1024 < this.size_limit
        if (!isLimit) {
            this.$toast.warning('The maximum file size is '+this.size_limit+'MB!')
            this.if_from_max_limit = 1
            return false
        }

        var allowedTypes = [
          'application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint,text/plain',
          'application/pdf', 'image/jpeg', 'image/png', 'image/jpg'
        ];
        if (!allowedTypes.includes(file.type)) {
          
          this.$toast.warning('Invalid file type. Please upload a document or image file only.')
          this.if_from_max_limit = 1
          return false
        }
      },
      onUploadChange(file, fileList){

        this.$emit('tableChange', {
            question_code: this.data.code,
            value: this.table_data
        })
        this.setCarouselBodyHeight()
      },
      handleExceed(files, fileList) {
        this.$message.warning(`The limit is 3, you selected ${files.length + fileList.length} totally, please first remove the unwanted file`);
      },

        changeG(row){
            this.setCarouselBodyHeight()
        },
        setCarouselBodyHeight(){
            var totalHeight = 0;
            $('.el-carousel__item:eq(15) > div').each(function(){
                totalHeight += $(this).height();
            });
            $(".el-carousel__container").css("height", (totalHeight + 60) + "px")
        },
        inputChange(){
            this.$emit('tableChange', {
                question_code: this.data.code,
                value: this.table_data
            })
        },
        deleteRow(index){

            if(this.table_data.length > 1){
                this.table_data.splice(index, 1)
            }
            this.setCarouselBodyHeight()
        },
        addItem(){
            if(this.table_data.length < 3){
                this.table_data.push({
                    data: {
                        table_code: this.incrementTableCode(this.table_data[(this.table_data.length - 1)].data.table_code), 
                        name: "",
                        point: "",
                        position: "",
                        email: "",
                        description: "",
                        start_date: "",
                        completion_date: "",
                        value: 0,
                        optional_file: [],
                        temp_optional_file: []
                    },
                })
            }
            else{
                this.$toast.warning('The maximum number of contract is 3 only')
            }

            this.setCarouselBodyHeight()
        },
    }
  }
  </script>
  