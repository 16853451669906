<template>
  <nav aria-label="breadcrumb">
    <ol class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb">
      <li class="text-sm breadcrumb-item">
        <router-link class="text-white" to="/main/dashboard">
          <i class="fa-solid fa-house"></i>
        </router-link>
      </li>
      <li v-if="currentDirectory" class="text-sm breadcrumb-item text-white">
        <a href="#" class="text-white">{{currentDirectory}}</a>
      </li>
      <li class="text-sm breadcrumb-item active text-white" aria-current="page">
        {{currentPage}}
      </li>
    </ol>
    <h6 class="mb-0 font-weight-bolder text-white">
      <company-label class-name="text-white" />
    </h6>
  </nav>
</template>

<script>

export default {
  name: "Breadcrumbs",
  props: {
    currentPage: {
      type: String,
      default: ""
    },
    currentDirectory: {
      type: String,
      default: ""
    }
  }
};
</script>
