<template>
    <div v-if="form" class="">
      <div class="pb-0 card-header">
        <h5 class="font-weight-bolder mb-0">{{title}}</h5>
      </div>
      <div class="card-body">
      <br/>
      <br>
        <form @submit.prevent.prevent="" @reset.prevent="onCancel">
    
          <h6>My Pipeline Form</h6>
  
          <div class="form-group">
            <label class="form-label">Project Name</label>
            <input type="text" v-model.trim="form.project_name"
            :class="{ 'is-invalid': v$.form.project_name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.project_name.$errors" :key="error.$uid">
              <div class="error-text">Project Name {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Call Off Start Date</label><br>
            <el-date-picker 
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            type="date" v-model.trim="form.call_off_start_date"
            :class="{ 'is-invalid': v$.form.call_off_start_date.$errors.length, '': true }" />
  
            <div class="input-errors" v-for="error of v$.form.call_off_start_date.$errors" :key="error.$uid">
              <div class="error-text">Call Off Start Date {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Keywords *</label>
            <ElTagInput v-model.trim="form.keywords"
            :data="form.keywords"
            :add_text="'Add'"
            @update="updateKeywords"
            :class="{ 'is-invalid': v$.form.keywords.$errors.length, '': true }"></ElTagInput>
  
            <div class="input-errors" v-for="error of v$.form.keywords.$errors" :key="error.$uid">
              <div class="error-text">Keywords {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Contact First Name *</label>
            <input type="text" v-model.trim="form.first_name"
            :class="{ 'is-invalid': v$.form.first_name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.first_name.$errors" :key="error.$uid">
              <div class="error-text">Contact First Name {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Contact Last Name *</label>
            <input type="text" v-model.trim="form.last_name"
            :class="{ 'is-invalid': v$.form.last_name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.last_name.$errors" :key="error.$uid">
              <div class="error-text">Contact Last Name {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Contact Middle Name</label>
            <input type="text" v-model.trim="form.middle_name"
            class="form-control form-control-sm">
          </div>

          <div class="form-group">
            <label class="form-label">Contact Email *</label>
            <input type="email" v-model.trim="form.email"
            :class="{ 'is-invalid': v$.form.email.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.email.$errors" :key="error.$uid">
              <div class="error-text">Contact Email {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Contact Tel No</label>
            <input type="text" v-model.trim="form.tel_no"
            class="form-control form-control-sm">
          </div>

          <div class="form-group">
            <label class="form-label">Contact Mobile No</label>
            <input type="text" v-model.trim="form.mobile_no"
            class="form-control form-control-sm">
          </div>

          <div class="form-group">
            <label class="form-label">Contact Title *</label>
            <input type="text" v-model.trim="form.title"
            :class="{ 'is-invalid': v$.form.title.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.title.$errors" :key="error.$uid">
              <div class="error-text">Contact Title {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Contact Position *</label>
            <input type="text" v-model.trim="form.position"
            :class="{ 'is-invalid': v$.form.position.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.position.$errors" :key="error.$uid">
              <div class="error-text">Contact Position {{ error.$message }}</div>
            </div>
          </div>
  
          <div class="pt-4 text-end form-buttons">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="button" @click="onSubmit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>
    
  </template>
  
  <script>
    import { MyPipeline } from '@/api_services/my_pipeline';
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, requiredIf } from '@vuelidate/validators'
    import { mapState } from 'vuex'
    import ElTagInput from '@/components/common/ElTagInput'
    import Swal from 'sweetalert2';

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: Object
      },
      components: {
        ElTagInput
      },
      data() {
        return {
          isSaving: false,
          form:{
            project_name: null,
            call_off_start_date: null,
            keywords: null,
            first_name: null,
            last_name: null,
            middle_name: null,
            email: null,
            tel_no: null,
            mobile_no: null,
            title: null,
            position: null
          }
        }
      },
      validations() {
        return {
          form: {
            project_name: {
              required,
              $autoDirty: true
            },
            call_off_start_date: {
              required,
              $autoDirty: true
            },
            keywords: {
              required,
              $autoDirty: true
            },
            first_name: {
              required,
              $autoDirty: true
            },
            last_name: {
              required,
              $autoDirty: true
            },
            email: {
              email,  
              required,
              $autoDirty: true
            },
            title: {
              required,
              $autoDirty: true
            },
            position: {
              required,
              $autoDirty: true
            }
          },
        }
      },
      created: function () {
        this.form = JSON.parse(JSON.stringify(this.selected))
      },
      watch: {
        selected(selected) {
          this.form = JSON.parse(JSON.stringify(selected))
        },
      },
      computed: {
        title() {
          return this.isNew ? 'Add New' : `Manage Pipeline`
        },
        isNew() {
          return !this.selected?.id
        },
      },
      methods: {
        updateKeywords(value){
            this.form.keywords = value
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return

          this.form.keywords = this.form.keywords.split(',')
          this.isSaving = true
          const promise = this.isNew ? MyPipeline.create(this.form) : MyPipeline.update(this.selected.id, this.form)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Pipeline has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
      }
    }
  </script>
  