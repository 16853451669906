<template>
  <div id="sidenavCard" class="card card-plain shadow-none">
    <img class="w-60 mx-auto" src="../../assets/img/illustrations/icon-documentation.svg"/>
    <div class="p-3 card-body text-center w-100 pt-0">
      <div class="docs-info">
        <h6 class="mb-0 up text-dark">
          {{card.title}}
        </h6>
        <p class="text-xs font-weight-bold">{{card.description}}</p>
        <router-link
          v-for="({ label, route, color }, index) of card.links"
          :key="index"
          :to="route"
          :class="`btn btn-${color} btn-sm w-100 ${
            index === 0 ? 'mb-3' : 'mb-0'
          }`"
          >{{ label }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidenavCard",
  props: {
    card: {
      type: Object,
      required: true,
      title: String,
      description: String,
      links: {
        type: Array,
        label: String,
        route: String,
        color: String
      }
    }
  },
  data() {
    return {};
  }
};
</script>
