<template>
    <el-form label-position="top" @submit.prevent="submit">
      <label class="h5 mb-5">Project: <b class="text-primary">{{ form.name }}</b></label>
      
      <div v-if="form.deadline" class="form-group">
        <label>PIN Deadline *</label><br>
        <el-date-picker 
            :readonly="form.is_deadline_passed == 1 ? true : false" 
            v-model="form.deadline" type="date" :clearable="false"
            :editable="false"></el-date-picker>

      </div>
      <br>
      <div class="form-group">
        <label>Main Description *</label>
        <div :class="{'has-error-field': v.form.main_description.$errors.length  }">
          <QuillEditor theme="snow" v-model:content="form.main_description" contentType="html" />
        </div>
        <div v-if="v.form.main_description.$errors.length" class="error-text">Description is required</div>
      </div>
      <div class="form-group">
        <label>Common Files Description *</label>
        <div :class="{ 'has-error-field': v.form.common_description.$errors.length }">
          <QuillEditor theme="snow" v-model:content="form.common_description" contentType="html" />
        </div>
        <div v-if="v.form.common_description.$errors.length" class="error-text">Common files description is required</div>
      </div>
      <div class="form-group">
        <label>My Files Description *</label>
        <div :class="{ 'has-error-field': v.form.my_files_description.$errors.length }">
          <QuillEditor theme="snow" v-model:content="form.my_files_description" contentType="html" />
        </div>
        <div v-if="v.form.my_files_description.$errors.length" class="error-text">My files description is required</div>
      </div>
      <div class="form-group">
        <label>Q&A Description *</label>
        <div :class="{ 'has-error-field': v.form.qa_description.$errors.length }">
          <QuillEditor theme="snow" v-model:content="form.qa_description" contentType="html" />
        </div>
        <div v-if="v.form.qa_description.$errors.length" class="error-text">Q&A description is required</div>
      </div>
      <div class="form-group">
        <label>My Questions Description *</label>
        <div :class="{ 'has-error-field': v.form.my_questions_description.$errors.length }">
          <QuillEditor theme="snow" v-model:content="form.my_questions_description" contentType="html" />
        </div>
        <div v-if="v.form.my_questions_description.$errors.length" class="error-text">My question description is required</div>
      </div>
      <div class="form-group">
        <label>Spec Description *</label>
        <div :class="{ 'has-error-field': v.form.spec_description.$errors.length }">
          <QuillEditor theme="snow" v-model:content="form.spec_description" contentType="html" />
        </div>
        <div v-if="v.form.spec_description.$errors.length" class="error-text">Spec description is required</div>
      </div>
      <el-button native-type="submit" class="" size="large" type="primary">
        <i class="fa fa-save"></i>&nbsp;Save</el-button>
    </el-form>
    </template>
    
    <script>
    import {
      PinNotices
    } from '@/api_services/pin_notices'
    
    import {
      useVuelidate
    } from '@vuelidate/core'
    import {
      required
    } from '@vuelidate/validators'
    import * as moment from 'moment'
    import { mapState } from 'vuex'

    export default {
      computed: {
        ...mapState(['ProcurementProjectStatuses'])
      },
      props: {
        pin_notice: {
          type: Object,
          required: false
        }
      },
      data() {
        return {
          form: {
            id: 0,
            name: null,
            main_description: null,
            common_description: null,
            my_files_description: null,
            qa_description: null,
            my_questions_description: null,
            spec_description: null
          },
          main_description: null
        }
      },
      watch: {
        pin_notice: function () {
          this.form = Object.assign({}, this.pin_notice)
        },
        'form.main_description': function () {
          if (this.form.main_description == '<p><br></p>') this.form.main_description = null
        },
        'form.common_description': function () {
          if (this.form.common_description == '<p><br></p>') this.form.common_description = null
        },
        'form.my_files_description': function () {
          if (this.form.my_files_description == '<p><br></p>') this.form.my_files_description = null
        },
        'form.qa_description': function () {
          if (this.form.qa_description == '<p><br></p>') this.form.qa_description = null
        },
        'form.my_questions_description': function () {
          if (this.form.my_questions_description == '<p><br></p>') this.form.my_questions_description = null
        },
        'form.spec_description': function () {
          if (this.form.spec_description == '<p><br></p>') this.form.spec_description = null
        }
      },
      setup: () => ({
        v: useVuelidate()
      }),
      validations() {
        return {
          form: {
            spec_description: {
              required,
              $autoDirty: true
            },
            my_questions_description: {
              required,
              $autoDirty: true
            },
            qa_description: {
              required,
              $autoDirty: true
            },
            my_files_description: {
              required,
              $autoDirty: true
            },
            main_description: {
              required,
              $autoDirty: true
            },
            common_description: {
              required,
              $autoDirty: true
            },
          }
        }
      },
      created: function () {
        if (this.pin_notice) this.form = Object.assign({}, this.pin_notice)
        console.log(this.pin_notice)
      },
      methods: {
        async submit() {
          const result = await this.v.$validate()
          if (!result) return
    
          this.globalState.loading = true

          if(this.form.deadline){
            this.form.deadline = moment(
              this.form.deadline
            ).format('YYYY-MM-DD')
          }
    
          let request = PinNotices.update(this.form)
    
          request
            .then(result => {
              this.$toast.success(result.data.message)
              this.$emit('close', true)
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      }
    }
    </script>
    
    <style></style>
    