<template>
  <div class="card my-3">
    <div class="card-header">
      <h5>{{ selectedName(selected) }}</h5>
    </div>
    <div class="card-body">
      <form @submit.prevent="onSubmit" @reset="onCancel">

        <div class="form-group">
          <label class="form-label">Name</label>
          <input type="text" v-model="selected.name"
            :class="{ 'is-invalid': v$.selected.name.$errors.length, 'form-control form-control-sm': true }">

          <div class="input-errors" v-for="error of v$.selected.name.$errors" :key="error.$uid">
            <div class="error-text">Name is required!</div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label">Type</label>

          <select v-model="selected.type"
            :class="{ 'is-invalid': v$.selected.type.$errors.length, 'form-control form-control-sm': true }">
            <option :value="0">Simple</option>
            <option :value="1">Complex</option>
          </select>

          <div class="input-errors" v-for="error of v$.selected.type.$errors" :key="error.$uid">
            <div class="error-text">Type is required!</div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label">Kind</label>


          <select v-model="selected.kind"
            :class="{ 'is-invalid': v$.selected.kind.$errors.length, 'form-control form-control-sm': true }">
            <option :value="0">Special</option>
            <option :value="1">Ordinary</option>
          </select>

          <div class="input-errors" v-for="error of v$.selected.kind.$errors" :key="error.$uid">
            <div class="error-text">Kind is required!</div>
          </div>
        </div>



        <div class="form-group">
          <label class="form-label">Framework</label>

          <ProductFrameworkComponent v-model="selected.itt_framework_id" :itt_framework_id="selected.itt_framework_id"
            :archived="true"
            :size="'default'" :class="{ 'is-invalid': v$.selected.itt_framework_id.$errors.length }"
            @update="setFrameworkID($event)"></ProductFrameworkComponent>

          <div class="input-errors" v-for="error of v$.selected.itt_framework_id.$errors" :key="error.$uid">
            <div class="error-text">Framework is required!</div>
          </div>
        </div>

        <button type="submit" class="btn bg-gradient-success">
          Save
        </button>
        <button type="reset" class="btn bg-gradient-secondary">
          Cancel
        </button>
      </form>
    </div>
  </div>
</template>
    
<script>

import { Category } from '@/api_services/category'

import { useVuelidate } from '@vuelidate/core'
import {  required } from '@vuelidate/validators'
import ProductFrameworkComponent from '../products/ProductFrameworkComponent.vue'

export default {
  components: {
    ProductFrameworkComponent
  },
    setup () {
      return { v$: useVuelidate() }
    },
    props: {
      categories: {
            type: Object,
            required: false
        },
        original: {
            type: String,
            required: false
        },
    },
    data() {
        return{
            selected:{
                name: null,
                id: null,
            type: null,
                kind: 0
            }
        }
    },
    validations() {
      return {
        selected: {
          name: {
            required,
            $autoDirty: true
          },
          type: {
            required,
            $autoDirty: true
          },
          kind: {
            required,
            $autoDirty: true
          },
          itt_framework_id: {
            required,
            $autoDirty: true
          },
        },
      }
    },
    watch: {
        categories: function () {
            this.selected = Object.assign({}, this.categories)
        },
    },
    created: function () {
        if (this.categories) this.selected = Object.assign({}, this.categories)
    },
    methods: {
      setFrameworkID(id){
        this.selected.itt_framework_id = id
      },
        async onSubmit(evt) {
            evt.preventDefault()

            const result = await this.v$.$validate()
            if (!result) return

            var action = this.selected.id
                ? Category.update(this.selected.id, this.selected)
                : Category.create(this.selected)
            action
                .then(result => {
                this.$toast.success('Saved successfully')
                this.$emit('close_form', "save")
                })
                .catch(error => {
                this.$toast.error('An error occured while saving a category')
                console.error('error:', error.response.data)
                })
        },
        selectedName(selected) {
            return selected.id ? 'Edit ' + selected.name : 'New'
        },
        reset() {
            this.selected = null
        },
        onCancel(evt) {
            evt.preventDefault()
            this.$emit('close_form', "close")
        },
    }
}
</script>
    
<style></style>
    