<template>
  <div>
    <h2 class="text-white">Manage Products</h2>
    <div class="card text-lg p-3">
      <p>
        Welcome to the Manage Product page of the CPRAS Procurement Platform.
      </p>
      <p>
        This is your dedicated space to input and manage your products or services. Here, you have full control over your product listings, enabling you to effectively present your offerings to potential clients.
      </p>
      <p>
        To get started, click the ‘Add’ button below to begin inputting your product or service. Please provide comprehensive and accurate information about each product, including detailed descriptions, high-quality images (where applicable), and any relevant specifications. 
      </p>
      <p>
        As this platform is compliant with procurement regulations ensuring fair competition among suppliers, it's crucial to maintain the accuracy of all product listings. Therefore, if there are any updates or changes to your offerings, you must promptly update the information here to keep potential clients well-informed.
      </p>
      <p>
        We wish you every success in promoting your offerings and securing valuable business partnerships through this platform. If you have any questions or need assistance, please don't hesitate to reach out to our dedicated support team via the link on your dashboard.
      </p>
    </div>
    <div style="text-align: left;" class="row">
      <div class="col-md-12">
        <div class="card my-3">
          <div class="card-body px-0 pb-2">
            <div class="table-responsive">
              <nav
                class="navbar navbar-expand-lg top-0 z-index-3 w-100 shadow-none my-3 navbar-transparent"
              >
                <div class="containter">
                  <div class="col-auto">
                    <form @submit.stop.prevent="search()">
                      <div class="bg-white border-radius-lg d-flex me-2">
                        <input
                          type="text"
                          name=""
                          id=""
                          class="form-control form-control-sm border-1 ps-3"
                          placeholder="Search"
                        />
                        <button
                          class="btn bg-gradient-secondary btn-sm my-1 me-1"
                          @click="search()"
                        >
                          Search
                        </button>
                        <button
                        v-if="$store.state.isAllowed('PRODUCTS', 'CREATE')"
                          type="button"
                          class="btn bg-gradient-secondary btn-sm my-1 me-1"
                          @click="handleAddButtonClick"
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Product
                    </th>
                    <th
                      class="text-uppercase text secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Status
                    </th>
                    <th
                      class="text-uppercase text secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Category
                    </th>
                    <th
                      class="text-uppercase text secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!products.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else v-for="product in products" :key="product.id">
                    <td style="width: 50%">
                      <div class="d-flex px-2 py-1">
                        <div>
                          <img
                            :src="
                              product.absolute_path ||
                                require('@/assets/product.png')
                            "
                            alt=""
                            class="avatar avatar-xxl me-3"
                          />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-lg">{{ product.name }}</h6>
                          <p
                            class="text-xs text-secondary mb-0 text-wrap quill-content"
                            v-html="product.short_description"
                          ></p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <el-tag :type="product.status == ProductStatuses.STATUS_DELETING ? 'danger' : $store.state.product_status_types[product.status]">
                        {{ $store.state.product_statuses[product.status] }}
                      </el-tag>
                    </td>
                    <td>
                      <span
                        v-for="categories in product.categories"
                        :key="categories.id"
                      >
                        <span class="badge badge-success text-xxs">{{
                          categories.name
                        }}</span>
                      </span>
                    </td>
                    <td style="width: 10%">
                      <a
                        class="link me-3"
                        @click.prevent="showModalPreview(product)"
                        v-tooltip="'Preview'"
                      >
                        <i class="fa-solid fa-eye text-secondary"></i>
                      </a>
                      <router-link
                        class="me-3"
                        :to="`list/${product.id}/view`"
                        v-tooltip="'Edit'"
                      >
                        <i class="fas fa-edit text-secondary"></i>
                      </router-link>

                      <a
                        class="link me-3"
                        @click.prevent="showHistory(product)"
                        v-tooltip="'History'"
                      >
                        <i class="fa-solid fa-list text-secondary"></i>
                      </a>

                      <a
                      v-if="$store.state.isAllowed('PRODUCTS', 'DELETE') && product.status != ProductStatuses.STATUS_DELETING"
                        class="link"
                        @click.prevent="showRemove(product)"
                        v-tooltip="'Delete'"
                      >
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getProducts()" />
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">

        <p>{{ (to_remove.status == ProductStatuses.STATUS_APPROVED ? 'Request admin to ': '') }} delete this product: {{ to_remove.name }}?</p>

        <el-input
          v-if="to_remove.status == ProductStatuses.STATUS_APPROVED"
          class="mt-3"
          v-model="to_remove.delete_reason"
          :rows="2"
          type="textarea"
          placeholder="Please state your reason here.."
        />
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      id="modal-preview"
      v-model="preview_modal"
      className="el-dialog preview_modal_adjustment"
      >
      
    <div v-if="selected.id">
      <SearchProductPreviewComponent

        :product="selected"
        :category_id="params.category_id"
        :step="params.step"
        :volume="params.volume"
        :params="params"
        :preview="true"
        @show_details="showDetailsModal"
      ></SearchProductPreviewComponent>
    </div>
    
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="preview_modal = false">Cancel</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      id="modal-preview-details"
      v-model="preview_details_modal"
      width="80%"
    >
      
    <div v-if="selected.id">
      <selected-product-view-only-component
        :category_id="null"
        :product_id="selected.id"
        :volume="null"
      ></selected-product-view-only-component>
    </div>
    
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="preview_details_modal = false">Cancel</el-button>
        </div>
      </template>
    </el-dialog>

    <el-drawer
    :title="'History'"
      v-model="historyDrawer"
      :destroy-on-close="true"
      direction="rtl"
    >
      <ApplicationHistory
        :table_id="selected.id"
        :table_name="'products'"
        :title_name="'Product: <b>' + selected.name + '</b>'"
      ></ApplicationHistory>
    </el-drawer>

  </div>
</template>

<script>
import { Product } from '@/api_services/product'
import { Category } from '@/api_services/category'
import { Supplier } from '@/api_services/suppliers'
import ProductPreviewComponent from '../search_solutions/ProductPreviewComponent.vue'
import SelectedProductViewOnlyComponent from './SelectedProductViewOnlyComponent.vue'
import SearchProductPreviewComponent from '../search_solutions/SearchProductPreviewComponent.vue'
import ApplicationHistory from '@/components/common/ApplicationHistory'
import { mapState } from 'vuex'

export default {
  components: { ProductPreviewComponent, SelectedProductViewOnlyComponent, SearchProductPreviewComponent, ApplicationHistory },
  data() {
    return {
      params: {
        back: 0,
        page: 1,
        charges: [],
        specs: [],
        subspecs: [],
        social_values: [],
        category_id: null,
        step: 1,
        product_match: 40,
        price: 40,
        social_value: 15,
        environment_impact: 10,
        management_info: 5,
        volume_type: 0,
        volume: 0,
        first_search: true,
        cost_per_unit: 0
      },
      preview_details_modal: false,
      preview_modal: false,
      dialogVisible: false,
      isLoading: true,
      selected_categories: [],
      categories: [],
      category_names: [],
      products: [],
      fields: ['id', 'name', 'categories', 'action'],
      selected: null,
      original: null,
      preview: null,
      validate: ['name', 'supplier', 'categories', 'unit'],
      to_remove: null,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      suppliers: [
        { text: '-- Select One --', value: null },
        { text: 'banking', value: 1 },
        { text: 'merchant service', value: 2 },
        { text: 'direct debits', value: 3 },
        { text: 'payment gateway', value: 4 },
        { text: 'ivr & call masking', value: 5 },
        { text: 'innovation fs', value: 6 }
      ],
      historyDrawer: false
    }
  },
  created: function() {
    // this.getCategories()
    // this.getSuppliers()
    this.getProducts()
  },
  methods: {
    showHistory(data){
      this.selected = Object.assign({}, data)
      this.historyDrawer = true
    },
    showDetailsModal(){
      this.preview_modal = false
      this.preview_details_modal = true
    },
    showModalPreview(data){
      this.selected = Object.assign({}, data)
      this.preview_modal = true
    },
    handleAddButtonClick(event) {
      this.$router.push({
        name: 'Product',
        params: { id: 'new', view: 'view' }
      })
    },
    search(vt) {
      this.meta.page = 1
      this.getProducts()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()

      var delete_product
      this.globalState.loading = true

      if(this.to_remove.status != this.ProductStatuses.STATUS_APPROVED){
        delete_product = Product.delete(this.to_remove)
      }
      else{
        if(this.to_remove.delete_reason == "" || !this.to_remove.delete_reason){
          this.$toast.warning('Reason for deleting is required!')
          this.globalState.loading = false
          return false
        }
        else{
          this.to_remove.request_type = 0
          delete_product = Product.requestDelete(this.to_remove)
        }
      }
      

      delete_product.then(result => {
          this.$toast.success(result.data.message)
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getProducts()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing a user')
          console.error('error:', error.response.data)
        })
        .finally(() => {
              this.globalState.loading = false
            })
    },
    selectedName(selected) {
      return selected.id ? 'Edit ' + selected.name : 'New'
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type)
      this.original = Object.assign({}, type)

      this.selected_categories = []
      var self = this
      this.selected.categories.forEach(function(category) {
        self.selected_categories.push(category.name)
      })
    },
    showPrice(product) {
      return '£' + product.prices.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    getCategories() {
      Category.get({ params: {} })
        .then(result => {
          if (result.data.categories) this.categories = result.data.categories
          this.category_names = []
          var self = this
          this.categories.forEach(function(category) {
            self.category_names.push(category.name)
          })
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching suppliers')
          console.error('error:', error.response.data)
        })
    },
    getSuppliers() {
      this.reset()
      Supplier.get({ params: { term: this.term } })
        .then(result => {
          if (result.data.suppliers) this.suppliers = result.data.suppliers
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching suppliers')
          console.error('error:', error.response.data)
        })
    },
    getProducts() {
      this.reset()
      this.isLoading = true
      Product.get({ params: { term: this.term, page: this.meta.page } })
        .then(result => {
          if (result.data.products) this.products = result.data.products
          this.meta = result.data.meta
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching products')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    validation(field) {
      switch (field) {
        case 'unit':
          if (!this.selected.unit) this.selected.unit = ''
          return this.selected.unit.length > 0
        case 'name':
          if (!this.selected.name) this.selected.name = ''
          return this.selected.name.length > 0
        case 'supplier':
          return this.selected.supplier_id ? true : false
        case 'quantity':
          return this.selected.quantity > 0
        case 'prices':
          return this.selected.prices > 0
        case 'categories':
          if (!this.selected.categories) this.selected.categories = []
          return this.selected.categories.length > 0
        default:
          return true
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'prices')
        return 'Price must be at least one'
      if (!this.validation(field) && field == 'quantity')
        return 'Quantity must be at least one'
      if (!this.validation(field) && field == 'name') return 'Name is required'
      if (!this.validation(field) && field == 'categories')
        return 'At least one category is required'
      if (!this.validation(field) && field == 'supplier')
        return 'Supplier is required'
      if (!this.validation(field) && field == 'unit') return 'Unit is required'
      return ''
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.gatherCategories()
      let formData = this.getFormData()
      var action = this.selected.id
        ? Product.update(formData)
        : Product.create(formData)
      action
        .then(result => {
          this.$toast.success('Saved successfully')
          this.getProducts()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a product')
          console.error('error:', error.response.data)
        })
    },
    getFormData() {
      var formData = new FormData()
      formData.append('id', this.selected.id)
      formData.append('file', this.selected.file)
      formData.append('name', this.selected.name)
      formData.append('description', this.selected.description)
      formData.append('categories', this.selected.categories)
      formData.append('supplier_id', this.selected.supplier_id)
      formData.append('quantity', this.selected.quantity)
      formData.append('unit', this.selected.unit)
      formData.append('prices', this.selected.prices)
      formData.append('website', this.selected.website)
      return formData
    },
    gatherCategories() {
      var list = []
      var self = this
      this.selected_categories.forEach(function(name) {
        self.categories.forEach(function(r) {
          if (r.name === name && !list.includes(r.id)) {
            list.push(r.id)
          }
        })
      })
      this.selected.categories = list
    },
    onCancel(evt) {
      evt.preventDefault()
      this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = { name: null, email: null }
      this.selected = Object.assign({}, this.original)
      this.selected_categories = []
    }
  },
  computed: {
    ...mapState(['ProductStatuses']),
    availableOptions() {
      return this.category_names.filter(
        opt => this.selected_categories.indexOf(opt) === -1
      )
    },
    validateAll() {
      var self = this
      var response = true
      this.gatherCategories()
      this.validate.forEach(function(field) {
        if (!self.validation(field)) response = false
      })

      return response
    }
  }
}
</script>

<style>
.quill-content {
  font-size: 10; /* Replace with your desired font size */
}
@media (min-width: 1600px) {
  .preview_modal_adjustment {
    width:18% !important;
  }
}
@media (max-width: 1599px) and (min-width: 1200px) {
  .preview_modal_adjustment {
    width:30% !important;
  }
}
@media (max-width: 600px) and (min-width: 351px) {
  .preview_modal_adjustment {
    width:80% !important;
  }
}
@media (max-width: 350px) {
  .preview_modal_adjustment {
    width:100% !important;
  }
}

</style>
