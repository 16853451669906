<template>
  <div class="container">
    <div v-if="company" class="row">
      <div class="col-md-12">
        <div class="card" v-if="!editing">
          <div class="card-header">
            <div class="input-group">
              <h4>{{ company.name }}</h4><button type="button" class="btn btn-transparent float-end shadow-none" v-tooltip="'Edit Company'" @click="edit()" v-if="$helpers.canEdit('COMPANY')"><i class="fa-solid fa-pen-to-square text-lg"></i></button>
            </div>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-borderless">
                <tbody>
                  <tr>
                    <td class="text-sm" style="font-weight: 900">DBA</td>
                    <td class="text-sm" >{{ company.dba_name }}</td>
                  </tr>
                  <tr>
                    <td class="text-sm" style="font-weight: 900">Contact Person</td>
                    <td class="text-sm" >{{ company.contact_person }}</td>
                  </tr>
                  <tr>
                    <td class="text-sm" style="font-weight: 900">Email Address</td>
                    <td class="text-sm" >{{ company.email }}</td>
                  </tr>
                  <tr>
                    <td class="text-sm" style="font-weight: 900">Council</td>
                    <td class="text-sm">{{ company.council_name }}</td>
                  </tr>
                  <tr>
                    <td class="text-sm" style="font-weight: 900">Address</td>
                    <td class="text-sm text-wrap">{{ company.complete_address }}</td>
                  </tr>
                  <tr>
                    <td class="text-sm" style="font-weight: 900">Contact Person</td>
                    <td class="text-sm">{{ company.contact_person }}</td>
                  </tr>
                  <tr v-if="user.role_names[0] == 'admin'">
                    <td class="text-sm" style="font-weight: 900">Proposed Annual Net Saving</td>
                    <td class="text-sm">{{ company.proposed_annual_net_saving }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
        </div>
        <!-- <el-card :title="company.name" sub-title="" v-if="!editing">
           <table class="table table-striped">
            <tr>
              <td>DBA</td>
              <td>{{ (company.dba_name) ? company.dba_name : "N/A" }}</td>
            </tr>
            <tr>
              <td>Council Name</td>
              <td>{{ (company.council_name) ? company.council_name : "N/A" }}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{{ company.complete_address }}</td>
            </tr>
            <tr>
              <td>Contact Person</td>
              <td>{{ (company.contact_person) ? company.contact_person : "N/A" }}</td>
            </tr>
            <tr v-if="user.role_names[0] == 'admin'">
              <td>Proposed Annual Net Saving</td>
              <td>{{ company.proposed_annual_net_saving }} </td>
            </tr>
           </table>
           <div style="text-align:center">
              <b-button type="button" variant="primary" @click="edit()" v-if="$helpers.canEdit('COMPANY')">Edit</b-button>
           </div>
        </el-card> -->
        <div class="card" v-if="editing">
          <div class="pb-0 card-header">
            <h5 class="font-weight-bolder">{{ 'Edit '+company.name }}</h5>
          </div>
          <div class="card-body">
            <CompanyFormComponent :key="'companyform' + company_form_key" :company="company" @save="updateCompany($event)"></CompanyFormComponent>
          </div>
        </div>
        <!-- <el-card :title="'Edit '+company.name" sub-title="" v-if="editing">
          <company-form-component :company="company" @save="updateCompany($event)"></company-form-component>
        </el-card> -->
      </div>
    </div>
  </div>
</template>

<script>
import CompanyFormComponent from './CompanyFormComponent.vue'

export default {
  components: { CompanyFormComponent },
  data() {
    return {
      company_form_key: 1,
      company: null,
      editing: false,
      user: null
    }
  },
  created: function() {
    this.init()
    this.user = this.$store.state.savedUser
  },
  methods: {
    init() {
      this.company = Object.assign({},this.$store.state.savedUser.company)
    },
    updateCompany(company) {
      if (company) {
        this.$store.commit('saveCompany', company)
        this.init()
      }
      this.editing = false
    },
    edit() {
      this.company_form_key = this.company.id
      this.editing = true
    }
  }
}
</script>
