<template>
  <div id="social-value-section">
    <h4 class="orange-color">Social Values</h4>
    <div class="table-responsive" v-if="product_social_values.length">
      <table class="table table-borderless">
        <thead class="text-sm">
          <tr>
            <th>Social Value</th>
          <th>Response/Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-sm"
            v-for="social_value in product_social_values"
            :key="social_value.id"
          >
            <td class="text-wrap text-sm">
              {{ social_value.social_value.original_description }}
            </td>
            <td class="text-wrap">{{ social_value.notes }}</td>
          </tr>
        </tbody>
      </table>
      <pagination :meta="meta" v-on:update="getSocialValues()" />
    </div>
  </div>
</template>

<script>
import { ProductSocialValue } from '@/api_services/product_social_value'

export default {
  props: ['product'],
  data() {
    return {
      product_social_values: [],
      loading: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  watch: {
    update: {
      deep: true,
      handler() {
        this.getSocialValues()
      }
    }
  },
  created: function() {
    this.getSocialValues()
  },
  methods: {
    getSVValue(social_value) {
      if (social_value.social_value.type == 1) {
        return (social_value.value == 1) ? 'Yes' : 'No'
      }

      return social_value.value
    },
    getSocialValues() {
      this.loading = true
      //console.log(this.product.id)
       //this.product_social_values = []
      ProductSocialValue.get({
        params: {
          product_id: this.product.id,
          term: this.term,
          page: this.meta.page,
          per_page: 5
        }
      })
        .then(result => {
          if (result.data.product_social_values)
            this.product_social_values = result.data.product_social_values
          this.meta = result.data.meta
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
