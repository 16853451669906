<template>
  <div>
    <h2 class="text-white">Merchant Services Acquiring Terminologies</h2>
    <div style="text-align: left;" class="row">
      <div class="col-md-6">
        <div class="card my-3">
          <!-- <div class="card-header"><h5>Merchant Services Acquiring Terminologies</h5></div> -->
          <div class="px-0 pb-2 card-body">
            <div class="table-responsive">
              <nav class="navbar shadow-none navbar-transparent float-end">
                <div class="d-flex">
                  <input type="text" class="form-control form-control-sm mb-3" placeholder="Search" v-model="term">
                  <button class="btn bg-gradient-secondary btn-sm" @click="search()">Search</button>
                  <button class="btn bg-gradient-secondary btn-sm" @click="create()">Add</button>
                </div>
              </nav>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Card Type</th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <tr v-if="!terminologies.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else v-for="terminology in terminologies" :key="terminology.id">
                    <td>{{ terminology.description }}</td>
                    <td>{{terminology.full_card_type_name }}</td>
                    <td style="width: 1%">
                      <a class="link me-3" @click="showEdit(terminology)" v-tooltip="'Edit'">
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                      <a class="link" @click="showRemove(terminology)" v-tooltip="'Delete'">
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getCollection()"/>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="original">
        <div class="card my-3">
          <div class="card-header"><h5>{{ selectedName(original) }}</h5></div>
          <div class="card-body">
            <form @submit="onSubmit" @reset="onCancel">
              <div class="form-group">
                <label 
                  id="description" label-for="input-description" valid-feedback=""
                  :invalid-feedback="invalidFeedback('description')"
                  :state="validation('description')"
                >Description</label>
                <input type="text" class="form-control form-control-sm mb-3" v-model="selected.description" :state="validation('description')">
              </div>
              <div class="form-group">
                <label 
                  id="card-type" label-for="input-card-type" valid-feedback=""
                  :invalid-feedback="invalidFeedback('card-type')"
                  :state="validation('card-type')"
                >Card Type</label>
                <input type="text" class="form-control form-control-sm mb-3" v-model="selected.card_type_translation" :state="validation('card-type')">
              </div>
              <div class="form-group">
                <label 
                  id="card-category" label-for="input-category" valid-feedback=""
                  :invalid-feedback="invalidFeedback('category')"
                  :state="validation('category')"
                >Category</label>
                <select v-model="selected.category" :state="validation('category')" class="form-select form-select-sm">
                  <option v-for="category in categories" :key="category.id" :value="category.value">{{ category.name }}</option>
                </select>
              </div>
              <button type="submit"  :disabled="!validateAll" class="btn bg-gradient-success btn-sm">Save</button>
              <button type="reset" class="btn bg-gradient-secondary btn-sm">Cancel</button>

            </form>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
    <div v-if="to_remove">
        You are about to remove {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import { MerchantTerminology } from '@/api_services/merchant_terminology'

export default {
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      terminologies:[],
      fields: ['id', 'description','card_type','action'],
      selected: null,
      original: null,
      validate: ['description','card-type'],
      to_remove: null,
      term: null,
      // categories: ['Normal','Premium','Account Fees','Non-Transactional'],
      categories: [
        {name: 'Normal', value: 0},
        {name: 'Premium', value: 1},
        {name: 'Account Fees', value: 2},
        {name: 'Non-Transactional', value: 3}
      ],
      meta: {
        page: 1, total: 0, pages: 1
      }
    }
  },
  created: function() {
    this.getCollection()
  },
  methods: {
    search() {
      this.meta.page = 1
      this.getCollection()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      MerchantTerminology.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getCollection()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing categry')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit '+selected.description: 'New'
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    showEdit(type) {
      this.selected = Object.assign({}, type);
      this.original = Object.assign({}, type);
    },
    getCollection() {
      this.isLoading = true
      MerchantTerminology.get({params: {term: this.term, page: this.meta.page}})
        .then(result => {
          if(result.data.terminologies)
            this.terminologies = result.data.terminologies
            this.meta = result.data.meta
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching terminologies')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    validation(field) {
      switch(field) {
        case 'description':
          if (!this.selected.description) this.selected.description = ""
          return this.selected.description.length > 0
        case 'card-type':
          if (!this.selected.card_type_translation) this.selected.card_type_translation = ""
          return this.selected.card_type_translation.length > 0
        default:
          return true;
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'description') return "Description is required"
      if (!this.validation(field) && field == 'card-type') return "Card type is required"
      return ""
    },
    onSubmit(evt) {
        evt.preventDefault()
        var action = this.selected.id ? MerchantTerminology.update(this.selected.id, this.selected) : MerchantTerminology.create(this.selected)
        action.then(result => {
          this.$toast.success('Saved successfully')
          this.getCollection()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a category')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
        evt.preventDefault()
        this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
    },
    create() {
      this.original = {description: null, card_type_translation: null, category: 0}
      this.selected = Object.assign({}, this.original);
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field){
        if (!self.validation(field)) response = false;
      })

      return response;
    }
  }
}
</script>
