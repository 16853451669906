<template>
  <div style="text-align:left">
    <h4>
      Status: {{ $store.state.call_off_statuses[selected.call_off_status] }}
    </h4>
    <hr />
    <call-off-info-component :product_search_result="selected" :start_date="selected.call_off_start_date"
      :contract_period="selected.contract_period" :ext_1="selected.extension_period"
      :ext_2="selected.extension_period_2" :ext_3="selected.extension_period_3"
      :ext_4="selected.extension_period_4"></call-off-info-component>
    <hr />

    <div
      v-if="selected.call_off_status < 3 || ( selected.call_off_status == 8 && selected.call_off_approval && selected.call_off_approval.final_signature == 'buyer')">
      <buyer-call-off-form :product_search_result="selected" @submit="handleSubmit($event)"
        @upload="handleUpload($event)"></buyer-call-off-form>
    </div>

    <div class="mt-3" v-if="isSupplierRejected() ">
      <el-alert type="warning">Awaiting the supplier to re-submit the signed document following its rejection.</el-alert>
    </div>

    <el-alert
      v-if="selected.call_off_status == 6 && selected.call_off_approval && selected.call_off_approval.final_signature == 'buyer'">Our
      team is currently reviewing the document you submitted. Please
      check
      back at a later time.</el-alert>

    <el-alert
      v-if="selected.call_off_status == 6 && selected.call_off_approval && selected.call_off_approval.final_signature == 'supplier'">The
      supplier has submitted a signed document that requires approval before proceeding. Please revisit at a later
      time.</el-alert>


  </div>
</template>

<script>
import { BuyerSearchQuery } from '@/api_services/buyer_search_query'
import { ProductSearchResult } from '@/api_services/product_search_result'
import CallOffInfoComponent from './CallOffInfoComponent.vue'
import * as moment from 'moment'
import BuyerCallOffForm from './BuyerCallOffForm.vue'

export default {
  props: ['product_search_result', 'preferred_contract_length'],
  components: { CallOffInfoComponent, BuyerCallOffForm },
  data() {
    return {
      selected: {
        call_off_start_date: null,
        contract_period: null,
        buyer_name: null,
        buyer_title: null,
        buyer_signature: null,
        call_off_status: 0
      },
    }
  },
  created: function() {
    this.selected = JSON.parse(JSON.stringify(this.product_search_result))
    if (!this.selected.contract_period) {
      this.selected.contract_period = this.preferred_contract_length
    }

    if (this.selected.call_off_status == 0) this.selected.contract_period = this.selected.params.contract_length
  },

  methods: {
    isSupplierRejected() {
      return this.selected.call_off_approval && this.selected.call_off_approval.final_signature == 'supplier' && this.selected.call_off_approval.supplier_status == 2
    },
    handleSubmit(form) {
      let formattedStartDate = moment(
        form.call_off_start_date
      ).format('YYYY-MM-DD')

      let formattedSignedDate = moment(
        form.buyer_signed_date
      ).format('YYYY-MM-DD')


      if (form.call_off_start_date && form.call_off_status == 2) formattedStartDate = formattedStartDate

      form.call_off_start_date = formattedStartDate
      form.buyer_signed_date = formattedSignedDate

      this.globalState.loading = true
      this.globalState.message = 'Saving'
      BuyerSearchQuery.submitCallOffFromBuyer(form)
        .then(result => {
          this.$toast.success('Call off submitted')
          this.$emit('save')
        })
        .catch(error => {
          this.$toast.error('An error occured while submitting call off')
          console.error('error:', error.response)
        })
        .finally(() => {
          this.globalState.loading = false
        })
    },
    handleUpload(form) {
      this.globalState.loading = true
      ProductSearchResult.uploadBuyerCallOffDocument(form.get('id'), form)
        .then(result => {
          this.$toast.success('Call off submitted')
          this.$emit('save')
        })
        .catch(error => {
          this.$toast.error('An error occured while submitting the call off')
          console.error('error:', error.response)
        })
        .finally(() => {
          this.globalState.loading = false
        })
    }
  }
}
</script>
