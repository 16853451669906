<style>
  .hide{
    display: none;
  }
</style>
<template>
  <div>
    <form @submit.prevent="onSubmit" @reset="onCancel">
      <div class="form-group">
        <label class="form-label">Name</label>
        <input type="text" :class="{ 'is-invalid': v$.selected.name.$errors.length, 'form-control': true }"
          v-model.trim="selected.name" />
        <div class="input-errors" v-for="error of v$.selected.name.$errors" :key="error.$uid">
          <div class="error-text">Name {{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Contact Person</label>
        <input type="text" :class="{ 'is-invalid': v$.selected.contact_person.$errors.length, 'form-control': true }"
          v-model.trim="selected.contact_person" />
        <div class="input-errors" v-for="error of v$.selected.contact_person.$errors" :key="error.$uid">
          <div class="error-text">Contact Person {{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Email</label>
        <input type="email" :class="{ 'is-invalid': v$.selected.email.$errors.length, 'form-control': true }"
          v-model.trim="selected.email" />
        <div class="input-errors" v-for="error of v$.selected.email.$errors" :key="error.$uid">
          <div class="error-text">Email {{ error.$message }}</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">CC</label>
        <MultipleEmailsComponent :key="'multiple_email_cc'+ selected.id" v-model="selected.cc" :email_data="selected.cc"
          @update="selected.cc = $event"></MultipleEmailsComponent>
      </div>

      <div class="form-group">
        <label class="form-label">BCC</label>
        <MultipleEmailsComponent :key="'multiple_email_bcc'+ selected.id" v-model="selected.bcc"
          :email_data="selected.bcc" @update="selected.bcc = $event"></MultipleEmailsComponent>
      </div>

      <div class="form-group">
        <label class="form-label">DBA Name</label>
        <input type="text" :class="{ 'is-invalid': v$.selected.dba_name.$errors.length, 'form-control': true }"
          v-model.trim="selected.dba_name" />
        <div class="input-errors" v-for="error of v$.selected.dba_name.$errors" :key="error.$uid">
          <div class="error-text">DBA Name {{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Council</label>
        <vSelect v-model.trim="selected.council_name" :options="councils" label="label"
           :reduce="council => council.value">
        </vSelect>
     
        <!-- <vSelect
          v-model.trim="selected.council_name"
          :options="councils"
          label="label"
          :class="{ 'is-invalid': v$.selected.council_name.$errors.length }"
          :reduce="council => council.value"
        ></vSelect>
        <div class="input-errors" v-for="error of v$.selected.council_name.$errors" :key="error.$uid">
          <div class="error-text">Council {{ error.$message }}</div>
        </div> -->

      </div>
      <div class="form-group">
        <label class="form-label">Address</label>
        <input type="text" v-model.trim="selected.address1"
          :class="{ 'is-invalid': v$.selected.address1.$errors.length, 'form-control': true }" />
        <div class="input-errors" v-for="error of v$.selected.address1.$errors" :key="error.$uid">
          <div class="error-text">Address {{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">City</label>
        <input type="text" :class="{ 'is-invalid': v$.selected.city.$errors.length, 'form-control': true }"
          v-model.trim="selected.city" />
        <div class="input-errors" v-for="error of v$.selected.city.$errors" :key="error.$uid">
          <div class="error-text">City {{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">State</label>
        <input type="text" :class="{ 'is-invalid': v$.selected.state.$errors.length, 'form-control': true }"
          v-model.trim="selected.state" />
        <div class="input-errors" v-for="error of v$.selected.state.$errors" :key="error.$uid">
          <div class="error-text">State {{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Zip</label>
        <input type="text" v-model.trim="selected.zip"
          :class="{ 'is-invalid': v$.selected.zip.$errors.length, 'form-control': true }" />
        <div class="input-errors" v-for="error of v$.selected.zip.$errors" :key="error.$uid">
          <div class="error-text">Zip {{ error.$message }}</div>
        </div>
      </div>

      <div v-if="!$store.state.isUserABuyer()" class="form-group">
        <label class="form-label">Proposed Annual Saving</label>
        <input type="number" step="1"
          :class="{ 'is-invalid': v$.selected.proposed_annual_net_saving.$errors.length, 'form-control': true }"
          v-model.trim="selected.proposed_annual_net_saving" />
        <div class="input-errors" v-for="error of v$.selected.proposed_annual_net_saving.$errors" :key="error.$uid">
          <div class="error-text">Proposed Annual Saving {{ error.$message }}</div>
        </div>
      </div>
      <div v-if="!$store.state.isUserABuyer()" class="form-group">
        <label class="form-label">Account</label>
        <input type="text" v-model.trim="selected.account"
          :class="{ 'is-invalid': v$.selected.account.$errors.length, 'form-control': true }" />
        <div class="input-errors" v-for="error of v$.selected.account.$errors" :key="error.$uid">
          <div class="error-text">Account {{ error.$message }}</div>
        </div>
      </div>
      <div v-if="!$store.state.isUserABuyer()" class="form-group">
        <label class="form-label">Purchase Order</label>
        <input type="text" class="form-control form-control-sm" v-model.trim="selected.purchase_order" />
      </div>
      <div v-if="company && !$store.state.isUserABuyer()" v-allowed="'companies,create'">
        <modal modal_main_class="addLotModal" v-model="addLotModal" title="Add Lot and Savings" size="sm" centered>
          <div class="container">
            <!-- <label for="">framework lot name</label> -->
            <!-- <input type="text" class="form-control" v-model="newLotSavings.name"> -->
            <div class="form-group">
              <label class="form-label">framework lot name</label>

              <vSelect v-model.trim="newLotSavings.name" :options="framework_names" label="label"
                :reduce="framework_name => framework_name.value" required></vSelect>
            </div>

            <label for="">projected saving</label>
            <input type="number" class="form-control" v-model="newLotSavings.savings">
            <br>
            <button type="button" class="btn btn-success mt-2 w-100" @click="addLotSavings">Add</button>
          </div>

        </modal>

        <div class="pb-3">
          <span><b>Savings Projections</b></span>
          <div v-for="(item, index) in selected.lot_savings_projections" :key="index">
            <hr>
            <div class="input-group input-group-sm">
              <span style="background-color:azure;" class="input-group-text text-bold">lot</span>
              <input v-model="item.name" type="text" class="form-control p-2 border-1" readonly>
              <span style="background-color:azure;" class="input-group-text text-bold">saving</span>
              <input v-model="item.savings" type="number" class="form-control p-2 border-1">
              <button v-tooltip="'Remove framework item'" class="btn shadow-none" @click="removeLotSavingItem(index)"><i
                  style="color: red;" class="fa-solid fa-xmark p-2"></i></button>
            </div>
          </div>
        </div>

        <button v-tooltip="'Add Framework lot and projected savings'" type="button"
          class="rounded-circle btn-secondary border-0" @click="openModal"><i class="fa-solid fa-plus"></i> </button>
      </div>

      <div :class="{ 'hide': if_hide_button, 'pt-4 text-end form-buttons': true }">
        <button type="reset" class="btn bg-gradient-secondary btn-sm">
          Cancel
        </button>
        <button ref="savebtn" type="submit" class="btn bg-gradient-success btn-sm">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>

import Modal from '@/components/common/Modal'
import { Company } from '@/api_services/company'
import { Council } from '@/api_services/council'
import { FrameworkName } from '@/api_services/framework_name'
import vSelect from "vue-select";
import $ from 'jquery'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import MultipleEmailsComponent from '@/components/common/MultipleEmailsComponent'

export default {
  setup () {
      return { v$: useVuelidate({scope: false}) }
    },
  props: {
    company: {
      type: Object
    },
    if_hide_button: {
      if_hide_button: Boolean,
      default: false
    }
  },
  components: {
    Modal,
    vSelect,
    MultipleEmailsComponent
  },
  data() {
    return {
      addLotModal: null,
      newLotSavings: {
        name: '',
        savings: ''
      },
      councils: [],
      framework_names: [],
      loading: false,
      selected: {
        name: '',
        contact_person: '',
        email: '',
        dba_name: '',
        council_name: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        proposed_annual_net_saving: 0,
        account: '',
        purchase_order: '',
        lot_savings_projections: [] 
      },
    }
  },
  validations() {
      return {
        selected: {
          name: {
            required,
            $autoDirty: true
          },
          contact_person: {
            required,
            $autoDirty: true
          },
          email: {
            required,
            $autoDirty: true
          },
          dba_name: {
            required,
            $autoDirty: true
          },
          address1: {
            required,
            $autoDirty: true
          },
          city: {
            required,
            $autoDirty: true
          },
          state: {
            required,
            $autoDirty: true
          },
          zip: {
            required,
            $autoDirty: true
          },
          proposed_annual_net_saving: {
            required: requiredIf(function () {
              return (this.$store.state.isUserABuyer()) ? false : true
            }),
            $autoDirty: true
          },
          account: {
            required: requiredIf(function () {
              return (this.$store.state.isUserABuyer()) ? false : true
            }),
            $autoDirty: true
          }
        },
      }
    },
    watch: {
    'selected.name': function(){
      this.$emit('update_data','full_name', this.selected.name)
    },
    'selected.address1': function(){
      this.$emit('update_data','office_address', this.selected.address1 + ", " + this.selected.city + ", " + this.selected.state + ", " + this.selected.zip)
    },
    'selected.city': function(){
      this.$emit('update_data','office_address', this.selected.address1 + ", " + this.selected.city + ", " + this.selected.state + ", " + this.selected.zip)
    },
    'selected.state': function(){
      this.$emit('update_data','office_address', this.selected.address1 + ", " + this.selected.city + ", " + this.selected.state + ", " + this.selected.zip)
    },
    'selected.zip': function(){
      this.$emit('update_data','office_address', this.selected.address1 + ", " + this.selected.city + ", " + this.selected.state + ", " + this.selected.zip)
    },
    'selected.email': function(){
      this.$emit('update_data','email', this.selected.email)
    },
  },  
  created: function() {
    this.getFrameworkNames()
    this.getCouncils()
    if(this.company) {
      this.selected.name = this.company.name || ''
      this.selected.contact_person = this.company.contact_person || ''
      this.selected.email = this.company.email || ''
      this.selected.dba_name = this.company.dba_name || ''
      this.selected.council_name = this.company.council_name || ''
      this.selected.address1 = this.company.address1 || ''
      this.selected.city = this.company.city || ''
      this.selected.state = this.company.state ||  ''
      this.selected.zip = this.company.zip || ''
      this.selected.proposed_annual_net_saving = this.company.proposed_annual_net_saving || 0
      this.selected.account = this.company.account || ''
      this.selected.purchase_order = this.company.purchase_order || ''
      this.selected.cc = this.company.cc || ''
      this.selected.bcc = this.company.bcc || ''
      // Check the type of 'lot_savings_projections'
      if (typeof this.company.lot_savings_projections === 'string') {
        // If it's a string, try parsing it as JSON
        try {
          this.selected.lot_savings_projections = JSON.parse(this.company.lot_savings_projections);
        } catch (error) {
          console.error('Error parsing lot_savings_projections:', error);
        }
      } else if (Array.isArray(this.company.lot_savings_projections)) {
        // If it's already an array, assign it directly
        this.selected.lot_savings_projections = this.company.lot_savings_projections;
      } else {
        // If it's neither a string nor an array, set it as an empty array
        this.selected.lot_savings_projections = [];
      }
    } else {
      this.create()
    }
    // this.selected = Object.assign({}, this.company);
  },
  methods: {
    clickSaveButton(){
      this.$refs.savebtn.click();
    },
    removeLotSavingItem(index) {
      if (index >= 0 && index < this.selected.lot_savings_projections.length) {
        this.selected.lot_savings_projections.splice(index, 1);
      } else {
        console.error('Invalid index:', index);
      }
    },
    openModal() {
      $(".addLotModal").modal("show")
    },
    addLotSavings() {
      if (this.newLotSavings.name && this.newLotSavings.savings) {
        this.selected.lot_savings_projections.push({ ...this.newLotSavings })
        this.newLotSavings = { name: '', savings: '' }
        $(".addLotModal").modal("hide")
      }
    },
    getCouncils() {
      Council.get({ params: {} })
        .then(result => {
          let cs = result.data.councils ? result.data.councils : [];
          this.councils = cs
          .slice(1)
          .map(c => {
            return { value: c.council, label: c.council };
          });
        })
        .catch(error => {
          console.error('error:', error);
        });
    },
    getFrameworkNames() {
      FrameworkName.get({ params: {} })
        .then(result => {
          let fn = result.data.framework_names ? result.data.framework_names : [];
          this.framework_names = fn
          .map(f => {
            return { value: f.framework_name, label: f.framework_name };
          });
        })
        .catch(error => {
          console.error('error:', error.response.data);
        });
    },
    async onSubmit(evt) {
      evt.preventDefault()

      const result = await this.v$.$validate()
      if (!result) return

      this.loading = true

      var action = this.company ? Company.update(this.company.id, this.selected) : Company.create(this.selected);

      // Company.update(this.company.id,this.selected)
      action
        .then(result => {
        this.$toast.success('Company details are saved successfully')
        this.$emit('save', result.data.company)

        if(this.$store.state.savedUser && this.if_hide_button){
          this.$store.state.savedUser.company.email = this.selected.email
          this.$store.state.savedUser.company.cc = this.selected.cc
          this.$store.state.savedUser.company.bcc = this.selected.bcc
          this.$store.commit('saveUser', this.$store.state.savedUser)
        }
      })
      .catch(error => {
        console.log("caught here")
        // this.$toast.error('An error occured while saving a company')
        console.error('error:', error.response.data)
      }).finally( () => {
        this.loading = false
      })
    },
    onCancel(evt) {
      if (evt)  evt.preventDefault()
      this.$emit('save', null)
    },
    create() {
      this.selected = {
        name: null, 
        contact_person: null, 
        dba_name: null, 
        council_name: null, 
        address1: null, 
        city: null, 
        state: null, 
        zip: null, 
        proposed_annual_net_saving: 0, 
        lot_savings_projections: null
      }
      'contact_person','dba_name', 'council_name', 'address1','city','state','zip','proposed_annual_net_saving', 'lot_savings_projections'
    },
    
  },
  mounted() {
    this.addLotSavings();
  }
}
</script>