<template>
  <el-form label-position="top" @submit.prevent="submit" v-loading="loading">
    <div class="row">
      <div class="col-md-2">
        <el-form-item label="" prop="description"
          :error="v.form.description.$errors.length ? 'Description is required' : ''">
          <el-input v-model="form.description" type="textarea"></el-input>
        </el-form-item>
      </div>
      <div class="col-md-2">

        <el-form-item label="" prop="transaction_volume">
          <el-input v-model="form.transaction_volume" style="width: 100%" type="number" placeholder="0.00"></el-input>
        </el-form-item>

      </div>
      <div class="col-md-2">
        <el-form-item label="" prop="transaction_value">
          <el-input v-model="form.transaction_value" style="width: 100%" type="number" placeholder="0.00"></el-input>
        </el-form-item>

      </div>
      <div class="col-md-1">
        <el-form-item label="" prop="rate_percent">
          <el-input v-model="form.rate_percent" style="width: 100%" type="number" placeholder="0.00"></el-input>
        </el-form-item>
      </div>
      <div class="col-md-1">
        <el-form-item label="" prop="ppt">
          <el-input v-model="form.ppt" style="width: 100%" type="number" placeholder="0.00"></el-input>
        </el-form-item>
      </div>
      <div class="col-md-1">
        <el-form-item label="" prop="cost">
          <el-input v-model="form.cost" disabled></el-input>
        </el-form-item>
      </div>
      <div class="col-md-1">
        <el-tooltip v-if="!approval_list" :enterable="false" placement="top-start">
          <template #content>
            <span>Delete</span>
          </template>
          <el-button size="small" plain @click="handleRemove()" type="danger"> <i class="fa fa-times"></i></el-button>
        </el-tooltip>
      </div>
    </div>
  </el-form>
</template>

<script>

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'

export default {
  props: {
    approval_list: {
      type: Boolean,
      default: false
    },
    cost_detail: {
      type: Object,
      default: null
    },
    show_save: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        value: 0,
        transaction_volume: 0,
        transaction_value: 0,
        ppt: 0,
        rate_percent: 0
      },
      loading: false,
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        description: {
          required,
          $autoDirty: true
        },
      }
    }
  },
  watch: {
    cost_detail: function () {
      if (this.cost_detail) this.form = Object.assign({}, this.cost_detail)
    },
    'form.transaction_volume': function () {
      this.computeCost()
    },
    'form.ppt': function () {
      this.computeCost()
    },
    'form.transaction_value': function () {
      this.computeCost()
    },
    'form.rate_percent': function () {
      this.computeCost()
    },
    'form.description': function () {
      this.computeCost()
    },
  },
  created: function () {
    if (this.cost_detail) this.form = Object.assign({}, this.cost_detail)
  },
  methods: {
    computeCost() {
      this.form.cost = (parseFloat(this.form.transaction_volume) * parseFloat(this.form.ppt)) + (parseFloat(this.form.transaction_value) * (parseFloat(this.form.rate_percent)/100) ) || 0
      this.$emit('update', this.form)
    },
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.$emit('close', this.form)
    },
    handleClose() {
      this.$emit('close')
    },
    handleRemove() {
      this.$emit('delete')
    }
  }
}
</script>

<style></style>
