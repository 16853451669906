<template>
    <div>
        <vue3-simple-typeahead
        :id="desc_id"
        class="form-control"
        placeholder="Search..."
        :items="this.social_values"
        :minInputLength="3"
        v-model="term"
        @selectItem="selectItemEventHandler"
        @onInput="onInputEventHandler"
        :defaultItem="current_desc"
        ></vue3-simple-typeahead>

    </div>
  </template>
  
  <script>
  
  import { SocialValue } from '@/api_services/social_value'
  
  
  export default {
    name: 'TestComponent',
    props: ['valid','word','category_id', "desc_id", "current_desc"],
    data() {
      return {
        awaitingSearch:false,
        clicked: false,
        term: '',
        social_values: [{
          original_description: ""
        }],
        selectedAddress: null,
        original_word: ''
      }
    },
    created: function() {
      this.term = this.word
      if (this.word) {
        this.original_word =  this.word.split('').join('');
        this.term = this.word.split('').join('');
      }
      this.search()
    },
    methods: {
      selectItemEventHandler(value){
        this.$emit("set-selected-word", value)
      },
      onInputEventHandler(){
        //this.search()
      },
      getDescriptionAndUnit(social_value) {
        return social_value.original_description
          // return social_value.description+" - "+this.$store.state.getSVTypeLabel(social_value.type)
      },
      setkey(word) {
        this.clicked = true
        this.$emit('update', word)
      },
      check(word) {
        this.$emit('update', word)
      },
      // setkey(obj) {
      //   this.clicked = true
      //   this.original_word =  obj.purchase_order.split('').join('');
      //   this.$emit('update', obj)
      // },
      // search() {  
      //   this.clicked = false
      //   this.check(this.term)
      //   var params = {per_page: 5, term: this.term}
      //   if (this.category_id) params.category_id = this.category_id
      //   Specification.get({ params: params })
      //     .then(result => {
      //       if(result.data.specifications)
      //         this.specifications = result.data.specifications
      //     })
      //     .catch( error => {
      //       this.$toast.error('An error occured while fetching specifications')
      //       console.error('error:', error.response.data)
      //     })
      // },
      search() {  
        this.social_values = []
      
        this.clicked = false
        this.check(this.term)
        var params = {per_page: 5, term: this.term}
        if (this.category_id) params.category_id = this.category_id
        SocialValue.get({ params: params })
          .then(result => {
            if(result.data.social_values){
              for(var x = 0; x <= result.data.social_values.length - 1; x++){
                this.social_values.push(result.data.social_values[x].original_description)
              }
            } 
          })
          .catch( error => {
         
          })
      },
    },
  
    watch: {
      word: function() {  
        this.term = this.word
      },
      term: function() {    
        
        let self = this
        if (this.term != this.original_word) this.check(this.term)

        if (!this.awaitingSearch) {
          setTimeout(() => {
            self.search() 

            this.awaitingSearch = false;
          }, 1500);
        }
        this.awaitingSearch = true;
        
      }
    }
  }
  </script>