<template>
  <div>
    <not-allowed v-if="!$store.state.isViewable('suppliers')"></not-allowed>
    <div v-else>
      <h2 class="text-white">Supplier Prospects</h2>
      <div style="text-align: left;" class="row">
        <div class="col-md-8">
          <div class="card my-3">
            <div class="card-body px-0 pb-2">
              <!-- <div class="container"> -->
              <div class="float-end">
                <button
                  class="btn bg-gradient-secondary btn-sm"
                  @click="create()"
                >
                  Add
                </button>
                <button
                  class="btn bg-gradient-secondary btn-sm"
                  @click="getSuppliers()"
                >
                  <i class="fa-solid fa-arrow-rotate-right text-white"></i>
                </button>
              </div>
              <table class="table table-fluid align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Merchant
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Prospect Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Category
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <tr v-if="!suppliers.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else v-for="supplier in suppliers" :key="supplier.id">
                    <td class="text-wrap" style="width: 20%">
                      {{ supplier.name }}
                    </td>
                    <td>
                      {{ supplier.prospect ? supplier.prospect.name : '' }}
                    </td>
                    <td class="text-wrap">
                      <span>{{
                        showConcatinatedCategories(supplier.categories)
                      }}</span>
                    </td>
                    <td style="width:20%">
                      <div>
                        <a
                          class="link me-3 "
                          @click="createDuplicate(supplier)"
                          v-tooltip="'Duplicate'"
                        >
                          <i class="fa-regular fa-copy text-secondary"></i>
                        </a>
                        <a
                          class="link me-3 "
                          @click="showBffRates(supplier)"
                          v-tooltip="'BFF Rates'"
                        >
                          <i class="fa-solid fa-percent text-secondary"></i>
                        </a>
                        <a
                          class="link me-3 "
                          @click="showEdit(supplier)"
                          v-tooltip="'Edit'"
                        >
                          <i class="fas fa-user-edit text-secondary"></i>
                        </a>
                        <a
                          class="link"
                          @click="showRemove(supplier)"
                          v-tooltip="'Delete'"
                        >
                          <i class="fas fa-trash text-secondary"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- </div> -->
            </div>
            <!-- <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getSuppliers()"/>
          </div> -->
          </div>
        </div>
        <div class="col-md-4" v-if="original">
          <div class="card my-3">
            <div class="card-header">
              <h5>{{ selectedName(original) }}</h5>
            </div>
            <div class="card-body">
              <form @submit="onSubmit" @reset="onCancel">
                <div class="form-group">
                  <label
                    id="name"
                    label-for="input-name"
                    valid-feedback=""
                    :invalid-feedback="invalidFeedback('name')"
                    :state="validation('name')"
                    >Name</label
                  >
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    id="input-name"
                    v-model="selected.name"
                    :state="validation('name')"
                  />
                </div>

                <div class="form-group">
                  <label class="mt-4">Categories</label>
                  <supplier-category-component
                    :supplier="selected"
                    @update="setSupplierCategories($event)"
                  ></supplier-category-component>
                </div>

                <div class="form-group">
                  <select
                    name=""
                    id=""
                    class="form-select form-select-sm"
                    v-model="selected_prospect"
                  >
                    <option
                      v-for="prospect in availableProspects"
                      :key="prospect.id"
                      >{{ prospect }}</option
                    >
                  </select>
                </div>
                <div class="form-group">
                  <button
                    :disabled="!validateAll"
                    type="button"
                    @click="setAddProspect()"
                    class="btn bg-gradient-secondary btn-sm"
                  >
                    <i class="fa-solid fa-plus text-white"></i> Add Prospect
                  </button>
                  <div v-if="show_add_prospect">
                    <input
                      id="input-name"
                      class="form-control form-control-sm"
                      v-model="add_prospect"
                      :state="validation('prospect_name')"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  :disabled="!validateAll"
                  class="btn bg-gradient-success btn-sm"
                >
                  Save
                </button>
                <button type="reset" class="btn bg-gradient-secondary btn-sm">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div> 

      <el-dialog
        id="modal-remove"
        v-model="dialogVisible"
        title="Are you sure?"
        width="500"
      >
      <div v-if="to_remove">
          You are about to remove {{ to_remove.name }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import { Supplier } from '@/api_services/suppliers'
import { Prospect } from '@/api_services/prospects'
import SupplierCategoryComponent from './SupplierCategoryComponent.vue'

export default {
  components: {
    SupplierCategoryComponent
  },
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      fields: ['id', 'name', 'prospect', 'categories', 'action'],
      suppliers: [],
      prospects: [],
      selected: null,
      original: null,
      validate: ['name', 'prospect_name'],
      to_remove: null,
      cat_string: [
        '',
        'Banking',
        'Merchant Services',
        'Direct Debit',
        'Payment Gateway',
        'IVR and Call Masking',
        'Innovation FS'
      ],
      categories: [],
      selected_category_names: [],
      show_add_prospect: false,
      selected_prospect: null,
      add_prospect: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      }
    }
  },
  created: function() {
    this.getSuppliers()
  },
  methods: {
    setSupplierCategories(collection) {
      console.log(collection, 'Bahog lobot')
      this.selected.categories = []
      let self = this
      collection.forEach(function(category) {
        self.selected.categories.push(parseInt(category))
      })

      this.selected.categories = [...new Set(this.selected.categories)]
    },
    setAddProspect() {
      this.show_add_prospect = true
    },
    getCategoryString(category) {
      switch (category) {
        case 1:
          return 'Banking'
        case 2:
          return 'Merchant Services'
        case 3:
          return 'Direct Debit'
        case 4:
          return 'Payment Gateway'
        case 5:
          return 'IVR and Call Masking'
        case 6:
          return 'Innovation FS'
        default:
          return ''
      }
    },
    showBffRates(type) {
      this.$router.push({ path: type.id + '/bff_rates' })
    },
    showTransactionTypes(type) {
      this.$router.push({ path: type.id + '/transaction_types' })
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      Supplier.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getSuppliers()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing transaction type')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit ' + selected.name : 'New'
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    listCategoryNames(categories) {
      var list = []
      this.cat_string.forEach(function(n, index) {
        categories.forEach(function(cat) {
          if (cat === index) list.push(n)
        })
      })

      return list
    },
    showConcatinatedCategories(categories) {
      if (!categories) categories = []
      return this.listCategoryNames(categories).join(',')
    },
    showEdit(type) {
      this.reset()
      this.selected = Object.assign({}, type)
      this.original = Object.assign({}, type)
      this.selected_category_names = []

      var self = this
      if (!this.selected.categories) this.selected.categories = []
      this.selected.categories.forEach(function(i) {
        self.cat_string.forEach(function(n, index) {
          if (i === index) self.selected_category_names.push(n)
        })
      })

      if (this.selected.prospect)
        this.selected_prospect = this.selected.prospect.name
    },
    getSuppliers() {
      this.reset()
      this.isLoading = true
      this.suppliers = []
      Supplier.get()
        .then(result => {
          if (result.data.suppliers) this.suppliers = result.data.suppliers
          // this.meta = result.data.meta
          this.getProspects()
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching suppliers')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // Prospect functions
    getProspects() {
      Prospect.get()
        .then(result => {
          if (result.data.prospects) this.prospects = result.data.prospects
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching prospects')
          console.error('error:', error.response.data)
        })
    },
    // End Prospect functions
    validation(field) {
      switch (field) {
        case 'name':
          if (!this.selected.name) this.selected.name = ''
          return this.selected.name.length > 0
        case 'category':
          return this.selected.category > 0
        default:
          return true
      }
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'name') return 'Name is required'
      return ''
    },
    gatherCategories() {
      var list = []
      var self = this
      this.selected_category_names.forEach(function(name) {
        self.cat_string.forEach(function(n, index) {
          if (n === name) list.push(index)
        })
      })
      this.selected.categories = list
    },
    onSubmit(evt) {
      evt.preventDefault()
      console.log(this.selected.categories, 'gwapo ka')
      // this.gatherCategories()
      if (this.selected_prospect)
        this.selected.prospect = { name: this.selected_prospect }
      if (this.add_prospect)
        this.selected.prospect = { name: this.add_prospect }

      var action = this.selected.id
        ? Supplier.update(this.selected)
        : Supplier.create(this.selected)
      action
        .then(result => {
          this.$toast.success('Saved successfully')

          this.getSuppliers()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving transaction type')
          console.error('error:', error.response.data)
        })

      // if(this.add_prospect != null) {
      //   Prospect.create({name: this.add_prospect, supplier_id: this.selected.id});
      // } else {
      //   var self = this;
      //   var prosId = null;
      //   this.prospects.forEach(function (pros) {
      //     if (pros.name === self.selected_prospect) {
      //       prosId = pros.id;
      //   }})
      //   Prospect.update({id: prosId,supplier_id: self.selected.id});
      // }
    },
    onCancel(evt) {
      evt.preventDefault()
      this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
      this.selected_prospect = null
      this.add_prospect = null
      this.show_add_prospect = false
    },
    create() {
      this.original = { name: null }
      this.selected = Object.assign({}, this.original)
      this.selected_category_names = []
    },
    createDuplicate(supplier) {
      Supplier.createDuplicate(supplier.id)
        .then(result => {
          this.$toast.success('Duplicate Created successfully')
          this.getSuppliers()
        })
        .catch(error => {
          this.$toast.error('An error occured while duplicating supplier')
          console.error('error:', error.response.data)
        })
    }
  },
  computed: {
    availableOptions() {
      return this.cat_string.filter(
        opt => this.selected_category_names.indexOf(opt) === -1
      )
    },
    availableProspects() {
      var prospect_options = []
      this.prospects.forEach(function(prospect) {
        prospect_options.push(prospect.name)
      })

      return prospect_options
    },
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field) {
        if (!self.validation(field)) response = false
      })

      return response
    }
  }
}
</script>