import api from './api'

const API = '/api/firebase-notifications'

export const FirebaseNotifications = {
  get: function(params) {
    return api.get(API, params)
  },
  delete: function(params) {
    return api.delete(API + '/' + params.id)
  }
}
