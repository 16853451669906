<template>
  <div class="row" v-if="buyer_search_query">
    <div class="col-md-12" style="text-align: center;">
      <el-button @click="handleBack" plain type="primary" v-if="step > 1">BACK</el-button>
      <el-button @click="handleNext" plain type="primary" v-if="step <= 1">NEXT</el-button>
      <el-button @click="confirmSubmit" plain type="success" v-if="step == 2">SUBMIT </el-button>
      <hr>
    </div>
    <div class="col-md-6" v-if="step == 1">
      <granular-weighting-form :params="buyer_search_query.params"
        @update="updateParams($event)"></granular-weighting-form>
    </div>
    <div class="col-md-6" v-if="step == 1">
      <over-all-weighting-component :params="buyer_search_query.params" :granular_button="false" :overall_button="false"
        @update="updateParams($event)"></over-all-weighting-component>
    </div>
    <div class="col-md-6" v-if="step == 2">
      <buyer-additional-documents-list :buyer_search_query="buyer_search_query"
        @update="updateDocuments($event)"></buyer-additional-documents-list>
    </div>
    <div class="col-md-6" v-if="step == 2">
      <h5>Before restarting this final competition, kindly provide some notes or comments.</h5>
      <el-input v-model="params.additional_comments" maxlength="300" :rows="5" placeholder="Leave some notes"
        show-word-limit type="textarea" />
    </div>
  </div>
</template>

<script>
import OverAllWeightingComponent from '../../search_solutions/OverAllWeightingComponent.vue'
import BuyerAdditionalDocumentsList from '../BuyerAdditionalDocumentsList.vue'
import GranularWeightingForm from '../GranularWeightingForm.vue'
import Swal from 'sweetalert2'
import {
  BuyerSearchQuery
} from '@/api_services/buyer_search_query'

export default {
  components: {
    GranularWeightingForm,
    OverAllWeightingComponent,
    BuyerAdditionalDocumentsList
  },
  props: ['buyer_search_query'],
  data() {
    return {
      step: 1,
      params: null,
      additional_documents: []
    }
  },
  methods: {
    updateDocuments(query) {
      if (query) this.additional_documents = query.buyer_additional_documents
    },
    updateParams(params) {
      this.params = params
    },
    handleNext() {
      let total = parseFloat(this.params.product_match) + parseFloat(this.params.price) + parseFloat(this.params.social_value) + parseFloat(this.params.management_info)

      if (total > 100) {
        this.$toast.error('The total overall weighting exceeds 100%.')

        return false
      }
      this.step++
    },
    handleBack() {
      this.step--
    },
    async confirmSubmit() {

      const { value: res } = await Swal.fire({
        title: 'CPRAS Procurement Platform',
        html: `
              <div style="text-align:center" class="text-sm">
                <p>
                  Before restarting this competition, please be aware that:
                </p>
                  <ul>
                    <li>The final report generated will be labeled as the initial report.</li>
                    <li>Restarting the final request will archive all previously generated final reports for your review.</li>
                  </ul>
              
                <p>
                  If you have any questions or require further assistance, please contact our support team via the link on your Dashboard. We are here to assist you through every stage of the procurement progress.
                </p>
              </div>
            `,
        icon: 'warning',
        confirmButtonClass: 'btn btn-success',
        buttonsStyling: true,
        showCancelButton: false,
        showCancelButton: false,
        confirmButtonText: 'Confirm action'
      })

      if (res) this.handleSubmit()
    },
    async success() {
      // this.globalState.loading = false
      const { value: res } = await Swal.fire({
        title: 'CPRAS Procurement Platform',
        html: `
              <div style="text-align:center" class="text-sm">
                <p>
                  You have successfully restarted the procurement.
                  You will now be redirected to your ‘My Procurement Progress’ page where you can review the provisional report, monitor the progress of this search and upload any additional documents required by the suppliers to generate their most competitive final offering.
                </p>
              </div>
            `,
        icon: 'success',
        confirmButtonClass: 'btn btn-success',
        buttonsStyling: true,
        showCancelButton: false,
        showCancelButton: false,
        confirmButtonText: 'Ok'
      })

      this.$router.push({ path: '/main/product_search_queries/list'})

    },
    handleSubmit() {
      this.globalState.loading = true
      this.globalState.message = "Loading...."
      let form = this.prepareFormData()
      console.log(JSON.parse(form.get('params')))

      BuyerSearchQuery.reInitiateProcurement(form)
        .then(result => {
          this.success()
        })
        .catch(error => {
          this.globalState.loading = false
        })
        .finally(() => {
        
        })
    },
    prepareFormData() {
      let formData = new FormData()

      formData.append('id', this.buyer_search_query.id)

      this.additional_documents.forEach(function (document, index) {
        if (document.file) {
          formData.append('files[]', document.file)
          formData.append('file_names[]', document.file_name)
        } else {
          formData.append('additional_document_ids[]', document.id)
        }
      })

      formData.append('params', JSON.stringify(this.params))

      

      return formData
    }
  }
}
</script>
