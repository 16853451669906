<template>
  <div v-loading="loading">
    <el-form label-position="top" @submit.prevent="submit">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <el-form-item label="Type *" prop="type" :error="v.form.type.$errors.length ? 'Type is required' : ''">
              <el-select v-model="form.type" disabled>
                <el-option :value="0" label="Usage">Usage</el-option>
                <el-option :value="1" label="Setup">Setup</el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <el-form-item label="Description *" prop="description"
              :error="v.form.description.$errors.length ? 'Description is required' : ''">
              <el-input v-model="form.description"></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Transaction Volume</label>
            <br />
            <el-input type="number" v-model="form.transaction_volume"></el-input>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>PPT</label>
            <br />
            <el-input type="number" v-model="form.ppt"></el-input>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Transaction Value</label>
            <br />
            <el-input type="number" v-model="form.transaction_value"></el-input>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Rate</label>
            <br />
            <el-input type="number" v-model="form.rate_percent"></el-input>
          </div>
        </div>



        <div class="col-md-12">
          <div class="form-group">
            <label>Total Cost</label>
            <br />
            <el-input :readonly="true" v-model="form.cost"></el-input>
          </div>
        </div>

      </div>

      <hr />
      <div>
        <el-button native-type="submit" type="primary" plain>Save</el-button>
        <el-button @click="onCancel()" class="mr-2">Close</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>

import {
  useVuelidate
} from '@vuelidate/core'
import {
  required
} from '@vuelidate/validators'
import { ProcProdCostDetail } from '@/api_services/proc_prod_cost_detail'

export default {
  props: ['cost_detail'],
  data() {
    return {
      form: {},
      loading: false
    }
  },
  watch: {
    cost_detail: {
      deep: true,
      handler() {
        this.loading = false
        if (this.cost_detail) this.form = Object.assign({}, this.cost_detail)
      }
    },
    form: {
      deep: true,
      handler() {
        this.form.cost = (parseFloat(this.form.transaction_value) * parseFloat(this.form.ppt)) +
          parseFloat(this.form.transaction_value) * (parseFloat(this.form.rate_percent) / 100) || 0
      }
    }
  },
  setup: () => ({
    v: useVuelidate()
  }),
  validations() {
    return {
      form: {
        description: {
          required,
          $autoDirty: true
        },
        type: {
          required,
          $autoDirty: true
        }
      }
    }
  },
  created: function () {
    this.loading = false
    if (this.cost_detail) this.form = Object.assign({}, this.cost_detail)
  },
  methods: {
    async submit() {
      const result = await this.v.$validate()
      if (!result) return

      this.loading = true
      if (this.form.type == 'usage') this.form.type = 0
      if (this.form.type == 'setup') this.form.type = 1
      let request = this.form.id ? ProcProdCostDetail.update(this.form) : ProcProdCostDetail.create(this.form)

      request.then(result => {
        this.$toast.success("Cost detail saved")
        this.$emit('save', result.data.items)
      })
        .catch(error => { })
        .finally(() => {
          this.loading = false
        })

    },
    onCancel() {
      this.$emit('close', null)
    },
  },
}
</script>
