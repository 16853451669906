<template>
  <div v-if="product_search_result" v-loading="loading">

    <el-tabs v-model="active_tab" type="border-card" style="min-height: 500px">
      <el-tab-pane label="Specifications/Requirements" name="specifications"
        v-if="$store.state.isAllowed('PRODUCT_SPECIFICATION', 'VIEW')">
        <div v-if="active_tab == 'specifications'">
          <div>
            <div v-if="has_generated_proc_prod_specifications">
              <proc-prod-specifications-list
                :product_search_result="product_search_result"></proc-prod-specifications-list>
            </div>
            <div v-else>
              Product specifications for this item have not been generated yet. Click the button to generate them if you
              wish
              to modify the response.
              <br>
              <el-button size="small" plain type="primary" @click="handleGenerateProdSpecs">GENERATE</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Social Values" name="social_values"
        v-if="$store.state.isAllowed('PRODUCT_SOCIAL_VALUE', 'VIEW')">
        <div v-if="active_tab == 'social_values'">
          <div>
            <div v-if="has_generated_proc_prod_social_values">
              <proc-prod-social-values-list
                :product_search_result="product_search_result"></proc-prod-social-values-list>
            </div>
            <div v-else>
              Product social values for this item have not been generated yet. Click the button to generate them if you
              wish
              to modify the response.
              <br>
              <el-button size="small" plain type="primary" @click="handleGenerateProdSocVal">GENERATE</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Pricing" name="pricing" v-if="$store.state.isAllowed('PRODUCT_PRICING', 'VIEW')">
        <div v-if="active_tab == 'pricing'">
          <div>
            <div v-if="has_generated_proc_prod_price_costs">
              <proc-prod-price-costs-list :product_search_result="product_search_result"></proc-prod-price-costs-list>
  
            </div>
            <div v-else>
              Pricing for this item have not been generated yet. Click the button to generate them if you
              wish
              to modify the response.
              <br>
              <el-button size="small" plain type="primary" @click="handleGenerateProdPriceCosts">GENERATE</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>


  </div>
</template>

<script>

import {
  ProductSearchResult
} from '@/api_services/product_search_result'
import ProcProdSpecificationsList from './ProcProdSpecifications/ProcProdSpecificationsList.vue'
import ProcProdSocialValuesList from './ProcProdSocialValues/ProcProdSocialValuesList.vue'
import ProcProdPriceCostsList from './ProcProdPriceCosts/ProcProdPriceCostsList.vue'

export default {
  components: { ProcProdSpecificationsList, ProcProdSocialValuesList, ProcProdPriceCostsList },
  props: ['product_search_result'],
  data() {
    return {
      has_generated_proc_prod_specifications: false,
      has_generated_proc_prod_social_values: false,
      has_generated_proc_prod_price_costs: false,
      loading: false,
      active_tab: 'specifications'
    }
  },
  watch: {
    product_search_result: function () {
      if (this.product_search_result) {
        this.has_generated_proc_prod_specifications = this.product_search_result.has_generated_proc_prod_specifications
        this.has_generated_proc_prod_social_values = this.product_search_result.has_generated_proc_prod_social_values
        this.has_generated_proc_prod_price_costs = this.product_search_result.has_generated_proc_prod_price_costs
      }
    }
  },
  created: function () {
    if (this.product_search_result) {
      this.has_generated_proc_prod_specifications = this.product_search_result.has_generated_proc_prod_specifications
      this.has_generated_proc_prod_social_values = this.product_search_result.has_generated_proc_prod_social_values
      this.has_generated_proc_prod_price_costs = this.product_search_result.has_generated_proc_prod_price_costs
    }
  },
  methods: {
    handleGenerateProdPriceCosts() {
      this.loading = true
      ProductSearchResult.generateProcProdPriceCosts(this.product_search_result.id)
        .then(result => {
          this.has_generated_proc_prod_price_costs = true
        })
        .catch(error => {
          this.$toast.error('An error occured while generating')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleGenerateProdSocVal() {
      this.loading = true
      ProductSearchResult.generateProcProdSocialValues(this.product_search_result.id)
        .then(result => {
          this.has_generated_proc_prod_social_values = true
        })
        .catch(error => {
          this.$toast.error('An error occured while generating')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleGenerateProdSpecs() {
      this.loading = true
      ProductSearchResult.generateProcProdSpecs(this.product_search_result.id)
        .then(result => {
          this.has_generated_proc_prod_specifications = true
        })
        .catch(error => {
          this.$toast.error('An error occured while generating')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>

</style>
