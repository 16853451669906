<template>
  <div class="card my-3">
    <div class="card-header">
      Bidding Model
    </div>
    <div class="card-body" style="text-align: left;">
      <form @submit="save" >
        <div class="row">

          <div class="col-md-12">
            
            <div class="form-group" id="are_you_lead_contact">
              <label for="input-are_you_lead_contact">Are you bidding as the lead contact for a group of economic operators?</label>
              <div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radio-yes" v-model="form.are_you_lead_contact" name="some-are_you_lead_contact" :value="true">
                  <label class="form-check-label" for="radio-yes">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radio-no" v-model="form.are_you_lead_contact" name="some-are_you_lead_contact" :value="false">
                  <label class="form-check-label" for="radio-no">No</label>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-12">
            
            <div class="form-group">
              <label class="form-label">Name of group of economic operators</label>
              <input type="text" id="name_of_group_economic_operators" 
              v-model="form.name_of_group_economic_operators" trim 
              :class="{ 'is-invalid': v$.form.name_of_group_economic_operators.$errors.length, 'form-control': true }">

              <div class="input-errors" v-for="error of v$.form.name_of_group_economic_operators.$errors" :key="error.$uid">
                <div class="error-text">Name {{ error.$message }}</div>
              </div>
            </div>

          </div>
          

          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Proposed legal structure if the group of economic operators intends to form a named single legal entity prior to signing a contract, if awarded. If you do not propose to form a single legal entity, please explain the legal structure.</label>
              <input type="text" id="proposed_legal_structure" 
              v-model="form.proposed_legal_structure" trim 
              :class="{ 'is-invalid': v$.form.proposed_legal_structure.$errors.length, 'form-control': true }">

              <div class="input-errors" v-for="error of v$.form.proposed_legal_structure.$errors" :key="error.$uid">
                <div class="error-text">Proposed legal structure {{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            
            <div class="form-group" id="are_you_using_subcontractor">
              <label for="input-are_you_using_subcontractor">Are you or, if applicable, the group of economic operators proposing to use sub-contractors?</label>
              <div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radio-yes2" v-model="form.are_you_using_subcontractor" name="some-are_you_using_subcontractor" :value="true">
                  <label class="form-check-label" for="radio-yes2">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="radio-no2" v-model="form.are_you_using_subcontractor" name="some-are_you_using_subcontractor" :value="false">
                  <label class="form-check-label" for="radio-no2">No</label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="form.are_you_using_subcontractor" class="col-md-12">
            <hr>
            <h5 class="col-md-12">Subcontractors (Should have at least one subcontractor)</h5>
            <div class="col-md-12" v-if="loaded_subcons">
              <div v-for="(subcontractor,i) in form.subcontractors" :key="i" >
                <!-- <b-link @click="showSubcontractorModal(subcontractor,i)">{{subcontractor.name}}</b-link> -->
                <a href="#" @click="showSubcontractorModal(subcontractor, i)">{{ subcontractor.name }}</a>
              </div>
            </div>
            <div> 
              <!-- <b-link @click="showSubcontractorModal(null,null)" >+ Add</b-link>  -->
              <a href="#" @click="showSubcontractorModal(null, null)">+ Add</a>
            </div>
          </div>

        </div>

        <div style="text-align:center">
          <!-- <b-button type="button" variant="info"  :disabled="!validateAll" @click="save($event)">Save</b-button>
          <b-button type="button"  @click="close()" v-if="editing" style="margin-left: 10px">Close</b-button> -->
          <button type="submit" class="btn btn-info" >Save</button>
          <button type="button" class="btn" @click="close()" v-if="editing" style="margin-left: 10px">Close</button>
        </div>
      </form>
      
    </div>

    <!-- <b-modal
      id="modal-subcontractor"
      title="Attention"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      :hide-footer="true"
    >
      <supplier-subcontractor-component :selected="subcontractor" @save="saveSubcontractor($event, null)"></supplier-subcontractor-component>
    </b-modal> -->

    <div class="modal fade" id="modal-subcontractor" tabindex="-1" aria-labelledby="modal-subcontractor-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-dark text-light">
            <h5 class="modal-title" id="modal-subcontractor-label">Attention</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <supplier-subcontractor-component :key="subcontractor" :selected="subcontractor" @save="saveSubcontractor($event, null)"></supplier-subcontractor-component>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>


import { ProductSupplier } from '@/api_services/supplier'
import SupplierSubcontractorComponent from './SupplierSubcontractorComponent.vue';
import $ from 'jquery'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  setup () {
      return { v$: useVuelidate({$scope: false}) }
    },
  components: { SupplierSubcontractorComponent },
  props: ['selected','editing'],
  data() {
    return {
      form: {
        'are_you_lead_contact':false,'name_of_group_economic_operators':null,'proposed_legal_structure':null, 'are_you_using_subcontractor':false
      },
      subcontractor: null,
      loaded_subcons: true,
      subcon_index: null
    }
  },
  validations() {
      return {
        form: {
          name_of_group_economic_operators: {
            required,
            $autoDirty: true
          },
          proposed_legal_structure: {
            required,
            $autoDirty: true
          }
        },
      }
    },
  watch: {
    selected() {
      if (this.selected) this.form = Object.assign({}, this.selected);
      if (!this.form.subcontractors) this.form.subcontractors = []
    }
  },
  created: function() {
    if (this.selected) this.form = Object.assign({}, this.selected);
    if (!this.form.subcontractors) this.form.subcontractors = []
  },
  methods: {
    saveSubcontractor(subcontractor) {
      let self = this
      if (subcontractor) {
        let index = this.subcon_index
        if (index == null) {
          this.form.subcontractors.push(subcontractor)
        } else {
          this.form.subcontractors.forEach(function(sub,i){
              if (index == i){
                self.form.subcontractors[i] = subcontractor
              }
          })
        }
        this.loaded_subcons = false

        setTimeout(function(){
            self.loaded_subcons = true
         }, 1000);
      }

      this.subcontractor = null
      this.subcon_index = null
      //this.$bvModal.hide('modal-subcontractor')
      $("#modal-subcontractor").modal("hide")
    },
    showSubcontractorModal(subcontractor,i) {
      // this.$bvModal.show('modal-subcontractor')
      const modalElement = document.getElementById('modal-subcontractor');
      const modal = new bootstrap.Modal(modalElement);
      modal.show();

      if (subcontractor) this.subcontractor = Object.assign({}, subcontractor);
      this.subcon_index = i
    },
    invalidFeedback(field) {
      return ""
    },
    close() {
      this.$emit('save', null)
      $("#modal-subcontractor").modal("hide")
    },
    async save(evt) {
      
      evt.preventDefault()

      const result = await this.v$.$validate()
      if (!result) return

    
      ProductSupplier.saveSupplierBiddingModel(this.form)
        .then(result => {
          this.$toast.success('Saved successfully')
          this.$emit('save', result.data.supplier)
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a user')
          console.error('error:', error.response.data)
        })
        $("#modal-subcontractor").modal("hide")
    },
    validation(field) {
      switch(field) {
        case 'are_you_lead_contact':
          return this.form.are_you_lead_contact != null;
        case 'proposed_legal_structure':
          return this.$store.state.isStringValid(this.form.proposed_legal_structure);
        case 'are_you_using_subcontractor':
          return this.form.are_you_using_subcontractor != null;
        default:
          return true;
      }
    },
  },
  computed: {
    validateAll() {
      var self = this
      var response = true

      this.validate.forEach(function(field){
        if (!self.validation(field)) {
           response = false;
        }
      })

      if ( this.form.are_you_using_subcontractor && ( (!this.form.subcontractors) || ( this.form.subcontractors && !this.form.subcontractors.length ) )) {
        response = false;
      }

      return response;
    }
  }
}
</script>
