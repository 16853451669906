import api from './api'

const API = '/api/company-facas'

export const CompanyFaca = {
  get: function(params) {
    return api.get(API, params)
  },
  update: function(id, params) {
    return api.update(API+'/'+id, params)
  }
}
