import api from './api'

const API = '/api/my_pipeline'

export const MyPipeline = {
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(id, params) {
    return api.update(API+'/'+id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  import: function(params) {
    return api.createFormData(API + "/import", params)
  },
  export: function(type, params) {
    return api.getBlob(API+"/export/" + type, params)
  },
}
