<template>
  <div class="">
     
     <div  v-if="$store.state.isViewable('comparison_tool')">
      <HomeDashboard />
    </div>
    <not-allowed v-else></not-allowed>
  </div>
</template>

<script>
import HomeDashboard from '@/views/HomeDashboard'

  export default {
    data() {
      return {
        
      }
    },
    components: { HomeDashboard },
  
  }
</script>

<style scoped>
  .custom-tag {
      font-size: 12px;
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      padding: 5px;
      border-radius: 8px;
      margin: 3px;
  }
</style>