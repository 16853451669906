
<template>
  <div>
    <section id="cookie-banner" style="display: inline; transition: opacity 1s ease-out 0s; opacity: 1;" v-if="show_cookie">
        <div class="container">
          <div class="row">
              <div class="col-md-11">
                    <p style="margin-bottom: 15px;margin-top: 0px">
                        CPRAS uses cookies which help us know how you use our website. These cookies help us improve your browsing experience. To continue using our website, you agree and accept our use of cookies.
                    </p>
              </div>
              <div class="col-md-1">
                  <a id="myBtn" class="btn btn-primary btn-sm" style="color: #fff;text-align: center;" @click="acceptCookie()">Accept</a>
              </div>
          </div>
        </div>
    </section>
  </div>

</template>

<script>

export default {
    name: 'CookieComponent',
    data() {
        return {
          show_cookie: false
        }
    },
    created: function() {
      this.show_cookie = !this.$cookies.get("accept")
    },
    methods: {
      acceptCookie() {
        var date = new Date;
        date.setDate(date.getDate() + 7);
        this.$cookies.set('accept',true, date);
        this.show_cookie = false
      }
    }
}
</script>

<style lang="css" scoped>

  #cookie-banner {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 110px;
    z-index: 888;
    left: 0px;
    background: #000000bf;
    color: #f3f3f3;
    padding-top: 20px;
}
</style>
