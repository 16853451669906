<template>
    <div v-if="form" class="">
      <div class="pb-0 card-header">
        <h5 class="font-weight-bolder mb-0">{{title}}</h5>
      </div>
      <div class="card-body">
        <el-switch
        v-if="form.id"
        v-model="enable_test_status"
        size="large"
        active-text="Enable Manual PIN Activation"
        inactive-text="Disable Manual PIN Activation"
      />
      <br/>
      <!-- <div v-if="selected.id">
        <el-button class="btn btn-success" @click="tenderModal = true">Publish PIN in Find a Tender</el-button>
      </div> -->
      <br>
        <form @submit.prevent.prevent="onSubmit" @reset.prevent="onCancel">
          
          <!--<el-alert
            v-if="form.status == ProcurementProjectStatuses.CODE_PENDING"
            class="mb-4 "
            title="Urgent Action Required!"
            type="warning"
            description="To proceed with your procurement project, it's necessary to 
            Submit a Pin Notice. Please do so by navigating to PIN page under Procurement, find your project and
            click the `Submit PIN Notice` button.
            Your prompt attention to this matter is greatly appreciated."
            show-icon
            :closable="false"
          />-->

          <el-alert
            v-if="form.status == ProcurementProjectStatuses.CODE_PENDING"
            class="mb-4 "
            title="Urgent Action Required!"
            type="warning"
            description="To proceed with your procurement project, it's necessary to 
            Publish a Pin Notice. Please do so by clicking the `Publish PIN Noice` button.
            Your prompt attention to this matter is greatly appreciated."
            show-icon
            :closable="false"
          />

          <div v-if="!isNew" class="row mb-3">
            <div class="col-md-6">
              <p><i class="fa fa-info-circle text-primary"></i>&nbsp;Status: <b>{{ form.status_name }}</b></p>
            </div>
            <div class="col-md-6">
              <!-- @click="sendPublishRequest"  -->
              <el-button 
              v-if="selected.status != ProcurementProjectStatuses.CODE_PENDING" 
              @click="tenderModal = true"
              type="primary"
              class="float-end"
               >
                <i class="fa fa-upload"></i>&nbsp;{{ selected.status == ProcurementProjectStatuses.CODE_PRE_PIN ? 'Publish' : 'Check published' }} PIN Notice</el-button>

              <el-button 
              v-if="selected.status == ProcurementProjectStatuses.CODE_PENDING"
              @click="setPrePINStatus"
              type="warning"
              class="float-end"
               >
                <i class="fa fa-upload"></i>&nbsp; Initiate Pre-PIN Phase</el-button>
            </div>

          </div>

          <h6>Procurement Project Form</h6>
        <div class="form-group">
            <label class="form-label">Type</label>
            <select 
            :class="{ 'is-invalid': v$.form.type.$errors.length, 'form-control form-control-sm': true }"
            v-model="form.type" >
                  <option v-for="procurement_type in procurement_types" :value="procurement_type.type_code">{{procurement_type.type}}</option>
            </select>
            <div class="input-errors" v-for="error of v$.form.type.$errors" :key="error.$uid">
              <div class="error-text">Select Type</div>
            </div>
          </div>
  
          <div class="form-group">
            <label class="form-label">Name</label>
            <input type="text" v-model.trim="form.name"
            :class="{ 'is-invalid': v$.form.name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
              <div class="error-text">Name {{ error.$message }}</div>
            </div>
          </div>
  
          <div class="form-group">
            <label class="form-label">Description</label>
            <textarea v-model.trim="form.description"
            :class="{ 'is-invalid': v$.form.description.$errors.length, 'form-control form-control-sm': true }"></textarea>
  
            <div class="input-errors" v-for="error of v$.form.description.$errors" :key="error.$uid">
              <div class="error-text">Description {{ error.$message }}</div>
            </div>
          </div>

          <div v-if="enable_test_status" class="form-group">
            <label class="form-label">Status</label>
            <select v-if="enable_test_status"
                class="form-control form-control-sm"
                v-model="form.status" >
                      <option value="ATC">Activate PIN</option>
                      <option value="ITT">Activate ITT</option>
                </select>
          </div>
          <br>

          <div v-if="isNew">
          <h6>Description Form</h6>

          <div class="form-group">
            <label>Main Description *</label>
            <div :class="{'has-error-field': v$.form.main_description.$errors.length  }">
              <QuillEditor theme="snow" v-model:content="form.main_description" contentType="html" />
            </div>
            <div v-if="v$.form.main_description.$errors.length" class="error-text">Description is required</div>
          </div>
          <div class="form-group">
            <label>Common Files Description *</label>
            <div :class="{ 'has-error-field': v$.form.common_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.common_description" contentType="html" />
            </div>
            <div v-if="v$.form.common_description.$errors.length" class="error-text">Common files description is required</div>
          </div>
          <div class="form-group">
            <label>My Files Description *</label>
            <div :class="{ 'has-error-field': v$.form.my_files_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.my_files_description" contentType="html" />
            </div>
            <div v-if="v$.form.my_files_description.$errors.length" class="error-text">My files description is required</div>
          </div>
          <div class="form-group">
            <label>Q&A Description *</label>
            <div :class="{ 'has-error-field': v$.form.qa_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.qa_description" contentType="html" />
            </div>
            <div v-if="v$.form.qa_description.$errors.length" class="error-text">Q&A description is required</div>
          </div>
          <div class="form-group">
            <label>My Questions Description *</label>
            <div :class="{ 'has-error-field': v$.form.my_questions_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.my_questions_description" contentType="html" />
            </div>
            <div v-if="v$.form.my_questions_description.$errors.length" class="error-text">My question description is required</div>
          </div>
          <div class="form-group">
            <label>Spec Description *</label>
            <div :class="{ 'has-error-field': v$.form.spec_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.spec_description" contentType="html" />
            </div>
            <div v-if="v$.form.spec_description.$errors.length" class="error-text">Spec description is required</div>
          </div>
        </div>
  
          <div class="pt-4 text-end form-buttons">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="submit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>

    <el-dialog
      v-model="tenderModal" 
      title="Find a tender form"
      width="100%"
      @close="tenderModal = false"
    >
    <div>
      <tender-form :procurement="selected" @close-dialog="handleCloseDialog"></tender-form>
    </div>
  </el-dialog>

    
  </template>
  
  <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects';
    import { useVuelidate } from '@vuelidate/core'
    import { required, requiredIf } from '@vuelidate/validators'
    import { mapState } from 'vuex'
    import TenderForm from '@/components/pages/find_a_tender/TenderFormComponent'
    import Swal from 'sweetalert2';

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: Object
      },
      components: {
        TenderForm
      },
      data() {
        return {
          tenderModal: false,
          enable_test_status: false,
          pin_modal: false,
          pin_notice: null,
          procurement_types: null,
          isSaving: false,
          form:{
            type: null,
            name: null,
            description: null,
            main_description: null,
            common_description: null,
            my_files_description: null,
            qa_description: null,
            my_questions_description: null,
            spec_description: null
          }
        }
      },
      validations() {
        return {
          form: {
            type: {
              required,
              $autoDirty: true
            },
            name: {
              required,
              $autoDirty: true
            },
            description: {
              required,
              $autoDirty: true
            },
            spec_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            my_questions_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            qa_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            my_files_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            main_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            common_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
          },
        }
      },
      created: function () {
        this.form = JSON.parse(JSON.stringify(this.selected))
        this.getProcurementProjectTypes()
        console.log('selected', this.selected)
      },
      watch: {
        selected(selected) {
          this.form = JSON.parse(JSON.stringify(selected))
        },
        'form.main_description': function () {
          if (this.form.main_description == '<p><br></p>') this.form.main_description = null
        },
        'form.common_description': function () {
          if (this.form.common_description == '<p><br></p>') this.form.common_description = null
        },
        'form.my_files_description': function () {
          if (this.form.my_files_description == '<p><br></p>') this.form.my_files_description = null
        },
        'form.qa_description': function () {
          if (this.form.qa_description == '<p><br></p>') this.form.qa_description = null
        },
        'form.my_questions_description': function () {
          if (this.form.my_questions_description == '<p><br></p>') this.form.my_questions_description = null
        },
        'form.spec_description': function () {
          if (this.form.spec_description == '<p><br></p>') this.form.spec_description = null
        }
      },
      computed: {
        title() {
          return this.isNew ? 'Add New' : `Manage Project`
        },
        isNew() {
          return !this.selected?.id
        },
        ...mapState(['ProcurementProjectStatuses'])
      },
      methods: {
        setPrePINStatus(){
          Swal.fire({
            title: 'Initiate Pre-PIN Phase?',
            text: 'By clicking continue, you\'re updating this project into pre-PIN phase. Do you want to proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true

              this.form.status = this.ProcurementProjectStatuses.CODE_PRE_PIN

              ProcurementProjects.update(this.selected.id, this.form)
              .then(result => {
                this.$toast.success('Successfully updated to Pre-PIN Phase')
                this.$emit('save', this.form)
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
              
            }
          });
        },
        getProcurementProjectTypes(){
            ProcurementProjects.getProcurementProjectTypes()
            .then(resp => {
                this.procurement_types = resp.data.procurement_project_types
            })
            .catch(error => {
                this.$toast.error('An error occured while getting procurement project types')
                console.error('error:', error.response.data)
            })
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return
  
          this.isSaving = true
          const promise = this.isNew ? ProcurementProjects.create(this.form) : ProcurementProjects.update(this.selected.id, this.form)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Procurement Project has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
        handleCloseDialog() {
          var self = this
          this.tenderModal = false;
          this.onCancel()
          setTimeout(() => {self.$emit("reload")}, 5000);
        }
      }
    }
  </script>
  